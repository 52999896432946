import { Modal, ModalBody, ModalContent, ModalOverlay, Box } from "@chakra-ui/react";
import { FC, useState } from "react";
import { PostTransferWalletBody } from "../../../api/types/postTransferWalletType";
import useAppHook from "../../../hooks/useAppHook";
import useWalletHook from "../../../hooks/useWalletHook";
import { Alert, AlertType } from "../../../types/Alert";
import { ModalProps } from "../../../types/ModalProps";
import { MyWallet } from "../../../types/MyWallet";
import ConfirmPassword from "../ConfirmPassword";
import TransferInput, { TransferInputValue } from "./TransferInput";
import TransferReview from "./TransferReview";

enum TransferWalletModalBody {
  TRANSFER,
  REVIEW,
  CONFIRM,
}

type TransferWalletModalContainerProps = {
  currentWallet: MyWallet;
  balance?: string;
} & ModalProps;

const TransferWalletModalContainer: FC<TransferWalletModalContainerProps> = (
  props
) => {
  const { isOpen, onClose, currentWallet, balance } = props;
  const [transferValue, setTransferValue] = useState<TransferInputValue>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>("NEW")
  const [modalBody, setModalBody] = useState<TransferWalletModalBody>(
    TransferWalletModalBody.TRANSFER
  );

  const { postTransferWalletHook, getTransactionTokenHook } = useWalletHook();
  const { setAlert } = useAppHook();

  const handleOnTransferBack = () => {
    onClose();
    clearState();
  };
  const handleOnTransferSubmit = (val: TransferInputValue) => {
    setTransferValue(val);
    setSelectedCurrency(val.currency);
    setModalBody(TransferWalletModalBody.REVIEW);
  };

  const handleReviewBack = () => {
    setModalBody(TransferWalletModalBody.TRANSFER);
  };
  const handleOnReviewConfirm = () => {
    setModalBody(TransferWalletModalBody.CONFIRM);
  };

  const handlePasswordBack = () => {
    setModalBody(TransferWalletModalBody.REVIEW);
  };
  const handleOnPasswordConfirm = async (val: string) => {
    const body: PostTransferWalletBody = {
      from_wallet_id: currentWallet.id,
      to_wallet_id: transferValue?.destination.id!,
      amount: Number.parseFloat(transferValue?.amount!),
      password: val,
      currency: transferValue?.currency ?? "NEW",
    };
    const response = await postTransferWalletHook(body);
    let alert: Alert = {
      visible: true,
    };
    if (response?.data.statusCode === 200) {
      onClose();
      clearState();
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your transfer was completed successfully.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
        },
      };
      getTransactionTokenHook();
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your transfer was failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      };
    }
    setAlert(alert);
  };

  const clearState = () => {
    setTransferValue(undefined);
    setModalBody(TransferWalletModalBody.TRANSFER);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Box
            display={
              modalBody === TransferWalletModalBody.TRANSFER ? "block" : "none"
            }
          >
            <TransferInput
              transferValue={transferValue}
              currentWallet={currentWallet}
              unit={selectedCurrency}
              balance={balance}
              onClickTransfer={handleOnTransferSubmit}
              onClickBack={handleOnTransferBack}
            />
          </Box>
          {modalBody === TransferWalletModalBody.REVIEW && (
            <TransferReview
              transferValue={transferValue}
              currentWallet={currentWallet}
              unit={selectedCurrency}
              onClickConfirm={handleOnReviewConfirm}
              onClickBack={handleReviewBack}
            />
          )}
          {modalBody === TransferWalletModalBody.CONFIRM && (
            <ConfirmPassword
              header="Confirm"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TransferWalletModalContainer;
