// ** Libs **
import { Grid, GridItem, Text } from "@chakra-ui/react"

const ParticipantDetailItem = ({
  label,
  value,
  align = "left",
}: {
  label: string
  value: string
  align?: "left" | "right"
}) => {
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      py={4}
      borderBottom="1px solid"
      borderColor="emptyColor"
    >
      <GridItem>
        <Text fontWeight="bold" color="secondaryTextColor">
          {label}
        </Text>
      </GridItem>
      <GridItem>
        <Text fontWeight="bold" textAlign={align}>
          {value}
        </Text>
      </GridItem>
    </Grid>
  )
}

export default ParticipantDetailItem
