import { AxiosResponse } from "axios"
import $axios from "./instance"
import { GetAcceptedInvoicesOfOffererAndGuaranteeResponse } from "./types/getAcceptedGuaranteedInvoicesType"

const getAcceptedInvoicesOfOffererAndGuarantee = async (): Promise<
  AxiosResponse<GetAcceptedInvoicesOfOffererAndGuaranteeResponse>
> => {
  const request = await $axios().get(
    "/get_accepted_invoices_of_offerer_and_guarantee"
  )
  return request
}

export default getAcceptedInvoicesOfOffererAndGuarantee
