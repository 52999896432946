// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Flex, Box, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { NftsDetail } from "modules/token_management/admin"

// ** Constants **
import { TIME_FRAME } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useTokenManagementHook from "hooks/useTokenManagementHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  capitalizeFirstLetter,
  formatDateTimeStamp,
  removeDuplicatesFields,
  filterDataByTimeframe,
  numberFormat,
} from "common/utils/transform"

// ** Types **
import { InvoiceWithHolder } from "types/TokenManagement"

const NftsTab = () => {
  const modalDetail = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("All Status")
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<InvoiceWithHolder[]>([])
  const [invoiceData, setInvoiceData] = useState<InvoiceWithHolder>()

  const { getInvoiceWithHolderHook, invoiceWithHolder } =
    useTokenManagementHook()

  const { isLoading } = useQuery({
    queryKey: "getInvoiceWithHolderHook",
    queryFn: getInvoiceWithHolderHook,
  })

  useEffect(() => {
    setData(invoiceWithHolder)
  }, [invoiceWithHolder])

  const onRowClick = (data: InvoiceWithHolder) => {
    setInvoiceData(data)
    modalDetail.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: InvoiceWithHolder[] = invoiceWithHolder

    if (trxType !== "All Status") {
      temp = temp.filter((m) => m.status === trxType.toLocaleLowerCase())
    }

    temp = removeDuplicatesFields(temp, [
      "id",
      "holder_wallet_address",
      "seller_wallet_address",
      "buyer_wallet_address",
      "status",
    ])
    temp = filterDataByTimeframe(temp, timeFrame, "updated_at")
    temp.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )

    const filterValue: InvoiceWithHolder[] = temp.filter((m) =>
      (
        m.doc_number +
        m.holder_company_name +
        m.holder_wallet_nickname +
        m.holder_wallet_address +
        m.seller_company_name +
        m.seller_wallet_nickname +
        m.seller_wallet_address
      )
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, invoiceWithHolder, trxType, timeFrame])

  if (isLoading) return <></>

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const tyxTypeOpts = [
    { title: "All Status", value: "All Status" },
    ...Array.from(new Set(invoiceWithHolder.map((item) => item.status))).map(
      (m) => ({ title: capitalizeFirstLetter(m), value: m })
    ),
  ]

  const column = [
    {
      title: "DocType",
      value: "doc_type",
      customRender: (key: string, value: any) => <Text>Invoice</Text>,
    },
    {
      title: "DocNo.",
      value: "doc_no",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{value.doc_number}</Text>
      ),
    },
    {
      title: "Holder name",
      value: "holder_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>
          {value.holder_company_name} -{" "}
          {capitalizeFirstLetter(value.holder_wallet_nickname)}(
          {abbreviateHexString(value.holder_wallet_address)})
        </Text>
      ),
    },
    {
      title: "Holder type",
      value: "holder_type",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.holder_type_name)}</Text>
      ),
    },
    {
      title: "Owner name",
      value: "owner_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>
          {value.seller_company_name} -{" "}
          {capitalizeFirstLetter(value.seller_wallet_nickname)}(
          {abbreviateHexString(value.seller_wallet_address)})
        </Text>
      ),
    },
    {
      title: "Owner type",
      value: "owner_type",
      customRender: (key: string, value: any) => <Text>Corporates</Text>,
    },
    {
      title: "Amount (NEW)",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.amount)}</Text>
      ),
    },
    {
      title: "Time Stamp",
      value: "updated_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.updated_at)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box onClick={() => onRowClick(value)} cursor="pointer">
          View
        </Box>
      ),
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <Flex>
        <Box
          border="1px solid #EDEDED"
          borderRadius="16px"
          p="24px"
          textAlign="center"
        >
          <Flex alignItems="center">
            <Text fontSize="24px" fontWeight="bold">
              {numberFormat(`${data.length}`)}
            </Text>
            <Text fontSize="16px" fontWeight="bold" ml="16px">
              Total NFTs
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Text fontSize="20px" fontWeight="bold" mt={4}>
        NFTs Holder List
      </Text>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Holder Name / Owner name"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      <NftsDetail
        {...modalDetail}
        data={invoiceData}
        onClickClose={() => modalDetail.onClose()}
      />
    </Box>
  )
}

export default NftsTab
