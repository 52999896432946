// ** Libs **
import { Box } from "@chakra-ui/react"

const BoxPercent = ({
  label,
  isActive,
}: {
  label: string
  isActive?: boolean
}) => {
  return (
    <Box
      textAlign="center"
      border="1px solid"
      borderColor="primary"
      borderRadius={4}
      bgColor={isActive ? "#0050C8" : "white"}
      color={isActive ? "white" : "#0050C8"}
      cursor="pointer"
      py={1}
    >
      {label}
    </Box>
  )
}
export default BoxPercent
