// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Card, Box, Flex } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import WalletSelect from "common/components/WalletSelect"
import {
  Dashboard,
  MainTab,
} from "modules/discounting"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"
import useQueryString from "hooks/useQueryString"

// ** Types **
import { MyWallet } from "types/MyWallet"

const DiscountingPage = () => {
  const tab = useQueryString("tab")

  const [tabIndex, setTabIndex] = useState(0)

  const { getCompanyWalletHook, myWallet } = useWalletHook()
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()

  const { isLoading } = useQuery({
    queryKey: "myWalletPageInit",
    queryFn: getCompanyWalletHook,
  })

  useEffect(() => {
    if (tab !== null) {
      setTabIndex(parseInt(tab))
    }
  }, [tab])

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoading || !selectedWallet) return <></>

  return (
    <Card p={6}>
      <Flex justifyContent="space-between">
        <Box w={800}>
          <TabCustom
            tabIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
            tabOpt={[
              {
                label: "Dashboard",
                icon: null,
                activeIcon: null,
                active: tabIndex === 0,
              },
              {
                label: "Financing",
                icon: null,
                activeIcon: null,
                active: tabIndex === 1,
              },
              {
                label: "Buyer Prepayment",
                icon: null,
                activeIcon: null,
                active: tabIndex === 2,
              },
              {
                label: "Guaranteed Payment",
                icon: null,
                activeIcon: null,
                active: tabIndex === 3,
              },
            ]}
          />
        </Box>
        <Box w={300}>
          <WalletSelect
            wallets={myWallet}
            onChange={(value) => setSelectedWallet(value)}
            value={selectedWallet}
          />
        </Box>
      </Flex>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <Dashboard setTabIndex={setTabIndex} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <MainTab option={1} />
      </Box>
      <Box display={tabIndex === 2 ? "block" : "none"}>
        <MainTab option={2} />
      </Box>
      <Box display={tabIndex === 3 ? "block" : "none"}>
        <MainTab option={3} />
      </Box>
    </Card>
  )
}

export default DiscountingPage
