// ** React **
import { FC, useState, useEffect } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { CompanyWallet, WalletPutBody } from "types/Participant"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { FormWithConfirmPasswordBody } from "constants/Enum"

type ParticipantDetailEditModalProps = {
  wallet: CompanyWallet
} & ModalProps

const FreezeWalletModal: FC<ParticipantDetailEditModalProps> = (props) => {
  const { isOpen, onClose, wallet } = props
  const { setAlert } = useAppHook()

  const [contentBody, setContentBody] = useState<FormWithConfirmPasswordBody>(
    FormWithConfirmPasswordBody.PASSWORD
  )

  const { postFreezeWalletHook, participantDetail } = useParticipantHook()

  const { control, handleSubmit, setValue, reset } = useForm<WalletPutBody>({
    defaultValues: {
      id: wallet.id,
    },
    mode: "onChange",
  })

  useEffect(() => {
    if (wallet) {
      reset({
        id: wallet.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  const onSubmit: SubmitHandler<WalletPutBody> = async (data) => {
    if (participantDetail) {
      const response = await postFreezeWalletHook(participantDetail.pt_id, data)
      let alert: Alert = { visible: true }
      if (response.statusCode === 200) {
        alert.data = {
          type: AlertType.SUCCESS,
          description: "This bank account has been frozen.",
          button: {
            label: "Close",
          },
        }
      } else {
        alert.data = {
          type: AlertType.ERROR,
          description: response.message,
          button: {
            label: "Close",
          },
        }
      }
      setAlert(alert)
      setValue("password", "")
      onClose()
      setContentBody(FormWithConfirmPasswordBody.PASSWORD)
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() => {
                    onClose()
                    setContentBody(FormWithConfirmPasswordBody.PASSWORD)
                  }}
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default FreezeWalletModal
