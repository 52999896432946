import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  TransactionToken,
  TransactionsInvoiceStable,
  CreditTransaction,
  TransactionsInvoiceCredit,
  TransactionsInvoiceNft,
  OffchainLogs,
} from "types/Transaction";

export interface TransactionState {
  transactionTokens: TransactionToken[]
  transactionsInvoiceStables: TransactionsInvoiceStable[]
  creditTransactions: CreditTransaction[]
  transactionsInvoiceCredits: TransactionsInvoiceCredit[]
  transactionsInvoiceNfts: TransactionsInvoiceNft[]
  offchainLogs: OffchainLogs[]
}

export const initialTransactionState: TransactionState = {
  transactionTokens: [],
  transactionsInvoiceStables: [],
  creditTransactions: [],
  transactionsInvoiceCredits: [],
  transactionsInvoiceNfts: [],
  offchainLogs: [],
}

const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialTransactionState,
  reducers: {
    setTransactionTokens: (state, action: PayloadAction<TransactionToken[]>) => {
      state.transactionTokens = action.payload
    },
    setTransactionsInvoiceStables: (state, action: PayloadAction<TransactionsInvoiceStable[]>) => {
      state.transactionsInvoiceStables = action.payload
    },
    setCreditTransactions: (state, action: PayloadAction<CreditTransaction[]>) => {
      state.creditTransactions = action.payload
    },
    setTransactionsInvoiceCredits: (state, action: PayloadAction<TransactionsInvoiceCredit[]>) => {
      state.transactionsInvoiceCredits = action.payload
    },
    setTransactionsInvoiceNfts: (state, action: PayloadAction<TransactionsInvoiceNft[]>) => {
      state.transactionsInvoiceNfts = action.payload
    },
    setOffchainLogs: (state, action: PayloadAction<OffchainLogs[]>) => {
      state.offchainLogs = action.payload
    },
  },
})

export const transactionReducer = transactionSlice.reducer
export const transactionActions = transactionSlice.actions
