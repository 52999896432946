// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Card, Box, Flex } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import WalletSelect from "common/components/WalletSelect"
import {
  PaymentTab,
  Dashboard,
  Alert,
  ARListTabForSeller,
  PaymentTabForSeller,
} from "modules/ap_management"
import { History, AR, Debtor } from "modules/ap_management/FI"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"
import useAuthHook from "hooks/useAuthHook"
import useQueryString from "hooks/useQueryString"

// ** Types **
import { MyWallet } from "types/MyWallet"

// ** Constants **
import { UserRole } from "constants/Enum"

const APManagementPage = () => {
  const tab = useQueryString("tab")

  const { getRole } = useAuthHook()
  const [tabIndex, setTabIndex] = useState(0)

  const { getCompanyWalletHook, myWallet } = useWalletHook()
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()

  const { isLoading } = useQuery({
    queryKey: "myWalletPageInit",
    queryFn: getCompanyWalletHook,
  })

  useEffect(() => {
    if (tab !== null) {
      setTabIndex(parseInt(tab))
    }
  }, [tab])

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoading || !selectedWallet) return <></>

  return (
    <>
      {getRole() === UserRole.BUYER && (
        <Card p={6}>
          <Flex justifyContent="space-between">
            <Box w={450}>
              <TabCustom
                tabIndex={tabIndex}
                onChange={(index) => setTabIndex(index)}
                tabOpt={[
                  {
                    label: "Dashboard",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 0,
                  },
                  {
                    label: "A/P List",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 1,
                  },
                  {
                    label: "Payment",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 2,
                  },
                  {
                    label: "Alert",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 3,
                  },
                ]}
              />
            </Box>
            <Box w={300}>
              <WalletSelect
                wallets={myWallet}
                onChange={(value) => setSelectedWallet(value)}
                value={selectedWallet}
              />
            </Box>
          </Flex>
          <Box display={tabIndex === 0 ? "block" : "none"}>
            <Dashboard setTabIndex={setTabIndex} />
          </Box>
          <Box display={tabIndex === 1 ? "block" : "none"}>
            <PaymentTab isPayment={false} />
          </Box>
          <Box display={tabIndex === 2 ? "block" : "none"}>
            <PaymentTab isPayment={true} />
          </Box>
          <Box display={tabIndex === 3 ? "block" : "none"}>
            <Alert />
          </Box>
        </Card>
      )}
      {getRole() === UserRole.SELLER && (
        <Card p={6}>
          <Flex justifyContent="space-between">
            <Box w={450}>
              <TabCustom
                tabIndex={tabIndex}
                onChange={(index) => setTabIndex(index)}
                tabOpt={[
                  {
                    label: "Dashboard",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 0,
                  },
                  {
                    label: "A/R List",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 1,
                  },
                  {
                    label: "Payment",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 2,
                  },
                  {
                    label: "Alert",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 3,
                  },
                ]}
              />
            </Box>
            <Box w={300}>
              <WalletSelect
                wallets={myWallet}
                onChange={(value) => setSelectedWallet(value)}
                value={selectedWallet}
              />
            </Box>
          </Flex>
          <Box display={tabIndex === 0 ? "block" : "none"}>
            <Dashboard setTabIndex={setTabIndex} />
          </Box>
          <Box display={tabIndex === 1 ? "block" : "none"}>
            <ARListTabForSeller />
          </Box>
          <Box display={tabIndex === 2 ? "block" : "none"}>
            <PaymentTabForSeller />
          </Box>
          <Box display={tabIndex === 3 ? "block" : "none"}>
            <Alert />
          </Box>
        </Card>
      )}
      {getRole() === UserRole.FI && (
        <Card p={6}>
          <Flex justifyContent="space-between">
            <Box w={450}>
              <TabCustom
                tabIndex={tabIndex}
                onChange={(index) => setTabIndex(index)}
                tabOpt={[
                  {
                    label: "Dashboard",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 0,
                  },
                  {
                    label: "Debtor",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 1,
                  },
                  {
                    label: "A/R",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 2,
                  },
                  {
                    label: "History",
                    icon: null,
                    activeIcon: null,
                    active: tabIndex === 3,
                  },
                ]}
              />
            </Box>
            <Box w={300}>
              <WalletSelect
                wallets={myWallet}
                onChange={(value) => setSelectedWallet(value)}
                value={selectedWallet}
              />
            </Box>
          </Flex>
          <Box display={tabIndex === 0 ? "block" : "none"}>
            <Dashboard setTabIndex={setTabIndex} />
          </Box>
          <Box display={tabIndex === 1 ? "block" : "none"}>
            <Debtor />
          </Box>
          <Box display={tabIndex === 2 ? "block" : "none"}>
            <AR />
          </Box>
          <Box display={tabIndex === 3 ? "block" : "none"}>
            <History />
          </Box>
        </Card>
      )}
    </>
  )
}

export default APManagementPage
