// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { dashboardActions } from "store/slice/DashboardSlice"

// ** APIs **
import {
  getSellerDashboard,
  getSponsorDashboard,
  getBuyerDashboard,
  getFIDashboard,
  getAdminDashboard,
} from "api/dashboard"

const useDashboardHook = () => {
  const dispatch = useAppDispatch()
  const {
    sellerDashboard,
    sponsorDashboard,
    buyerDashboard,
    fiDashboard,
    adminDashboard,
  } = useAppSelector((state: RootState) => state.dashboard)

  const getSellerDashboardHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getSellerDashboard()
      dispatch(dashboardActions.setSellerDashboard(response.data.data))
    } catch (err) {
      console.log("getSellerDashboardHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getSponsorDashboardHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getSponsorDashboard()
      dispatch(dashboardActions.setSponsorDashboard(response.data.data))
    } catch (err) {
      console.log("getSponsorDashboardHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getBuyerDashboardHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getBuyerDashboard()
      dispatch(dashboardActions.setBuyerDashboard(response.data.data))
    } catch (err) {
      console.log("getBuyerDashboardHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getFIDashboardHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getFIDashboard()
      dispatch(dashboardActions.setFIDashboard(response.data.data))
    } catch (err) {
      console.log("getFIDashboardHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAdminDashboardHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAdminDashboard()
      dispatch(dashboardActions.setAdminDashboard(response.data.data))
    } catch (err) {
      console.log("getAdminDashboardHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getSellerDashboardHook,
    getSponsorDashboardHook,
    getBuyerDashboardHook,
    getFIDashboardHook,
    getAdminDashboardHook,
    sellerDashboard,
    sponsorDashboard,
    buyerDashboard,
    fiDashboard,
    adminDashboard,
  }
}

export default useDashboardHook
