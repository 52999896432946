import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetAcceptedGuaranteedInvoicesResponse } from "./types/getAcceptedGuaranteedInvoicesType";

const getAcceptedGuaranteedInvoices = async (): Promise<AxiosResponse<GetAcceptedGuaranteedInvoicesResponse>> => {
  const request = await $axios().get("/get_accepted_guaranteed_invoices");
  return request;
};

export default getAcceptedGuaranteedInvoices;
