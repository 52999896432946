import getAllWallet from "../api/getAllWallet"
import getCompanyWallet from "../api/getCompanyWallet"
import getBuyerWallet from "../api/getBuyerWallet"
import getParticipantProfile from "../api/getParticipantProfile"
import getWalletBalance from "../api/getWalletBalance"
import {
  getTransactionToken,
  getTransactionInvoice,
} from "api/getTransactionToken"
import postTopupWallet from "../api/postTopupWallet"
import postTransferWallet from "../api/postTransferWallet"
import postWithdrawWallet from "../api/postWithdrawWallet"
import postMintCreditToken from "api/postMintCreditToken"
import postAdjustmentCreditToken from "api/postAdjustmentCreditToken"
import putWalletNickname from "api/putWalletNickname"
import { PostTopupWalletBody } from "../api/types/postTopupWalletType"
import { PostTransferWalletBody } from "../api/types/postTransferWalletType"
import { PostWithdrawWalletBody } from "../api/types/postWithdrawWalletType"
import { PostMintCreditTokenBody } from "../api/types/postMintCreditTokenType"
import { PostAdjustmentCreditTokenBody } from "../api/types/postAdjustmentCreditTokenType"
import { PutWalletNicknameData } from "api/types/putWalletNickname"
import { RootState, useAppDispatch, useAppSelector } from "../store"
import { appActions } from "../store/slice/AppSlice"
import { walletActions } from "../store/slice/WalletSlice"
import type { MyWallet as TMyWallet } from "types/MyWallet"
import type { ResponseWithOutData } from "api/types/ApiResponse"

// ** Utils **
import { handleError } from "common/utils/Response"

const useWalletHook = () => {
  const dispatch = useAppDispatch()
  const {
    myWallet,
    buyerWallet,
    participantProfile,
    transactionTokens,
    transactionInvoices,
  } = useAppSelector((state: RootState) => state.wallet)

  //service hook
  const getCompanyWalletHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCompanyWallet()
      dispatch(
        walletActions.setMyWallet(
          response.data.data.filter(
            (item: TMyWallet) => item.wallet_address !== "pending"
          )
        )
      )
    } catch (err) {
      console.log("getCompanyWalletHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getBuyerWalletHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getBuyerWallet()
      dispatch(walletActions.setBuyerWallet(response.data.data))
    } catch (err) {
      console.log("getBuyerWalletHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getParticipantProfileHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipantProfile()
      dispatch(walletActions.setParticipantProfile(response.data.data))
    } catch (err) {
      console.log("getCompanyWalletHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getWalletBalanceHook = async (walletAddress: string) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getWalletBalance(walletAddress)
      return response.data
    } catch (err) {
      console.log("getCompanyWalletHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getTransactionTokenHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getTransactionToken()
      dispatch(walletActions.setTransactionToken(response.data.data))
    } catch (err) {
      console.log("getTransactionTokenHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getTransactionInvoiceHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getTransactionInvoice()
      dispatch(walletActions.setTransactionInvoice(response.data.data))
    } catch (err) {
      console.log("getTransactionInvoiceHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCompanyWallets = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCompanyWallet()
      return response
    } catch (err) {
      console.log("getCompanyWallets err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAllWalletHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAllWallet()
      return response
    } catch (err) {
      console.log("getAllWalletHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postTransferWalletHook = async (body: PostTransferWalletBody) => {
    try {
      dispatch(appActions.showLoader())
      const response = await postTransferWallet(body)
      return response
    } catch (err) {
      console.log("postTransferWalletHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postTopupWalletHook = async (body: PostTopupWalletBody) => {
    try {
      dispatch(appActions.showLoader())
      const response = await postTopupWallet(body)
      return response
    } catch (err) {
      console.log("postTopupWalletHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postWithdrawWalletHook = async (body: PostWithdrawWalletBody) => {
    try {
      dispatch(appActions.showLoader())
      const response = await postWithdrawWallet(body)
      return response
    } catch (err) {
      console.log("postWithdrawWalletHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postMintCreditTokenHook = async (body: PostMintCreditTokenBody) => {
    try {
      dispatch(appActions.showLoader())
      const response = await postMintCreditToken(body)
      return response
    } catch (err) {
      console.log("postMintCreditTokenHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postAdjustmentCreditTokenHook = async (
    body: PostAdjustmentCreditTokenBody
  ) => {
    try {
      dispatch(appActions.showLoader())
      const response = await postAdjustmentCreditToken(body)
      return response
    } catch (err) {
      console.log("postAdjustmentCreditTokenHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const putWalletNicknameHook = async (
    id: number,
    body: PutWalletNicknameData
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await putWalletNickname(id, body)
      await getCompanyWalletHook()
      return response.data
    } catch (err) {
      console.log("putWalletNicknameHook err => ", err)
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getCompanyWalletHook,
    getCompanyWallets,
    getBuyerWalletHook,
    getParticipantProfileHook,
    getWalletBalanceHook,
    getAllWalletHook,
    getTransactionTokenHook,
    getTransactionInvoiceHook,
    postTransferWalletHook,
    postTopupWalletHook,
    postWithdrawWalletHook,
    postMintCreditTokenHook,
    postAdjustmentCreditTokenHook,
    putWalletNicknameHook,
    myWallet,
    buyerWallet,
    participantProfile,
    transactionTokens,
    transactionInvoices,
  }
}

export default useWalletHook
