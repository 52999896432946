import { Box, Divider, Stack } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { GetUserData } from "../../api/types/getUserType";
import { UserRole } from "../../constants/Enum";
import { pathCleanId } from "../utils/transform";
import DrawerItem from "./DrawerItem";
import DrawerBarByRole from "./DrawerbarByRole";
import { ItmxLogoReversed2 } from "./Icon";
import packageEnv from '../../../package.json';

type DrawerBarProps = {
  user: GetUserData;
};

const DrawerBar: FC<DrawerBarProps> = ({ user }) => {
  const location = useLocation();
  const {
    ADMIN_ITEMS,
    SELLER_ITEMS,
    FI_ITEMS,
    BUYER_ITEMS,
    SPONSOR_ITEMS,
    TCG_ITEMS
  } =
    DrawerBarByRole();

  const drawerBarItems = useMemo(() => {
    if (user.pt_id === 0) return ADMIN_ITEMS;
    switch (user.pt_role) {
      case UserRole.ADMIN || UserRole.OPERATION:
        return ADMIN_ITEMS;
      case UserRole.BUYER:
        return BUYER_ITEMS;
      case UserRole.SELLER:
        return SELLER_ITEMS;
      case UserRole.FI:
        return FI_ITEMS;
      case UserRole.SPONSOR:
        return SPONSOR_ITEMS;
      case UserRole.TCG:
        return TCG_ITEMS;
    }
  }, [
    ADMIN_ITEMS,
    BUYER_ITEMS,
    FI_ITEMS,
    SELLER_ITEMS,
    SPONSOR_ITEMS,
    TCG_ITEMS,
    user.pt_role,
    user.pt_id,
  ]);

  return (
    <Stack align="center" px={6} py={8} bgColor="primary" h="100%">
      <Box>
        <ItmxLogoReversed2 />
      </Box>
      <Divider my={8} />
      <Stack gap={5} w="100%">
        {drawerBarItems &&
          drawerBarItems.map((draItem, i) => (
            <DrawerItem
              key={"draItem_" + i}
              route={draItem.route}
              icon={draItem.icon}
              title={draItem.title}
              navTitle={draItem.navTitle}
              active={draItem.activeRoute.includes(pathCleanId(location.pathname))}
            />
          ))}
      </Stack>
      <Divider my={4} />
      <Box mb={4} color="white">
        Version {packageEnv.version}
      </Box>
    </Stack>
  );
};

export default DrawerBar;
