import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  useSystemColorMode: false,
  colors: {
    background: "#F1F8FF",
    primary: "#00249c",
    success: "#198754",
    green: "#6EC67A",
    red: "#E11B22",
    orange: "#FF7A00",
    blue: "#0050C8",
    disable: "#D8D8D8",
    error: "#dc3545",
    grey400: "#CED4DA",
    primary500: {
      500: "#00249c",
    },
    selected: "#45A0F6",
    completed: "#51BC68",
    textColor: "#42474C",
    primaryTextColor: "#363945",
    secondaryTextColor: "#6A727A",
    emptyColor: "#E1F1FF",
    grey3: "#828282",
    valueText: "#333",
  },
  components: {
    Checkbox: {
      defaultProps: {
        colorScheme: "primary500",
      },
    },
  },
});

export default theme;
