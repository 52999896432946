// ** Libs **
import { Box, Text, Flex } from "@chakra-ui/react"

// ** Icons **
import { CashIcon, DiscountIcon, CoinReceiveIcon } from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

const Dashboard = ({ setTabIndex }: { setTabIndex: any }) => {
  const { getSellerDashboardHook, sellerDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initSellerDashboard",
    queryFn: getSellerDashboardHook,
  })

  if (isLoading) return <></>

  const { invoice_discount } = sellerDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={4}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(1)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Financing</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormat(`${invoice_discount["1"]?.num}`)}
            </Text>
          </Box>
          <Box w="70px" m={4}>
            <CashIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${invoice_discount["1"]?.amount_after_discount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(2)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Buyer Prepayment</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormat(`${invoice_discount["2"]?.num}`)}
            </Text>
          </Box>
          <Box w="83px" m={4}>
            <CoinReceiveIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${invoice_discount["2"]?.amount_after_discount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(3)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Guaranteed Discounting</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormat(`${invoice_discount["3"]?.num}`)}
            </Text>
          </Box>
          <Box w="83px" m={4}>
            <DiscountIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${invoice_discount["3"]?.amount_after_discount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Dashboard
