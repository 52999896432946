// ** Libs
import { Tab, TabList, Tabs, Box } from "@chakra-ui/react"

// ** Types **
import type { TabCustomProps } from "types/Tab"

const TabCustom: React.FC<TabCustomProps> = ({ tabOpt, onChange, tabIndex }) => {
  return (
    <Tabs
      index={tabIndex} 
      onChange={onChange}
      w="100%"
      align="center"
      isFitted
      className="tab-custom"
    >
      <TabList>
        {tabOpt.map((t, i) => (
          <Tab key={i}>
            <Box mr={1}>{t.active ? t.activeIcon : t.icon}</Box>
            {t.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default TabCustom
