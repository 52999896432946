import ActivatePage from "../modules/authenticate/ActivatePage";
import ForgetPasswordPage from "../modules/authenticate/ForgetPasswordPage";
import LoginPage from "../modules/authenticate/LoginPage";
import ResetPasswordPage from "../modules/authenticate/ResetPasswordPage";
import BiddingAndOfferingPage from "../modules/bidding_and_offering/BiddingAndOfferingPage";
import CampaignManagementPage from "../modules/campaign_management/CampaignManagementPage";
import CompanyProfilePage from "../modules/company_profile/CompanyProfilePage";
import EditCompanyProfilePage from "../modules/company_profile/EditCompanyProfilePage";
import DashBoardPage from "../modules/dashboard/DashboardPage";
import MyInvoicePage from "../modules/invoice/MyInvoicePage";
import InvoiceOfferingByIdPage from "../modules/listing_and_offering/InvoiceOfferingByIdPage";
import ListingAndOfferingPage from "../modules/listing_and_offering/ListingAndOfferingPage";
import MarketplaceManagementPage from "../modules/marketplace_management/MarketplaceManagementPage";
import EditUserProfilePage from "../modules/profile/EditUserProfilePage";
import UserProfilePage from "../modules/profile/UserProfilePage";
import TokenManagementPage from "../modules/token_management/TokenManagementPage";
import TransactionAndActivityPage from "../modules/transaction/TransactionAndActivityPage";
import MyWalletPage from "../modules/user_wallet_management/MyWalletPage";
import AccountManagementPage from "modules/user_wallet_management/AccountManagementPage";
import RevenuePage from "../modules/revenue/RevenuePage";
import APManagementPage from "modules/ap_management/APManagementPage";
import GuaranteeManagementPage from "modules/guarantee_management/GuaranteeManagementPage";
import ClientManagementPage from "modules/client_management/ClientManagementPage";
import NewsPage from "modules/news/NewsPage";
import DiscountingPage from "modules/discounting/DiscountingPage";
import * as AppPath from "./AppPath";

interface Route {
  path: string;
  element: React.ReactElement;
  isProtectRoute?: boolean;
}

const Routes: Route[] = [
  {
    path: AppPath.NEWS_PAGE_PATH,
    element: <NewsPage />,
  },
  {
    path: AppPath.AUTHENTICATE_ACTIVATE_PAGE_PATH,
    element: <ActivatePage />,
  },
  {
    path: AppPath.AUTHENTICATE_FORGOT_PASSWORD_PAGE_PATH,
    element: <ForgetPasswordPage />,
  },
  {
    path: AppPath.AUTHENTICATE_LOGIN_PAGE_PATH,
    element: <LoginPage />,
  },
  {
    path: AppPath.AUTHENTICATE_RESET_PASSWORD_PAGE_PATH,
    element: <ResetPasswordPage />,
  },
  {
    path: AppPath.DASHBOARD_PAGE_PATH,
    element: <DashBoardPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.COMPANY_PROFILE_PAGE_PATH,
    element: <CompanyProfilePage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.EDIT_COMPANY_PROFILE_PAGE_PATH,
    element: <EditCompanyProfilePage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.USER_PROFILE_PAGE_PATH,
    element: <UserProfilePage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.EDIT_USER_PROFILE_PAGE_PATH,
    element: <EditUserProfilePage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.TOKEN_MANAGEMENT_PAGE_PATH,
    element: <TokenManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.MARKETPLACE_MANAGEMENT_PAGE_PATH,
    element: <MarketplaceManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.USER_WALLET_MANAGEMENT_PAGE_PATH,
    element: <AccountManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.TRANSACTION_AND_ACTIVITY_PAGE_PATH,
    element: <TransactionAndActivityPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.CAMPAIGN_MANAGEMENT_PAGE_PATH,
    element: <CampaignManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.MY_WALLET_PAGE_PATH,
    element: <MyWalletPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.MY_INVOICE_PAGE_PATH,
    element: <MyInvoicePage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.LISTING_AND_OFFERING_PAGE_PATH,
    element: <ListingAndOfferingPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.INVOICE_OFFERING_BY_ID_PAGE_PATH,
    element: <InvoiceOfferingByIdPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.BIDDING_AND_OFFERING_PAGE_PATH,
    element: <BiddingAndOfferingPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.REVENUE_PAGE_PATH,
    element: <RevenuePage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.AP_MANAGEMENT_PAGE_PATH,
    element: <APManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.AR_MANAGEMENT_PAGE_PATH,
    element: <APManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.GUARANTEE_MANAGEMENT_PAGE_PATH,
    element: <GuaranteeManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.CLIENT_MANAGEMENT_PAGE_PATH,
    element: <ClientManagementPage />,
    isProtectRoute: true,
  },
  {
    path: AppPath.DISCOUNTING_PAGE_PATH,
    element: <DiscountingPage />,
    isProtectRoute: true,
  },
];

export default Routes;
