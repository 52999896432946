import { AxiosResponse } from "axios"
import $axios from "./instance"
import { PostSetPasswordBody } from "./types/postLoginType"

const postSetPassword = async (
  body: PostSetPasswordBody
): Promise<AxiosResponse> => {
  const request = await $axios().post("/reset_password", body)
  return request
}

export default postSetPassword
