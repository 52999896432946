// ** React **
import { FC, useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { TransactionTokenDetailModal } from "modules/user_wallet_management"

// ** Constants **
import {
  TIME_FRAME,
  TRANSACTION_TOKEN,
} from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  capitalizeFirstLetter,
  formatDateTimeStamp,
  removeDuplicates,
  filterDataByTimeframe,
} from "common/utils/transform"

// ** Types **
import { MyWallet, TransactionToken } from "types/MyWallet"

type TransactionListProps = {
  wallet: MyWallet
}

const TransactionList: FC<TransactionListProps> = ({ wallet }) => {
  const transactionDetailModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string[]>([
    "Top-up",
    "Transfer",
    "Withdraw",
    "Received",
  ])
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [currency, setCurrency] = useState("")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<TransactionToken[]>([])
  const [selected, setSelected] = useState<TransactionToken>()

  const tyxTypeOpts = TRANSACTION_TOKEN.map((m) => ({ title: m, value: m }))

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getTransactionTokenHook, transactionTokens } = useWalletHook()


  const currencyOpts = [
    { title: "All Currency", value: "" },
    ...Array.from(
      new Set(transactionTokens.map((item) => item.currency))
    ).map((m) => ({ title: m, value: m })),
  ]

  const { isLoading } = useQuery({
    queryKey: "initTransactionToken",
    queryFn: getTransactionTokenHook,
  })

  useEffect(() => {
    setData(
      transactionTokens
        .map((item) =>
          item.to_address === wallet.wallet_address &&
          item.action === "transfer"
            ? { ...item, action: "receive" }
            : item
        )
        .filter(
          (item) =>
            (item.action !== "top-up" &&
              item.from_address === wallet.wallet_address) ||
            item.to_address === wallet.wallet_address
        )
    )
  }, [transactionTokens, wallet])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: TransactionToken[] = []

    const original = transactionTokens.map((item) =>
      item.to_address === wallet.wallet_address && item.action === "transfer"
        ? { ...item, action: "receive" }
        : item
    )

    if (trxType.indexOf("Top-up") > -1) {
      temp = temp.concat(
        temp,
        original.filter((m) => m.action === "top-up")
      )
    }
    if (trxType.indexOf("Transfer") > -1) {
      temp = temp.concat(
        temp,
        original.filter((m) => m.action === "transfer")
      )
    }
    if (trxType.indexOf("Withdraw") > -1) {
      temp = temp.concat(
        temp,
        original.filter((m) => m.action === "withdraw")
      )
    }
    if (trxType.indexOf("Received") > -1) {
      temp = temp.concat(
        temp,
        original.filter((m) => m.action === "receive")
      )
    }
    if (currency) {
      temp = temp.filter((m) => m.currency === currency)
    }
    temp = removeDuplicates(temp, "id")
    temp = filterDataByTimeframe(temp, timeFrame, "created_at")
    temp.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
    const filterValue: TransactionToken[] = temp
      .filter(
        (item) =>
          (item.action !== "top-up" &&
            item.from_address === wallet.wallet_address) ||
          item.to_address === wallet.wallet_address
      )
      .filter((m) =>
        (
          m.to_company_name +
          m.to +
          m.to_address +
          m.from_address +
          m.from_juristic_id +
          m.to_juristic_id +
          m.from_bank_code +
          m.to_bank_code
        )
          .toLowerCase()
          .includes(fillValue.toLowerCase())
      )
    setData(filterValue)
  }, [fillValue, transactionTokens, wallet, trxType, currency, timeFrame])

  if (isLoading && wallet) return <></>

  const column = [
    {
      title: "From",
      value: "from",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {value.action === "top-up"
            ? `${value.from_bank_name} - ${value.from_bank_account_number}`
            : `${capitalizeFirstLetter(
                value.from_company_name
              )} - ${capitalizeFirstLetter(
                value.from_wallet_nickname
              )} (${abbreviateHexString(value.from_address)})`}
        </Text>
      ),
    },
    {
      title: "To",
      value: "to",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {value.action === "withdraw"
            ? `${value.to_bank_name} - ${value.to_bank_account_number}`
            : `${capitalizeFirstLetter(
                value.to_company_name
              )} - ${capitalizeFirstLetter(
                value.to_wallet_nickname
              )} (${abbreviateHexString(value.to_address)})`}
        </Text>
      ),
    },
    {
      title: "Amount",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.amount)}</Text>
      ),
    },
    {
      title: "Currency",
      value: "currency",
      sortType: TableSortType.STRING,
    },
    {
      title: "Time Stamp",
      value: "created_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.created_at)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Action",
      value: "action",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box
          css={{
            "&:first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {value.action}
        </Box>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Link
            key={key}
            onClick={() => {
              setSelected(value)
              transactionDetailModalAction.onOpen()
            }}
          >
            View
          </Link>
        )
      },
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string[]) => setTrxType(value),
      value: trxType,
      type: FilterType.CHECKBOX,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
    {
      options: currencyOpts,
      onChange: (value: string) => setCurrency(value),
      value: currency,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Juristic Id/ Bank Code"
        isSearchOnlyNumber={true}
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      {selected && (
        <TransactionTokenDetailModal
          {...transactionDetailModalAction}
          data={selected}
        />
      )}
    </Box>
  )
}

export default TransactionList
