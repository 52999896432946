import { AxiosResponse } from "axios";
import $axios from "./instance";
import {
  PostMintCreditTokenBody,
  PostMintCreditTokenResponse,
} from "./types/postMintCreditTokenType";

const postMintCreditToken = async (
  body: PostMintCreditTokenBody
): Promise<AxiosResponse<PostMintCreditTokenResponse>> => {
  const request = await $axios().post("/credit/topup", body);
  return request;
};

export default postMintCreditToken;
