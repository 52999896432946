// ** Libs **
import { Box, Flex, Heading, useDisclosure } from "@chakra-ui/react"

// ** Components **
import {
  ParticipantDetailHeader,
  ParticipantDetailItem,
  ParticipantDetailEditModal,
} from "modules/client_management"
import { UpdateParticipantDetailModal } from "modules/user_wallet_management"

// ** Hooks **
import useParticipantHook from "hooks/useParticipantHook"
import useAuthHook from "hooks/useAuthHook"

// ** Icons **
import { EditIcon } from "assets/icons"
import SuccessBadge from "common/components/SuccessBadge"

// ** Utils **
import { capitalizeFirstLetter } from "common/utils/transform"

const ParticipantDetail = () => {
  const editDetailModalAction = useDisclosure()

  const { user, isAdmin } = useAuthHook()
  const { participantDetail } = useParticipantHook()

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Heading size="md" color="primary">
          Participant Detail
        </Heading>
        {((user && user.pt_id === participantDetail?.pt_created_id) ||
          isAdmin) && (
          <Box cursor="pointer">
            <EditIcon onClick={editDetailModalAction.onOpen} />
          </Box>
        )}
      </Flex>
      <Box p={6}>
        <ParticipantDetailItem
          label="Company name"
          value={capitalizeFirstLetter(participantDetail?.company_name ?? "")}
        />
        <ParticipantDetailItem
          label="Type"
          value={participantDetail?.type ?? ""}
        />
        {isAdmin && (
          <ParticipantDetailItem
            label="Role"
            value={participantDetail?.role ?? ""}
          />
        )}
        <ParticipantDetailItem
          label="Juristic ID"
          value={participantDetail?.juristic_id ?? ""}
        />
        {isAdmin && (
          <ParticipantDetailHeader
            label="Status"
            value={<SuccessBadge label={participantDetail?.status ?? ""} />}
          />
        )}
      </Box>
      {isAdmin ? (
        <UpdateParticipantDetailModal {...editDetailModalAction} />
      ) : (
        <ParticipantDetailEditModal {...editDetailModalAction} />
      )}
    </Box>
  )
}

export default ParticipantDetail
