// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import {
  Flex,
  Box,
  HStack,
  Heading,
  Center,
  Text,
  Stack,
} from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import WalletSelect from "common/components/WalletSelect"
import PrimaryButton from "common/components/PrimaryButton"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { HistoryBadge } from "common/components/Icon"
import ContainerTable from "common/components/Table/ContainerTable"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"
import useCreditHook from "hooks/useCreditHook"

// ** Types **
import { MyWallet } from "types/MyWallet"
import { CreditTransaction } from "types/Credit"

// ** Constants **
import { TIME_FRAME, TRX_CREDIT_MINT } from "constants/AppOptions"
import { FilterType, CreditBody } from "constants/Enum"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  formatDateTimeStamp,
  removeDuplicates,
  filterDataByTimeframe,
} from "common/utils/transform"

const Credit = ({ setBody }: { setBody: any }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string[]>(TRX_CREDIT_MINT)
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [data, setData] = useState<any[]>([])
  const [fillValue, setFillValue] = useState<string>("")

  const tyxTypeOpts = TRX_CREDIT_MINT.map((m) => ({ title: m, value: m }))

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getCompanyWalletHook, myWallet } = useWalletHook()
  const {
    getCreditTransactionHook,
    creditTransactions,
    getTotalSupplyHook,
    totalSupply,
  } = useCreditHook()
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()

  const { isLoading } = useQuery({
    queryKey: "initMyWallet",
    queryFn: getCompanyWalletHook,
  })

  const { isLoading: isLoadingCredit } = useQuery({
    queryKey: "initCreditTransaction",
    queryFn: getCreditTransactionHook,
  })

  const { isLoading: isLoadingTotalSupply } = useQuery({
    queryKey: "initTotalSupply",
    queryFn: getTotalSupplyHook,
  })

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  useEffect(() => {
    if (creditTransactions.length > 0) {
      setData(creditTransactions)
    }
  }, [creditTransactions])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )
  useEffect(() => {
    let temp: CreditTransaction[] = []
    if (trxType.indexOf("View All") > -1) {
      temp = creditTransactions
    }
    if (trxType.indexOf("Top-up Credit") > -1) {
      temp = temp.concat(
        temp,
        creditTransactions.filter((m) => m.action === "top-up")
      )
    }
    if (trxType.indexOf("Credit Adjustment") > -1) {
      temp = temp.concat(
        temp,
        creditTransactions.filter((m) => m.action === "adjust")
      )
    }
    temp = removeDuplicates(temp, "id")
    temp = filterDataByTimeframe(temp, timeFrame, "created_at")
    temp.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
    const filterValue: CreditTransaction[] = temp.filter((m) =>
      (
        m.hash +
        m.to_company_name +
        m.to_bank_code +
        m.to_bank_account_number +
        m.from_company_name +
        m.from_bank_code +
        m.from_bank_account_number +
        m.from_jurictic_id +
        m.to_jurictic_id
      )
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, creditTransactions, trxType, timeFrame])

  if (isLoading || isLoadingCredit || !selectedWallet || isLoadingTotalSupply)
    return <></>

  const column = [
    {
      title: "Transaction id",
      value: "hash",
      sortType: TableSortType.STRING,
      w: "180px",
      customRender: (key: string, value: any) => {
        return <Box key={key}>{abbreviateHexString(value.hash)}</Box>
      },
    },
    {
      title: "Action",
      value: "action",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => {
        return (
          <Box
            key={key}
            css={{
              "&:first-letter": {
                textTransform: "uppercase",
              },
            }}
          >
            {value.action}
          </Box>
        )
      },
    },
    {
      title: "From",
      value: "from",
      sortType: TableSortType.STRING,
      w: "260px",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {value.from_company_name} - {value.from_nickname ?? ""}(
            {abbreviateHexString(value.from_address)})
          </Box>
        )
      },
    },
    {
      title: "To",
      value: "to",
      sortType: TableSortType.STRING,
      w: "320px",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {value.to_company_name} - {value.to_nickname ?? ""}({abbreviateHexString(value.to_address)})
          </Box>
        )
      },
    },
    {
      title: "Amount(Credit)",
      value: "amount",
      sortType: TableSortType.INTEGER,
      w: "220px",
      customRender: (key: string, value: any) => {
        return <Box key={key}>{numberFormatForCurrency(value.amount)}</Box>
      },
    },
    {
      title: "Credit Type",
      value: "credit_type",
      sortType: TableSortType.STRING,
    },
    {
      title: "Guarantee Rate",
      value: "guarantee_rate",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => {
        return <Box key={key}>{value.guarantee_rate}%</Box>
      },
    },
    {
      title: "Time Stamp",
      value: "created_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => {
        return <Box key={key}>{formatDateTimeStamp(value.created_at)}</Box>
      },
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string[]) => setTrxType(value),
      value: trxType,
      type: FilterType.CHECKBOX,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none" p={6} mb={6}>
      <Flex justifyContent="flex-end">
        <Box w={300}>
          <WalletSelect
            wallets={myWallet}
            onChange={(value) => setSelectedWallet(value)}
            value={selectedWallet}
          />
        </Box>
      </Flex>
      <Stack align="center" gap={6}>
        <Heading size="sm">Total Credit</Heading>
        <Flex alignItems="center">
          <Heading fontSize="40px" mr={4}>
            {numberFormatForCurrency(totalSupply.total_supply)}
          </Heading>
          <Heading fontSize="24px" color="disable">
            {totalSupply.name}
          </Heading>
        </Flex>
      </Stack>
      <Center mt={4} mb={4}>
        <PrimaryButton
          colorScheme="primary"
          backgroundColor="primary"
          color="white"
          w="235px"
          size="lg"
          onClick={() => setBody(CreditBody.MINT)}
        >
          <HStack>
            <Heading size="sm">Credit Top-up (MINT)</Heading>
          </HStack>
        </PrimaryButton>
      </Center>
      <Center>
        <PrimaryButton
          colorScheme="primary"
          backgroundColor="primary"
          color="white"
          w="235px"
          size="lg"
          onClick={() => setBody(CreditBody.ADJUSTMENT)}
        >
          <HStack>
            <Heading size="sm">Credit Adjustment</Heading>
          </HStack>
        </PrimaryButton>
      </Center>
      <Flex gap={4} direction="column">
        <HistoryBadge label="Transaction History" />
        <ContainerTable
          column={column}
          data={displayData}
          rawData={data}
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={data.length}
          filter={filterTable}
          searchPlaceholder="Juristic Id/ Bank Code"
          canExport={true}
          onViewSizeChange={(size) => {
            setPageSize(size)
            setCurrentPage(1)
          }}
          onPageChange={(page: number) => setCurrentPage(page)}
          onSearchChange={onSearchChange}
        />
      </Flex>
    </Box>
  )
}

export default Credit
