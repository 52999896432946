// ** React **
import { FC, useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { TransactionInvoiceDetailModal } from "modules/user_wallet_management"

// ** Constants **
import {
  TIME_FRAME,
  TRANSACTION_INVOICE,
  TRANSACTION_INVOICE_SPONSOR,
  TRANSACTION_INVOICE_FI,
} from "constants/AppOptions"
import { UserRole, FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useAuthHook from "hooks/useAuthHook"
import useWalletHook from "hooks/useWalletHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  formatDateTimeStamp,
  capitalizeFirstLetter,
  invoiceOptionIdToString,
  removeDuplicatesFields,
  filterDataByTimeframe,
} from "common/utils/transform"

// ** Types **
import { MyWallet, TransactionInvoice } from "types/MyWallet"

type TransactionListProps = {
  wallet: MyWallet
}

const TransactionInvoiceList: FC<TransactionListProps> = ({ wallet }) => {
  const { getRole } = useAuthHook()
  const transactionDetailModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string[]>(
    getRole() === UserRole.TCG || getRole() === UserRole.SPONSOR
      ? TRANSACTION_INVOICE_SPONSOR
      : getRole() === UserRole.FI
      ? TRANSACTION_INVOICE_FI
      : TRANSACTION_INVOICE
  )
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [currency, setCurrency] = useState("")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<TransactionInvoice[]>([])
  const [selected, setSelected] = useState<TransactionInvoice>()

  let tyxTypeOpts: any[] = []
  if (getRole() === UserRole.TCG) {
  } else if (getRole() === UserRole.SPONSOR) {
    tyxTypeOpts = TRANSACTION_INVOICE_SPONSOR.map((m) => ({
      title: m,
      value: m,
    }))
  } else if (getRole() === UserRole.FI) {
    tyxTypeOpts = TRANSACTION_INVOICE_FI.map((m) => ({
      title: m,
      value: m,
    }))
  } else {
    tyxTypeOpts = TRANSACTION_INVOICE.map((m) => ({ title: m, value: m }))
  }

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getTransactionInvoiceHook, transactionInvoices } = useWalletHook()

  const currencyOpts = [
    { title: "All Currency", value: "" },
    ...Array.from(
      new Set(transactionInvoices.map((item) => item.currency))
    ).map((m) => ({ title: m, value: m })),
  ]

  const { isLoading } = useQuery({
    queryKey: "initTransactionInvoice",
    queryFn: getTransactionInvoiceHook,
  })

  useEffect(() => {
    setData(
      transactionInvoices.filter(
        (item) =>
          item.from_wallet_address === wallet.wallet_address ||
          item.to_wallet_address === wallet.wallet_address
      )
    )
  }, [transactionInvoices, wallet])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: TransactionInvoice[] = []

    if (trxType.indexOf("Received") > -1) {
      temp = temp.concat(
        temp,
        transactionInvoices.filter((m) => m.action === "receive")
      )
    }

    if (trxType.indexOf("LG") > -1) {
      temp = temp.concat(
        temp,
        transactionInvoices.filter((m) => m.action === "LG")
      )
    }

    if (trxType.indexOf("Offer") > -1) {
      temp = temp.concat(
        temp,
        transactionInvoices.filter((m) => m.action === "offer")
      )
    }

    if (trxType.indexOf("Transfer") > -1) {
      temp = temp.concat(
        temp,
        transactionInvoices.filter((m) => m.action === "transfer")
      )
    }

    if (currency) {
      temp = temp.filter((m) => m.currency === currency)
    }

    temp = removeDuplicatesFields(temp, ["id", "action", "amount", "currency", "doc_number", "option", "status", "updated_at"])

    temp = filterDataByTimeframe(temp, timeFrame, "updated_at")

    temp.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )

    const filterValue: TransactionInvoice[] = temp
      .filter(
        (item) =>
          item.from_wallet_address === wallet.wallet_address ||
          item.to_wallet_address === wallet.wallet_address
      )
      .filter((m) =>
        (
          m.doc_number +
          m.from_juristic_id +
          m.to_juristic_id +
          m.from_bank_code +
          m.to_bank_code
        )
          .toLowerCase()
          .includes(fillValue.toLowerCase())
      )
    setData(filterValue)
  }, [fillValue, transactionInvoices, wallet, trxType, currency, timeFrame])

  if (isLoading && wallet) return <></>

  const column = [
    {
      title: "DocType",
      value: "doc_type",
      w: "200px",
      customRender: () => <Text>Invoice</Text>,
    },
    {
      title: "DocNo.",
      value: "doc_number",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>{value.doc_number}</Text>
      ),
    },
    {
      title: "From",
      value: "from",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {capitalizeFirstLetter(value.from_company_name)} -{" "}
          {capitalizeFirstLetter(value.from_wallet_nickname)} (
          {abbreviateHexString(value.from_wallet_address)})
        </Text>
      ),
    },
    {
      title: "To",
      value: "to",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {capitalizeFirstLetter(value.to_company_name)} -{" "}
          {capitalizeFirstLetter(value.to_wallet_nickname)} (
          {abbreviateHexString(value.to_wallet_address)})
        </Text>
      ),
    },
    {
      title: "Amount",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">
          {numberFormatForCurrency(parseFloat(value.amount).toFixed(2))}
        </Text>
      ),
    },
    {
      title: "Currency",
      value: "currency",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">
          {value.currency}
        </Text>
      ),
    },
    {
      title: "Time Stamp",
      value: "updated_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.updated_at)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Options",
      value: "option",
      sortType: TableSortType.ITEM,
      w: "240px",
      customRender: (key: string, value: any) => (
        <Box>{invoiceOptionIdToString(value.option)}</Box>
      ),
    },
    {
      title: "Action",
      value: "action",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box
          css={{
            "&:first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {value.action}
        </Box>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Link
            key={key}
            onClick={() => {
              setSelected(value)
              transactionDetailModalAction.onOpen()
            }}
          >
            View
          </Link>
        )
      },
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string[]) => setTrxType(value),
      value: trxType,
      type: FilterType.CHECKBOX,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
    {
      options: currencyOpts,
      onChange: (value: string) => setCurrency(value),
      value: currency,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Juristic Id/ Bank Code"
        isSearchOnlyNumber={true}
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      {selected && (
        <TransactionInvoiceDetailModal
          {...transactionDetailModalAction}
          data={selected}
        />
      )}
    </Box>
  )
}

export default TransactionInvoiceList
