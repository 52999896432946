// ** APIs **
import {
  getAcceptedGuaranteedForSponsor,
  getAcceptedGuaranteedGroupForSponsorBuyer,
  getAcceptedGuaranteedGroupForSponsorBuyerInvoice,
  getAcceptedGuaranteedGroupForSponsorSeller,
  getAcceptedGuaranteedGroupForSponsorSellerInvoice,
} from "api/guarantee"

// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { guaranteeActions } from "store/slice/GuaranteeSlice"

const useGuaranteeHook = () => {
  const dispatch = useAppDispatch()
  const {
    histories,
    buyerCompanies,
    buyerInvoices,
    sellerCompanies,
    sellerInvoices,
  } = useAppSelector((state: RootState) => state.guarantee)

  const getAcceptedGuaranteedForSponsorHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedGuaranteedForSponsor()
      dispatch(guaranteeActions.setHistories(response.data.data))
    } catch (err) {
      console.log("getAcceptedGuaranteedForSponsorHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAcceptedGuaranteedGroupForSponsorBuyerHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedGuaranteedGroupForSponsorBuyer()
      dispatch(guaranteeActions.setBuyerCompanies(response.data.data))
    } catch (err) {
      console.log("getAcceptedGuaranteedGroupForSponsorBuyerHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAcceptedGuaranteedGroupForSponsorBuyerInvoiceHook = async (
    ptId: number
  ) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedGuaranteedGroupForSponsorBuyerInvoice(
        ptId
      )
      dispatch(guaranteeActions.setBuyerInvoices(response.data.data))
    } catch (err) {
      console.log(
        "getAcceptedGuaranteedGroupForSponsorBuyerInvoiceHook err => ",
        err
      )
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAcceptedGuaranteedGroupForSponsorSellerHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedGuaranteedGroupForSponsorSeller()
      dispatch(guaranteeActions.setSellerCompanies(response.data.data))
    } catch (err) {
      console.log("getAcceptedGuaranteedGroupForSponsorSellerHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAcceptedGuaranteedGroupForSponsorSellerInvoiceHook = async (
    ptId: number
  ) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedGuaranteedGroupForSponsorSellerInvoice(
        ptId
      )
      dispatch(guaranteeActions.setSellerInvoices(response.data.data))
    } catch (err) {
      console.log(
        "getAcceptedGuaranteedGroupForSponsorBuyerInvoiceHook err => ",
        err
      )
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getAcceptedGuaranteedForSponsorHook,
    getAcceptedGuaranteedGroupForSponsorBuyerHook,
    getAcceptedGuaranteedGroupForSponsorBuyerInvoiceHook,
    getAcceptedGuaranteedGroupForSponsorSellerHook,
    getAcceptedGuaranteedGroupForSponsorSellerInvoiceHook,
    histories,
    buyerCompanies,
    buyerInvoices,
    sellerCompanies,
    sellerInvoices,
  }
}

export default useGuaranteeHook
