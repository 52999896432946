import { ChakraProvider } from "@chakra-ui/react";
import { FC, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import SuspensePage from "./modules/SuspensePage";
import AppRoute from "./router/AppRoute";
import store from "./store";
import theme from "./theme";

const queryClient = new QueryClient();

const App: FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ChakraProvider theme={theme}>
              <AppRoute />
            </ChakraProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
