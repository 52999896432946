// ** React **
import { useEffect } from "react"

// ** Libs **
import {
  Heading,
  Box,
  Grid,
  GridItem,
  Flex,
  Container,
} from "@chakra-ui/react"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useAuthHook from "hooks/useAuthHook"
import useCreditHook from "hooks/useCreditHook"

// ** Constants **
import { UserRole } from "constants/Enum"

// ** Icons **
import { SponsorCoin } from "assets/icons"

// ** Utils **
import { numberFormatForCurrency } from "common/utils/transform"

// ** Type **
import { MyWallet } from "types/MyWallet"

const CreditSponserBox = ({ wallet }: { wallet: MyWallet }) => {
  const { getRole } = useAuthHook()

  const { getCreditSponsorBalanceHook, creditSponsorBalance } = useCreditHook()
  
  const { isLoading, refetch } = useQuery({
    queryKey: "initCreditSponserBalanceBox",
    queryFn: () => getCreditSponsorBalanceHook(`${wallet.id}`),
  })

  useEffect(() => { 
    refetch()
  }, [wallet, refetch])

  if (UserRole.BUYER !== getRole()) return null

  if (isLoading) return null

  return (
    <Container maxW="5xl" mt={8} mb={8}>
      <Heading size="sm" mb={4}>Credit Balance</Heading>
      <Grid templateColumns="repeat(2,1fr)" gap={5}>
        {creditSponsorBalance.map((creditSponsor) => (
          <GridItem>
            <Flex alignItems="center">
              <SponsorCoin w="48px" h="48px" mr={4} />
              <Box>
                <Box>Sponser {creditSponsor.name}</Box>
                <Box>Credit Balance : {numberFormatForCurrency(creditSponsor?.credit?.amount)} {creditSponsor.name}</Box>
                <Box>Credit Total : {numberFormatForCurrency(`${creditSponsor?.credit?.credit_amount}`)} {creditSponsor.name}</Box>
                <Box>Guarantee Fee Rate : {numberFormatForCurrency(`${creditSponsor?.credit?.interest_rate}`)}%</Box>
              </Box>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Container>
  )
}

export default CreditSponserBox
