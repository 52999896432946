import { Icon, IconProps } from "@chakra-ui/react"

const OfferIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 90 90" fill="none" {...props}>
    <path
      d="M86.6654 41.1912V45.0245C86.6602 54.0096 83.7508 62.7523 78.3709 69.9488C72.9911 77.1452 65.429 82.4098 56.8127 84.9574C48.1963 87.505 38.9873 87.1991 30.559 84.0853C22.1307 80.9714 14.9348 75.2166 10.0444 67.6789C5.15397 60.1413 2.83116 51.2248 3.42235 42.2591C4.01354 33.2935 7.48706 24.7592 13.3249 17.929C19.1627 11.0987 27.052 6.33859 35.8162 4.35844C44.5803 2.37829 53.7498 3.28424 61.9571 6.94117M86.6654 11.6673L44.9987 53.3757L32.4987 40.8757"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default OfferIcon
