import { AxiosResponse } from "axios";
import $axios from "./instance";
import { PostLoginBody, PostLoginResponse } from "./types/postLoginType";

const postLogin = async (
  body: PostLoginBody
): Promise<AxiosResponse<PostLoginResponse>> => {
  const request = await $axios().post("/login", body);
  return request;
};

export default postLogin;
