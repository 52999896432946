// ** React **
import { useState } from "react"

// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import TextInput from "common/components/TextInput"
import CardContent from "common/components/CardContent"
import PrimaryButton from "common/components/PrimaryButton"

// ** Routers **
import { AUTHENTICATE_LOGIN_PAGE_PATH } from "router/AppPath"

// ** Icons **
import { EmailCheckIcon } from "common/components/Icon"

// ** Constants **
import { EMAIL_REGEX } from "constants/AppStrings"
import packageEnv from "../../../package.json"

// ** Types **
import type { PostRecoveryPasswordBody } from "api/types/postLoginType"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"
import useAppHook from "hooks/useAppHook"

enum ContentBody {
  DEFAULT,
  SUCCESS,
}

const ForgetPasswordPage = () => {
  const navigate = useNavigate()
  const { setAlert } = useAppHook()
  const { postRecoveryPasswordHook } = useAuthHook()

  const [contentBody, setContentBody] = useState<ContentBody>(
    ContentBody.DEFAULT
  )

  const { control, handleSubmit, reset } = useForm<PostRecoveryPasswordBody>({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  })

  const onSubmit: SubmitHandler<PostRecoveryPasswordBody> = async (data) => {
    const response = await postRecoveryPasswordHook(data)
    if (response.statusCode !== 200) {
      let alert: Alert = {
        visible: true,
        data: {
          type: AlertType.ERROR,
          description: response.message,
          button: {
            label: "Close",
          },
        },
      }
      setAlert(alert)
      return
    }
    reset()
    setContentBody(ContentBody.SUCCESS)
  }

  return (
    <div className="forgot-password-page">
      <div className="content">
        <Box display={contentBody === ContentBody.DEFAULT ? "block" : "none"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Flex direction="column" gap={2}>
                <Box mb={4}>
                  <Heading size="md" textAlign="center">
                    Recover Password
                  </Heading>
                </Box>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      id="email"
                      type="email"
                      label="Email"
                      placeholder="Enter your email"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter your email",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(EMAIL_REGEX).test(v) ||
                        "The system supports only english lengauge and @ is required EX. NITMX123@gmail.com",
                    },
                  }}
                />
                <Box mb={4}>
                  <PrimaryButton
                    colorScheme={"primary"}
                    backgroundColor={"primary"}
                    color={"white"}
                    type="submit"
                    w="100%"
                  >
                    Send
                  </PrimaryButton>
                </Box>
                <Text
                  textAlign="center"
                  decoration="underline"
                  fontWeight="semibold"
                  cursor="pointer"
                  onClick={() => navigate(AUTHENTICATE_LOGIN_PAGE_PATH)}
                >
                  Back to login
                </Text>
              </Flex>
            </CardContent>
          </form>
        </Box>
        <Box display={contentBody === ContentBody.SUCCESS ? "block" : "none"}>
          <CardContent>
            <Flex direction="column" justify="center" align="center">
              <Box>
                <EmailCheckIcon />
              </Box>
              <Box mt={8}>
                <Heading size="md">A link has been sent to your email.</Heading>
              </Box>
              <Box mt={1}>
                <Heading size="md">Please check your email.</Heading>
              </Box>
              <Box mt={8}>
                <PrimaryButton
                  size="md"
                  variant="outline"
                  w="100%"
                  onClick={() => navigate(AUTHENTICATE_LOGIN_PAGE_PATH)}
                >
                  Back to login
                </PrimaryButton>
              </Box>
            </Flex>
          </CardContent>
        </Box>
        <Box mt={4} mb={4} color="white" textAlign="center">
          Version {packageEnv.version}
        </Box>
      </div>
    </div>
  )
}

export default ForgetPasswordPage
