export enum UserRole {
  ADMIN = "Admin",
  OPERATION = "Operation",
  SELLER = "Seller",
  BUYER = "Buyer",
  FI = "Financial Institute",
  SPONSOR = "Sponsoring",
  TCG = "TCG",
}

export const TypeID: any = { 
  0: "ITMX",
  1: "Corporate",
  2: "Financial Institution",
  3: "TCG",
}

export const RoleID: any = {
  null: "ITMX",
  "Seller": "Seller",
  "Buyer": "Buyer",
  "Financial Institute": "Financing",
  "Sponsoring": "Sponsoring",
  "TCG": "บสย. (TCG)",
}

export enum FilterType {
  DROPDOWN,
  CHECKBOX,
}

export enum ListingOption {
  FINANCING = "Financing",
  BUYER_PREPAYMENT = "Buyer Prepayment",
  GUARANTEE_DISCOUNTING = "Guarantee Discounting",
}

export const TIME_FRAME: any = {
  "All Time": null,
  "24 Hr.": 24 * 60 * 60 * 1000, // 24 hours in milliseconds
  "1 Day": 24 * 60 * 60 * 1000, // 1 day in milliseconds
  "3 Days": 3 * 24 * 60 * 60 * 1000, // 3 days in milliseconds
  "1 Week": 7 * 24 * 60 * 60 * 1000, // 1 week in milliseconds
  "2 Weeks": 14 * 24 * 60 * 60 * 1000, // 2 weeks in milliseconds
  "1 Month": 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
  "3 Months": 90 * 24 * 60 * 60 * 1000, // 90 days in milliseconds
}

export type ListingOptionKey = keyof typeof ListingOption

export enum CreditBody {
  DEFAULT,
  MINT,
  ADJUSTMENT,
}

export enum RevenueByBuyerBody {
  DEFAULT,
  LIST,
}

export enum CLientManagementBody {
  LIST,
  DETAIL,
}

export enum FormWithConfirmPasswordBody {
  FORM,
  PASSWORD,
}

export enum FormReviewWithConfirmPasswordBody {
  FORM,
  REVIEW,
  PASSWORD,
}

export enum FormReviewBody {
  FORM,
  REVIEW,
}

export enum LandingBody {
  LIST,
  DETAIL,
}

export enum ListBody {
  LIST,
  DETAIL,
}

export enum SubpageBody {
  DEFAULT,
  LIST,
}
