import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Discounting, DiscountingByCompany } from "types/Discounting"

export interface DiscountingState {
  discountingsOp1: Discounting[]
  discountingsOp2: Discounting[]
  discountingsOp3: Discounting[]
  companiesOp1: DiscountingByCompany[]
  companiesOp2: DiscountingByCompany[]
  companiesOp3: DiscountingByCompany[]
}

export const initialCreditState: DiscountingState = {
  discountingsOp1: [],
  discountingsOp2: [],
  discountingsOp3: [],
  companiesOp1: [],
  companiesOp2: [],
  companiesOp3: [],
}

const discountingSlice = createSlice({
  name: "discounting",
  initialState: initialCreditState,
  reducers: {
    setDiscountingsOp1: (state, action: PayloadAction<Discounting[]>) => {
      state.discountingsOp1 = action.payload
    },
    setDiscountingsOp2: (state, action: PayloadAction<Discounting[]>) => {
      state.discountingsOp2 = action.payload
    },
    setDiscountingsOp3: (state, action: PayloadAction<Discounting[]>) => {
      state.discountingsOp3 = action.payload
    },
    setCompaniesOp1: (state, action: PayloadAction<DiscountingByCompany[]>) => {
      state.companiesOp1 = action.payload
    },
    setCompaniesOp2: (state, action: PayloadAction<DiscountingByCompany[]>) => {
      state.companiesOp2 = action.payload
    },
    setCompaniesOp3: (state, action: PayloadAction<DiscountingByCompany[]>) => {
      state.companiesOp3 = action.payload
    },
  },
})

export const discountingReducer = discountingSlice.reducer
export const discountingActions = discountingSlice.actions
