// ** React **
import { FC, useState, useEffect } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { CreateWalletBody } from "types/Participant"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { FormWithConfirmPasswordBody } from "constants/Enum"
import { ACCOUNT_NUMBER_REGEX } from "constants/AppStrings"

// ** Utils **
import { bankOptionsAvalible } from "common/utils/bank"

// ** Icons **
import IconOption from "assets/icons/bank"

type ParticipantDetailEditModalProps = {} & ModalProps
const ParticipantCreateWalletModalForAdmin: FC<
  ParticipantDetailEditModalProps
> = (props) => {
  const { isOpen, onClose } = props
  const { setAlert } = useAppHook()

  const [contentBody, setContentBody] = useState<FormWithConfirmPasswordBody>(
    FormWithConfirmPasswordBody.FORM
  )

  const { createParticipantWalletHook, participantDetail } =
    useParticipantHook()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<CreateWalletBody>({
    defaultValues: {},
    mode: "onChange",
  })

  const bank = watch("bank")
  const statusOption = watch("statusOption")

  useEffect(() => {
    if (participantDetail !== null) {
      reset({
        pt_id: participantDetail.pt_id,
      })
    }
  }, [participantDetail, reset])

  useEffect(() => {
    if (bank !== undefined) {
      setValue("bank_name", bank.label)
      setValue("bank_code", bank.code)
    }
  }, [bank, setValue])

  useEffect(() => {
    if (statusOption) {
      setValue("status", statusOption.value)
    }
  }, [statusOption, setValue])

  const validateForm = () => {
    const bank_account_number = watch("bank_account_number")
    const statusOption = watch("statusOption")
    if (bank === undefined)
      setError("bank", { message: "Please enter your bank name" })
    if (bank_account_number === undefined)
      setError("bank_account_number", {
        message: "Please enter your bank account number",
      })
    if (!RegExp(ACCOUNT_NUMBER_REGEX).test(bank_account_number))
      setError("bank_account_number", {
        message: "Please enter only number from 10-35 digits",
      })
    if (statusOption === undefined)
      setError("statusOption", { message: "Please enter Status" })
    if (
      bank !== undefined &&
      bank_account_number !== undefined &&
      statusOption !== undefined &&
      RegExp(ACCOUNT_NUMBER_REGEX).test(bank_account_number) === true
    ) {
      setContentBody(FormWithConfirmPasswordBody.PASSWORD)
    }
  }

  const onSubmit: SubmitHandler<CreateWalletBody> = async (data) => {
    let body: any = { ...data }
    delete body.bank
    delete body.statusOption
    const response = await createParticipantWalletHook(body)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Create Wallet Complete",
        button: {
          label: "Close",
        },
      }
      reset()
      onClose()
      setContentBody(FormWithConfirmPasswordBody.FORM)
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
      if (response.statusCode === 400) {
        setContentBody(FormWithConfirmPasswordBody.PASSWORD)
      } else {
        setContentBody(FormWithConfirmPasswordBody.FORM)
      }
    }
    setAlert(alert)
    setValue("password", "")
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.FORM
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="md">Add Wallet</Heading>
                </Box>
                <Controller
                  control={control}
                  name="bank_account_number"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Account Number"
                      placeholder="Enter your bank account number"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your bank account number",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(ACCOUNT_NUMBER_REGEX).test(v) ||
                        "Please enter only number from 10-35 digits",
                    },
                  }}
                />
                <Box mb={5} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Bank</Heading>
                  </Box>
                  <Controller
                    name="bank"
                    render={({ field, fieldState: { error } }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select bank"
                          options={bankOptionsAvalible()}
                          components={{ Option: IconOption }}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: "Please select Bank" }}
                  />
                  {errors.bank && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.bank.message}
                    </Text>
                  )}
                </Box>
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Status</Heading>
                  </Box>
                  <Controller
                    name="statusOption"
                    render={({ field, fieldState: { error } }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select status"
                          options={[
                            { value: "active", label: "Active" },
                            { value: "inactive", label: "Inactive" },
                          ]}
                          onChange={(value) => field.onChange(value)}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: "Please select status" }}
                  />
                  {errors.statusOption && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.statusOption.message}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Submit
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ParticipantCreateWalletModalForAdmin
