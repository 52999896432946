import { Icon, IconProps } from "@chakra-ui/react"

const CalendarIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 74 82" fill="none" {...props}>
    <path
      d="M53.668 3.5V20.1667M20.3346 3.5V20.1667M3.66797 36.8333H70.3346M3.66797 20.1667C3.66797 17.9565 4.54594 15.8369 6.10875 14.2741C7.67155 12.7113 9.79116 11.8333 12.0013 11.8333H62.0013C64.2114 11.8333 66.3311 12.7113 67.8939 14.2741C69.4567 15.8369 70.3346 17.9565 70.3346 20.1667V70.1667C70.3346 72.3768 69.4567 74.4964 67.8939 76.0592C66.3311 77.622 64.2114 78.5 62.0013 78.5H12.0013C9.79116 78.5 7.67155 77.622 6.10875 76.0592C4.54594 74.4964 3.66797 72.3768 3.66797 70.1667V20.1667Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.8359 57.6667C32.8359 58.7717 33.2749 59.8315 34.0563 60.6129C34.8377 61.3943 35.8975 61.8333 37.0026 61.8333C38.1077 61.8333 39.1675 61.3943 39.9489 60.6129C40.7303 59.8315 41.1693 58.7717 41.1693 57.6667C41.1693 56.5616 40.7303 55.5018 39.9489 54.7204C39.1675 53.939 38.1077 53.5 37.0026 53.5C35.8975 53.5 34.8377 53.939 34.0563 54.7204C33.2749 55.5018 32.8359 56.5616 32.8359 57.6667Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CalendarIcon
