import { Box, Card, Flex } from "@chakra-ui/react"
import { useState } from "react"
import TabCustom from "../../common/components/TabCustom"
import BiddingStatusTab from "./tab/BiddingStatusTab"
import BiddingTab from "./tab/BiddingTab"

const BiddingAndOfferingPage = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const tabOpts = [
    { label: "Bidding", icon: null, activeIcon: null, active: tabIndex === 0 },
    {
      label: "Bidding Status",
      icon: null,
      activeIcon: null,
      active: tabIndex === 1,
    },
  ]

  return (
    <Card p={6}>
      <Flex direction="column" gap={4}>
        <Box w="300px">
          <TabCustom
            tabIndex={tabIndex}
            tabOpt={tabOpts}
            onChange={(index) => setTabIndex(index)}
          ></TabCustom>
        </Box>
        <Box>
          {tabIndex === 0 && <BiddingTab />}
          {tabIndex === 1 && <BiddingStatusTab />}
        </Box>
      </Flex>
    </Card>
  )
}

export default BiddingAndOfferingPage
