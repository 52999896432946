// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import {
  Flex,
  Box,
  Text,
  HStack,
  Heading,
  useDisclosure,
} from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Components **
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import PrimaryButton from "common/components/PrimaryButton"
import { CreateParticipantModal } from "modules/client_management"

// ** Types **
import { CompanyInfo } from "types/Participant"

// ** Constants **
import { CLientManagementBody } from "constants/Enum"

// ** Utils **
import {
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Icons **
import { PlusIcon } from "common/components/Icon"

const ClientList = ({
  setContentBody,
  setSelectCompany,
}: {
  setContentBody: any
  setSelectCompany: any
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])

  const createParticipantModalAction = useDisclosure()
  const { getParticipantsHook, participants } = useParticipantHook()

  const { isLoading } = useQuery({
    queryKey: "initClientList",
    queryFn: getParticipantsHook,
  })

  useEffect(() => {
    if (participants.length > 0) {
      setData(participants)
    }
  }, [participants])

  const column: TableColumn[] = [
    {
      title: "Company Name",
      value: "company_name",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {capitalizeFirstLetter(value.company_name)}
          </Text>
        )
      },
    },
    {
      title: "Juristic ID",
      value: "juristic_id",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {value.juristic_id}
          </Text>
        )
      },
    },
    {
      title: "Type",
      value: "type_name",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {value.type_name}
          </Text>
        )
      },
    },
    {
      title: "Total Wallet",
      value: "total_wallet",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {value.total_wallet}
          </Text>
        )
      },
    },
    {
      title: "Total Credit",
      value: "credit_balance",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {numberFormatForCurrency(value.credit_balance)}
          </Text>
        )
      },
    },
    {
      title: "Total Amount (NEW)",
      value: "token_balance",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {numberFormatForCurrency(value.token_balance)}
          </Text>
        )
      },
    },
    {
      title: "Status",
      value: "status",
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
  ]

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  const onClick = (data: CompanyInfo) => {
    setSelectCompany(data)
    setContentBody(CLientManagementBody.DETAIL)
  }

  useEffect(() => {
    const filterValue = participants.filter((m) =>
      (m.company_name + m.juristic_id)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, participants])

  if (isLoading) return <></>

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <PrimaryButton
          colorScheme="primary"
          backgroundColor="primary"
          color="white"
          w="235px"
          size="lg"
          onClick={() => createParticipantModalAction.onOpen()}
        >
          <HStack>
            <PlusIcon />
            <Heading size="sm">Create Participant</Heading>
          </HStack>
        </PrimaryButton>
      </Flex>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        style={TableStyle.BOX}
        onRowClick={onClick}
        searchPlaceholder="Company / Juristic ID"
        onSearchChange={onSearchChange}
      />
      {createParticipantModalAction.isOpen && (
        <CreateParticipantModal {...createParticipantModalAction} />
      )}
    </Box>
  )
}

export default ClientList
