import { Box, Card, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import _, { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import ListInvoiceModalContainer from "../../common/components/ListInvoiceModal/ListInvoiceModalContainer";
import ContainerTable, {
  TableStyle,
} from "../../common/components/Table/ContainerTable";
import { TableColumn } from "../../common/components/Table/ListDataTable";
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "../../common/utils/transform";
import useInvoiceHook from "../../hooks/useInvoiceHook";
import { useQuery } from "../../hooks/useQueryHook";
import useWalletHook from "../../hooks/useWalletHook";
import { Invoice } from "../../types/Invoice";
import { MyWallet } from "../../types/MyWallet";

const MyInvoicePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [data, setData] = useState<Invoice[]>([]);
  const [fillValue, setFillValue] = useState<string>("");
  const modalAction = useDisclosure();

  const { getInvoicesHook, myInvoices } = useInvoiceHook();
  const { getCompanyWalletHook, myWallet } = useWalletHook();

  const initPage = async () => {
    return {
      myInvoices: await getInvoicesHook(),
      myWallet: await getCompanyWalletHook(),
    };
  };

  const { isLoading, refetch } = useQuery({
    queryKey: "getInvoicesHook",
    queryFn: initPage,
  });

  const column: TableColumn[] = [
    {
      title: "heros",
      value: "",
    },
    {
      title: "DocNo",
      value: "doc_number",
    },
    {
      title: "DocType",
      value: "doc_number",
      customRender: (key) => {
        return (
          <Heading key={key} size="xs" color="#353535">
            Invoice
          </Heading>
        );
      },
    },
    {
      title: "Buyer Name",
      value: "buyer_name",
      customRender(key, value) {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.buyer_name)}
          </Text>
        );
      },
    },
    {
      title: "Seller Name",
      value: "seller_name",
      customRender(key, value) {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.seller_name)}
          </Text>
        );
      },
    },
    {
      title: "Doc Date",
      value: "doc_date",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="#353535">
            {formatDateDD_MMMM_YYYY(value.doc_date)}
          </Heading>
        );
      },
    },
    {
      title: "Payment Date",
      value: "payment_date",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="#353535">
            {formatDateDD_MMMM_YYYY(value.payment_date)}
          </Heading>
        );
      },
    },
    {
      title: "Amount",
      value: "amount",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="primary">
            {numberFormatForCurrency(value.amount)}
          </Heading>
        );
      },
    },
    {
      title: "Currency",
      value: "currency",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="primary">
            {value.currency}
          </Heading>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue);
    }, 1000),
    []
  );

  const onRowClick = (data: Invoice) => {
    setSelectedInvoice(data);
    modalAction.onOpen();
  };

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize]);

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  );

  useEffect(() => {
    setData(myInvoices);
  }, [myInvoices]);

  useEffect(() => {
    if (myWallet[0]) {
      setSelectedWallet(myWallet[0]);
    }
  }, [myWallet]);

  useEffect(() => {
    const filterValue: Invoice[] = myInvoices.filter((m) =>
      (m.buyer_name + m.doc_number)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    );
    setData(filterValue);
  }, [fillValue, myInvoices]);

  useEffect(() => {
    if (!modalAction.isOpen) {
      refetch();
    }
  }, [modalAction.isOpen, refetch]);

  if (isLoading || !selectedWallet) return <></>;

  return (
    <>
      <Card p={6}>
        <Flex direction="column" align="flex-end">
          <Box w="100%">
            <ContainerTable
              column={column}
              data={displayData}
              rawData={data}
              currentPage={currentPage}
              pageSize={pageSize}
              onViewSizeChange={(size) => {
                setPageSize(size)
                setCurrentPage(1)
              }}
              onPageChange={(page: number) => setCurrentPage(page)}
              totalCount={data.length}
              searchPlaceholder="Debtor Name / Invoice id"
              onSearchChange={onSearchChange}
              style={TableStyle.BOX}
              onRowClick={onRowClick}
            />
          </Box>
        </Flex>
      </Card>
      <ListInvoiceModalContainer
        {...modalAction}
        invoice={selectedInvoice}
        selectedWallet={selectedWallet}
      />
    </>
  );
};

export default MyInvoicePage;
