// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Text } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"

// ** Constants **
import {
  TIME_FRAME,
  WALLET_STATUS,
} from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useTokenManagementHook from "hooks/useTokenManagementHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  capitalizeFirstLetter,
  formatDateTimeStamp,
  removeDuplicatesFields,
  filterDataByTimeframe,
} from "common/utils/transform"

// ** Types **
import { WalletCredit } from "types/TokenManagement"

const CreditHolder = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("All Status")
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [currency, setCurrency] = useState("")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<WalletCredit[]>([])

  const { getAllWalletWithCreditBalanceHook, allWalletWithCreditBalance } =
    useTokenManagementHook()

  const { isLoading } = useQuery({
    queryKey: "getAllWalletWithCreditBalanceHook",
    queryFn: getAllWalletWithCreditBalanceHook,
  })

  useEffect(() => {
    setData(allWalletWithCreditBalance)
  }, [allWalletWithCreditBalance])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: WalletCredit[] = allWalletWithCreditBalance

    if (trxType !== "All Status") {
      temp = temp.filter((m) => m.status === trxType.toLocaleLowerCase())
    }

    if (currency) {
      temp = temp.filter((m) => m.currency === currency)
    }

    temp = removeDuplicatesFields(temp, ["wallet_address", "currency"])
    temp = filterDataByTimeframe(temp, timeFrame, "updated_at")
    temp.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )

    const filterValue: WalletCredit[] = temp.filter((m) =>
      (m.company_name + m.bank_name + m.wallet_address + m.juristic_id + m.nickname)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, allWalletWithCreditBalance, trxType, currency, timeFrame])

  if (isLoading) return <></>

  const tyxTypeOpts = WALLET_STATUS.map((m) => ({ title: m, value: m }))

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const currencyOpts = [
    { title: "All Currency", value: "" },
    ...Array.from(
      new Set(allWalletWithCreditBalance.map((item) => item.currency))
    ).map((m) => ({ title: m, value: m })),
  ]

  const column = [
    {
      title: "Holder name",
      value: "holder_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>
          {value.nickname ?? ""} ({abbreviateHexString(value.wallet_address)})
        </Text>
      ),
    },
    {
      title: "Corporate Name",
      value: "corporate_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.company_name)} - {value.juristic_id}</Text>
      ),
    },
    {
      title: "Holder Type",
      value: "holder_type",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => <Text>Corporates</Text>,
    },
    {
      title: "Amount (Credit)",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.balance)}</Text>
      ),
    },
    {
      title: "Credit Type",
      value: "credit_type",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => <Text>{value.currency}</Text>,
    },
    {
      title: "Guarantee Fee Rate %",
      value: "guarantee_fee_rate",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.guarantee_fee_rate)} %</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Time Stamp",
      value: "updated_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.updated_at)}</Text>
      ),
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
    {
      options: currencyOpts,
      onChange: (value: string) => setCurrency(value),
      value: currency,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Holder Name / Corporate Name"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
    </Box>
  )
}

export default CreditHolder
