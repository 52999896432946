import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetOwnOfferHistoryResponse } from "./types/getOwnOfferHistoryType";

const getOwnOfferHistory = async (): Promise<
  AxiosResponse<GetOwnOfferHistoryResponse>
> => {
  const response = await $axios().get("/own_offer_history");
  return response;
};

export default getOwnOfferHistory;
