import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ArItem, Alert } from "../../types/Ar"

export interface ArState {
  arapCurrentOp1: ArItem[]
  arapCurrentOp2: ArItem[]
  arapCurrentOp3: ArItem[]
  arapHistoryOp1: ArItem[]
  arapHistoryOp2: ArItem[]
  arapHistoryOp3: ArItem[]
  alert: Alert[]
}

export const initialArState: ArState = {
  arapCurrentOp1: [],
  arapCurrentOp2: [],
  arapCurrentOp3: [],
  arapHistoryOp1: [],
  arapHistoryOp2: [],
  arapHistoryOp3: [],
  alert: [],
}

const arSlice = createSlice({
  name: "ar",
  initialState: initialArState,
  reducers: {
    setArCurrentOp1: (state, action: PayloadAction<ArItem[]>) => {
      state.arapCurrentOp1 = action.payload
    },
    setArCurrentOp2: (state, action: PayloadAction<ArItem[]>) => {
      state.arapCurrentOp2 = action.payload
    },
    setArCurrentOp3: (state, action: PayloadAction<ArItem[]>) => {
      state.arapCurrentOp3 = action.payload
    },
    setArHistoryOp1: (state, action: PayloadAction<ArItem[]>) => {
      state.arapHistoryOp1 = action.payload
    },
    setArHistoryOp2: (state, action: PayloadAction<ArItem[]>) => {
      state.arapHistoryOp2 = action.payload
    },
    setArHistoryOp3: (state, action: PayloadAction<ArItem[]>) => {
      state.arapHistoryOp3 = action.payload
    },
    setAlert: (state, action: PayloadAction<Alert[]>) => {
      state.alert = action.payload
    },
  },
})

export const arReducer = arSlice.reducer
export const arActions = arSlice.actions
