// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Image,
  Heading,
} from "@chakra-ui/react"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import { useQuery } from "hooks/useQueryHook"
import useCampaignHook from "hooks/useCampaignHook"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

type CampaignConfirmDeleteModalProps = {
  id: number
} & ModalProps

const CampaignConfirmDeleteModal: FC<CampaignConfirmDeleteModalProps> = (
  props
) => {
  const { isOpen, onClose, id } = props
  const { setAlert } = useAppHook()

  const { getCampaignHook, deleteCampaignHook, campaign } = useCampaignHook()

  const { isLoading } = useQuery({
    queryKey: "initCampaignDetail",
    queryFn: () => getCampaignHook(id),
  })

  if (isLoading) return <></>

  const handleRemove = async () => {
    const response = await deleteCampaignHook(id)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "News & Campaign has been removed.",
        button: {
          label: "Close",
        },
      }
      onClose()
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Box mb={4} textAlign="center">
            <Heading fontSize="24px" color="#353535">
              Remove News & Campaign
            </Heading>
          </Box>
          <Flex alignItems="center">
            {campaign.logo ? (
              <Image
                w={40}
                src={process.env.REACT_APP_BASE_URL + "/" + campaign.logo}
              />
            ) : (
              <Box w={40} h={40} bgColor="grey400" borderRadius={8} />
            )}
            <Text fontWeight="bold">{campaign.title}</Text>
          </Flex>
          <Box mb={4} textAlign="center">
            <Heading fontSize="18px" color="#353535">
              Are you sure to remove this News & Campaign?
            </Heading>
          </Box>
          <Flex mt={8} gap={4}>
            <PrimaryButton
              colorScheme="red"
              backgroundColor="red"
              color="white"
              onClick={handleRemove}
            >
              Remove
            </PrimaryButton>
            <PrimaryButton variant="outline" onClick={() => onClose()}>
              Cancel
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CampaignConfirmDeleteModal
