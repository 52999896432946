import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetWalletByIdResponse } from "./types/getWalletBalance";

const getWalletById = async (
  walletAddress: string
): Promise<AxiosResponse<GetWalletByIdResponse>> => {
  const request = await $axios().get("/balance/" + walletAddress);
  return request;
};

export default getWalletById;
