import { Icon, IconProps } from "@chakra-ui/react"

const CoinBalanceIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 90 90" fill="none" {...props}>
    <path
      d="M37.0863 11.6673C42.3468 6.51237 49.5516 3.33398 57.4987 3.33398C73.607 3.33398 86.6654 16.3923 86.6654 32.5006C86.6654 40.4479 83.4869 47.6527 78.3318 52.9133M61.6654 57.5006C61.6654 73.609 48.607 86.6673 32.4987 86.6673C16.3904 86.6673 3.33203 73.609 3.33203 57.5006C3.33203 41.3923 16.3904 28.334 32.4987 28.334C48.607 28.334 61.6654 41.3923 61.6654 57.5006Z"
      stroke="#FBFDFF"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CoinBalanceIcon
