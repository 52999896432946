import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FC, forwardRef } from "react";
import { FieldError } from "react-hook-form";

type TextInputProps = {
  label?: string;
  labelSmall?: boolean;
  error?: FieldError;
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
} & InputProps;

const TextInput: FC<TextInputProps> = forwardRef((props, ref) => {
  const { id, label, labelSmall, error, rightIcon, leftIcon, ...propsInput } = props;

  return (
    <Stack spacing={2} className="password-input" w="100%">
      {label && (
        <Box>
          <label htmlFor={id} className="label">
            {labelSmall ? (
              <Text color="grey3" fontSize="sm">
                {label}
              </Text>
            ) : (
              <Heading size="sm">{label}</Heading>
            )}
          </label>
        </Box>
      )}
      <Box mb={labelSmall ? 0 : 4}>
        <Stack>
          <InputGroup>
            {leftIcon ? (
              <InputLeftElement pointerEvents="none">
                {leftIcon}
              </InputLeftElement>
            ) : (
              <></>
            )}
            <Input
              {...propsInput}
              ref={ref}
              _hover={{ borderColor: "none" }}
              _focusVisible={{ borderColor: "none" }}
              _disabled={{
                background: "#C2C2C2",
                border: "1px solid rgba(189, 189, 189, 0.30)",
              }}
            />
            {rightIcon ? (
              <InputRightElement pointerEvents="none">
                {rightIcon}
              </InputRightElement>
            ) : (
              <></>
            )}
          </InputGroup>
          {error && (
            <Text fontSize="sm" color={"error"}>
              {error?.message}
            </Text>
          )}
        </Stack>
      </Box>
    </Stack>
  );
});

export default TextInput;
