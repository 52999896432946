// ** React **
import { FC } from "react"

// ** Lis **
import { Flex, Box, Text, Image } from "@chakra-ui/react"

// ** Types **
import { Announce } from "types/Campaign"

// ** Utils **
import { formatDateDD_MMMM_YYYY } from "common/utils/transform"

const NewsDetail: FC<Announce> = ({
  title,
  owner_first_name,
  owner_last_name,
  detail,
  date,
  logo,
  cover,
}) => {
  return (
    <Box
      borderRadius="lg"
      sx={{
        boxShadow:
          "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box position="relative">
        <Image
          width="100%"
          borderRadius="8px 8px 0 0"
          src={process.env.REACT_APP_BASE_URL + "/" + cover}
          alt="cover"
        />
        <Flex position="absolute" width="100%" bottom="-30px">
          <Image
            position="absolute"
            left="0"
            right="0"
            margin="auto"
            borderRadius="8px"
            src={process.env.REACT_APP_BASE_URL + "/" + logo}
            alt="cover"
            bottom="0"
          />
        </Flex>
      </Box>
      <Box bgColor="white" borderRadius="0 0 8px 8px" p={10} pt={16}>
        <Box borderBottom="1px solid" borderColor="disable" mb={4}>
          <Text fontSize="md" color="secondaryTextColor" mb={2}>
            Title
          </Text>
          <Text fontWeight="bold" pb={4}>
            {title}
          </Text>
        </Box>
        <Box borderBottom="1px solid" borderColor="disable" mb={4}>
          <Text fontSize="md" color="secondaryTextColor" mb={2}>
            Description
          </Text>
          <Text pb={4}>{detail}</Text>
        </Box>
        <Box borderBottom="1px solid" borderColor="disable" mb={4}>
          <Text fontSize="md" color="secondaryTextColor" mb={2}>
            Owner
          </Text>
          <Text fontWeight="bold" pb={4}>
            {owner_first_name} {owner_last_name}
          </Text>
        </Box>
        <Box borderBottom="1px solid" borderColor="disable" mb={4}>
          <Text fontSize="md" color="secondaryTextColor" mb={2}>
            Date
          </Text>
          <Text fontWeight="bold" pb={4}>
            {formatDateDD_MMMM_YYYY(date)}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default NewsDetail
