// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Grid,
  Flex,
  Box,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import SuccessBadge from "common/components/SuccessBadge"
import PrimaryButton from "common/components/PrimaryButton"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { Discounting } from "types/Discounting"

// ** Utils **
import {
  abbreviateHexString,
  formatDateDD_MMMM_YYYY,
  formatDateTimeStamp,
  numberFormatForCurrency,
  invoiceOptionIdToString,
  exportToCSV,
  downloadCSV,
} from "common/utils/transform"

type DiscountingModalProps = {
  data?: Discounting
  onClickClose: VoidFunction
} & ModalProps

const DiscountingModal: FC<DiscountingModalProps> = (props) => {
  const { isOpen, onClose, data, onClickClose } = props

  if (data === undefined) return <></>

  const handleExport = () => {
    const content = exportToCSV([data], [])
    downloadCSV(content, `discounting_${data.id}.csv`)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Center>
            <Heading size="md">Invoice detail</Heading>
          </Center>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4}>
            <GridItemPopupDetail title="Doctype" value="Invoice" />
            <GridItemPopupDetail title="Doc No." value={data.doc_number} />
            <GridItemPopupDetail
              title="Buyer Juristic Id"
              value={data.buyer_juristic_id}
            />
            <GridItemPopupDetail
              title="Buyer Name"
              value={data.buyer_company_name}
            />
            <GridItemPopupDetail
              title="Seller Juristic Id"
              value={data.seller_juristic_id}
            />
            <GridItemPopupDetail
              title="Seller Name"
              value={data.seller_company_name}
            />
            <GridItemPopupDetail
              title="Doc Date"
              value={formatDateDD_MMMM_YYYY(data.doc_date)}
            />
            <GridItemPopupDetail
              title="Amount. (include VAT)"
              value={numberFormatForCurrency(data.amount)}
            />
            <GridItemPopupDetail title="Currency" value={data.currency} />
            <GridItemPopupDetail
              title="Schedule Payment Date"
              value={formatDateDD_MMMM_YYYY(data.payment_date)}
            />
            <GridItemPopupDetail
              title="Payment Guarantee Status"
              value={
                data.offer_option === 3 && data.offer_status === "accepted"
                  ? "Yes"
                  : "No"
              }
            />
            <GridItemPopupDetail
              title="Owner Name"
              value={data.seller_company_name}
            />
            <GridItemPopupDetail
              title="Holder Name"
              value={data?.holder_company_name ?? "-"}
            />
            <GridItemPopupDetail
              title="Holder Wallet"
              value={`${
                data!.holder_wallet_nickname ? data!.holder_wallet_nickname : ""
              } ${
                data!.holder_wallet_address
                  ? abbreviateHexString(data!.holder_wallet_address)
                  : ""
              }`}
            />
            <GridItemPopupDetail
              title="Offering Start Date"
              value={
                data!.offer_start_date
                  ? formatDateDD_MMMM_YYYY(data!.offer_start_date)
                  : "-"
              }
            />
            <GridItemPopupDetail
              title="Offering end Date"
              value={
                data!.offer_end_date
                  ? formatDateDD_MMMM_YYYY(data!.offer_end_date)
                  : "-"
              }
            />
            <GridItemPopupDetail
              title="Option"
              value={invoiceOptionIdToString(data.offer_option)}
            />
            <GridItemPopupDetail
              title="Total Discount (NEW)"
              value={
                data.discount ? numberFormatForCurrency(data.discount) : "-"
              }
            />
            <Flex
              w="100%"
              justify="space-between"
              borderBottom="1px solid #E1F1FF"
              p={3}
            >
              <Box>
                <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                  Status:
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" fontWeight={700} ml={1}>
                  <SuccessBadge
                    label={data.status === "overdue" ? "default" : "success"}
                  />
                </Text>
              </Box>
            </Flex>
            <GridItemPopupDetail
              title="Timestamp"
              value={
                data!.timestamp ? formatDateTimeStamp(data!.timestamp) : "-"
              }
            />
            <GridItemPopupDetail
              title="Offer Name"
              value={data.offer_company_name}
            />
            <GridItemPopupDetail
              title="Offer Wallet"
              value={
                data!.offer_wallet_address
                  ? abbreviateHexString(data!.offer_wallet_address)
                  : ""
              }
            />
            <GridItemPopupDetail
              title="Finance Date"
              value={formatDateDD_MMMM_YYYY(data.updated_at)}
            />
            <GridItemPopupDetail
              title="Financial Amount (NEW)"
              value={numberFormatForCurrency(data.amount)}
            />
          </Grid>
          <Grid templateColumns="repeat(1, 1fr)" gap={2} mt={4}>
            <GridItemPopupDetail
              title="Overdue"
              value={`${data.overdue_day ?? "-"} days`}
            />
            <Flex
              w="100%"
              justify="space-between"
              borderBottom="1px solid #E1F1FF"
              p={3}
            >
              <Box>
                <Text fontSize="lg" color="primary" fontWeight={700}>
                  Amount After Discount
                </Text>
              </Box>
              <Box>
                <Text fontSize="lg" color="primary" fontWeight={700} ml={1}>
                  {numberFormatForCurrency(
                    `${parseFloat(data.amount) - parseFloat(data.discount)}`
                  ) + " NEW"}
                </Text>
              </Box>
            </Flex>
          </Grid>
          <Flex mt={4} gap={4} justify="center">
            <PrimaryButton variant="outline" onClick={onClickClose} w="250px">
              Close
            </PrimaryButton>
            <PrimaryButton
              onClick={handleExport}
              bgColor="primary"
              colorScheme="white"
              w="250px"
            >
              Export to CSV
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DiscountingModal
