import dayjs from "dayjs";
import { ListingOption, TIME_FRAME, UserRole } from "../../constants/Enum";

export const abbreviateHexString = (
  hexString: string,
  prefixLength = 6,
  suffixLength = 6
) => {
  if (hexString === null) return "";
  if (hexString.length <= prefixLength + suffixLength) {
    return hexString; // No need to abbreviate
  }

  const prefix = hexString.slice(0, prefixLength);
  const suffix = hexString.slice(-suffixLength);

  return `${prefix}...${suffix}`;
};

export const numberFormat = (value: string): string => {
  const numberValue = Number(value);

  // Check if the converted value is not a number
  if (isNaN(numberValue)) {
    return "0"; // Return "0" if the value is not a number
  }

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
  }).format(Number(value));
};

export const numberFormatForCurrency = (value: string): string => {
  const numberValue = Number(value);

  // Check if the converted value is not a number
  if (isNaN(numberValue)) {
    return "0.00"; // Return "0.00" if the value is not a number
  }

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
  }).format(Number(value));
};

export const formatDateDD_MM_YYYY = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const formatDateDD_MMMM_YYYY = (date: string) => {
  return dayjs(date).format("DD MMMM YYYY");
};

export const formatDateTimeStamp = (date: string) => {
  return dayjs(date).format("DD MMMM YYYY HH:mm");
};

export function diffDate(endDate: Date, startDate: Date): number {
  const msPerSecond = 1000;
  const msPerMinute = 60 * msPerSecond;
  const msPerHour = 60 * msPerMinute;
  const msPerDay = 24 * msPerHour;

  const end = new Date(endDate);
  const start = new Date(startDate);
  const diff = end.getTime() - start.getTime();

  const days = Math.floor(diff / msPerDay);

  return days;
}

export const buildInvoiceListOption = (options: boolean[]) => {
  return `${options[0] ? ListingOption.FINANCING : ""}
  ${options[1] ? ListingOption.BUYER_PREPAYMENT : ""}
  ${options[2] ? ListingOption.GUARANTEE_DISCOUNTING : ""}`.trim();
};

export const invoiceOptionIdToString = (option: number) => {
  if (option === 1) return ListingOption.FINANCING;
  else if (option === 2) return ListingOption.BUYER_PREPAYMENT;
  else if (option === 3) return ListingOption.GUARANTEE_DISCOUNTING;
  else return "";
};

export const combinePathWithId = (path: string, id: string) => {
  return path.replace(":id", id);
};

export const pathCleanId = (path: string) => {
  const pathSplit = path.split("/");
  if (pathSplit[2]) {
    return path.replace(pathSplit[2], ":id");
  }
  return path;
};

export const buildOptionLabel = (option: number) => {
  if (option === 1) return "Interest rate";
  else if (option === 2) return "Discounting rate (per amount)";
  else if (option === 3) return "Commitment Fee (per amount)";
  else return "";
};

export const maskString = (input: string) => {
  if (input === "undefined") { 
    return ""
  } else if (input.length > 35) {
    return '*'.repeat(35);
  } else {
    return input.replace(/./g, '*');
  }
}

export function allowOnlyNumbersAndDecimals(input: string): string {
  // First, remove everything that is not a number or a decimal point
  let sanitizedInput = input.replace(/[^0-9.]+/g, '');

  // Next, handle multiple decimal points
  const firstDecimalIndex = sanitizedInput.indexOf('.');
  if (firstDecimalIndex !== -1) {
    // Keep the part before the first decimal, and then replace all other decimals in the remaining part
    sanitizedInput = sanitizedInput.slice(0, firstDecimalIndex + 1) + sanitizedInput.slice(firstDecimalIndex + 1).replace(/\./g, '');
  }

  return sanitizedInput;
}

export function allowOnlyNumbers(input: string): string {
  // Match a number with optional decimal part
  const matches = input.match(/^\d*(\.\d+)?$/);

  // If there's a match, return it, otherwise return an empty string
  return matches ? matches[0] : '';
}

export function roundupDecimal(input: string): string {
  const decimalPart = input.split(".")[1]
  if (!decimalPart) {
    return input
  } else if (decimalPart.split('').length > 2) {
    return input.split(".")[0] + "." + decimalPart.slice(0, 2)
  } else { 
    return input
  }
}

export function createShortDescription(description: string, maxLength: number = 100): string {
  // Check if the length of the description is greater than the maximum allowed length
  if (description.length > maxLength) {
    // If it is, return the substring from the start to the maxLength minus 3 (for the ellipsis) and add the ellipsis
    return description.substring(0, maxLength - 3) + '...';
  } else {
    // If not, return the original description
    return description;
  }
}

export function filterDataByTimeframe(data: any[], timeframe: string, field: string): any[] {
  const now = new Date().getTime();
  const timeframeDuration = TIME_FRAME[timeframe];

  if (timeframeDuration === null) {
    return data;
  }

  return data.filter(item => {
    const itemDate = new Date(item[field]).getTime();
    return now - itemDate <= timeframeDuration;
  });
}

export function removeDuplicates(data: any[], field: string): any[] {
  const seen = new Set();
  const uniqueData = [];

  for (const item of data) {
    if (!seen.has(item[field])) {
      seen.add(item[field]);
      uniqueData.push(item);
    }
  }

  return uniqueData;
}

export function removeDuplicatesFields(data: any[], fields: string[]): any[] {
  const seen = new Set();
  const uniqueData = [];

  for (const item of data) {
    // Create a unique key based on the combination of all field values
    const key = fields.map(field => item[field]).join('|');

    if (!seen.has(key)) {
      seen.add(key);
      uniqueData.push(item);
    }
  }

  return uniqueData;
}

export function exportToCSV(data: Record<string, any>[], excludeFields: string[] = []): string {
  // Filter out the excluded fields from each data object
  const filteredData = data.map(item => {
      const filteredItem = { ...item };
      excludeFields.forEach(field => delete filteredItem[field]);
      return filteredItem;
  });

  // Create CSV content
  const csvRows = [];
  const headers = Object.keys(filteredData[0]);
  csvRows.push(headers.join(','));

  for (const row of filteredData) {
      const values = headers.map(header => JSON.stringify(row[header]));
      csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
}

export function downloadCSV(csvContent: string, fileName: string = 'data.csv'): void {
  // Create a Blob from the CSV String
  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a link element, use it for download, and remove it when done
  const tempLink = document.createElement('a');
  tempLink.href = URL.createObjectURL(blob);
  tempLink.download = fileName;

  // This part will append the link to the body and trigger the download
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);

  // Release the blob URL
  URL.revokeObjectURL(tempLink.href);
}

export function add24Hours(dateStr: string): string {
  // Parse the input date string
  const date = new Date(dateStr);
  
  // Add 24 hours
  date.setHours(date.getHours() + 24);

  // Return the updated date as a string
  return date.toISOString();
}

export function removeObjectFromArray<T>(arr: T[], obj: T): T[] {
  return arr.filter(element => JSON.stringify(element) !== JSON.stringify(obj));
}

export function findFirstPositiveValueIndex(inputArray: string[]): number {
  for (let i = 0; i < inputArray.length; i++) {
      if (parseFloat(inputArray[i]) > 0) {
          return i;
      }
  }
  return -1;
}

export function findWalletAddressIndex(inputArray: string[], walletAddress: string): number {
  for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i] === walletAddress) {
          return i;
      }
  }
  return -1;
}

export const getTotalBiddingOptions = (userRole: string, options: boolean[]) => {
  let count = 0
  switch (userRole) {
    case UserRole.BUYER:
      // 2 = Buyer Prepayment
      // 3 = Guarantee Discounting
      if (options[1] === true) count++
      if (options[2] === true) count++
      break
    case UserRole.FI:
      // 1 = Financing
      // 3 = Guarantee Discounting
      if (options[0] === true) count++
      if (options[2] === true) count++
      break
    default:
      count = options.filter((m: boolean) => m === true).length
      break
  }
  return count
}

export const getDisplayBiddingOptions = (userRole: string, options: boolean[]) => {
  const displayOptions = []
  switch (userRole) {
    case UserRole.BUYER:
      if (options[1] === true) displayOptions.push("Buyer Prepayment")
      if (options[2] === true) displayOptions.push("Guarantee Discounting")
      break
    case UserRole.FI:
      if (options[0] === true) displayOptions.push("Financing")
      if (options[2] === true) displayOptions.push("Guarantee Discounting")
      break
    default:
      return buildInvoiceListOption(options)
  }
  return displayOptions.join(
    `
  `
  )
}

export const capitalizeFirstLetter = (input: string | null) => {
  if (input === null || input === undefined) return '';
  return input.charAt(0).toUpperCase() + input.slice(1);
}
