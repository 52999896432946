import {
  Center,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Box,
  Text,
  GridItem,
  Spacer,
} from "@chakra-ui/react"
import _, { debounce } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import GridItemPopupDetail from "../../../common/components/GridItemPopupDetail"
import PrimaryButton from "../../../common/components/PrimaryButton"
import {
  TableColumn,
  TableSortType,
} from "../../../common/components/Table/BoxDataTable"
import ContainerTable from "../../../common/components/Table/ContainerTable"
import {
  abbreviateHexString,
  formatDateTimeStamp,
  invoiceOptionIdToString,
  numberFormatForCurrency,
  capitalizeFirstLetter,
  formatDateDD_MMMM_YYYY,
  exportToCSV,
  downloadCSV,
} from "../../../common/utils/transform"
import useInvoiceHook from "../../../hooks/useInvoiceHook"
import { useQuery } from "../../../hooks/useQueryHook"
import { InvoiceOfferingHistory } from "../../../types/Invoice"
import SuccessBadge from "common/components/SuccessBadge"
import PopOver from "common/components/PopOver"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import { UserRole } from "constants/Enum"

const BiddingStatusTab = () => {
  const { getOwnOfferHistoryHook, offerHistory } = useInvoiceHook()
  const { isLoading } = useQuery({ queryFn: getOwnOfferHistoryHook })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<InvoiceOfferingHistory[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [viewData, setViewData] = useState<InvoiceOfferingHistory>()

  const { getRole } = useAuthHook()

  const statusMapping: any = {
    accepted: "success",
    pending: "pending",
    cancelled: "rejected",
    offered: "offered",
  }

  const popupStatus = () => {
    return (
      <Box ml={1} cursor="pointer">
        <PopOver header="Status Explanation" width="450px">
          <Box>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="offering" />
              </Text>
              <Text>: Offering in process</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="offered" />
              </Text>
              <Text>: Make an offer Waiting for Seller Accept</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="success" />
              </Text>
              <Text>: Accepted an offer</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="rejected" />
              </Text>
              <Text>: Timeout / Declined an offer</Text>
            </Flex>
          </Box>
        </PopOver>
      </Box>
    )
  }

  const column: TableColumn[] =
    getRole() === UserRole.FI
      ? [
          {
            title: "Seller Name",
            value: "seller_company_name",
            sortType: TableSortType.STRING,
            customRender(key, value) {
              return (
                <Text key={key}>
                  {value.seller_company_name
                    ? capitalizeFirstLetter(value.seller_company_name)
                    : ""}
                </Text>
              )
            },
          },
          {
            title: "Buyer Name",
            value: "buyer_company_name",
            sortType: TableSortType.STRING,
            customRender(key, value) {
              return (
                <Text key={key}>
                  {value.buyer_company_name
                    ? capitalizeFirstLetter(value.buyer_company_name)
                    : ""}
                </Text>
              )
            },
          },
          {
            title: "DocNo",
            value: "doc_number",
            sortType: TableSortType.STRING,
          },
          {
            title: "DocType",
            value: "doc_type",
            customRender: (key) => <div key={key}>Invoice</div>,
          },
          {
            title: "Interest rate (per year)",
            value: "offer_interest_rate",
            sortType: TableSortType.INTEGER,
            w: "210px",
            customRender(key, value) {
              return (
                <Text key={key} align="right">
                  {numberFormatForCurrency(value.offer_interest_rate)}%
                </Text>
              )
            },
          },
          {
            title: "Total Guarantee Fee (NEW)",
            value: "total_guarantee_fee",
            headerCenter: true,
            sortType: TableSortType.INTEGER,
            w: "240px",
            customRender(key, value) {
              return (
                <Text key={key} align="right">
                  {value.offer_option === 3
                    ? numberFormatForCurrency(
                        `${
                          parseFloat(value.total_guarantee_fee) +
                          parseFloat(value.commitment_fee)
                        }`
                      )
                    : "0.00"}
                </Text>
              )
            },
          },
          {
            title: "Amount(NEW)",
            value: "amount",
            headerCenter: true,
            sortType: TableSortType.INTEGER,
            customRender(key, value) {
              return (
                <Text key={key} textAlign="right">
                  {numberFormatForCurrency(value.amount)}
                </Text>
              )
            },
          },
          {
            title: "Option",
            value: "options",
            sortType: TableSortType.STRING,
            customRender(key, value) {
              return (
                <div key={key}>
                  {invoiceOptionIdToString(value.offer_option)}
                </div>
              )
            },
          },
          {
            title: "Status",
            value: "status",
            sortType: TableSortType.STRING,
            customSuffixRender: popupStatus,
            customRender(key, value) {
              return (
                <div key={key}>
                  <Text className="hidden">
                    {statusMapping[value.offer_status] ?? value.offer_status}
                  </Text>
                  <SuccessBadge
                    label={
                      statusMapping[value.offer_status] ?? value.offer_status
                    }
                  />
                </div>
              )
            },
          },
          {
            title: "Timestamp",
            value: "timestamp",
            sortType: TableSortType.ITEM,
            w: "220px",
            customRender(key, value) {
              return (
                <div key={key}>
                  {value.timestamp
                    ? formatDateTimeStamp(value.timestamp)
                    : formatDateTimeStamp(value.created_at)}
                </div>
              )
            },
          },
          {
            title: "Detail",
            value: "id",
            valueCenter: true,
            headerCenter: true,
            customRender(key, value) {
              return (
                <Heading
                  key={key}
                  size="xs"
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickShowDetail(value)}
                >
                  View
                </Heading>
              )
            },
          },
        ]
      : [
          {
            title: "Seller Name",
            value: "seller_company_name",
            sortType: TableSortType.STRING,
            customRender(key, value) {
              return (
                <Text key={key}>
                  {value.seller_company_name
                    ? capitalizeFirstLetter(value.seller_company_name)
                    : ""}
                </Text>
              )
            },
          },
          {
            title: "Buyer Name",
            value: "buyer_company_name",
            sortType: TableSortType.STRING,
            customRender(key, value) {
              return (
                <Text key={key}>
                  {value.buyer_company_name
                    ? capitalizeFirstLetter(value.buyer_company_name)
                    : ""}
                </Text>
              )
            },
          },
          {
            title: "DocNo",
            value: "doc_number",
            sortType: TableSortType.STRING,
          },
          {
            title: "DocType",
            value: "doc_type",
            customRender: (key) => <div key={key}>Invoice</div>,
          },
          {
            title: "Total Discounting (NEW)",
            value: "offer_discount",
            headerCenter: true,
            sortType: TableSortType.INTEGER,
            w: "220px",
            customRender(key, value) {
              return (
                <Text key={key} align="right">
                  {value.offer_option === 3
                    ? numberFormatForCurrency(
                        `${
                          parseFloat(value.total_guarantee_fee) +
                          parseFloat(value.commitment_fee)
                        }`
                      )
                    : value.offer_option === 2
                    ? numberFormatForCurrency(
                        `${parseFloat(value.offer_discount)}`
                      )
                    : "0.00"}
                </Text>
              )
            },
          },
          {
            title: "Amount (NEW)",
            value: "amount",
            headerCenter: true,
            sortType: TableSortType.INTEGER,
            customRender(key, value) {
              return (
                <Text key={key} align="right">
                  {numberFormatForCurrency(value.amount)}
                </Text>
              )
            },
          },
          {
            title: "Option",
            value: "options",
            sortType: TableSortType.STRING,
            customRender(key, value) {
              return (
                <div key={key}>
                  {invoiceOptionIdToString(value.offer_option)}
                </div>
              )
            },
          },
          {
            title: "Status",
            value: "status",
            sortType: TableSortType.STRING,
            customSuffixRender: popupStatus,
            customRender(key, value) {
              return (
                <div key={key}>
                  <Text className="hidden">
                    {statusMapping[value.offer_status] ?? value.offer_status}
                  </Text>
                  <SuccessBadge
                    label={
                      statusMapping[value.offer_status] ?? value.offer_status
                    }
                  />
                </div>
              )
            },
          },
          {
            title: "Timestamp",
            value: "timestamp",
            sortType: TableSortType.ITEM,
            w: "220px",
            customRender(key, value) {
              return (
                <div key={key}>
                  {value.timestamp
                    ? formatDateTimeStamp(value.timestamp)
                    : formatDateTimeStamp(value.created_at)}
                </div>
              )
            },
          },
          {
            title: "Detail",
            value: "id",
            valueCenter: true,
            headerCenter: true,
            customRender(key, value) {
              return (
                <Heading
                  key={key}
                  size="xs"
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickShowDetail(value)}
                >
                  View
                </Heading>
              )
            },
          },
        ]

  const onClickShowDetail = (data: InvoiceOfferingHistory) => {
    onOpen()
    setViewData(data)
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    setData(offerHistory)
  }, [offerHistory])

  useEffect(() => {
    let temp = [...offerHistory]

    if (temp.length > 0) {
      temp.sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      )
    }

    const filterValue = temp.filter((m) =>
      (
        m.seller_juristic_id +
        m.buyer_juristic_id +
        m.offer_juristic_id +
        m.seller_bank_code +
        m.buyer_bank_code +
        m.offer_bank_code
      )
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, offerHistory])

  if (isLoading) return <></>

  const handleExport = () => {
    if (viewData) {
      const content = exportToCSV([viewData], [])
      downloadCSV(content, `listing_${viewData.id}.csv`)
    }
  }

  return (
    <>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        searchPlaceholder="Juristic Id/ Bank Code"
        canExport={true}
        onSearchChange={onSearchChange}
      />

      {viewData && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={8}>
              {getRole() === UserRole.FI && (
                <Flex direction="column" gap={8}>
                  <Center>
                    <Heading size="md">Bidding History Detail</Heading>
                  </Center>
                  <Grid templateColumns="repeat(2,1fr)" gap={5}>
                    <GridItemPopupDetail title="Doc Type" value="Invoice" />
                    <GridItemPopupDetail
                      title="Doc No."
                      value={viewData!.doc_number ? viewData!.doc_number : ""}
                    />
                    <GridItemPopupDetail
                      title="Buyer Juristic ID"
                      value={viewData!.buyer_juristic_id}
                    />
                    <GridItemPopupDetail
                      title="Buyer Name"
                      value={capitalizeFirstLetter(
                        viewData!.buyer_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Seller Juristic ID"
                      value={viewData!.seller_juristic_id}
                    />
                    <GridItemPopupDetail
                      title="Seller Name"
                      value={capitalizeFirstLetter(
                        viewData!.seller_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Doc Date"
                      value={formatDateDD_MMMM_YYYY(viewData!.doc_date)}
                    />
                    <GridItemPopupDetail
                      title="Amount (Include VAT)"
                      value={numberFormatForCurrency(
                        viewData!.amount?.toString()
                      )}
                    />
                    <GridItemPopupDetail
                      title="Currency"
                      value={viewData!.currency}
                    />
                    <GridItemPopupDetail
                      title="Schedule Payment Date"
                      value={formatDateDD_MMMM_YYYY(viewData!.payment_date)}
                    />
                    <GridItemPopupDetail
                      title="Payment Guarantee Status"
                      value={viewData?.offer_option === 3 ? "Yes" : "No"}
                    />
                    <GridItemPopupDetail
                      title="Owner Name"
                      value={capitalizeFirstLetter(
                        viewData!.seller_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Holder Name"
                      value={viewData!.holder_company_name ?? ""}
                    />
                    <GridItemPopupDetail
                      title="Holder Wallet"
                      value={`${
                        viewData!.holder_wallet_nickname
                          ? viewData!.holder_wallet_nickname
                          : ""
                      } ${
                        viewData!.holder_wallet_address
                          ? abbreviateHexString(viewData!.holder_wallet_address)
                          : ""
                      }`}
                    />
                    <GridItemPopupDetail
                      title="Offering Start Date"
                      value={
                        viewData!.offer_start_date
                          ? formatDateDD_MMMM_YYYY(viewData!.offer_start_date)
                          : ""
                      }
                    />
                    <GridItemPopupDetail
                      title="Offering end Date"
                      value={
                        viewData!.offer_end_date
                          ? formatDateDD_MMMM_YYYY(viewData!.offer_end_date)
                          : ""
                      }
                    />
                    <GridItemPopupDetail
                      title="Option"
                      value={invoiceOptionIdToString(viewData!.offer_option)}
                    />
                    <GridItemPopupDetail
                      title="Interest rate(per year)"
                      value={
                        viewData!.offer_interest_rate
                          ? numberFormatForCurrency(
                              viewData!.offer_interest_rate
                            ) + "%"
                          : "-%"
                      }
                    />
                    <GridItemPopupDetail
                      title="Total Guarantee Fee (NEW)"
                      value={
                        viewData!.offer_option === 3 &&
                        viewData!.total_guarantee_fee
                          ? numberFormatForCurrency(
                              `${
                                parseFloat(viewData!.total_guarantee_fee) +
                                parseFloat(viewData!.commitment_fee)
                              }`
                            )
                          : "0.00"
                      }
                    />
                    <GridItemPopupDetail
                      title="Time Stamp"
                      value={
                        viewData!.timestamp
                          ? formatDateTimeStamp(viewData!.timestamp)
                          : formatDateTimeStamp(viewData!.created_at)
                      }
                    />
                    <GridItem
                      w="100%"
                      display="inline-flex"
                      borderBottom="1px solid #E1F1FF"
                      p={3}
                    >
                      <Flex alignItems="center">
                        <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                          Status
                        </Text>
                        {popupStatus()}
                      </Flex>
                      <Spacer />
                      <Box>
                        <Text fontSize="sm" fontWeight={700} textAlign="right">
                          <SuccessBadge
                            label={
                              statusMapping[viewData!.offer_status] ??
                              viewData!.offer_status
                            }
                          />
                        </Text>
                      </Box>
                    </GridItem>
                  </Grid>
                  <Grid templateColumns="repeat(1,1fr)" gap={5}>
                    <GridItemPopupDetail
                      title="Financial Amount (NEW)"
                      value={numberFormatForCurrency(
                        viewData!.amount?.toString()
                      )}
                    />
                  </Grid>
                  <Flex mt={4} gap={4} justify="center">
                    <PrimaryButton
                      maxWidth="300px"
                      variant="outline"
                      onClick={onClose}
                    >
                      Close
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={handleExport}
                      bgColor="primary"
                      colorScheme="white"
                      w="250px"
                    >
                      Export to CSV
                    </PrimaryButton>
                  </Flex>
                </Flex>
              )}
              {getRole() === UserRole.BUYER && (
                <Flex direction="column" gap={8}>
                  <Center>
                    <Heading size="md">Bidding Status Detail</Heading>
                  </Center>
                  <Grid templateColumns="repeat(2,1fr)" gap={5}>
                    <GridItemPopupDetail title="Doc Type" value="Invoice" />
                    <GridItemPopupDetail
                      title="Doc No."
                      value={viewData!.doc_number}
                    />
                    <GridItemPopupDetail
                      title="Buyer Juristic ID"
                      value={viewData!.buyer_juristic_id}
                    />
                    <GridItemPopupDetail
                      title="Buyer Name"
                      value={capitalizeFirstLetter(
                        viewData!.buyer_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Seller Juristic ID"
                      value={viewData!.seller_juristic_id}
                    />
                    <GridItemPopupDetail
                      title="Seller Name"
                      value={capitalizeFirstLetter(
                        viewData!.seller_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Doc Date"
                      value={formatDateDD_MMMM_YYYY(viewData!.doc_date)}
                    />
                    <GridItemPopupDetail
                      title="Amount (Include VAT)"
                      value={numberFormatForCurrency(
                        viewData!.amount?.toString()
                      )}
                    />
                    <GridItemPopupDetail
                      title="Currency"
                      value={viewData!.currency}
                    />
                    <GridItemPopupDetail
                      title="Schedule Payment Date"
                      value={formatDateDD_MMMM_YYYY(viewData!.payment_date)}
                    />
                    <GridItemPopupDetail
                      title="Payment Guarantee Status"
                      value={viewData?.offer_option === 3 ? "Yes" : "No"}
                    />
                    <GridItemPopupDetail
                      title="Owner Name"
                      value={capitalizeFirstLetter(
                        viewData!.seller_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Holder Name"
                      value={capitalizeFirstLetter(
                        viewData!.holder_company_name
                      )}
                    />
                    <GridItemPopupDetail
                      title="Holder Wallet"
                      value={`${capitalizeFirstLetter(
                        viewData!.holder_wallet_nickname
                      )} ${abbreviateHexString(
                        viewData!.holder_wallet_address
                      )}`}
                    />
                    <GridItemPopupDetail
                      title="Offering Start Date"
                      value={
                        viewData!.offer_start_date
                          ? formatDateDD_MMMM_YYYY(viewData!.offer_start_date)
                          : ""
                      }
                    />
                    <GridItemPopupDetail
                      title="Offering end Date"
                      value={
                        viewData!.offer_end_date
                          ? formatDateDD_MMMM_YYYY(viewData!.offer_end_date)
                          : ""
                      }
                    />
                    <GridItemPopupDetail
                      title="Option"
                      value={invoiceOptionIdToString(viewData!.offer_option)}
                    />
                    <GridItem
                      w="100%"
                      display="inline-flex"
                      borderBottom="1px solid #E1F1FF"
                      p={3}
                    >
                      <Flex alignItems="center">
                        <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                          Status
                        </Text>
                        {popupStatus()}
                      </Flex>
                      <Spacer />
                      <Box>
                        <Text fontSize="sm" fontWeight={700} textAlign="right">
                          <SuccessBadge
                            label={
                              statusMapping[viewData!.offer_status] ??
                              viewData!.offer_status
                            }
                          />
                        </Text>
                      </Box>
                    </GridItem>
                    <GridItemPopupDetail
                      title="Discounting rate (per amount)"
                      value={
                        viewData?.offer_option === 2
                          ? numberFormatForCurrency(
                              viewData!.offer_interest_rate
                            ) + "%"
                          : "-"
                      }
                    />
                    <GridItemPopupDetail
                      title="Commitment Fee"
                      value={
                        viewData?.offer_option === 3
                          ? numberFormatForCurrency(
                              viewData!.commitment_fee_rate
                            ) + "%"
                          : "-"
                      }
                    />
                    <GridItemPopupDetail
                      title="Guarantee Fee"
                      value={
                        viewData?.offer_option === 3
                          ? numberFormatForCurrency(
                              viewData!.guarantee_fee_rate
                            ) + "%"
                          : "-"
                      }
                    />
                    <GridItemPopupDetail
                      title="Guarantee Fee Extra"
                      value={
                        viewData?.offer_option === 3
                          ? numberFormatForCurrency(
                              viewData!.guarantee_fee_rate_extra
                            ) + "%"
                          : "-"
                      }
                    />
                    <GridItemPopupDetail
                      title="Time Stamp"
                      value={
                        viewData!.timestamp
                          ? formatDateTimeStamp(viewData!.timestamp)
                          : formatDateTimeStamp(viewData!.created_at)
                      }
                    />
                    <GridItemPopupDetail
                      title="Total Guarantee Fee (NEW)"
                      value={
                        viewData!.offer_option === 3
                          ? numberFormatForCurrency(
                              `${
                                parseFloat(viewData!.total_guarantee_fee) +
                                parseFloat(viewData!.commitment_fee)
                              }`
                            )
                          : "0.00"
                      }
                    />
                  </Grid>
                  <Flex mt={4} gap={4} justify="center">
                    <PrimaryButton
                      maxWidth="300px"
                      variant="outline"
                      onClick={onClose}
                    >
                      Close
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={handleExport}
                      bgColor="primary"
                      colorScheme="white"
                      w="250px"
                    >
                      Export to CSV
                    </PrimaryButton>
                  </Flex>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default BiddingStatusTab
