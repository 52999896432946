// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Text, Flex, Image } from "@chakra-ui/react"

// ** Icons **
import {
  CoinBalanceIcon,
  WaittingAuctionIcon,
  OfferIcon,
  CalendarIcon,
  CardCoinIcon,
  CreditCardIcon,
  RevenueIcon,
} from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

// ** Router **
import {
  AP_MANAGEMENT_PAGE_PATH,
  MY_WALLET_PAGE_PATH,
  BIDDING_AND_OFFERING_PAGE_PATH,
  REVENUE_PAGE_PATH,
} from "router/AppPath"

const BuyerDashboard = () => {
  const navigate = useNavigate()
  const { getBuyerDashboardHook, buyerDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initBuyerDashboard",
    queryFn: getBuyerDashboardHook,
  })

  if (isLoading) return <></>

  const {
    total_loan_option2,
    total_loan_option3,
    stable_coin,
    credit,
    bidding,
    offering,
    due,
    revenue,
  } = buyerDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={6}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(AP_MANAGEMENT_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total Account Payable</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option2?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <CreditCardIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (THB)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option2?.amount.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AP_MANAGEMENT_PAGE_PATH,
            search: "?tab=1",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total Payment Guarantee</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option3?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/coins-guarantee.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Payment Guarantee Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option3?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex gap={4}>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() => navigate(MY_WALLET_PAGE_PATH)}
        >
          <Flex justify="space-between" alignItems="center">
            <Box w="100px" m={2}>
              <CardCoinIcon w="100%" h="100%" />
            </Box>
            <Box
              borderRadius={16}
              bgColor="#054BB5"
              color="white"
              p={4}
              px={8}
              overflowY="scroll"
              height="140px"
            >
              {credit.map((item, index) => (
                <Flex justify="space-between" gap={4}>
                  <Text>{item.name}</Text>
                  <Text>{numberFormatForCurrency(`${item?.amount?.toFixed(2)}`)}</Text>
                </Flex>
              ))}
            </Box>
            <Box textAlign="right">
              <Text fontSize={24}>Total Credit</Text>
              <Text fontSize={20} fontWeight="bold">
                {numberFormatForCurrency(
                  `${credit.reduce((acc, item) => acc + item.amount, 0)}`
                )}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() => navigate(MY_WALLET_PAGE_PATH)}
        >
          <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
            <Text>Stable Coin</Text>
          </Box>
          <Flex justify="space-between">
            <Box textAlign="left">
              <Text fontSize={20}>Stable Coin (Hold) (New)</Text>
              <Text fontSize={20} fontWeight="bold">
                {numberFormatForCurrency(`${stable_coin?.hold?.toFixed(2)}`)}
              </Text>
            </Box>
            <Box w="70px" m={2}>
              <CoinBalanceIcon w="100%" h="100%" />
            </Box>
            <Box textAlign="right">
              <Text fontSize={20}>Stable Coin (New)</Text>
              <Text fontSize={20} fontWeight="bold">
                {numberFormatForCurrency(`${stable_coin?.balance?.toFixed(2)}`)}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(BIDDING_AND_OFFERING_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>New Bidding</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${bidding?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <WaittingAuctionIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Bidding Options</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${bidding?.activity}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(BIDDING_AND_OFFERING_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Offering</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${offering?.num}`)}
            </Text>
            <Text fontSize={24}>Total Offers</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${offering?.activity}`)}
            </Text>
          </Box>
          <Box w="80px" m={4}>
            <OfferIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${offering?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AP_MANAGEMENT_PAGE_PATH,
            search: "?tab=2",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Due this Month</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${due?.num}`)}
            </Text>
          </Box>
          <Box w="70px" m={4}>
            <CalendarIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${due?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(REVENUE_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Revenue</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px"></Box>
          <Box w="72px" m={4}>
            <RevenueIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Annual Revenue (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${revenue?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default BuyerDashboard
