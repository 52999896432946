// ** Libs **
import {
  Box,
  Divider,
  Flex,
  Spacer,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import PasswordInput from "common/components/PasswordInput"
import PrimaryButton from "common/components/PrimaryButton"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useAuthHook from "hooks/useAuthHook"

// ** Types **
import type { ChangePasswordBody } from "types/User"
import { Alert, AlertType } from "types/Alert"

const ChangePassword = () => {
  const { setAlert } = useAppHook()

  const { postChangePasswordHook } = useAuthHook()

  const { control, handleSubmit, watch, reset } = useForm<ChangePasswordBody>({
    mode: "onChange",
  })

  const new_password = watch("new_password")

  const onSubmit: SubmitHandler<ChangePasswordBody> = async (data) => {
    const response = await postChangePasswordHook(data)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "New password has been set.",
        button: {
          label: "Close",
        },
      }
      reset({
        password: "",
        new_password: "",
        confirm_password: "",
      })
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  return (
    <>
      <Divider borderColor="#d8d8d8" my={8} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex px={8} gap={3}>
          <Box flex={1}>
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState: { error } }) => (
                <PasswordInput
                  {...field}
                  id="password"
                  type="password"
                  label="Current password"
                  placeholder="Enter your Current password"
                  error={error}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: "Please enter your password",
                },
              }}
            />
          </Box>
          <Box flex={1}>
            <Flex direction="column" gap={3}>
              <Box>
                <Controller
                  control={control}
                  name="new_password"
                  render={({ field, fieldState: { error } }) => (
                    <PasswordInput
                      {...field}
                      id="password"
                      type="password"
                      label="New Password"
                      placeholder="Enter your New Password"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter New Password",
                    },
                  }}
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="confirm_password"
                  render={({ field, fieldState: { error } }) => (
                    <PasswordInput
                      {...field}
                      id="confirmPassword"
                      type="password"
                      label="Repeat New Password"
                      placeholder="Enter your Repeat New Password"
                      error={error}
                      password={new_password}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter Confirm New Password",
                    },
                    validate: {
                      match: (v: string) => {
                        return v === new_password || "Passwords missmatch!"
                      },
                    },
                  }}
                />
              </Box>
              <Flex mt={8}>
                <Spacer />
                <PrimaryButton
                  size="md"
                  w="300px"
                  variant="outline"
                  type="submit"
                >
                  Save Change
                </PrimaryButton>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </form>
    </>
  )
}

export default ChangePassword
