// ** Libs **
import { Card, Flex, Box, Center, Text } from "@chakra-ui/react"

// ** Hook **
import { useQuery } from "hooks/useQueryHook"
import useTokenManagementHook from "hooks/useTokenManagementHook"

// ** Utils **
import { numberFormatForCurrency } from "common/utils/transform"

const CreditMonthlyReport = () => {
  const { getMonthlyGuaranteeHook, monthlyGuarantee } = useTokenManagementHook()

  const { isLoading } = useQuery({
    queryKey: "getMonthlyGuaranteeHook",
    queryFn: getMonthlyGuaranteeHook,
  })

  if (isLoading) return <></>

  const { num, amount } = monthlyGuarantee

  return (
    <Card p={6} h="100%" fontSize="16px" fontWeight="bold">
      <Center mb={4}>
        <Text>Monthly Report</Text>
      </Center>
      <Flex justify="space-between" mb={4}>
        <Box>Total Credit Token Transaction</Box>
        <Box>{num}</Box>
      </Flex>
      <Flex justify="space-between" mb={4}>
        <Box>Total Payment Guarantee</Box>
        <Box>{numberFormatForCurrency(`${amount}`)}</Box>
      </Flex>
    </Card>
  )
}

export default CreditMonthlyReport
