// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { campaignActions } from "store/slice/CampaignSlice"

// ** APIs **
import {
  getCampaigns,
  getCampaign,
  createCampaign,
  updateCampaign,
  setHighlight,
  deleteCampaign,
  postActivateCampaign,
  postDeactivateCampaign,
  getAnnounces,
  updateViewCount,
} from "api/campaign"

// ** Types **
import { CampaignBody } from "types/Campaign"
import type { ResponseWithOutData } from "api/types/ApiResponse"

// ** Utils **
import { handleError } from "common/utils/Response"

const useParticipantHook = () => {
  const dispatch = useAppDispatch()

  const { campaigns, campaign, announces } = useAppSelector(
    (state: RootState) => state.campaign
  )

  const getCampaignsHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCampaigns()
      dispatch(campaignActions.setCampaigns(response.data.data))
    } catch (err) {
      console.log("getCampaignsHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCampaignHook = async (id: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCampaign(id)
      dispatch(campaignActions.setCampaign(response.data.data[0]))
    } catch (err) {
      console.log("getCampaignHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const createCampaignHook = async (
    body: CampaignBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await createCampaign(body)
      await getCampaignsHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const updateCampaignHook = async (
    id: number,
    body: CampaignBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await updateCampaign(id, body)
      await getCampaignHook(id)
      await getCampaignsHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const setCampaignHighlightHook = async (
    id: number,
    body: CampaignBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await setHighlight(id, body)
      await getCampaignHook(id)
      await getCampaignsHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const deleteCampaignHook = async (
    id: number
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await deleteCampaign(id)
      await getCampaignsHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const activateCampaignHook = async (
    id: number
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postActivateCampaign(id)
      await getCampaignsHook()
      await getCampaignHook(id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const deactivateCampaignHook = async (
    id: number
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postDeactivateCampaign(id)
      await getCampaignsHook()
      await getCampaignHook(id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAnnouncesHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAnnounces()
      dispatch(campaignActions.setAnnounces(response.data.data))
    } catch (err) {
      console.log("getAnnouncesHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const updateAnnouncenHook = async (
    id: number
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await updateViewCount(id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getCampaignsHook,
    getCampaignHook,
    createCampaignHook,
    updateCampaignHook,
    setCampaignHighlightHook,
    deleteCampaignHook,
    activateCampaignHook,
    deactivateCampaignHook,
    getAnnouncesHook,
    updateAnnouncenHook,
    campaigns,
    campaign,
    announces,
  }
}

export default useParticipantHook
