import { Box, Checkbox, Flex, Heading, Text } from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import CardContent from "../../common/components/CardContent"
import PasswordInput from "../../common/components/PasswordInput"
import PrimaryButton from "../../common/components/PrimaryButton"
import TextInput from "../../common/components/TextInput"
import { EMAIL_REGEX } from "../../constants/AppStrings"
import useAppHook from "../../hooks/useAppHook"
import useAuthHook from "../../hooks/useAuthHook"
import {
  AUTHENTICATE_FORGOT_PASSWORD_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
} from "../../router/AppPath"
import { Alert, AlertType } from "../../types/Alert"
import packageEnv from "../../../package.json"

type LoginPageForm = {
  email: string
  password: string
}

const LoginPage = () => {
  const { postLoginHook } = useAuthHook()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm<LoginPageForm>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  })

  const { setAlert } = useAppHook()

  const onSubmit: SubmitHandler<LoginPageForm> = async (data) => {
    const response = await postLoginHook(data)
    if (response?.statusCode !== 200) {
      let message = ""
      switch (response.message) {
        case "Invalid credential":
          // User is not exist
          message = "Invalid email or password. "
          break

        case "Invalid credential.":
          // Password is wrong
          message = "Invalid email or password. "
          break

        default:
          message = response.message
          break
      }
      let alert: Alert = {
        visible: true,
        data: {
          type: AlertType.ERROR,
          description: message,
          button: {
            label: "Close",
          },
        },
      }
      setAlert(alert)
      return
    }
    navigate(DASHBOARD_PAGE_PATH)
  }

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Flex direction="column" gap={2}>
            <Box>
              <Heading size="md" textAlign="center">
                Sign in
              </Heading>
            </Box>
            <Box mt={8}>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    id="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    error={error}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter your email",
                  },
                  validate: {
                    format: (v: string) =>
                      RegExp(EMAIL_REGEX).test(v) || "The system supports only english lengauge and @ is required EX. NITMX123@gmail.com",
                  },
                }}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <PasswordInput
                    {...field}
                    id="password"
                    type="password"
                    label="Password"
                    placeholder="Enter your password"
                    error={error}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter your password",
                  },
                }}
              />
            </Box>

            <Flex justify="space-between" mt={2}>
              <Box>
                <Checkbox defaultChecked>
                  <Text fontWeight="semibold">Remember me</Text>
                </Checkbox>
              </Box>
              <Box>
                <Text
                  decoration="underline"
                  fontWeight="semibold"
                  cursor="pointer"
                  onClick={() =>
                    navigate(AUTHENTICATE_FORGOT_PASSWORD_PAGE_PATH)
                  }
                >
                  Forgot password
                </Text>
              </Box>
            </Flex>

            <Box mt={4}>
              <PrimaryButton
                colorScheme={"primary"}
                backgroundColor={"primary"}
                color={"white"}
                type="submit"
                w="100%"
              >
                Sign in
              </PrimaryButton>
            </Box>
          </Flex>
        </CardContent>
        <Box mt={4} mb={4} color="white" textAlign="center">
          Version {packageEnv.version}
        </Box>
      </form>
    </div>
  )
}

export default LoginPage
