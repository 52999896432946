// ** React **
import { FC } from "react"

// ** Libs **
import { useLocation } from "react-router-dom"
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
} from "@chakra-ui/react"

// ** Components **
import AccountMenu from "./AccountMenu"

// ** Constants **
import { TypeID, RoleID } from "constants/Enum"

// ** Types **
import { GetUserData } from "api/types/getUserType"

// ** Icons **
import { AccountIcon } from "./Icon"
import { ArrowDownIcon } from "assets/icons"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

type NavBarProps = {
  user: GetUserData
}

const NavBar: FC<NavBarProps> = ({ user }) => {
  const location = useLocation()
  const title = location?.state?.navTitle || "Dashboard"

  const { switchRoleHook } = useAuthHook()

  const renderOptionByRole = () => {
    switch (user.type_id) {
      case 1:
        if (user.pt_role === "Seller") {
          return (
            <MenuItem>
              <Flex alignItems="center" onClick={() => switchRoleHook(2)}>
                <Box lineHeight={1} mr={3}>
                  <AccountIcon />
                </Box>
                <Text>Corporate - Buyer</Text>
              </Flex>
            </MenuItem>
          )
        }
        if (user.pt_role === "Buyer") {
          return (
            <MenuItem onClick={() => switchRoleHook(1)}>
              <Flex alignItems="center">
                <Box lineHeight={1} mr={3}>
                  <AccountIcon />
                </Box>
                <Text>Corporate - Seller</Text>
              </Flex>
            </MenuItem>
          )
        }
        break
      case 2:
        if (user.pt_role === "Financial Institute") {
          return (
            <MenuItem onClick={() => switchRoleHook(4)}>
              <Flex alignItems="center">
                <Box lineHeight={1} mr={3}>
                  <AccountIcon />
                </Box>
                <Text>Financial Institute - Sponsoring</Text>
              </Flex>
            </MenuItem>
          )
        }
        if (user.pt_role === "Sponsoring") {
          return (
            <MenuItem onClick={() => switchRoleHook(3)}>
              <Flex alignItems="center">
                <Box lineHeight={1} mr={3}>
                  <AccountIcon />
                </Box>
                <Text>Financial Institute - Financing</Text>
              </Flex>
            </MenuItem>
          )
        }
        break

      default:
        return null
    }
  }

  return (
    <Flex px={8} gap={4} align="center" h={"100%"}>
      <Box>
        <Text fontSize="2xl" fontWeight="bold">
          {title}
        </Text>
      </Box>
      <Spacer />
      <Menu>
        <MenuButton>
          <HStack
            gap={4}
            p={2}
            align="center"
            border="1px solid"
            borderRadius={4}
            borderColor="grey400"
          >
            <Box lineHeight={1}>
              <AccountIcon />
            </Box>
            <Flex lineHeight={1} alignItems="center">
              {[1, 2].indexOf(user.type_id) > -1 && (
                <Text mr={1}>{TypeID[user.type_id]} -</Text>
              )}
              <Text fontSize="md" pt={0.5}>
                {RoleID[user.pt_role]}
              </Text>
            </Flex>
            {[1, 2].indexOf(user.type_id) > -1 && (
              <Box lineHeight={1} mr={1}>
                <ArrowDownIcon />
              </Box>
            )}
          </HStack>
        </MenuButton>
        {[1, 2].indexOf(user.type_id) > -1 && (
          <MenuList>{renderOptionByRole()}</MenuList>
        )}
      </Menu>
      <AccountMenu
        image={user.avatar}
        name={`${user.firstname !== null ? user.firstname : ""} ${
          user.lastname !== null ? user.lastname : ""
        }`}
        email={user.email}
      />
    </Flex>
  )
}

export default NavBar
