import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { FC, useState } from "react";
import { PostWithdrawWalletBody } from "../../../api/types/postWithdrawWalletType";
import useAppHook from "../../../hooks/useAppHook";
import useWalletHook from "../../../hooks/useWalletHook";
import { Alert, AlertType } from "../../../types/Alert";
import { ModalProps } from "../../../types/ModalProps";
import { MyWallet } from "../../../types/MyWallet";
import ConfirmPassword from "../ConfirmPassword";
import WithdrawInput from "./WithdrawInput";
import WithdrawReview from "./WithdrawReview";

export type WithdrawInputValue = {
  amount: string;
};

enum WithdrawModalBody {
  TRANSFER,
  REVIEW,
  CONFIRM,
}

type WithdrawModalContainerProps = {
  currentWallet: MyWallet;
  balance?: string;
} & ModalProps;

const WithdrawModalContainer: FC<WithdrawModalContainerProps> = (props) => {
  const { isOpen, onClose, currentWallet, balance } = props;
  const [withdrawValue, setWithdrawValue] = useState<WithdrawInputValue>();
  const [modalBody, setModalBody] = useState<WithdrawModalBody>(
    WithdrawModalBody.TRANSFER
  );

  const { postWithdrawWalletHook, getTransactionTokenHook } = useWalletHook();
  const { setAlert } = useAppHook();

  const handleOnWithdrawBack = () => {
    onClose();
    clearState();
  };
  const handleOnWithdrawSubmit = (val: WithdrawInputValue) => {
    setWithdrawValue(val);
    setModalBody(WithdrawModalBody.REVIEW);
  };

  const handleReviewBack = () => {
    setModalBody(WithdrawModalBody.TRANSFER);
  };
  const handleOnReviewConfirm = () => {
    setModalBody(WithdrawModalBody.CONFIRM);
  };

  const handlePasswordBack = () => {
    setModalBody(WithdrawModalBody.REVIEW);
  };
  const handleOnPasswordConfirm = async (val: string) => {
    const body: PostWithdrawWalletBody = {
      from_wallet_id: currentWallet.id,
      to_wallet_id: currentWallet.id,
      amount: Number.parseFloat(withdrawValue?.amount!),
      password: val,
    };
    const response = await postWithdrawWalletHook(body);
    let alert: Alert = {
      visible: true,
    };
    if (response?.data.statusCode === 200) {
      onClose();
      clearState();
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your withdraw was completed successfully.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
        },
      };
      getTransactionTokenHook();
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your withdraw was failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      };
    }
    setAlert(alert);
  };

  const clearState = () => {
    setWithdrawValue(undefined);
    setModalBody(WithdrawModalBody.TRANSFER);
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          {modalBody === WithdrawModalBody.TRANSFER && (
            <WithdrawInput
              currentWallet={currentWallet}
              withdrawValue={withdrawValue}
              balance={balance || "0"}
              onClickBack={handleOnWithdrawBack}
              onClickWithdraw={handleOnWithdrawSubmit}
            />
          )}
          {modalBody === WithdrawModalBody.REVIEW && (
            <WithdrawReview
              currentWallet={currentWallet}
              withdrawValue={withdrawValue}
              unit={"NEW"}
              onClickConfirm={handleOnReviewConfirm}
              onClickBack={handleReviewBack}
            />
          )}
          {modalBody === WithdrawModalBody.CONFIRM && (
            <ConfirmPassword
              header="Confirm"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WithdrawModalContainer;
