import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { FC, useState } from "react"
import { PostListInvoiceBody } from "../../../api/types/postListInvoiceType"
import useAppHook from "../../../hooks/useAppHook"
import useInvoiceHook from "../../../hooks/useInvoiceHook"
import { Alert, AlertType } from "../../../types/Alert"
import { Invoice } from "../../../types/Invoice"
import { ModalProps } from "../../../types/ModalProps"
import { MyWallet } from "../../../types/MyWallet"
import ConfirmPassword from "../ConfirmPassword"
import ListInvoicePreview from "./ListInvoicePreview"
import ListInvoiceInput from "./ListInvoiceInput"

enum ListInvoiceModalBody {
  PREVIEW,
  SELECT,
  CONFIRM,
}

type ListInvoiceModalContainerProps = {
  invoice?: Invoice
  selectedWallet: MyWallet
} & ModalProps

const ListInvoiceModalContainer: FC<ListInvoiceModalContainerProps> = (
  props
) => {
  const { isOpen, onClose, invoice, selectedWallet } = props
  const [listInvoiceValue, setListInvoiceValue] = useState<string[]>([])
  const [modalBody, setModalBody] = useState<ListInvoiceModalBody>(
    ListInvoiceModalBody.PREVIEW
  )

  const { postListInvoiceHook } = useInvoiceHook()
  const { setAlert } = useAppHook()

  const clearState = () => {
    setListInvoiceValue([])
    setModalBody(ListInvoiceModalBody.PREVIEW)
  }

  const handleOnSelectClose = () => {
    clearState()
    onClose()
  }

  const handleOnSelectConfirm = (val: string[]) => {
    setListInvoiceValue(val)
    setModalBody(ListInvoiceModalBody.CONFIRM)
  }

  const handlePasswordBack = () => {
    setModalBody(ListInvoiceModalBody.SELECT)
  }
  const handleOnPasswordConfirm = async (pwd: string) => {
    const body: PostListInvoiceBody = {
      doc_number: invoice!.doc_number,
      option1: listInvoiceValue.some((m) => m === "1"),
      option2: listInvoiceValue.some((m) => m === "2"),
      option3: listInvoiceValue.some((m) => m === "3"),
      wallet_id: selectedWallet.id,
      password: pwd,
    }
    const response = await postListInvoiceHook(body)
    let alert: Alert = {
      visible: true,
    }
    if (response?.data.statusCode === 200) {
      onClose()
      clearState()
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your invoice listing was completed successfully.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your invoice listing was failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  if (!invoice) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalBody !== ListInvoiceModalBody.CONFIRM ? "3xl" : "lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          {modalBody === ListInvoiceModalBody.PREVIEW && (
            <ListInvoicePreview
              invoice={invoice}
              onClickClose={handleOnSelectClose}
              onClickConfirm={() => setModalBody(ListInvoiceModalBody.SELECT)}
            />
          )}
          {modalBody === ListInvoiceModalBody.SELECT && (
            <ListInvoiceInput
              invoice={invoice}
              listInvoiceValue={listInvoiceValue}
              onClickClose={handleOnSelectClose}
              onClickConfirm={handleOnSelectConfirm}
            />
          )}
          {modalBody === ListInvoiceModalBody.CONFIRM && (
            <ConfirmPassword
              header="Listing Offer"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ListInvoiceModalContainer
