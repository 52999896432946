// ** React **
import { FC, useState, useEffect } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useAdminHook from "hooks/useAdminHook"

// ** Types **
import type { AdminBody } from "types/Admin"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { FormWithConfirmPasswordBody } from "constants/Enum"
import { EMAIL_REGEX } from "constants/AppStrings"

type ParticipantDetailEditModalProps = {} & ModalProps
const CreateAdminModal: FC<ParticipantDetailEditModalProps> = (props) => {
  const { isOpen, onClose } = props
  const { setAlert } = useAppHook()

  const [contentBody, setContentBody] = useState<FormWithConfirmPasswordBody>(
    FormWithConfirmPasswordBody.FORM
  )

  const { createAdminHook } = useAdminHook()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<AdminBody>({
    defaultValues: {},
    mode: "onChange",
  })

  const status = watch("status")

  useEffect(() => {
    if (status) {
      setValue("is_active", status.value === "true" ? true : false)
    }
  }, [status, setValue])

  const validateForm = () => {
    const email = watch("email")
    const firstname = watch("firstname")
    const lastname = watch("lastname")
    const contact = watch("contact")
    const status = watch("status")
    if (email === undefined)
      setError("email", { message: "Please enter your email" })
    if (firstname === undefined)
      setError("firstname", { message: "Please enter your firstname" })
    if (lastname === undefined)
      setError("lastname", { message: "Please enter your lastname" })
    if (contact === undefined)
      setError("contact", { message: "Please enter your contact" })
    if (status === undefined)
      setError("status", { message: "Please enter your status" })
    if (
      email !== undefined &&
      firstname !== undefined &&
      lastname !== undefined &&
      contact !== undefined &&
      status !== undefined
    ) {
      setContentBody(FormWithConfirmPasswordBody.PASSWORD)
    }
  }

  const onSubmit: SubmitHandler<AdminBody> = async (data) => {
    let body: any = { ...data }
    delete body.status
    const response = await createAdminHook(body)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Generation completed successfully.\nPlease check your email.",
        button: {
          label: "Close",
        },
      }
      reset()
      onClose()
      setContentBody(FormWithConfirmPasswordBody.FORM)
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
      if (response.statusCode === 400) {
        setContentBody(FormWithConfirmPasswordBody.PASSWORD)
      } else { 
        setContentBody(FormWithConfirmPasswordBody.FORM) 
      }
    }
    setAlert(alert)
    setValue("password", "")
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.FORM
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="md">Add Admin</Heading>
                </Box>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      id="email"
                      type="email"
                      label="Email"
                      placeholder="Enter your email"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter your email",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(EMAIL_REGEX).test(v) ||
                        "The system supports only english lengauge and @ is required EX. NITMX123@gmail.com",
                    },
                  }}
                />
                <Controller
                  control={control}
                  name="firstname"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="First name"
                      placeholder="Enter your first name"
                      error={error}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Controller
                  control={control}
                  name="lastname"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Last name"
                      placeholder="Enter your last name"
                      error={error}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Controller
                  control={control}
                  name="contact"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Phone number"
                      placeholder="Enter your phone number"
                      error={error}
                    />
                  )}
                  rules={{ required: true }}
                />
                <TextInput label="Role" value="Admin" isDisabled={true} />
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Status</Heading>
                  </Box>
                  <Controller
                    name="status"
                    render={({ field, fieldState: { error } }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select status"
                          options={[
                            { value: "true", label: "Active" },
                            { value: "false", label: "Inactive" },
                          ]}
                          onChange={(value) => field.onChange(value)}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: "Please select status" }}
                  />
                  {errors.status && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.status.message}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Submit
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateAdminModal
