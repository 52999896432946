// ** React **
import { useState } from "react"

// ** Libs **
import { Box, Flex } from "@chakra-ui/react"

// ** Icons **
import { HistoryBadge } from "common/components/Icon"

// ** Components **
import { APCurrent, APHistory } from "modules/ap_management"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import { UserRole } from "constants/Enum"

const WithoutPaymentGuarantee = ({ isPayment }: { isPayment: boolean }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { getRole } = useAuthHook()

  return (
    <Box>
      <Flex gap={6}>
        <Box onClick={() => setTabIndex(0)} cursor="pointer">
          <HistoryBadge
            label={getRole() === UserRole.BUYER ? "Current A/P" : "Current A/R"}
            color={tabIndex === 0 ? "primary" : "grey400"}
          />
        </Box>
        <Box onClick={() => setTabIndex(1)} cursor="pointer">
          <HistoryBadge
            label={getRole() === UserRole.BUYER ? "A/P History" : "A/R History"}
            color={tabIndex === 1 ? "primary" : "grey400"}
          />
        </Box>
      </Flex>

      <Box display={tabIndex === 0 ? "block" : "none"}>
        <APCurrent option={1} isPayment={isPayment} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <APHistory option={1}isPayment={isPayment}  />
      </Box>
    </Box>
  )
}

export default WithoutPaymentGuarantee
