// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { TransactionsInvoiceStablesDetailModal } from "./"

// ** Constants **
import { TIME_FRAME } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useTransactionHook from "hooks/useTransactionHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  formatDateTimeStamp,
  capitalizeFirstLetter,
  invoiceOptionIdToString,
  removeDuplicatesFields,
  filterDataByTimeframe,
} from "common/utils/transform"

// ** Types **
import { TransactionsInvoiceStable } from "types/Transaction"

const TransactionsInvoiceStables = () => {
  const transactionsInvoiceStablesDetailModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [option, setOption] = useState("")
  const [action, setAction] = useState("")
  const [status, setStatus] = useState("")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<TransactionsInvoiceStable[]>([])
  const [selected, setSelected] = useState<TransactionsInvoiceStable>()

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getTransactionsInvoiceStablesHook, transactionsInvoiceStables } =
    useTransactionHook()

  const optionOpts = [
    { title: "All Options", value: "" },
    ...Array.from(
      new Set(transactionsInvoiceStables.map((item) => invoiceOptionIdToString(item.offer_option))),
    ).map((m) => ({ title: m, value: m })),
  ]

  const actionOpts = [
    { title: "All Action", value: "" },
    ...Array.from(
      new Set(transactionsInvoiceStables.map((item) => item.action)),
    ).map((m) => ({
      title: capitalizeFirstLetter(m),
      value: m,
    })),
  ]

  const statusOpts = [
    { title: "All Status", value: "" },
    ...Array.from(
      new Set(transactionsInvoiceStables.map((item) => item.status)),
    ).map((m) => ({ title: capitalizeFirstLetter(m), value: m })),
  ]

  const { isLoading } = useQuery({
    queryKey: "initTransactionsInvoiceStablesHook",
    queryFn: getTransactionsInvoiceStablesHook,
  })

  useEffect(() => {
    setData(transactionsInvoiceStables)
  }, [transactionsInvoiceStables])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    [],
  )

  useEffect(() => {
    let temp: TransactionsInvoiceStable[] = transactionsInvoiceStables

    if (option) {
      temp = temp.filter((m) => invoiceOptionIdToString(m.offer_option) === option)
    }

    if (action) {
      temp = temp.filter((m) => m.action === action)
    }

    if (status) {
      temp = temp.filter((m) => m.status === status)
    }

    temp = removeDuplicatesFields(temp, [
      "id",
      "action",
      "amount",
      "currency",
      "doc_number",
      "option",
      "status",
      "updated_at",
    ])

    temp = filterDataByTimeframe(temp, timeFrame, "updated_at")

    temp.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    )

    const filterValue: TransactionsInvoiceStable[] = temp.filter((m) =>
      m.doc_number.toLowerCase().includes(fillValue.toLowerCase()),
    )
    setData(filterValue)
  }, [
    fillValue,
    transactionsInvoiceStables,
    option,
    action,
    status,
    timeFrame,
  ])

  if (isLoading) return <></>

  const column = [
    {
      title: "DocType",
      value: "doc_type",
      w: "200px",
      customRender: () => <Text>Invoice</Text>,
    },
    {
      title: "DocNo.",
      value: "doc_number",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>{value.doc_number}</Text>
      ),
    },
    {
      title: "From",
      value: "from",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {capitalizeFirstLetter(value.from_company_name)} -{" "}
          {capitalizeFirstLetter(value.from_wallet_nickname)} (
          {abbreviateHexString(value.from_wallet_address)})
        </Text>
      ),
    },
    {
      title: "To",
      value: "to",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {capitalizeFirstLetter(value.to_company_name)} -{" "}
          {capitalizeFirstLetter(value.to_wallet_nickname)} (
          {abbreviateHexString(value.to_wallet_address)})
        </Text>
      ),
    },
    {
      title: "Amount",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">
          {numberFormatForCurrency(parseFloat(value.amount).toFixed(2))}
        </Text>
      ),
    },
    {
      title: "Currency",
      value: "currency",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{value.currency}</Text>
      ),
    },
    {
      title: "Time Stamp",
      value: "updated_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.updated_at)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Options",
      value: "option",
      sortType: TableSortType.ITEM,
      w: "240px",
      customRender: (key: string, value: any) => (
        <Box>{invoiceOptionIdToString(value.offer_option)}</Box>
      ),
    },
    {
      title: "Action",
      value: "action",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box
          css={{
            "&:first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {value.action}
        </Box>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Link
            key={key}
            onClick={() => {
              setSelected(value)
              transactionsInvoiceStablesDetailModalAction.onOpen()
            }}
          >
            View
          </Link>
        )
      },
    },
  ]

  const filterTable = [
    {
      options: optionOpts,
      onChange: (value: string) => setOption(value),
      value: option,
      type: FilterType.DROPDOWN,
    },
    {
      options: actionOpts,
      onChange: (value: string) => setAction(value),
      value: action,
      type: FilterType.DROPDOWN,
    },
    {
      options: statusOpts,
      onChange: (value: string) => setStatus(value),
      value: status,
      type: FilterType.DROPDOWN,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="DocNo."
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      {selected && (
        <TransactionsInvoiceStablesDetailModal
          {...transactionsInvoiceStablesDetailModalAction}
          data={selected}
        />
      )}
    </Box>
  )
}

export default TransactionsInvoiceStables
