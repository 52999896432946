import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  ARItem,
  BuyerInvoice,
  BuyerInvoiceList,
  SellerInvoice,
} from "types/ARManagement"

export interface ARManagementState {
  currents: ARItem[]
  histories: ARItem[]
  buyerInvoice: BuyerInvoice[]
  buyerInvoiceList: BuyerInvoiceList[]
  sellerInvoice: SellerInvoice[]
  sellerInvoiceList: BuyerInvoiceList[]
}

export const initialARManagementState: ARManagementState = {
  currents: [],
  histories: [],
  buyerInvoice: [],
  buyerInvoiceList: [],
  sellerInvoice: [],
  sellerInvoiceList: [],
}

const arManagementSlice = createSlice({
  name: "armanagement",
  initialState: initialARManagementState,
  reducers: {
    setCurrents: (state, action: PayloadAction<ARItem[]>) => {
      state.currents = action.payload
    },
    setHistories: (state, action: PayloadAction<ARItem[]>) => {
      state.histories = action.payload
    },
    setBuyerInvoice: (state, action: PayloadAction<BuyerInvoice[]>) => {
      state.buyerInvoice = action.payload
    },
    setBuyerInvoiceList: (state, action: PayloadAction<BuyerInvoiceList[]>) => {
      state.buyerInvoiceList = action.payload
    },
    setSellerInvoice: (state, action: PayloadAction<SellerInvoice[]>) => {
      state.sellerInvoice = action.payload
    },
    setSellerInvoiceList: (
      state,
      action: PayloadAction<BuyerInvoiceList[]>
    ) => {
      state.sellerInvoiceList = action.payload
    },
  },
})

export const arManagementReducer = arManagementSlice.reducer
export const arManagementActions = arManagementSlice.actions
