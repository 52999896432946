// ** React **
import { useState } from "react"

// ** Libs **
import { Card, Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { BySellerCompanyInvoiceList } from "modules/guarantee_management"

const BySellerCompanyInvoiceTab = ({ ptId }: { ptId: number }) => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Card p={6}>
      <Box w="300px">
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Normal",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Default",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
          ]}
        />
      </Box>
      <Box mt={4}>
        <Box display={tabIndex === 0 ? "block" : "none"}>
          <BySellerCompanyInvoiceList ptId={ptId} isNormal={true} />
        </Box>
        <Box display={tabIndex === 1 ? "block" : "none"}>
          <BySellerCompanyInvoiceList ptId={ptId} isNormal={false} />
        </Box>
      </Box>
    </Card>
  )
}

export default BySellerCompanyInvoiceTab
