// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "../store"
import { appActions } from "store/slice/AppSlice"
import { adminActions } from "store/slice/AdminSlice"

// ** APIs **
import { getAdmin, createAdmin, updateAdmin } from "api/admin"

// ** Types **
import { AdminBody } from "types/Admin"
import type { ResponseWithOutData } from "api/types/ApiResponse"

// ** Utils **
import { handleError } from "common/utils/Response"

const useAppHook = () => {
  const dispatch = useAppDispatch()

  const { admin } = useAppSelector((state: RootState) => state.admin)

  const getAdminHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAdmin()
      dispatch(adminActions.setAdmin(response.data.data))
    } catch (err) {
      console.log("getParticipantsHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const createAdminHook = async (
    body: AdminBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await createAdmin(body)
      await getAdminHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const updateAdminHook = async (
    id: number,
    body: AdminBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await updateAdmin(id, body)
      await getAdminHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return { getAdminHook, createAdminHook, updateAdminHook, admin }
}

export default useAppHook
