// ** React **
import { FC, useState, useEffect } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"
import Select from "react-select"
import { ParticipantDetailItem } from "modules/client_management"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { CreateParticipantAdminBody } from "types/Participant"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"
import { Option } from "types/Global"

// ** Constants **
import { FormReviewWithConfirmPasswordBody } from "constants/Enum"
import { COMPANY_NAME_REGEX, JURISTIC_ID_REGEX } from "constants/AppStrings"

// ** Utils **
import { bankOptions } from "common/utils/bank"

// ** Icons **
import IconOption from "assets/icons/bank"

type ParticipantDetailEditModalProps = {} & ModalProps

const CreateParticipantAdminModal: FC<ParticipantDetailEditModalProps> = (
  props
) => {
  const { isOpen, onClose } = props
  const { setAlert } = useAppHook()

  const [optionTypes, setOptionTypes] = useState<Option[]>([])
  const [optionRoles, setOptionRoles] = useState<Option[]>([])
  const [, setSelectedRole] = useState<Option | null>(null)
  const [contentBody, setContentBody] =
    useState<FormReviewWithConfirmPasswordBody>(
      FormReviewWithConfirmPasswordBody.FORM
    )

  const { createParticipantHook, participantTypes, participantRoles } =
    useParticipantHook()

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<CreateParticipantAdminBody>({
    defaultValues: {},
    mode: "onChange",
  })

  const type = watch("type")
  const role = watch("role")
  const bank = watch("bank")

  useEffect(() => {
    if (bank !== undefined) {
      setValue("bank_code", bank.code)
    }
  }, [bank, setValue])

  useEffect(() => {
    if (participantTypes) {
      setOptionTypes(
        participantTypes
          .filter((item) => item.id !== 0)
          .map((item) => {
            return { value: `${item.id}`, label: item.name }
          })
      )
    }
  }, [participantTypes])

  useEffect(() => {
    if (participantRoles && type) {
      const roleOptions = participantRoles
        .filter((item) => item.type_id === parseInt(type.value))
        .map((item) => {
          return { value: `${item.id}`, label: item.name }
        })
      setOptionRoles(roleOptions)
      setValue("role", roleOptions.length > 0 ? roleOptions[0] : undefined)
      setValue(
        "role_id",
        roleOptions.length > 0 ? parseInt(roleOptions[0].value) : undefined
      )
      setSelectedRole(null)
    }
  }, [participantRoles, type, setValue])

  useEffect(() => {
    if (type) {
      setValue("type_id", parseInt(type.value))
    }
  }, [type, setValue])

  useEffect(() => {
    if (role) {
      setValue("role_id", parseInt(role.value))
    }
  }, [role, setValue])

  const validateForm = () => {
    const company_name = watch("company_name")
    const type = watch("type")
    const role = watch("role")
    const bank_code = watch("bank_code")
    const juristic_id = watch("juristic_id")
    if (company_name === undefined)
      setError("company_name", { message: "Please enter Company Name" })
    if (
      company_name !== undefined &&
      !RegExp(COMPANY_NAME_REGEX).test(company_name)
    ) {
      setError("company_name", {
        message: "Please enter valid company name without special character",
      })
    }
    if (type === undefined)
      setError("type", { message: "Please enter Company Type" })
    if (role === undefined) setError("role", { message: "Please enter Role" })
    if (bank_code === undefined)
      setError("bank_code", {
        message: "Please enter Bank Code",
      })
    if (juristic_id === undefined)
      setError("juristic_id", { message: "Please enter Juristic ID" })
    if (
      juristic_id !== undefined &&
      !RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setError("juristic_id", { message: "Please enter valid Juristic ID" })
    }
    if (
      company_name !== undefined &&
      RegExp(COMPANY_NAME_REGEX).test(company_name) &&
      type !== undefined &&
      role !== undefined &&
      bank_code !== undefined &&
      juristic_id !== undefined &&
      RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setContentBody(FormReviewWithConfirmPasswordBody.REVIEW)
    }
  }

  const onSubmit: SubmitHandler<CreateParticipantAdminBody> = async (data) => {
    let temp: any = { ...data }
    delete temp.type
    delete temp.role
    delete temp.bank
    const response = await createParticipantHook(temp)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Create Participant Complete",
        button: {
          label: "Close",
        },
      }
      reset()
      onClose()
      setContentBody(FormReviewWithConfirmPasswordBody.FORM)
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
      if (response.statusCode === 400) {
        setContentBody(FormReviewWithConfirmPasswordBody.PASSWORD)
      } else {
        setContentBody(FormReviewWithConfirmPasswordBody.FORM)
      }
    }
    setAlert(alert)
    setValue("password", "")
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormReviewWithConfirmPasswordBody.FORM
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="md">Create Participant</Heading>
                </Box>
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Company Name"
                      placeholder="Enter your Company Name"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your company name",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(COMPANY_NAME_REGEX).test(v) ||
                        "Please enter valid company name without special character",
                    },
                  }}
                />
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Company Type</Heading>
                  </Box>
                  <Controller
                    name="type"
                    render={({ field }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select Company Type"
                          options={optionTypes}
                          onChange={(value) => field.onChange(value)}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                  {errors.type && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.type.message}
                    </Text>
                  )}
                </Box>
                {optionRoles && optionRoles.length > 0 && (
                  <Box mb={3} w="100%">
                    <Box mb={2}>
                      <Heading size="sm">Role</Heading>
                    </Box>
                    <TextInput
                      value={optionRoles.map((m) => m.label).join("/")}
                      isDisabled={true}
                    />
                  </Box>
                )}
                <Box mb={5} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Bank</Heading>
                  </Box>
                  <Controller
                    name="bank"
                    render={({ field, fieldState: { error } }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select bank"
                          options={bankOptions()}
                          components={{ Option: IconOption }}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: "Please select Bank" }}
                  />
                  {errors.bank && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.bank.message}
                    </Text>
                  )}
                </Box>
                <Controller
                  control={control}
                  name="bank_code"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Bank Code"
                      placeholder="Enter your Bank Code"
                      error={error}
                      isDisabled={true}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your bank code",
                    },
                  }}
                />
                <Controller
                  control={control}
                  name="juristic_id"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Juristic ID"
                      placeholder="Enter your Juristic ID"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your Juristic ID",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(JURISTIC_ID_REGEX).test(v) ||
                        "Please enter valid Juristic ID",
                    },
                  }}
                />
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor="#0050C8"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Create Participant
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormReviewWithConfirmPasswordBody.REVIEW
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center" mb={6}>
                <Box mb={4}>
                  <Heading size="md">Confirm Participant Detail</Heading>
                </Box>
                <Box w="100%">
                  <ParticipantDetailItem
                    label="Company Name"
                    value={watch("company_name")}
                    align="right"
                  />
                  <ParticipantDetailItem
                    label="Company Type"
                    value={watch("type") ? watch("type").label : ""}
                    align="right"
                  />
                  <ParticipantDetailItem
                    label="Role"
                    value={
                      optionRoles.length
                        ? optionRoles.map((m) => m.label).join("/")
                        : ""
                    }
                    align="right"
                  />
                  <ParticipantDetailItem
                    label="Bank Code"
                    value={watch("bank_code")}
                    align="right"
                  />
                  <ParticipantDetailItem
                    label="Juristic ID"
                    value={watch("juristic_id")}
                    align="right"
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormReviewWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  onClick={() =>
                    setContentBody(FormReviewWithConfirmPasswordBody.PASSWORD)
                  }
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormReviewWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormReviewWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateParticipantAdminModal
