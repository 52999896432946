// ** Components **
import {
  AdminDashboard,
  SellerDashboard,
  BuyerDashboard,
  FIDashboard,
  SponsorDashboard,
} from "./role"

// ** Constants **
import { UserRole } from "constants/Enum"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

const DashBoardPage = () => {
  const { getRole } = useAuthHook()
  return (
    <div className="dashboard-page">
      {getRole() === null && <AdminDashboard />}
      {getRole() === UserRole.SELLER && <SellerDashboard />}
      {getRole() === UserRole.BUYER && <BuyerDashboard />}
      {getRole() === UserRole.FI && <FIDashboard />}
      {getRole() === UserRole.SPONSOR && <SponsorDashboard />}
      {getRole() === UserRole.TCG && <SponsorDashboard />}
    </div>
  )
}

export default DashBoardPage
