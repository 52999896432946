import { AxiosResponse } from "axios";
import $axios from "./instance";
import ApiResponse from "./types/ApiResponse";
import { PostOfferRepayBody } from "./types/postOfferRepayType";

const postOfferRepay = async (
  body: PostOfferRepayBody
): Promise<AxiosResponse<ApiResponse>> => {
  const response = await $axios().post("/offer_repay", body);
  return response;
};

export default postOfferRepay;
