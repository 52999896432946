import { Icon, IconProps } from "@chakra-ui/react"

const WaittingAuctionIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 100 96" fill="none" {...props}>
    <path
      d="M3.57031 92.9294H57.1417M49.9989 92.9294V75.0723H10.7132V92.9294"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.6191 5.16023L30.0117 30.7676C27.2222 33.5571 27.2222 38.0797 30.0117 40.8691L41.3254 52.1828C44.1149 54.9723 48.6375 54.9723 51.4269 52.1828L77.0343 26.5755C79.8238 23.786 79.8238 19.2634 77.0343 16.4739L65.7206 5.16023C62.9311 2.37077 58.4085 2.37077 55.6191 5.16023Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.2852 39.3574L96.428 71.5003"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default WaittingAuctionIcon
