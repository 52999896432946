// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Card, Flex, Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import {
  CreditTab,
  NftsTab,
  StableCoinTab,
} from "modules/token_management/admin"
import {
  Credit,
  CreditTopUp,
  CreditAdjustment,
  StableCoinContainer,
} from "./tab"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"
import useQueryString from "hooks/useQueryString"

// ** Constants **
import { UserRole, CreditBody } from "constants/Enum"

// ** Icons **
import { CreditIcon, StableCoinIcon } from "assets/icons"

const TokenManagementPage = () => {
  const tab = useQueryString("tab")
  const { getRole } = useAuthHook()
  const [tabIndex, setTabIndex] = useState(0)
  const [body, setBody] = useState<CreditBody>(CreditBody.DEFAULT)

  useEffect(() => {
    if (tab !== null) {
      setTabIndex(parseInt(tab))
    }
  }, [tab])

  if (getRole() === UserRole.TCG || getRole() === UserRole.SPONSOR) {
    return (
      <Card p={6}>
        <Flex
          direction="column"
          align="left"
          gap={8}
          display={body === CreditBody.DEFAULT ? "block" : "none"}
        >
          <Box w={350}>
            <TabCustom
              tabIndex={tabIndex}
              onChange={(index) => setTabIndex(index)}
              tabOpt={[
                {
                  label: "Stable Coin",
                  icon: <StableCoinIcon />,
                  activeIcon: <StableCoinIcon color="primary" />,
                  active: tabIndex === 0,
                },
                {
                  label: "Credit",
                  icon: <CreditIcon />,
                  activeIcon: <CreditIcon color="primary" />,
                  active: tabIndex === 1,
                },
              ]}
            />
          </Box>
          <Box display={tabIndex === 0 ? "block" : "none"}>
            <StableCoinContainer />
          </Box>
          {tabIndex === 1 && <Credit setBody={setBody} />}
        </Flex>
        <Box display={body === CreditBody.MINT ? "block" : "none"}>
          <CreditTopUp setBody={setBody} />
        </Box>
        <Box display={body === CreditBody.ADJUSTMENT ? "block" : "none"}>
          <CreditAdjustment setBody={setBody} />
        </Box>
      </Card>
    )
  } else if (getRole() === null) {
    return (
      <Card p={6}>
        <Box w="370px">
          <TabCustom
            tabIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
            tabOpt={[
              {
                label: "Credit",
                icon: null,
                activeIcon: null,
                active: tabIndex === 0,
              },
              {
                label: "Stable Coin",
                icon: null,
                activeIcon: null,
                active: tabIndex === 1,
              },
              {
                label: "NFTs",
                icon: null,
                activeIcon: null,
                active: tabIndex === 2,
              },
            ]}
          />
        </Box>
        <Box mt={4}>
          {tabIndex === 0 && <CreditTab />}
          {tabIndex === 1 && <StableCoinTab />}
          {tabIndex === 2 && <NftsTab />}
        </Box>
      </Card>
    )
  } else {
    return null
  }
}

export default TokenManagementPage
