// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { InvoiceSponsor, RevenueByInvoiceForFI, RevenueByInvoiceForBuyer } from "modules/revenue"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"
import useQueryString from "hooks/useQueryString"

// ** Constants **
import { UserRole } from "constants/Enum"

const RevenueByInvoice = ({ ptId }: { ptId?: number }) => {
  const subtab = useQueryString("subtab")
  const { getRole } = useAuthHook()
  const [tabIndex, setTabIndex] = useState(0)

  const role = getRole()

  useEffect(() => {
    if (subtab !== null) {
      setTabIndex(parseInt(subtab))
    }
  }, [subtab])

  return (
    <Box py={4}>
      {(role === UserRole.TCG || role === UserRole.SPONSOR) && (
        <>
          <Box w={300}>
            <TabCustom
              tabIndex={tabIndex}
              onChange={(index) => setTabIndex(index)}
              tabOpt={[
                {
                  label: "Normal",
                  icon: null,
                  activeIcon: null,
                  active: tabIndex === 0,
                },
                {
                  label: "Default",
                  icon: null,
                  activeIcon: null,
                  active: tabIndex === 1,
                },
              ]}
            />
          </Box>
          <Box display={tabIndex === 0 ? "block" : "none"}>
            <InvoiceSponsor type="normal" ptId={ptId} />
          </Box>
          <Box display={tabIndex === 1 ? "block" : "none"}>
            <InvoiceSponsor type="default" ptId={ptId} />
          </Box>
        </>
      )}
      {role === UserRole.FI && <RevenueByInvoiceForFI ptId={ptId} />}
      {role === UserRole.BUYER && <RevenueByInvoiceForBuyer ptId={ptId} />}
    </Box>
  )
}

export default RevenueByInvoice
