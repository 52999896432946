import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { DOTS, usePagination } from "../../../hooks/usePagination";
type PaginationTableProps = {
  onPageChange: (page: number) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
};

const PaginationTable: React.FC<PaginationTableProps> = (props) => {
  const { onPageChange, totalCount, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return (
      <Flex className="pagination-container" justify="center" gap={2} mt={2}>
        <Box className="pagination-item disable">
          <ChevronLeftIcon onClick={() => { }} fontSize="2xl" />
        </Box>
        {paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return (
              <Box className="pagination-item disable" key={i}>
                <Text lineHeight="16px">&#8230;</Text>
              </Box>
            );
          }

          return (
            <Box
              key={i}
              className={clsx("pagination-item disable", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber as number)}
            >
              <Text lineHeight="16px">{pageNumber}</Text>
            </Box>
          );
        })}
        <Box className="pagination-item disable">
          <ChevronRightIcon onClick={() => { }} fontSize="2xl" />
        </Box>
      </Flex>
    );
  }

  const onNext = () => {
    if (currentPage === lastPage) return;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) return;
    onPageChange(currentPage - 1);
  };

  return (
    <Flex className="pagination-container" justify="center" gap={2} mt={2}>
      <Box className="pagination-item">
        <ChevronLeftIcon onClick={onPrevious} fontSize="2xl" />
      </Box>
      {paginationRange.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <Box className="pagination-item" key={i}>
              <Text lineHeight="16px">&#8230;</Text>
            </Box>
          );
        }

        return (
          <Box
            key={i}
            className={clsx("pagination-item", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber as number)}
          >
            <Text lineHeight="16px">{pageNumber}</Text>
          </Box>
        );
      })}
      <Box className="pagination-item">
        <ChevronRightIcon onClick={onNext} fontSize="2xl" />
      </Box>
    </Flex>
  );
};

export default PaginationTable;
