// ** React **
import { FC, useEffect, useState } from "react"

// ** Libs **
import {
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Box,
  Stack,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  HStack,
  Grid,
  GridItem,
} from "@chakra-ui/react"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { MyWallet } from "types/MyWallet"
import { ParticipantCreditItem } from "types/Credit"
import { PostMintCreditTokenBody } from "api/types/postMintCreditTokenType"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"
import useAppHook from "hooks/useAppHook"
import useCreditHook from "hooks/useCreditHook"

// ** Icons **
import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  CheckedIcon,
  SelectNoneIcon,
  PeopleIcon,
  WalletCreditCardIcon,
} from "../Icon"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  findFirstPositiveValueIndex,
  findWalletAddressIndex,
} from "common/utils/transform"

// ** Components **
import AmountInput from "../AmountInput"
import PercentInput from "../PercentInput"
import PrimaryButton from "../PrimaryButton"
import ConfirmPassword from "../ConfirmPassword"

enum MintModalBody {
  MINT,
  REVIEW,
  CONFIRM,
}

type AdjustmentCreditTokenModalContainerProps = {
  defaultSelect?: ParticipantCreditItem
  refetch: any
} & ModalProps

const AdjustmentCreditTokenModalContainer: FC<
  AdjustmentCreditTokenModalContainerProps
> = (props) => {
  const { isOpen, onClose, defaultSelect, refetch } = props

  const { postAdjustmentCreditTokenHook, myWallet } = useWalletHook()
  const { getCorporateWalletHook, corporateWallet } = useCreditHook()
  const { setAlert } = useAppHook()

  // ** States **
  const [selectCompany, setSelectCompany] = useState<string>()
  const [selectedFromWallet, setSelectedFromWallet] = useState<MyWallet>()
  const [selectedToWallet, setSelectedToWallet] = useState<MyWallet>()
  const [amount, setAmount] = useState<string>("")
  const [data, setData] = useState<any>()
  const [rate, setRate] = useState<string>("")
  const [modalBody, setModalBody] = useState<MintModalBody>(MintModalBody.MINT)

  useEffect(() => {
    if (defaultSelect) {
      setSelectCompany(defaultSelect.company_name)
    }
  }, [defaultSelect])

  useEffect(() => {
    if (defaultSelect && corporateWallet.length > 0) {
      const index = findFirstPositiveValueIndex(defaultSelect.amount)
      if (index > -1) {
        const wallet_address = defaultSelect.wallet_address[index]
        setSelectedToWallet(
          corporateWallet.find((w) => w.wallet_address === wallet_address)
        )
      }
    }
  }, [defaultSelect, corporateWallet])

  useEffect(() => {
    if (defaultSelect && selectedToWallet) {
      const index = findWalletAddressIndex(
        defaultSelect.wallet_address,
        selectedToWallet.wallet_address
      )
      setData({
        amount: defaultSelect.amount[index],
        guarantee_fee: defaultSelect.guarantee_fee[index],
      })
      if (defaultSelect.amount[index]) {
        setAmount(defaultSelect.amount[index])
      }
      if (defaultSelect.guarantee_fee[index]) {
        setRate(`${defaultSelect.guarantee_fee[index]}`)
      }
    }
  }, [defaultSelect, selectedToWallet])

  const { isLoading: isLoadingCorporateWallet } = useQuery({
    queryKey: "initCorporateWallet",
    queryFn: getCorporateWalletHook,
  })

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedFromWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoadingCorporateWallet || !selectedFromWallet) return <></>

  const clearState = () => {
    setSelectedToWallet(undefined)
    setModalBody(MintModalBody.MINT)
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  const onClickMint = () => {
    if (selectedToWallet) {
      setModalBody(MintModalBody.REVIEW)
    }
  }

  const onConfirmMint = async (password: string) => {
    if (selectedToWallet) {
      const body: PostMintCreditTokenBody = {
        from_wallet_id: selectedFromWallet.id,
        to_wallet_id: selectedToWallet.id,
        amount: Number.parseFloat(amount),
        fee_rate: Number.parseFloat(rate),
        password,
      }
      const response = await postAdjustmentCreditTokenHook(body)
      let alert: Alert = {
        visible: true,
      }
      if (response?.data.statusCode === 200) {
        onClose()
        clearState()
        alert.data = {
          type: AlertType.SUCCESS,
          description: `Credit has been adjusted to ${numberFormatForCurrency(
            amount
          )} TCGCT.\nGuarantee rate fee has been adjusted to ${rate}%.`,
          button: {
            label: "Close",
          },
        }
      } else {
        alert.data = {
          type: AlertType.ERROR,
          description: "Adjustment Credit failed.\nPlease try again.",
          button: {
            label: "Close",
          },
        }
        setModalBody(MintModalBody.MINT)
      }
      setAlert(alert)
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        {/* Adjustment Credit Token */}
        <Box display={modalBody === MintModalBody.MINT ? "block" : "none"}>
          <Flex
            direction="column"
            justify="center"
            align="center"
            gap={4}
            p={6}
          >
            <Box>
              <Heading size="md">Adjustment Credit Token</Heading>
            </Box>
            {/* From */}
            <Box w="100%">
              <Stack gap={2}>
                <Box>
                  <Heading size="sm">From</Heading>
                </Box>
                <Box w={"100%"}>
                  <Menu>
                    <MenuButton
                      size="sm"
                      variant="outline"
                      minW="110px"
                      w="100%"
                      h="64px"
                      as={Button}
                      rightIcon={
                        myWallet.length > 1 ? <ChevronDownIcon /> : undefined
                      }
                      borderRadius={8}
                      borderColor="#D8D8D8"
                      px={4}
                      py={2}
                    >
                      <Flex align="center" gap={3}>
                        <Box>
                          <CheckedIcon />
                        </Box>
                        <Box>
                          <WalletCreditCardIcon
                            boxSize={6}
                            color="secondaryTextColor"
                          />
                        </Box>
                        <Box>
                          <Text
                            textAlign="left"
                            fontSize="small"
                            color="secondaryTextColor"
                          >
                            Company name:
                          </Text>
                          <Text
                            textAlign="left"
                            fontSize="small"
                            color="secondaryTextColor"
                          >
                            บรรษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม (บสย.)
                          </Text>
                          <Text
                            textAlign="left"
                            fontSize="small"
                            color="secondaryTextColor"
                          >
                            {`${selectedFromWallet?.company_name} -
                        ${
                          selectedFromWallet.nickname || ""
                        } (${abbreviateHexString(
                              selectedFromWallet.wallet_address
                            )})`}
                          </Text>
                        </Box>
                      </Flex>
                    </MenuButton>
                    {myWallet.length > 1 && (
                      <MenuList>
                        {myWallet
                          .filter((m) => m.id !== selectedFromWallet.id)
                          .map((m, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => setSelectedFromWallet(m)}
                            >
                              <Text fontSize="sm" color="#212529">
                                {m.company_name} - {m.nickname || ""} (
                                {abbreviateHexString(m.wallet_address)})
                              </Text>
                            </MenuItem>
                          ))}
                      </MenuList>
                    )}
                  </Menu>
                </Box>
              </Stack>
            </Box>
            {/* To */}
            <Box w="100%">
              <Stack gap={2}>
                <Box>
                  <Heading size="sm">To</Heading>
                </Box>
                <Box w={"100%"}>
                  <Menu>
                    <MenuButton
                      size="sm"
                      variant="outline"
                      minW="110px"
                      w="100%"
                      h="42px"
                      as={Button}
                      borderRadius={8}
                      borderColor="#D8D8D8"
                      px={4}
                      py={2}
                    >
                      <Flex align="center" gap={3}>
                        <Box>
                          {selectCompany ? <CheckedIcon /> : <SelectNoneIcon />}
                        </Box>
                        <Box>
                          <PeopleIcon boxSize={6} color="secondaryTextColor" />
                        </Box>
                        <Box>
                          <Text
                            color="primary"
                            fontSize="small"
                            fontWeight="bold"
                          >
                            Company {selectCompany}
                          </Text>
                        </Box>
                      </Flex>
                    </MenuButton>
                  </Menu>
                </Box>
                {selectCompany && (
                  <Box w={"100%"}>
                    <Menu>
                      <MenuButton
                        size="sm"
                        variant="outline"
                        minW="110px"
                        w="100%"
                        h="42px"
                        as={Button}
                        rightIcon={
                          corporateWallet
                            .filter((m) =>
                              selectedToWallet
                                ? m.id !== selectedToWallet.id
                                : true
                            )
                            .filter((m) => m.company_name === selectCompany)
                            .filter((m) =>
                              defaultSelect?.wallet_address.indexOf(
                                m.wallet_address
                              ) === -1
                                ? false
                                : true
                            ).length > 0 ? (
                            <ChevronDownIcon />
                          ) : (
                            <></>
                          )
                        }
                        borderRadius={8}
                        borderColor="#D8D8D8"
                        px={4}
                        py={2}
                      >
                        <Flex align="center" gap={3}>
                          <Box>
                            {selectedToWallet ? (
                              <CheckedIcon />
                            ) : (
                              <SelectNoneIcon />
                            )}
                          </Box>
                          <Box>
                            <WalletCreditCardIcon
                              boxSize={6}
                              color="secondaryTextColor"
                            />
                          </Box>
                          <Box>
                            <Text
                              color="primary"
                              fontSize="small"
                              fontWeight="bold"
                            >
                              {selectCompany} -{" "}
                              {selectedToWallet &&
                                `${
                                  selectedToWallet.nickname
                                    ? selectedToWallet.nickname
                                    : ""
                                } (${abbreviateHexString(
                                  selectedToWallet.wallet_address
                                )})`}
                            </Text>
                          </Box>
                        </Flex>
                      </MenuButton>
                      {corporateWallet
                        .filter((m) =>
                          selectedToWallet ? m.id !== selectedToWallet.id : true
                        )
                        .filter((m) => m.company_name === selectCompany)
                        .filter((m) =>
                          defaultSelect?.wallet_address.indexOf(
                            m.wallet_address
                          ) === -1
                            ? false
                            : true
                        ).length > 0 && (
                        <MenuList>
                          {corporateWallet
                            .filter((m) =>
                              selectedToWallet
                                ? m.id !== selectedToWallet.id
                                : true
                            )
                            .filter((m) => m.company_name === selectCompany)
                            .filter((m) =>
                              defaultSelect?.wallet_address.indexOf(
                                m.wallet_address
                              ) === -1
                                ? false
                                : true
                            )
                            .map((m, i) => (
                              <MenuItem
                                key={i}
                                onClick={() => setSelectedToWallet(m)}
                              >
                                <Text fontSize="sm" color="#212529">
                                  {m.company_name} - {m.nickname || ""} (
                                  {abbreviateHexString(m.wallet_address)})
                                </Text>
                              </MenuItem>
                            ))}
                        </MenuList>
                      )}
                    </Menu>
                  </Box>
                )}
              </Stack>
            </Box>
            {/* Credit Amount */}
            <Box w="100%" mt={4}>
              <Stack gap={4}>
                <Box>
                  <Flex justify="space-between">
                    <Heading size="sm">Adjustment Credit</Heading>
                    <Text fontSize="xs">
                      Current Credit Balance :{" "}
                      {numberFormatForCurrency(data?.amount)} TCGCT
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  {amount !== "" ? (
                    <AmountInput
                      value={amount}
                      step={1000000}
                      current={data?.amount}
                      onChange={(val: string) => setAmount(val)}
                      unit="TCGCT"
                    />
                  ) : (
                    <AmountInput
                      value={"0"}
                      step={1000000}
                      current={data?.amount}
                      onChange={(val: string) => setAmount(val)}
                      unit="TCGCT"
                    />
                  )}
                </Box>
              </Stack>
            </Box>
            {/* Guarantee Fee Rate */}
            <Box w="100%" mt={4}>
              <Stack gap={4}>
                <Box>
                  <Flex justify="space-between">
                    <Heading size="sm">Guarantee Fee Rate (%)</Heading>
                    <Text fontSize="xs">
                      Current Rate : {data?.guarantee_fee?.toFixed(2)} %
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  {rate !== "" ? (
                    <PercentInput
                      value={rate}
                      step={0.01}
                      current={data?.guarantee_fee}
                      onChange={(val: string) => setRate(val)}
                    />
                  ) : (
                    <PercentInput
                      value={"0"}
                      step={0.01}
                      current={data?.guarantee_fee}
                      onChange={(val: string) => setRate(val)}
                    />
                  )}
                </Box>
              </Stack>
            </Box>
            <Flex w="100%" justify="space-between" mt={4}>
              <Heading size="sm">Final Credit Amount</Heading>
              <Text fontSize="md">{numberFormatForCurrency(amount)} TCGCT</Text>
            </Flex>
            <Flex w="100%" justify="space-between" mt={4} mb={4}>
              <Heading size="sm">Final Guarantee Fee Rate</Heading>
              <Text fontSize="md">{rate} %</Text>
            </Flex>
            {/* Button */}
            <Box w="100%">
              <HStack>
                <PrimaryButton variant="outline" onClick={onClose}>
                  Back
                </PrimaryButton>
                <PrimaryButton
                  variant="outline"
                  onClick={() => {
                    setAmount("")
                    setRate("")
                    setTimeout(() => {
                      setAmount(data?.amount)
                      setRate(data?.guarantee_fee)
                    }, 111)
                  }}
                >
                  Original Value
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor={selectedToWallet ? "completed" : "disable"}
                  border="secondaryTextColor"
                  color="white"
                  onClick={onClickMint}
                >
                  Adjust
                </PrimaryButton>
              </HStack>
            </Box>
            <Text fontSize="md" color="green" fontWeight="bold">
              Valid Until + 1 Year
            </Text>
          </Flex>
        </Box>
        {/* Adjustment Credit Token confirm */}
        <Box display={modalBody === MintModalBody.REVIEW ? "block" : "none"}>
          <Flex
            direction="column"
            justify="center"
            align="center"
            gap={4}
            p={6}
          >
            <Box>
              <Heading size="md">Credit Adjust Token confirm</Heading>
            </Box>
            <Box w="100%">
              <Grid templateColumns="140px 1fr" gap={4}>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Company name
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedToWallet && selectedToWallet?.company_name}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Juristic ID
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedToWallet && selectedToWallet?.juristic_id}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Wallet Address
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedToWallet &&
                    `${selectedToWallet.company_name} - ${
                      selectedToWallet.nickname || ""
                    } (${abbreviateHexString(
                      selectedToWallet.wallet_address
                    )})`}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Amount
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {numberFormatForCurrency(amount)} TCGCT
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Rate
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {rate}%
                </GridItem>
              </Grid>
            </Box>
            {/* Button */}
            <Box w="100%">
              <HStack>
                <PrimaryButton
                  variant="outline"
                  onClick={() => setModalBody(MintModalBody.MINT)}
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor="completed"
                  border="secondaryTextColor"
                  color="white"
                  onClick={() => setModalBody(MintModalBody.CONFIRM)}
                >
                  Confirm
                </PrimaryButton>
              </HStack>
            </Box>
          </Flex>
        </Box>
        {/* Confirm */}
        {modalBody === MintModalBody.CONFIRM && (
          <ModalBody p={8}>
            <ConfirmPassword
              header="Confirm"
              onClickBack={() => setModalBody(MintModalBody.REVIEW)}
              onClickConfirm={(password) => onConfirmMint(password)}
            />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default AdjustmentCreditTokenModalContainer
