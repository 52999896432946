import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetOwnListedInvoicesResponse } from "./types/getOwnListedInvoicesType";

const getOwnListedInvoices = async (): Promise<
  AxiosResponse<GetOwnListedInvoicesResponse>
> => {
  const response = await $axios().get("/own_listed_invoices");
  return response;
};

export default getOwnListedInvoices;
