// ** Libs **
import { Box, Card, Flex, Heading, Spacer } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

// ** Components **
import InformationValue from "common/components/InformationValue"
import ProfilePicture from "common/components/ProfilePicture"

// ** Routers **
import { EDIT_COMPANY_PROFILE_PAGE_PATH } from "router/AppPath"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useParticipantHook from "hooks/useParticipantHook"

const CompanyProfilePage = () => {
  const navigate = useNavigate()

  const { getParticipantProfileHook, participantProfile } = useParticipantHook()

  const { isLoading } = useQuery({
    queryKey: "initCompanyProfile",
    queryFn: getParticipantProfileHook,
  })

  if (isLoading) return <></>

  const onClickEditProfile = () => {
    navigate(EDIT_COMPANY_PROFILE_PAGE_PATH, {
      state: {
        navTitle: "Profile",
      },
    })
  }

  return (
    <Card mb={10}>
      <Flex direction="column">
        <Box flex={1} py={2} px={8} borderBottom="0.5px solid black">
          <Heading fontSize="22px" color="primaryTextColor">
            Company Profile
          </Heading>
        </Box>
        <Flex px={6} py={8} mt={8} direction="column">
          <Box flex={1} px={16}>
            <ProfilePicture
              bgUrl={participantProfile.banner}
              profileUrl={participantProfile.logo}
            />
          </Box>
          <Flex gap={3} mt={3} mb={10} px={36} direction="column">
            <Box>
              <InformationValue label="Company Name" value={participantProfile.company_name} />
            </Box>
            <Box>
              <InformationValue label="Juristic ID" value={participantProfile.juristic_id} />
            </Box>
            <Box>
              <InformationValue label="Type" value={participantProfile.type} />
            </Box>
            <Box>
              <InformationValue
                label="Address 1"
                value={participantProfile.address1 ?? "-"}
              />
            </Box>
            <Box>
              <InformationValue
                label="Address 2"
                value={participantProfile.address2 ?? "-"}
              />
            </Box>
            <Flex gap={3}>
              <Box flex={1}>
                <InformationValue
                  label="Email"
                  value={participantProfile.email ?? "-"}
                />
              </Box>
              <Box flex={1}>
                <InformationValue
                  label="Tel. Number 1"
                  value={participantProfile.tel_number2 ?? "-"}
                />
              </Box>
            </Flex>
            <Box>
              <InformationValue
                label="Tel. Number 2"
                value={participantProfile.tel_number3 ?? "-"}
              />
            </Box>
            <Box>
              <InformationValue
                label="Description"
                value=""
                html={participantProfile.description ?? "-"}
              />
            </Box>
            <Flex>
              <Spacer />
              <Box>
                <Heading
                  size="sm"
                  color="selected"
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={onClickEditProfile}
                >
                  Edit Profile
                </Heading>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}

export default CompanyProfilePage
