// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Box, Text, Flex, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { DiscountingModal } from "modules/discounting"

// ** Hooks **
import useDiscountingHook from "hooks/useDiscountingHook"

// ** Types **
import { Discounting } from "types/Discounting"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateDD_MMMM_YYYY,
  invoiceOptionIdToString,
} from "common/utils/transform"

// ** Icons **
import { ArrowRightIcon } from "assets/icons"

const DiscountingList = ({
  option,
  isSuccess,
  ptId,
}: {
  option: number
  isSuccess: boolean
  ptId?: number
}) => {
  const modalDetail = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [selected, setSelected] = useState<Discounting>()

  const { discountingsOp1, discountingsOp2, discountingsOp3 } =
    useDiscountingHook()

  useEffect(() => {
    let temp: Discounting[] = []

    switch (option) {
      case 1:
        temp = discountingsOp1
          .filter((m) =>
            isSuccess ? m.status !== "overdue" : m.status === "overdue"
          )
          .filter((m) => (ptId ? m.offer_pt_id === ptId : true))
        break
      case 2:
        temp = discountingsOp2
          .filter((m) =>
            isSuccess ? m.status !== "overdue" : m.status === "overdue"
          )
          .filter((m) => (ptId ? m.offer_pt_id === ptId : true))
        break
      case 3:
        temp = discountingsOp3
          .filter((m) =>
            isSuccess ? m.status !== "overdue" : m.status === "overdue"
          )
          .filter((m) => (ptId ? m.offer_pt_id === ptId : true))
        break

      default:
        break
    }
    setData(temp)
  }, [
    option,
    isSuccess,
    discountingsOp1,
    discountingsOp2,
    discountingsOp3,
    ptId,
  ])

  const column: TableColumn[] = [
    {
      title: "Custom",
      value: "custom",
      isFooter: true,
      customRender: (key: string, value: any) => {
        return (
          <Flex w="100%" alignItems="center">
            <Box
              flex="0 0 80px"
              bgColor="emptyColor"
              h="80px"
              borderRadius="50%"
              mr={3}
            />
            <Box w="100%" mr={4}>
              <Flex fontSize={16} fontWeight="bold">
                <Text mr={2}>DocNo. : </Text>
                <Text>{value.doc_number}</Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>DocType : </Text>
                <Text fontWeight="bold">Invoice</Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Buyer Name :</Text>
                <Text fontWeight="bold" className="capital">
                  {value.buyer_company_name}
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Seller Name :</Text>
                <Text fontWeight="bold" className="capital">
                  {value.seller_company_name}
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Option :</Text>
                <Text fontWeight="bold" className="capital">
                  {invoiceOptionIdToString(value.offer_option)}
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Payment Date :</Text>
                <Text fontWeight="bold" className="capital">
                  {formatDateDD_MMMM_YYYY(value.payment_date)}
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Amount (NEW) :</Text>
                <Text fontWeight="bold" className="capital">
                  {numberFormatForCurrency(value.amount)}
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Total Discount (NEW) :</Text>
                <Text fontWeight="bold" className="capital">
                  {numberFormatForCurrency(value.discount)}
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Overdue :</Text>
                <Text fontWeight="bold" className="capital">
                  {value.overdue_day ?? "-"} days
                </Text>
              </Flex>
              <Flex fontSize={16}>
                <Text mr={2}>Amount After Discount :</Text>
                <Text fontWeight="bold" className="capital">
                  {numberFormatForCurrency(
                    `${parseFloat(value.amount) - parseFloat(value.discount)}`
                  )}
                </Text>
              </Flex>
              <Flex fontSize={16} alignItems="center">
                <Text mr={2}>Status :</Text>
                <Text fontWeight="bold" className="capital">
                  <SuccessBadge label={value.status === "overdue" ? "default" : "success"} />
                </Text>
              </Flex>
            </Box>
            <Flex alignItems="center">
              <ArrowRightIcon />
            </Flex>
          </Flex>
        )
      },
    },
  ]

  const onRowClick = (data: Discounting) => {
    setSelected(data)
    modalDetail.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: Discounting[] = []

    switch (option) {
      case 1:
        temp = discountingsOp1
          .filter((m) =>
            isSuccess ? m.status !== "overdue" : m.status === "overdue"
          )
          .filter((m) => (ptId ? m.offer_pt_id === ptId : true))
        break
      case 2:
        temp = discountingsOp2
          .filter((m) =>
            isSuccess ? m.status !== "overdue" : m.status === "overdue"
          )
          .filter((m) => (ptId ? m.offer_pt_id === ptId : true))
        break
      case 3:
        temp = discountingsOp3
          .filter((m) =>
            isSuccess ? m.status !== "overdue" : m.status === "overdue"
          )
          .filter((m) => (ptId ? m.offer_pt_id === ptId : true))
        break

      default:
        break
    }

    const filterValue = temp.filter((m) =>
      (m.doc_number + m.buyer_company_name)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [
    fillValue,
    isSuccess,
    discountingsOp1,
    discountingsOp2,
    discountingsOp3,
    option,
    ptId,
  ])

  return (
    <Box>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        style={TableStyle.BOX}
        onRowClick={onRowClick}
        searchPlaceholder="Debtor Name/ Invoice Id"
        onSearchChange={onSearchChange}
        isCustom={true}
      />
      <DiscountingModal
        {...modalDetail}
        data={selected}
        onClickClose={() => modalDetail.onClose()}
      />
    </Box>
  )
}

export default DiscountingList
