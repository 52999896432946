// ** React **
import { useState } from "react"

// ** Libs **
import { Card, Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import {
  RevenueByInvoice,
  RevenueByBuyer,
  RevenueByBuyerOffer,
  RevenueBySellerOffer,
} from "modules/revenue"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import { UserRole } from "constants/Enum"

const RevenuePage = () => {
  const { getRole } = useAuthHook()
  const [tabIndex, setTabIndex] = useState(0)

  const role = getRole()

  if (role === UserRole.TCG || role === UserRole.SPONSOR) {
    return (
      <Card p={6}>
        <Box w={450}>
          <TabCustom
            tabIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
            tabOpt={[
              {
                label: "Revenue by Invoice",
                icon: null,
                activeIcon: null,
                active: tabIndex === 0,
              },
              {
                label: "Revenue by Buyer",
                icon: null,
                activeIcon: null,
                active: tabIndex === 1,
              },
            ]}
          />
        </Box>
        <Box display={tabIndex === 0 ? "block" : "none"}>
          <RevenueByInvoice />
        </Box>
        <Box display={tabIndex === 1 ? "block" : "none"}>
          <RevenueByBuyer />
        </Box>
      </Card>
    )
  } else {
    return (
      <Card p={6}>
        <Box w={450}>
          <TabCustom
            tabIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
            tabOpt={[
              {
                label:
                  role === UserRole.FI || role === UserRole.BUYER
                    ? "Revenue by Invoice"
                    : "Revenue by Buyer",
                icon: null,
                activeIcon: null,
                active: tabIndex === 0,
              },
              {
                label:
                  role === UserRole.FI
                    ? "Revenue by Debtor"
                    : "Revenue by Seller",
                icon: null,
                activeIcon: null,
                active: tabIndex === 1,
              },
            ]}
          />
        </Box>
        <Box display={tabIndex === 0 ? "block" : "none"}>
          <RevenueByInvoice />
        </Box>
        <Box display={tabIndex === 1 ? "block" : "none"}>
          {role === UserRole.FI && <RevenueByBuyerOffer />}
          {role === UserRole.BUYER && <RevenueBySellerOffer />}
        </Box>
      </Card>
    )
  }
}

export default RevenuePage
