import { Icon, IconProps } from "@chakra-ui/react"

const SponsorCoin: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 48 48" fill="black" {...props}>
    <circle cx="24" cy="24" r="24" fill="black" />
    <g clip-path="url(#clip0_1785_39081)">
      <path
        d="M18.5 27.5L24.0002 30L29.5 27.5M34 24C34 29.5228 29.5228 34 24 34C18.4772 34 14 29.5228 14 24C14 18.4772 18.4772 14 24 14C29.5228 14 34 18.4772 34 24ZM18.5 23.5L24.0002 26L29.5 23.5L24.0002 17L18.5 23.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1785_39081">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </Icon>
)

export default SponsorCoin
