import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetAcceptedGuaranteedInvoicesResponse } from "./types/getAcceptedGuaranteedInvoicesType";

const getAcceptedInvoicesOfferer = async (): Promise<AxiosResponse<GetAcceptedGuaranteedInvoicesResponse>> => {
  const request = await $axios().get("/get_accepted_invoices_of_offerer");
  return request;
};

export default getAcceptedInvoicesOfferer;
