import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FC, forwardRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { InValidIcon, PasswordIcon, ValidIcon } from "./Icon";
import { maskString } from 'common/utils/transform';

type PasswordInputProps = {
  label?: string;
  labelSmall?: boolean;
  error?: FieldError;
  password?: string;
} & InputProps;

const PasswordInput: FC<PasswordInputProps> = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };
  const { id, label, labelSmall, error, password, ...propsInput } = props;

  const passwordMatch =
    password === undefined
      ? undefined
      : password && props.value && password === props.value;
  
  const handleInputChange = (event: any) => {
    const value = event.target.value;
    // Regular expression to match Thai characters
    const thaiCharRegex = /[\u0E00-\u0E7F]/;
    if (!thaiCharRegex.test(value) && props.onChange) {
      props.onChange(value);
    }
  };

  const RightIcon = () => {
    if (passwordMatch === true) {
      return <ValidIcon />;
    } else if (passwordMatch === false) {
      return <InValidIcon />;
    } else {
      return <></>;
    }
  };

  const borderColor = () => {
    if (password === undefined) return undefined;

    if (passwordMatch) {
      return "success";
    } else if (error) {
      return "error";
    }
  };

  return (
    <Stack spacing={2} className="password-input">
      {label && (
        <Box>
          <label htmlFor={id} className="label">
            {labelSmall ? (
              <Text color="grey3" fontSize="sm">
                {label}
              </Text>
            ) : (
              <Heading size="sm">{label}</Heading>
            )}
          </label>
        </Box>
      )}
      <Box mb={labelSmall ? 0 : 4}>
        <Stack>
          <InputGroup size="md">
            <Input
                {...propsInput}
                ref={ref}
                pr={9}
                type="text"
                borderColor={borderColor()}
                _hover={{ borderColor: "none" }}
                _focusVisible={{ borderColor: "none" }}
                autoComplete="new-password"
                onChange={handleInputChange}
            />
            {!show && (
              <div className="input-password-masking">
                {maskString(`${props.value}`)}
              </div>
            )}
            <InputRightElement width={9}>
              {password === undefined && <PasswordIcon onClick={handleClick} />}
              <RightIcon />
            </InputRightElement>
          </InputGroup>
          {error && (
            <Text fontSize="sm" color={"error"}>
              {error?.message}
            </Text>
          )}
          {passwordMatch && (
            <Text fontSize="sm" color={"success"}>
              Password Match!
            </Text>
          )}
        </Stack>
      </Box>
    </Stack>
  );
});

export default PasswordInput;
