import { Icon, IconProps } from "@chakra-ui/react"

const NFTsIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 15 18" fill="none" {...props}>
    <path
      d="M5.50065 10.0007C5.94268 10.0007 6.3666 9.82506 6.67916 9.5125C6.99172 9.19993 7.16732 8.77601 7.16732 8.33398C7.16732 7.89196 6.99172 7.46803 6.67916 7.15547C6.3666 6.84291 5.94268 6.66732 5.50065 6.66732C5.05862 6.66732 4.6347 6.84291 4.32214 7.15547C4.00958 7.46803 3.83398 7.89196 3.83398 8.33398C3.83398 8.77601 4.00958 9.19993 4.32214 9.5125C4.6347 9.82506 5.05862 10.0007 5.50065 10.0007ZM15.9173 5.41732L8.00065 0.833984L0.0839844 5.41732V14.584L8.00065 19.1673L15.9173 14.584V5.41732ZM8.00065 2.75982L14.2507 6.37815V12.1115L10.449 9.83148L3.80648 14.8132L1.75065 13.6232V6.37815L8.00065 2.75982ZM8.00065 17.2415L5.37398 15.7207L10.5523 11.8373L13.884 13.8357L8.00065 17.2415Z"
      fill="currentColor"
    />
  </Icon>
)

export default NFTsIcon
