// ** React **
import { useState } from "react"

// ** Styles **
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Flex, Text, Button } from "@chakra-ui/react"
import Slider from "react-slick"

// ** Components **
import { NewsCard, NewsDetail } from "modules/news"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useCampaignHook from "hooks/useCampaignHook"

// ** Router **
import { AUTHENTICATE_LOGIN_PAGE_PATH } from "router/AppPath"

// ** Constants **
import { LandingBody } from "constants/Enum"

// ** Types **
import type { Announce } from "types/Campaign"

// ** Icons **
import { ArrowLeftIcon } from "assets/icons"

const NewsPage = () => {
  const navigate = useNavigate()

  const [content, setContent] = useState<Announce>()
  const [contentBody, setContentBody] = useState<LandingBody>(LandingBody.LIST)

  const { getAnnouncesHook, announces, updateAnnouncenHook } = useCampaignHook()

  const { isLoading } = useQuery({
    queryKey: "initAnnouncesList",
    queryFn: getAnnouncesHook,
  })

  if (isLoading) return <></>

  const settings = {
    speed: 500,
    slidesToShow: announces.length >= 4 ? 4 : announces.length,
    slidesToScroll: announces.length >= 4 ? 4 : announces.length,
  }

  return (
    <div className="lading-page">
      <Box display={contentBody === LandingBody.LIST ? "block" : "none"}>
        <Box
          width={announces.length >= 4 ? "100%" : `${announces.length * 450}px`}
        >
          <Slider {...settings}>
            {announces.map((data, index) => (
              <Box
                key={index}
                cursor="pointer"
                onClick={() => {
                  setContent(data)
                  setContentBody(LandingBody.DETAIL)
                  updateAnnouncenHook(data.id)
                }}
              >
                <NewsCard {...data} />
              </Box>
            ))}
          </Slider>
        </Box>
        <Flex justify="center" mt={10}>
          <Button
            w={450}
            bgColor="#FBFDFF"
            color="#00249C"
            colorScheme="blue"
            onClick={() => navigate(AUTHENTICATE_LOGIN_PAGE_PATH)}
          >
            Login
          </Button>
        </Flex>
      </Box>
      <Box display={contentBody === LandingBody.DETAIL ? "block" : "none"}>
        <Flex justify="space-between">
          <Box
            onClick={() => setContentBody(LandingBody.LIST)}
            cursor="pointer"
            mb={12}
          >
            <ArrowLeftIcon />
          </Box>
          <Text color="white" fontSize="3xl">
            News Details
          </Text>
          <Box></Box>
        </Flex>
        {content && <NewsDetail {...content} />}
      </Box>
    </div>
  )
}

export default NewsPage
