// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"

// ** Types **
import type { DeclineOffer } from "types/Invoice"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useInvoiceHook from "hooks/useInvoiceHook"

type DeclineAnOfferModalProps = {
  id: number
} & ModalProps

const DeclineAnOfferModal: FC<DeclineAnOfferModalProps> = (props) => {
  const { isOpen, onClose, id } = props

  const { setAlert } = useAppHook()
  const { postDeclineOfferHook } = useInvoiceHook()

  const { control, handleSubmit } = useForm<DeclineOffer>({
    defaultValues: { id },
    mode: "onChange",
  })

  const onSubmit: SubmitHandler<DeclineOffer> = async (data) => {
    const response = await postDeclineOfferHook(data)
    let alert: Alert = {
      visible: true,
    }
    if (response?.statusCode === 200) {
      onClose()
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "You have successfully declined an offer.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
          action: () => {
            window.location.reload()
          },
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your declined an offer failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" justify="center" align="center">
              <Box mb={4}>
                <Heading fontSize="24px" color="#353535">
                  Confirm
                </Heading>
              </Box>
              <Text color="#353535" mb={4}>
                Enter your password to confirm.
              </Text>
              <Box w="100%">
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <PasswordInput
                      label="Password"
                      type="text"
                      {...field}
                      error={fieldState.error}
                      autoComplete="current-password"
                    />
                  )}
                  rules={{ required: "Please enter your password" }}
                />
              </Box>
            </Flex>
            <Flex gap={4}>
              <PrimaryButton variant="outline" onClick={() => onClose()}>
                Back
              </PrimaryButton>
              <PrimaryButton
                colorScheme="green"
                backgroundColor="green"
                color="white"
                type="submit"
              >
                Confirm
              </PrimaryButton>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DeclineAnOfferModal
