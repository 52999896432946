// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Box, Card, Flex } from "@chakra-ui/react"

// ** Components **
import WalletSelect from "common/components/WalletSelect"
import TabCustom from "../../common/components/TabCustom"
import ListingTab from "./tab/ListingTab"
import OfferingTab from "./tab/OfferingTab"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"

// ** Types **
import { MyWallet } from "types/MyWallet"

const ListingAndOfferingPage = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const { getCompanyWalletHook, myWallet } = useWalletHook()
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()

  const { isLoading } = useQuery({
    queryKey: "myWalletPageInit",
    queryFn: getCompanyWalletHook,
  })

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoading || !selectedWallet) return <></>

  const tabOpts = [
    { label: "Offering", icon: null, activeIcon: null, active: tabIndex === 0 },
    {
      label: "Listing Status",
      icon: null,
      activeIcon: null,
      active: tabIndex === 1,
    },
  ]

  return (
    <Card p={6}>
      <Flex direction="column" gap={4}>
        <Flex justifyContent="space-between">
          <Box w="300px">
            <TabCustom
              tabIndex={tabIndex}
              tabOpt={tabOpts}
              onChange={(index) => setTabIndex(index)}
            ></TabCustom>
          </Box>
          <Box w={300}>
            <WalletSelect
              wallets={myWallet}
              onChange={(value) => setSelectedWallet(value)}
              value={selectedWallet}
            />
          </Box>
        </Flex>
        <Box>
          {tabIndex === 0 && <OfferingTab />}
          {tabIndex === 1 && <ListingTab />}
        </Box>
      </Flex>
    </Card>
  )
}

export default ListingAndOfferingPage
