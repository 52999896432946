import React, { FC } from "react";
import { Route, Routes as Router } from "react-router-dom";
import AppAlert from "../common/components/AppAlert";
import AppLoader from "../common/components/AppLoader";
import ProtectedRoute from "../hocs/ProtectRoute";
import Routes from "./Routes";

const AppRoute: FC = () => {
  const protectRouter = Routes.filter((m) => m.isProtectRoute);
  const nonProtectRouter = Routes.filter((m) => !m.isProtectRoute);

  return (
    <React.Fragment>
      <Router>
        {protectRouter.map((route, i) => (
          <Route
            path={route.path}
            element={<ProtectedRoute />}
            key={`route__${i}`}
          >
            <Route path={route.path} element={route.element} />
          </Route>
        ))}
        {nonProtectRouter.map((route, i) => (
          <Route
            path={route.path}
            element={route.element}
            key={`route__${i}`}
          />
        ))}
      </Router>
      <AppLoader />
      <AppAlert />
    </React.Fragment>
  );
};

export default AppRoute;
