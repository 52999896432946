import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetAcceptedGuaranteedBuyersResponse } from "./types/getAcceptedGuaranteedBuyersType";

const getAcceptedOfferBuyer = async (): Promise<AxiosResponse<GetAcceptedGuaranteedBuyersResponse>> => {
  const request = await $axios().get("/get_accepted_offer_buyer");
  return request;
};

export default getAcceptedOfferBuyer;
