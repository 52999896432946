import { AxiosResponse } from "axios"
import $axios from "./instance"
import ApiResponse from "./types/ApiResponse"
import { PostAcceptOfferBody } from "./types/postAcceptOfferType"
import { DeclineOffer } from "types/Invoice"

const postAcceptOffer = async (
  body: PostAcceptOfferBody
): Promise<AxiosResponse<ApiResponse>> => {
  const response = await $axios().post("/accept_offer", body)
  return response
}

const postDeclineOffer = async (
  body: DeclineOffer
): Promise<AxiosResponse<ApiResponse>> => {
  const response = await $axios().post("/cancel_offer", body)
  return response
}

export { postAcceptOffer, postDeclineOffer }
