import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetSellerDashboardResponse,
  GetSponsorDashboardResponse,
  GetBuyerDashboardResponse,
  GetFIDashboardResponse,
  GetAdminDashboardResponse,
} from "./types/dashboard"

const getSellerDashboard = async (): Promise<
  AxiosResponse<GetSellerDashboardResponse>
> => {
  const request = await $axios().get("/dashboard")
  return request
}

const getSponsorDashboard = async (): Promise<
  AxiosResponse<GetSponsorDashboardResponse>
> => {
  const request = await $axios().get("/dashboard")
  return request
}

const getBuyerDashboard = async (): Promise<
  AxiosResponse<GetBuyerDashboardResponse>
> => {
  const request = await $axios().get("/dashboard")
  return request
}

const getFIDashboard = async (): Promise<
  AxiosResponse<GetFIDashboardResponse>
> => {
  const request = await $axios().get("/dashboard")
  return request
}

const getAdminDashboard = async (): Promise<
  AxiosResponse<GetAdminDashboardResponse>
> => {
  const request = await $axios().get("/dashboard")
  return request
}

export {
  getSellerDashboard,
  getSponsorDashboard,
  getBuyerDashboard,
  getFIDashboard,
  getAdminDashboard,
}
