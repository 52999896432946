import { Icon, IconProps } from "@chakra-ui/react"

const ArrowDownIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 12 8" fill="none" {...props}>
    <path
      d="M11.5326 2.02927L6.53255 7.02927C6.46287 7.09919 6.38008 7.15467 6.28892 7.19252C6.19775 7.23038 6.10001 7.24986 6.0013 7.24986C5.90259 7.24986 5.80485 7.23038 5.71369 7.19252C5.62252 7.15467 5.53973 7.09919 5.47005 7.02927L0.470051 2.02927C0.329155 1.88837 0.25 1.69728 0.25 1.49802C0.25 1.29876 0.329155 1.10767 0.470051 0.96677C0.610947 0.825873 0.802044 0.746719 1.0013 0.746719C1.20056 0.746719 1.39165 0.825873 1.53255 0.96677L6.00193 5.43614L10.4713 0.966145C10.6122 0.825249 10.8033 0.746094 11.0026 0.746094C11.2018 0.746094 11.3929 0.825249 11.5338 0.966145C11.6747 1.10704 11.7539 1.29814 11.7539 1.4974C11.7539 1.69665 11.6747 1.88775 11.5338 2.02865L11.5326 2.02927Z"
      fill="#00249C"
    />
  </Icon>
)

export default ArrowDownIcon
