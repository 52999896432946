// ** Libs **
import {
  Box,
  Card,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Spacer,
  Stack,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

// ** Components **
import InformationValue from "common/components/InformationValue"

// ** Router **
import { EDIT_USER_PROFILE_PAGE_PATH } from "router/AppPath"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

const UserProfilePage = () => {
  const navigate = useNavigate()

  const { user } = useAuthHook()

  const onClickEditProfile = () => {
    navigate(EDIT_USER_PROFILE_PAGE_PATH, {
      state: {
        navTitle: "Profile",
      },
    })
  }

  if (!user) return <></>

  return (
    <Card mb={10}>
      <Flex direction="column" gap={8} mb={8}>
        <Box flex={1} py={2} px={8} borderBottom="0.5px solid black">
          <Heading fontSize="22px" color="primaryTextColor">
            User Profile
          </Heading>
        </Box>
        <Box>
          <Grid gridTemplateColumns={"275px 1fr 1fr"} columnGap={8}>
            <GridItem>
              <Center>
                {user?.avatar ? (
                  <Image
                    borderRadius={"30px"}
                    boxSize="225px"
                    src={process.env.REACT_APP_BASE_URL + "/" + user.avatar}
                  />
                ) : (
                  <Box
                    w={225}
                    h={225}
                    bgColor="grey400"
                    borderRadius={40}
                  ></Box>
                )}
              </Center>
            </GridItem>
            <GridItem>
              <Stack gap={3}>
                <Box>
                  <InformationValue label="First Name" value={user.firstname} />
                </Box>

                <Box>
                  <InformationValue
                    label="Tel. Number 1"
                    value={user.contact}
                  />
                </Box>
                <Box>
                  <InformationValue label="Email" value={user.email} />
                </Box>
              </Stack>
            </GridItem>
            <GridItem>
              <Box>
                <InformationValue label="Last Name" value={user.lastname} />
              </Box>
              <Box>
                <InformationValue label="User Role" value={user.pt_role} />
              </Box>
            </GridItem>
          </Grid>
          <Flex mt={3} mr={16}>
            <Spacer />
            <Box>
              <Heading
                size="sm"
                color="selected"
                textDecoration="underline"
                cursor="pointer"
                onClick={onClickEditProfile}
              >
                Edit Profile / Change Password
              </Heading>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Card>
  )
}

export default UserProfilePage
