import { Icon, IconProps } from "@chakra-ui/react"

const CopyIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 18 20" fill="none" {...props}>
    <path
      d="M4 9.16797C4 6.8113 4 5.63214 4.7325 4.90047C5.46417 4.16797 6.64333 4.16797 9 4.16797H11.5C13.8567 4.16797 15.0358 4.16797 15.7675 4.90047C16.5 5.63214 16.5 6.8113 16.5 9.16797V13.3346C16.5 15.6913 16.5 16.8705 15.7675 17.6021C15.0358 18.3346 13.8567 18.3346 11.5 18.3346H9C6.64333 18.3346 5.46417 18.3346 4.7325 17.6021C4 16.8705 4 15.6913 4 13.3346V9.16797Z"
      stroke="#00249C"
      strokeWidth="1.5"
    />
    <path
      d="M4 15.8346C3.33696 15.8346 2.70107 15.5712 2.23223 15.1024C1.76339 14.6336 1.5 13.9977 1.5 13.3346V8.33464C1.5 5.19214 1.5 3.62047 2.47667 2.64464C3.4525 1.66797 5.02417 1.66797 8.16667 1.66797H11.5C12.163 1.66797 12.7989 1.93136 13.2678 2.4002C13.7366 2.86904 14 3.50493 14 4.16797"
      stroke="#00249C"
      strokeWidth="1.5"
    />
  </Icon>
)

export default CopyIcon
