import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetParticipantCreditResponse,
  GetCorporateWalletResponse,
  GetTotalSupplyResponse,
} from "./types/credit"

const getAllParticipants = async (): Promise<
  AxiosResponse<GetParticipantCreditResponse>
> => {
  const request = await $axios().get("/credit/granted_pt/all")
  return request
}

const getParticipants = async (): Promise<
  AxiosResponse<GetParticipantCreditResponse>
> => {
  const request = await $axios().get("/credit/granted_pt")
  return request
}

const getCorporateWallet = async (): Promise<
  AxiosResponse<GetCorporateWalletResponse>
> => {
  const request = await $axios().get("/wallet/corporate")
  return request
}

const getTotalSupply = async (): Promise<
  AxiosResponse<GetTotalSupplyResponse>
> => {
  const request = await $axios().get("/total_supply")
  return request
}

export {
  getAllParticipants,
  getParticipants,
  getCorporateWallet,
  getTotalSupply,
}
