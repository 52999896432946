// ** Libs **
import { Box } from "@chakra-ui/react"

// ** Components **
import { StableCoinBalance, StableCoinHolder } from "modules/token_management/admin"

const StableCoinTab = () => {
  return (
    <Box>
      <StableCoinBalance />
      <StableCoinHolder />
    </Box>
  )
}

export default StableCoinTab
