import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ customClick }: { customClick?: any }) => {
  const navigate = useNavigate();
  const onBack = () => {
    if (customClick) {
      customClick();
    } else { 
      navigate(-1);
    }
  };
  return (
    <Flex
      display="inline-flex"
      h={6}
      align="center"
      onClick={onBack}
      cursor="pointer"
    >
      <ChevronLeftIcon boxSize={8} color="primary" />
      <Heading size="md" color="primary">
        Back
      </Heading>
    </Flex>
  );
};

export default BackButton;
