import { Icon, IconProps } from "@chakra-ui/react"

const CreditIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.75014 14.3147V15.7576M9.75014 7.15759V8.50045M9.75014 19.2719C14.7501 19.2719 18.3216 17.5004 18.3216 13.5576C18.3216 9.27187 16.1787 6.41473 11.893 4.27188L13.5787 2.10045C13.6643 1.95798 13.7108 1.7954 13.7134 1.62919C13.7159 1.46298 13.6745 1.29905 13.5933 1.15401C13.512 1.00898 13.3939 0.887994 13.2509 0.803323C13.1078 0.718651 12.9449 0.673303 12.7787 0.671875H6.72157C6.55535 0.673303 6.39246 0.718651 6.24941 0.803323C6.10636 0.887994 5.98824 1.00898 5.90702 1.15401C5.8258 1.29905 5.78437 1.46298 5.78692 1.62919C5.78948 1.7954 5.83593 1.95798 5.92157 2.10045L7.60728 4.28616C3.32157 6.4433 1.17871 9.30045 1.17871 13.5862C1.17871 17.5004 4.75014 19.2719 9.75014 19.2719Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.32123 13.6568C8.49554 13.8637 8.71493 14.0279 8.96256 14.1369C9.2102 14.2459 9.4795 14.2966 9.7498 14.2854C10.163 14.3135 10.5709 14.1804 10.888 13.914C11.205 13.6476 11.4064 13.2686 11.4498 12.8568C11.4064 12.445 11.205 12.066 10.888 11.7996C10.5709 11.5332 10.163 11.4001 9.7498 11.4282C9.33665 11.4564 8.92867 11.3233 8.61164 11.0569C8.29461 10.7904 8.09321 10.4115 8.0498 9.99965C8.08973 9.58638 8.29015 9.20525 8.60801 8.93814C8.92588 8.67102 9.33583 8.53923 9.7498 8.57108C10.0154 8.55484 10.2812 8.59766 10.5282 8.69646C10.7752 8.79527 10.9973 8.94761 11.1784 9.14251"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CreditIcon
