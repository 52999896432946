// ** React **
import { FC, useState, useEffect } from "react"

// ** Libs **
import {
  Box,
  Heading,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { MyWallet, UpdateWalletNickname } from "types/MyWallet"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useWalletHook from "hooks/useWalletHook"

// ** Icons **
import { CheckedIcon, WalletCreditCardIcon } from "common/components/Icon"

// ** Utils **
import { abbreviateHexString } from "common/utils/transform"

type EditNicknameWalletModalProps = {
  defaultWallet: MyWallet
} & ModalProps

const EditNicknameWalletModal: FC<EditNicknameWalletModalProps> = (props) => {
  const { isOpen, onClose, defaultWallet } = props
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>(defaultWallet)
  const { myWallet, putWalletNicknameHook } = useWalletHook()
  const { setAlert } = useAppHook()

  const { control, handleSubmit, reset, setError } =
    useForm<UpdateWalletNickname>({
      mode: "onChange",
    })
  
  useEffect(() => {
    if (defaultWallet.id !== selectedWallet.id) { 
      setSelectedWallet(defaultWallet)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWallet])

  useEffect(() => {
    if (defaultWallet) {
      reset({
        nickname: defaultWallet.nickname !== null ? `${defaultWallet.nickname}` : "",
      })
    }
  }, [defaultWallet, reset])

  useEffect(() => {
    if (selectedWallet) {
      reset({
        nickname:
          selectedWallet.nickname !== null ? `${selectedWallet.nickname}` : "",
      })
    }
  }, [selectedWallet, reset])

  const onSubmit: SubmitHandler<UpdateWalletNickname> = async (data) => {
    const isDuplicate = myWallet.filter(
      (item) =>
        item.nickname === data.nickname &&
        item.wallet_address !== selectedWallet.wallet_address
    )
    if (isDuplicate.length > 0) {
      setError("nickname", { message: "This nickname is already in use" })
      return
    }

    const response = await putWalletNicknameHook(selectedWallet.id, data)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Update Wallet Nickname Complete",
        button: {
          label: "Close",
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" justify="center" align="center">
              <Box mb={4}>
                <Heading size="sm">Edit Nickname</Heading>
              </Box>
              <Stack gap={2} w="100%" mb={4}>
                <Box>
                  <Heading size="sm">Wallet Address</Heading>
                </Box>
                <Menu>
                  <MenuButton
                    size="sm"
                    variant="outline"
                    minW="110px"
                    w="100%"
                    h="42px"
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    borderRadius={8}
                    borderColor="#D8D8D8"
                    px={4}
                    py={2}
                  >
                    <Flex align="center" gap={3}>
                      <Box>
                        <CheckedIcon />
                      </Box>
                      <Box>
                        <WalletCreditCardIcon boxSize={6} color="primary" />
                      </Box>
                      <Box>
                        <Text
                          textAlign="left"
                          color="primary"
                          fontSize="small"
                          fontWeight="bold"
                        >
                          {selectedWallet?.company_name} -{" "}
                          {selectedWallet?.nickname}{" "}
                        </Text>
                        <Text
                          textAlign="left"
                          color="primary"
                          fontSize="x-small"
                        >
                          {abbreviateHexString(
                            `${selectedWallet?.wallet_address}`
                          )}
                        </Text>
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {myWallet.map((m, i) => (
                      <MenuItem key={i} onClick={() => setSelectedWallet(m)}>
                        <Text fontSize="sm" color="#212529">
                          {m.company_name} - {m.nickname} (
                          {abbreviateHexString(m.wallet_address)})
                        </Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Stack>
              <TextInput
                label="Bank Account Number"
                value={selectedWallet.bank_account_number}
                isDisabled={true}
              />
              <TextInput
                label="Bank Name"
                value={selectedWallet.bank_name}
                isDisabled={true}
              />
              <Controller
                control={control}
                name="nickname"
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    label="Nickname"
                    placeholder="Enter your nickname"
                    error={error}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter wallet nickname",
                  },
                }}
              />
            </Flex>
            <Flex gap={4}>
              <PrimaryButton variant="outline" onClick={() => onClose()}>
                Cancel
              </PrimaryButton>
              <PrimaryButton
                colorScheme="#0050C8"
                backgroundColor="#0050C8"
                color="white"
                type="submit"
              >
                Save
              </PrimaryButton>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EditNicknameWalletModal
