import { useState, useEffect } from 'react';

// Custom hook to get a query string value by name
const useQueryString = (parameter: string): string | null => {
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    // Function to extract the query string value
    const getQueryStringValue = (param: string): string | null => {
      const params = new URLSearchParams(window.location.search);
      return params.get(param);
    };

    // Update the state with the value of the query string parameter
    const queryValue = getQueryStringValue(parameter);
    setValue(queryValue);
  }, [parameter]);

  return value;
};

export default useQueryString;