import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetParticipantResponse,
  GetParticipantProfileResponse,
  PutParticipantProfileesponse,
  GetParticipantTypeResponse,
  GetParticipantRoleResponse,
  GetParticipantDetailResponse,
  PostCreateParticipantDataResponse,
  PutParticipantDetailResponse,
  PostCreateParticipantWalletDataResponse,
  PutParticipantWalletResponse,
  PostCreateParticipantUserDataResponse,
  PutParticipantUserResponse,
} from "./types/getParticipant"
import {
  ParticipantProfileBody,
  CreateParticipantBody,
  ParticipantDetailEditBody,
  CreateWalletPostBody,
  EditWalletPostBody,
  CreateUserBody,
  EditUserBody,
  CreateParticipantAdminBody,
  WalletPutBody,
} from "types/Participant"
import type { ResponseWithOutData } from "api/types/ApiResponse"

const getParticipant = async (): Promise<
  AxiosResponse<GetParticipantResponse>
> => {
  const request = await $axios().get("/participant")
  return request
}

const getParticipantProfile = async (): Promise<
  AxiosResponse<GetParticipantProfileResponse>
> => {
  const request = await $axios().get("/participant_profile")
  return request
}

const putParticipantProfile = async (
  body: ParticipantProfileBody
): Promise<AxiosResponse<PutParticipantProfileesponse>> => {
  const request = await $axios().put("/participant_profile", body)
  return request
}

const getParticipantType = async (): Promise<
  AxiosResponse<GetParticipantTypeResponse>
> => {
  const request = await $axios().get("/participant_type")
  return request
}

const getParticipantRole = async (): Promise<
  AxiosResponse<GetParticipantRoleResponse>
> => {
  const request = await $axios().get("/participant_role")
  return request
}

const getParticipantDetail = async (
  id: number
): Promise<AxiosResponse<GetParticipantDetailResponse>> => {
  const request = await $axios().get("/participant/" + id)
  return request
}

const createParticipant = async (
  body: CreateParticipantBody | CreateParticipantAdminBody
): Promise<AxiosResponse<PostCreateParticipantDataResponse>> => {
  const request = await $axios().post("/participant", body)
  return request
}

const putParticipantDetail = async (
  id: number,
  body: ParticipantDetailEditBody
): Promise<AxiosResponse<PutParticipantDetailResponse>> => {
  const request = await $axios().put("/participant/" + id, body)
  return request
}

const createParticipantWallet = async (
  body: CreateWalletPostBody
): Promise<AxiosResponse<PostCreateParticipantWalletDataResponse>> => {
  const request = await $axios().post("/wallet", body)
  return request
}

const putParticipantWallet = async (
  id: number,
  body: EditWalletPostBody
): Promise<AxiosResponse<PutParticipantWalletResponse>> => {
  const request = await $axios().put("/wallet/" + id, body)
  return request
}

const createParticipantUser = async (
  body: CreateUserBody
): Promise<AxiosResponse<PostCreateParticipantUserDataResponse>> => {
  const request = await $axios().post("/user/member", body)
  return request
}

const putParticipantUser = async (
  id: number,
  body: EditUserBody
): Promise<AxiosResponse<PutParticipantUserResponse>> => {
  const request = await $axios().put("/user/member/" + id, body)
  return request
}

const postFreezeWallet = async (
  body: WalletPutBody
): Promise<AxiosResponse<PutParticipantWalletResponse>> => {
  const request = await $axios().post("/freeze_wallet", body)
  return request
}

const postUnfreezeWallet = async (
  body: WalletPutBody
): Promise<AxiosResponse<PutParticipantWalletResponse>> => {
  const request = await $axios().post("/unfreeze_wallet", body)
  return request
}

const postSuspendWallet = async (
  body: WalletPutBody
): Promise<AxiosResponse<PutParticipantWalletResponse>> => {
  const request = await $axios().post("/suspend_wallet", body)
  return request
}

const postUnsuspendWallet = async (
  body: WalletPutBody
): Promise<AxiosResponse<PutParticipantWalletResponse>> => {
  const request = await $axios().post("/unsuspend_wallet", body)
  return request
}

const uploadCompanyImage = async (
  type: string,
  file: File
): Promise<AxiosResponse<ResponseWithOutData>> => {
  let data = new FormData()
  data.append("type", type)
  data.append("file", file)

  const request = await $axios().post("/participant_profile/image", data)
  return request
}

export {
  getParticipant,
  getParticipantProfile,
  putParticipantProfile,
  getParticipantType,
  getParticipantRole,
  getParticipantDetail,
  createParticipant,
  putParticipantDetail,
  createParticipantWallet,
  putParticipantWallet,
  createParticipantUser,
  putParticipantUser,
  postFreezeWallet,
  postUnfreezeWallet,
  postSuspendWallet,
  postUnsuspendWallet,
  uploadCompanyImage,
}
