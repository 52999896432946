const bank_list: any = {
  bbl: {
    code: "002",
    color: "#1e4598",
    official_name: "BANGKOK BANK PUBLIC COMPANY LTD.",
    name_en: "Bangkok Bank",
    name_th: "ธนาคารกรุงเทพ",
  },
  kbank: {
    code: "004",
    color: "#138f2d",
    official_name: "KASIKORNBANK PUBLIC COMPANY LTD.",
    name_en: "Kasikornbank",
    name_th: "ธนาคารกสิกร",
  },
  ktb: {
    code: "006",
    color: "#1ba5e1",
    official_name: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    name_en: "Krungthai Bank",
    name_th: "ธนาคารกรุงไทย",
  },
  tmb: {
    code: "011",
    color: "#1279be",
    official_name: "TMB BANK PUBLIC COMPANY LIMITED.",
    name_en: "TMB Bank",
    name_th: "ธนาคารทหารไทย",
  },
  scb: {
    code: "014",
    color: "#4e2e7f",
    official_name: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.",
    name_en: "Siam Commercial Bank",
    name_th: "ธนาคารไทยพาณิชย์",
  },
  citi: {
    code: "017",
    color: "#1583c7",
    official_name: "CITIBANK, N.A.",
    name_en: "Citibank",
    name_th: "ธนาคารซิตี้แบงก์",
  },
  sc: {
    code: "020",
    color: "#0f6ea1",
    official_name: "STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED",
    name_en: "Standard Chartered (Thai)",
    name_th: "ธนาคารสแตนดาร์ดชาร์เตอร์ด ไทย",
  },
  cimb: {
    code: "022",
    color: "#7e2f36",
    official_name: "CIMB THAI BANK PUPBLIC COMPANY LTD.",
    name_en: "CIMB Thai Bank",
    name_th: "ธนาคารซีไอเอ็มบีไทย",
  },
  uob: {
    code: "024",
    color: "#0b3979",
    official_name: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED",
    name_en: "United Overseas Bank (Thai)",
    name_th: "ธนาคารยูโอบี",
  },
  bay: {
    code: "025",
    color: "#fec43b",
    official_name: "BANK OF AYUDHYA PUBLIC COMPANY LTD.",
    name_en: "Bank of Ayudhya (Krungsri)",
    name_th: "ธนาคารกรุงศรีอยุธยา",
  },
  gsb: {
    code: "030",
    color: "#eb198d",
    official_name: "THE GOVERNMENT SAVINGS BANK",
    name_en: "Government Savings Bank",
    name_th: "ธนาคารออมสิน",
  },
  baac: {
    code: "034",
    color: "#4b9b1d",
    official_name: "BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES",
    name_en: "Bank for Agriculture and Agricultural Cooperatives",
    name_th: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  },
  tbank: {
    code: "065",
    color: "#fc4f1f",
    official_name: "THANACHART BANK PUBLIC COMPANY LTD.",
    name_en: "Thanachart Bank",
    name_th: "ธนาคารธนชาต",
  },
  tisco: {
    code: "067",
    color: "#12549f",
    official_name: "TISCO BANK PUBLIC COMPANY LIMITED",
    name_en: "Tisco Bank",
    name_th: "ธนาคารทิสโก้",
  },
  icbc: {
    code: "070",
    color: "#c50f1c",
    official_name:
      "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED",
    name_en: "Industrial and Commercial Bank of China (Thai)",
    name_th: "ธนาคารสินเอเซีย",
  },
  lhb: {
    code: "073",
    color: "#6d6e71",
    official_name: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED",
    name_en: "Land and Houses Bank",
    name_th: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
  },
  ttb: {
    code: "076",
    color: "#ecf0f1",
    official_name: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED",
    name_en: "TMBThanachart Bank",
    name_th: "ธนาคารทหารไทยธนชาต",
  },
}

const bank_list_avalible: any = {
  ktb: {
    code: "006",
    color: "#1ba5e1",
    official_name: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    name_en: "Krungthai Bank",
    name_th: "ธนาคารกรุงไทย",
  },
  ttb: {
    code: "076",
    color: "#ecf0f1",
    official_name: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED",
    name_en: "TMBThanachart Bank",
    name_th: "ธนาคารทหารไทยธนชาต",
  },
  tcg: {
    code: "000",
    color: "#ecf0f1",
    official_name: "Thai Credit Guarantee Corporation (TCG)",
    name_en: "Thai Credit Guarantee Corporation (TCG)",
    name_th: "บรรษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม (บสย.)",
  },
}

const bankOptions = () =>
  Object.entries(bank_list).map(([bank, info]) => {
    return {
      value: bank,
      label: bank_list[bank].name_th,
      color: bank_list[bank].color,
      code: bank_list[bank].code,
    }
  })

const bankOptionsAvalible = () =>
  Object.entries(bank_list_avalible).map(([bank, info]) => {
    return {
      value: bank,
      label: bank_list_avalible[bank].name_th,
      color: bank_list_avalible[bank].color,
      code: bank_list_avalible[bank].code,
    }
  })

export { bank_list, bankOptions, bankOptionsAvalible }
