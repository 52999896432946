import { Card, CardProps } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

type CardContentProps = {} & PropsWithChildren & CardProps;

const CardContent: FC<CardContentProps> = (props) => {
  return <Card className="card-content" {...props}></Card>;
};

export default CardContent;
