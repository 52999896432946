import { AxiosResponse } from "axios"
import $axios from "./instance"
import { SwitchRoleResponse } from "./types/user"

const switchRole = async (
  role_id: number
): Promise<AxiosResponse<SwitchRoleResponse>> => {
  const request = await $axios().get("/user?role=" + role_id)
  return request
}

export { switchRole }
