/* eslint-disable react-hooks/exhaustive-deps */
// ** React **
import { FC, useEffect, useState } from "react"

// ** Libs **
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Stack,
  Text,
  Checkbox,
  Input,
} from "@chakra-ui/react"

// ** Constants **
import { UserRole } from "constants/Enum"

// ** Components **
import GridItemPopupDetail from "../GridItemPopupDetail"
import PrimaryButton from "../PrimaryButton"
import TextInput from "../TextInput"
import { MakeBiddingInputValue } from "./MakeBiddingModalContainer"
import BoxPercent from "./BoxPercent"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"
import useWalletHook from "hooks/useWalletHook"
import useCreditHook from "hooks/useCreditHook"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  abbreviateHexString,
  allowOnlyNumbersAndDecimals,
  roundupDecimal,
  capitalizeFirstLetter,
} from "common/utils/transform"
import { calculateInterestFee, calculateByPercent } from "common/utils/bidding"
import { getLocalStorageData } from "common/utils/localStorage"

// ** Types **
import { InvoiceListed } from "types/Invoice"
import { MyWallet } from "types/MyWallet"
import { CreditSponsor, CreditUsage } from "types/Credit"

type InputInterestProps = {
  title: string
  inputLabel: string
  onChange: (value: number) => void
}

const InputInterest: FC<InputInterestProps> = ({
  title,
  inputLabel,
  onChange,
}) => {
  const [value, setValue] = useState<string>()

  useEffect(() => {
    if (value) {
      onChange(parseFloat(`${value}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleOnChange = (e: any) => {
    if (parseFloat(e.target.value) > 100) {
      setValue("100")
    } else if (parseFloat(e.target.value) < 0) {
      setValue("0")
    } else {
      setValue(roundupDecimal(allowOnlyNumbersAndDecimals(e.target.value)))
    }
  }

  return (
    <Stack gap={1}>
      <Box>
        <Heading size="xs">{title}</Heading>
      </Box>
      <Box>
        <Text color="#6A727A" fontSize="small">
          {inputLabel}
        </Text>
      </Box>
      <Box>
        <TextInput
          textAlign="right"
          value={value}
          onChange={(e) => handleOnChange(e)}
          pr={7}
          rightIcon={<Text>%</Text>}
        />
      </Box>
    </Stack>
  )
}

type MakeBiddingInputProps = {
  invoice: InvoiceListed
  selectedWallet: MyWallet
  makeBiddingValue: MakeBiddingInputValue[]
  onClickClose: VoidFunction
  onClickConfirm: (
    val: MakeBiddingInputValue[],
    creditUsage: CreditUsage[]
  ) => void
  onHiddenInvoice: (id: number) => void
  onUnhiddenInvoice: (id: number) => void
}

const CreditItem = ({
  invoice,
  data,
  creditUsage,
  selectCreditUsage,
}: {
  invoice: InvoiceListed
  data: CreditSponsor
  creditUsage: CreditUsage[]
  selectCreditUsage: any
}) => {
  const [status, setState] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>("")

  const setPercent = (percent: number) => {
    const creditUsage = (parseFloat(invoice.amount) * percent) / 100
    if (creditUsage <= parseFloat(data.credit.amount)) {
      setAmount(`${creditUsage.toFixed(2)}`)
    } else {
      setAmount(parseFloat(data.credit.amount).toFixed(2))
    }
  }

  const balanceCredit = () => {
    const invoiceAmount = parseFloat(invoice.amount)
    let currentAmount = creditUsage.reduce(
      (a: number, b: CreditUsage) => a + b.amount,
      0
    )
    const remainingAmount = invoiceAmount - currentAmount

    const otherCredit = creditUsage.filter(
      (item: CreditUsage) => item.credit_name !== data.name
    )
    if (otherCredit.length > 0) {
      const name = otherCredit[0].credit_name
      let temp = [...creditUsage]
      temp.map((item: CreditUsage) => {
        if (item.credit_name === name) {
          item.amount += remainingAmount
        }
        return item
      })
      selectCreditUsage(temp)
    }
  }

  useEffect(() => {
    if (status === true) {
      if (creditUsage.length === 0) {
        selectCreditUsage([
          {
            credit_name: data.name,
            amount:
              parseFloat(amount) > 0
                ? parseFloat(`${amount}`)
                : parseFloat(invoice.amount),
          },
        ])
      } else if (
        creditUsage.filter(
          (item: CreditUsage) => item.credit_name === data.name
        ).length === 0
      ) {
        let temp = [...creditUsage]
        temp.push({
          credit_name: data.name,
          amount: parseFloat(amount),
        })
        selectCreditUsage(temp)
      }
    } else {
      selectCreditUsage(
        creditUsage.filter(
          (item: CreditUsage) => item.credit_name !== data.name
        )
      )
      balanceCredit()
    }
  }, [status])

  useEffect(() => {
    if (
      creditUsage.filter((item: CreditUsage) => item.credit_name === data.name)
        .length > 0
    ) {
      let temp = [...creditUsage]
      temp.map((item: CreditUsage) => {
        if (item.credit_name === data.name) {
          item.amount = parseFloat(amount)
        }
        return item
      })
      selectCreditUsage(temp)
      balanceCredit()
    }
  }, [amount])

  useEffect(() => {
    const temp = creditUsage.filter(
      (item: CreditUsage) => item.credit_name === data.name
    )
    if (temp.length > 0 && temp[0].amount !== parseFloat(amount)) {
      setAmount(`${temp[0].amount.toFixed(2)}`)
    }
  }, [creditUsage])

  return (
    <Box>
      <Flex justify="space-between" alignItems="flex-end" mt={2}>
        <Checkbox
          onChange={(e) => {
            setState(e.target.checked)
          }}
        >
          <Box fontWeight="bold" color="secondaryTextColor">
            {data.name}
          </Box>
        </Checkbox>
        <Text fontSize="sm">
          Available Credit :{" "}
          {isNaN(parseFloat(data?.credit?.amount))
            ? "-"
            : numberFormatForCurrency(data?.credit?.amount)}{" "}
          {data.name}
        </Text>
      </Flex>
      {status && (
        <Box mt={3}>
          <Grid templateColumns="repeat(4,1fr)" gap={5}>
            <GridItem>
              <Box onClick={() => setPercent(25)}>
                <BoxPercent
                  label="25%"
                  isActive={
                    amount === (parseFloat(invoice.amount) * 0.25).toFixed(2)
                      ? true
                      : false
                  }
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box onClick={() => setPercent(50)}>
                <BoxPercent
                  label="50%"
                  isActive={
                    amount === (parseFloat(invoice.amount) * 0.5).toFixed(2)
                      ? true
                      : false
                  }
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box onClick={() => setPercent(75)}>
                <BoxPercent
                  label="75%"
                  isActive={
                    amount === (parseFloat(invoice.amount) * 0.75).toFixed(2)
                      ? true
                      : false
                  }
                />
              </Box>
            </GridItem>
            <GridItem>
              <Box onClick={() => setPercent(100)}>
                <BoxPercent
                  label="100%"
                  isActive={
                    amount === parseFloat(invoice.amount).toFixed(2)
                      ? true
                      : false
                  }
                />
              </Box>
            </GridItem>
          </Grid>
          <Box mt={3}>
            <Input
              textAlign="right"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

const MakeBiddingInput: FC<MakeBiddingInputProps> = (props) => {
  const {
    onClickClose,
    onClickConfirm,
    invoice,
    makeBiddingValue,
    onHiddenInvoice,
    onUnhiddenInvoice,
    selectedWallet,
  } = props

  const { getRole, user } = useAuthHook()
  const userRole = getRole()
  const { getAllWalletHook } = useWalletHook()
  const { getCreditBalanceHook } = useCreditHook()

  const [value, setValue] = useState<MakeBiddingInputValue[]>(makeBiddingValue)
  const [interestFeeOp1, setInterestFeeOp1] = useState(0)
  const [interestFee, setInterestFee] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [commitmentFee, setCommitmentFee] = useState(0)
  const [, setSellerWallet] = useState<MyWallet>()
  const [, setBuyerWallet] = useState<MyWallet>()
  const [credit, setCredit] = useState<CreditSponsor[]>()
  const [creditUsage, selectCreditUsage] = useState<CreditUsage[]>([])
  const [hidden, setHidden] = useState<number[]>([])
  const [guaranteeFeePercent, setGuaranteeFeePercent] = useState(0.0)
  const [guaranteeFeeAmount, setGuaranteeFeeAmount] = useState(0.0)

  useEffect(() => {
    if (user) {
      setHidden(getLocalStorageData("user_invoice", user.id) ?? [])
    }
  }, [user])

  useEffect(() => {
    getWalletMapping()
  }, [])

  useEffect(() => {
    if (selectedWallet) {
      getCreditBalanceByWalletId(`${selectedWallet.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet])

  useEffect(() => {
    const term_days = invoice.term_days

    let interestRate: any = {}
    credit?.forEach((item: any) => {
      interestRate[item.name] = item.credit.interest_rate
    })

    let totalGuaranteeFeeAmount = 0
    let interestRates: number[] = []
    creditUsage.forEach((item) => {
      const guarantee_amount =
        item.amount * (term_days / 365) * (interestRate[item.credit_name] / 100)
      totalGuaranteeFeeAmount += guarantee_amount
      interestRates.push(interestRate[item.credit_name])
    })

    const totalPercent = interestRates.reduce(
      (a: number, b: number) => a + b,
      0
    )
    const avgPercent = totalPercent / interestRates.length

    setGuaranteeFeeAmount(totalGuaranteeFeeAmount)
    setGuaranteeFeePercent(avgPercent)
  }, [creditUsage])

  const getCreditBalanceByWalletId = async (id: string) => {
    let balance = await getCreditBalanceHook(id)
    if (balance && balance.length > 0) {
      let temp = [
        ...balance.filter(
          (item: CreditSponsor) => parseFloat(item.credit.amount) > 0
        ),
      ]
      temp.sort((a: CreditSponsor, b: CreditSponsor) => {
        if (a.extra === b.extra) {
          return parseFloat(b.credit.amount) - parseFloat(a.credit.amount)
        }
        return a.extra ? -1 : 1
      })

      setCredit(temp)
    }
  }

  const getWalletMapping = async () => {
    const allWallets = await getAllWalletHook()
    if (allWallets?.status === 200) {
      // Set Seller Wallet
      const sellerWallets = allWallets.data.data.filter(
        (item: any) => item.id === invoice.seller_wallet_id
      )
      setSellerWallet(sellerWallets[0])

      // Set Buyer Wallet
      const buyerWallets = allWallets.data.data.filter(
        (item: any) => item.id === invoice.buyer_wallet_id
      )
      setBuyerWallet(buyerWallets[0])
    }
  }

  const onClickHide = () => {
    onHiddenInvoice(invoice.id)
    onClickClose()
    if (user) {
      setHidden(getLocalStorageData("user_invoice", user.id) ?? [])
    }
  }

  const onClickUnhide = () => {
    onUnhiddenInvoice(invoice.id)
    onClickClose()
    if (user) {
      setHidden(getLocalStorageData("user_invoice", user.id) ?? [])
    }
  }

  const handleOnSubmit = () => {
    if (
      value.filter((m) => m.value === null).length === 3 ||
      (userRole === UserRole.BUYER &&
        value[2].value !== null &&
        creditUsage.length === 0)
    )
      return
    onClickConfirm(value, creditUsage)
  }

  return (
    <Flex direction="column" gap={8}>
      <Center>
        <Heading size="md">Make an Bidding</Heading>
      </Center>
      <Grid templateColumns="repeat(2,1fr)" gap={5}>
        <GridItemPopupDetail title="Doctype" value="Invoice" />
        <GridItemPopupDetail title="Doc No." value={invoice.doc_number} />
        <GridItemPopupDetail
          title="Buyer Juristic Id."
          value={invoice.buyer_juristic_id}
        />
        <GridItemPopupDetail
          title="Buyer Name"
          value={capitalizeFirstLetter(invoice.buyer_company_name)}
        />
        <GridItemPopupDetail
          title="Seller Juristic Id."
          value={invoice.seller_juristic_id}
        />
        <GridItemPopupDetail
          title="Seller Name"
          value={capitalizeFirstLetter(invoice.seller_company_name)}
        />
        <GridItemPopupDetail
          title="Doc Date"
          value={formatDateDD_MMMM_YYYY(invoice.doc_date)}
        />
        <GridItemPopupDetail
          title="Amount"
          value={numberFormatForCurrency(invoice.amount.toString())}
        />
      </Grid>
      <Grid templateColumns="repeat(1,1fr)" gap={5}>
        <GridItemPopupDetail title="Currency" value={invoice?.currency} />
        <GridItemPopupDetail
          title="Owner Name"
          value={
            invoice?.seller_company_name
              ? capitalizeFirstLetter(invoice?.seller_company_name)
              : ""
          }
        />
      </Grid>
      <Grid templateColumns="repeat(2,1fr)" gap={5}>
        <GridItemPopupDetail
          title="Holder Name"
          value={
            invoice?.seller_company_name
              ? capitalizeFirstLetter(invoice?.seller_company_name)
              : ""
          }
        />
        <GridItemPopupDetail
          title="Holder Wallet"
          value={`${
            invoice?.seller_wallet_address
              ? abbreviateHexString(invoice?.seller_wallet_address)
              : ""
          }`}
        />
      </Grid>
      <Grid templateColumns="repeat(1,1fr)" gap={5}>
        <GridItemPopupDetail
          title="Schedule Payment Date"
          value={formatDateDD_MMMM_YYYY(invoice.payment_date)}
        />
        <GridItemPopupDetail
          title="Financial Amount (NEW)"
          value={numberFormatForCurrency(invoice.amount.toString())}
        />
      </Grid>
      {userRole === UserRole.FI &&
        invoice.options[0] &&
        invoice.offered.indexOf(1) === -1 && (
          <Box>
            <InputInterest
              title="Option 1 : Financing"
              inputLabel="Interest rate (per year)"
              onChange={(val: number) => {
                if (!val) {
                  let temp = [...value]
                  temp[0].value = null
                  setValue(temp)
                  setInterestFeeOp1(0)
                  return
                } else {
                  let temp = [...value]
                  temp[0].value = val
                  setValue(temp)

                  const today = new Date()
                  today.setHours(0, 0, 0, 0)

                  const interestFee = calculateInterestFee(
                    today,
                    new Date(invoice.payment_date),
                    val,
                    parseFloat(invoice.amount)
                  )
                  setInterestFeeOp1(interestFee)
                }
              }}
            />
            <Flex justify="space-between">
              <Text>Total Interest Fee (NEW)</Text>
              <Text>
                {numberFormatForCurrency(`${interestFeeOp1.toFixed(2)}`)}
              </Text>
            </Flex>
          </Box>
        )}
      {userRole === UserRole.FI &&
        invoice.options[0] &&
        invoice.offered.indexOf(1) !== -1 && (
          <Box>
            <Box>
              <Heading size="xs">Option 1 : Financing</Heading>
            </Box>
            <Center mt={4}>
              <Text>Already offer this options.</Text>
            </Center>
          </Box>
        )}
      {userRole === UserRole.BUYER && invoice.options[1] && (
        <>
          {invoice.offered.indexOf(2) === -1 ? (
            <Box>
              <InputInterest
                title="Option 2 : Buyer Prepayment"
                inputLabel="Discounting rate (per amount)"
                onChange={(val: number) => {
                  if (!val) {
                    let temp = [...value]
                    temp[1].value = null
                    setValue(temp)
                    setDiscount(0)
                    return
                  } else {
                    let temp = [...value]
                    temp[1].value = val
                    setValue(temp)

                    const totalDiscount = calculateByPercent(
                      val,
                      parseFloat(invoice.amount)
                    )
                    setDiscount(totalDiscount)
                  }
                }}
              />
              <Flex justify="space-between">
                <Text>Total Discount (NEW)</Text>
                <Text>{numberFormatForCurrency(`${discount.toFixed(2)}`)}</Text>
              </Flex>
            </Box>
          ) : (
            <Box>
              <Box>
                <Heading size="xs">Option 2 : Buyer Prepayment</Heading>
              </Box>
              <Center mt={4}>
                <Text>Already offer this option.</Text>
              </Center>
            </Box>
          )}
        </>
      )}
      {userRole === UserRole.BUYER &&
        invoice.options[2] &&
        (invoice.guarantee_status === null ||
          invoice.guarantee_status === "failed") && (
          <Box>
            {credit && credit.length > 0 ? (
              <>
                <InputInterest
                  title="Option 3 : Buyer Guarantee"
                  inputLabel="Commitment Fee (per amount)"
                  onChange={(val: number) => {
                    if (!val) {
                      let temp = [...value]
                      temp[2].value = null
                      setValue(temp)
                      setCommitmentFee(0)
                      return
                    } else {
                      let temp = [...value]
                      temp[2].value = val
                      setValue(temp)

                      const totalCommitmentFee = calculateByPercent(
                        val,
                        parseFloat(invoice.amount)
                      )
                      setCommitmentFee(totalCommitmentFee)
                    }
                  }}
                />
                <Flex justify="space-between">
                  <Text fontWeight="bold">Total Commitment Fee (NEW)</Text>
                  <Text>
                    {numberFormatForCurrency(`${commitmentFee.toFixed(2)}`)}
                  </Text>
                </Flex>
                <Flex justify="space-between" alignItems="center" mt={2}>
                  <Text fontWeight="bold">Guarantee Fee</Text>
                  <Box textAlign="right" fontWeight="bold" fontSize="xs">
                    <Text>
                      {numberFormatForCurrency(
                        `${guaranteeFeePercent.toFixed(2)}`
                      )}
                      %
                    </Text>
                    <Text>
                      {numberFormatForCurrency(
                        `${guaranteeFeeAmount.toFixed(2)}`
                      )}{" "}
                      NEW
                    </Text>
                  </Box>
                </Flex>
                <Text fontWeight="bold" mt={4}>
                  Credit Usage
                </Text>
                <Box mt={2}>
                  {credit && credit.length > 0 && (
                    <Box>
                      {credit.map((item: CreditSponsor) => (
                        <CreditItem
                          data={item}
                          invoice={invoice}
                          creditUsage={creditUsage}
                          selectCreditUsage={selectCreditUsage}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Box>
                <Box>
                  <Heading size="xs">Option 3 : Buyer Guarantee</Heading>
                </Box>
                <Center mt={4}>
                  {credit && credit.length === 0 ? (
                    <Text color="red">No credit for Guarantee</Text>
                  ) : (
                    <Text>Loading...</Text>
                  )}
                </Center>
              </Box>
            )}
          </Box>
        )}
      {userRole === UserRole.BUYER &&
        invoice.options[2] &&
        invoice.guarantee_status !== null && (
          <Box>
            <Text fontWeight="bold" fontSize="sm">
              Option 3 : Buyer Guarantee
            </Text>
            <Flex justify="space-between" mt={2}>
              <Text>Commitment Fee (per amount)</Text>
              <Text>
                {numberFormatForCurrency(`${invoice.commitment_fee}`)}%
              </Text>
            </Flex>
            <Flex justify="space-between" mt={2}>
              <Text>Total Commitment Fee (NEW)</Text>
              <Text>
                {numberFormatForCurrency(`${invoice.commitment_flat_fee}`)}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mt={2}>
              <Text>Guarantee Fee</Text>
              <Text>
                {isNaN(parseFloat(invoice.guarantee_flat_fee))
                  ? "-"
                  : numberFormatForCurrency(
                      `${invoice.guarantee_flat_fee}`
                    )}{" "}
                NEW
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mt={2}>
              <Text>Guarantee Status</Text>
              <Text
                color={
                  invoice.guarantee_status === "guaranteed" ? "green" : "orange"
                }
                fontWeight="bold"
                className="capital"
              >
                {invoice.guarantee_status}
              </Text>
            </Flex>
          </Box>
        )}
      {userRole === UserRole.FI &&
        invoice.options[2] &&
        invoice.guarantee_flat_fee !== null &&
        invoice.offered.indexOf(3) === -1  && (
          <Box>
            <InputInterest
              title="Option 3 : Guarantee Discounting"
              inputLabel="Interest rate (per year)"
              onChange={(val: number) => {
                if (!val) {
                  let temp = [...value]
                  temp[2].value = null
                  setValue(temp)
                  setInterestFee(0)
                  return
                } else {
                  let temp = [...value]
                  temp[2].value = val
                  setValue(temp)

                  const today = new Date()
                  today.setHours(0, 0, 0, 0)

                  const interestFee = calculateInterestFee(
                    today,
                    new Date(invoice.payment_date),
                    val,
                    parseFloat(invoice.amount)
                  )
                  setInterestFee(interestFee)
                }
              }}
            />
            <Flex justify="space-between">
              <Text>Total Interest Fee (NEW)</Text>
              <Text>
                {numberFormatForCurrency(`${interestFee.toFixed(2)}`)}
              </Text>
            </Flex>
            <Flex justify="space-between">
              <Text>Total Guarantee Fee (NEW)</Text>
              <Text>
                {numberFormatForCurrency(
                  `${
                    parseFloat(invoice.guarantee_flat_fee) +
                    parseFloat(invoice.guarantee_flat_fee_extra) +
                    parseFloat(invoice.commitment_flat_fee)
                  }`
                )}
              </Text>
            </Flex>
          </Box>
        )}
      {userRole === UserRole.FI &&
        invoice.options[2] &&
        invoice.guarantee_flat_fee !== null &&
        invoice.offered.indexOf(3) !== -1  && (
          <Box>
            <Box>
              <Heading size="xs">Option 3 : Guarantee Discounting</Heading>
            </Box>
            <Center mt={4}>
              <Text>Already offer this options.</Text>
            </Center>
          </Box>
        )}
      {userRole === UserRole.FI &&
        invoice.options[2] &&
        invoice.guarantee_flat_fee === null && (
          <Box>
            <Box>
              <Heading size="xs">Option 3 : Buyer Guarantee</Heading>
            </Box>
            <Center mt={4}>
              <Text>Currently awaiting confirmation of buyer guarantee.</Text>
            </Center>
          </Box>
        )}
      <HStack gap={4} justify="space-between">
        <Box flex={1}>
          <PrimaryButton variant="outline" onClick={onClickClose}>
            Close
          </PrimaryButton>
        </Box>
        <Box flex={1}>
          <PrimaryButton
            variant="outline"
            colorScheme="#0050C8"
            borderColor="#0050C8"
            color="#0050C8"
            onClick={
              hidden.indexOf(invoice.id) === -1 ? onClickHide : onClickUnhide
            }
          >
            {hidden.indexOf(invoice.id) === -1 ? "Hide" : "Unhide"}
          </PrimaryButton>
        </Box>
        <Box flex={1}>
          <PrimaryButton
            colorScheme="#0050C8"
            backgroundColor="#0050C8"
            color="white"
            isDisabled={
              value.filter((m) => m.value === null).length === 3 ||
              (userRole === UserRole.BUYER &&
                value[2].value !== null &&
                creditUsage.length === 0)
                ? true
                : false
            }
            onClick={handleOnSubmit}
          >
            Make an Offer
          </PrimaryButton>
        </Box>
      </HStack>
    </Flex>
  )
}

export default MakeBiddingInput
