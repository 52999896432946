// ** React **
import { useState } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { DiscountingList } from "modules/discounting"

const SubTab = ({
  option,
  ptId,
}: {
  option: number
  ptId?: number
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box py="6">
      <Box w={300}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Success",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Default",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <DiscountingList option={option} isSuccess={true} ptId={ptId} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <DiscountingList option={option} isSuccess={false} ptId={ptId} />
      </Box>
    </Box>
  )
}

export default SubTab
