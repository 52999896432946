// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Box, Text, Flex, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { InvoiceModalSponsor } from "modules/revenue"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useInvoiceHook from "hooks/useInvoiceHook"

// ** Types **
import { AcceptedGuaranteedInvoice } from "types/Invoice"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateDD_MMMM_YYYY,
  invoiceOptionIdToString,
  removeDuplicates,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Constants **
import { TRANSACTION_OPTION_FI } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

const InvoiceSponsor = ({ type, ptId }: { type: string; ptId?: number }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("All Option")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [invoiceData, setInvoiceData] = useState<AcceptedGuaranteedInvoice>()
  const modalDetail = useDisclosure()

  const tyxTypeOpts = TRANSACTION_OPTION_FI.map((m) => ({ title: m, value: m }))

  const { getAcceptedGuaranteedInvoicesHook, acceptedGuaranteedInvoices } =
    useInvoiceHook()

  useEffect(() => {
    if (acceptedGuaranteedInvoices.length > 0) {
      setData(
        acceptedGuaranteedInvoices
          .filter((item: any) =>
            type === "normal"
              ? item.status === "normal"
              : item.status === "default"
          )
          .filter((item: any) =>
            ptId !== undefined ? item.buyer_pt_id === ptId : true
          )
      )
    }
  }, [acceptedGuaranteedInvoices, type, ptId])

  const { isLoading } = useQuery({
    queryKey: "initRevenueByInvoice",
    queryFn: getAcceptedGuaranteedInvoicesHook,
  })

  const column: TableColumn[] = [
    {
      title: "DocNo.",
      value: "doc_number",
    },
    {
      title: "DocType",
      value: "doc_type",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontSize="14" fontWeight="bold">
            Invoice
          </Text>
        )
      },
    },
    {
      title: "Buyer Name",
      value: "buyer_company_name",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.buyer_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.seller_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Schedule payment Date",
      value: "payment_date",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {formatDateDD_MMMM_YYYY(value.payment_date)}
          </Text>
        )
      },
    },
    {
      title: "Option",
      value: "offer_option",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {invoiceOptionIdToString(value.offer_option)}
          </Text>
        )
      },
    },
    {
      title: "Guarantee Fee Rate",
      value: "guarantee_flat_fee",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold">
            {numberFormatForCurrency(value.guarantee_fee_rate)}%
          </Text>
        )
      },
    },
    {
      title: "Amount (NEW)",
      value: "amount",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key} fontWeight="bold">
            {numberFormatForCurrency(value.amount)}
          </Box>
        )
      },
    },
    {
      title: "Overdue",
      value: "overdue",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key} fontWeight="bold">
            {value.overdue_day ?? "-"} days
          </Box>
        )
      },
    },
    {
      title: "Revenue",
      value: "revenue",
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} fontWeight="bold" fontSize="lg" color="primary">
            {numberFormatForCurrency(value.guarantee_flat_fee)} NEW
          </Text>
        )
      },
    },
    {
      title: "Status",
      value: "status",
      customRender: (key: string, value: any) => {
        return <SuccessBadge label={value.status} />
      },
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
  ]

  const onRowClick = (data: AcceptedGuaranteedInvoice) => {
    setInvoiceData(data)
    modalDetail.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: AcceptedGuaranteedInvoice[] = []

    if (trxType.indexOf("All Option") > -1) {
      temp = acceptedGuaranteedInvoices
    }

    if (trxType.indexOf("Financing") > -1) {
      temp = temp.concat(
        temp,
        acceptedGuaranteedInvoices.filter((m) => m.offer_option === 1)
      )
    }

    if (trxType.indexOf("Buyer Prepayment") > -1) {
      temp = temp.concat(
        temp,
        acceptedGuaranteedInvoices.filter((m) => m.offer_option === 2)
      )
    }

    if (trxType.indexOf("Buyer Guarantee") > -1) {
      temp = temp.concat(
        temp,
        acceptedGuaranteedInvoices.filter((m) => m.offer_option === 3)
      )
    }

    temp = removeDuplicates(temp, "id")

    const filterValue = temp
      .filter((item: any) =>
        type === "normal" ? item.status === "normal" : item.status === "default"
      )
      .filter((item: any) =>
        ptId !== undefined ? item.buyer_pt_id === ptId : true
      )
      .filter((m) =>
        (
          m.doc_number +
          m.offer_company_name +
          m.seller_company_name +
          m.buyer_company_name
        )
          .toLowerCase()
          .includes(fillValue.toLowerCase())
      )
    setData(filterValue)
  }, [fillValue, acceptedGuaranteedInvoices, type, ptId, trxType])

  if (isLoading) return <></>

  return (
    <Box>
      {ptId !== undefined && data && data.length > 0 && (
        <Flex p={8} py={4} gap={8} fontSize={16}>
          <Flex gap={8}>
            <Text>Buyer Name</Text>
            <Text
              fontWeight="bold"
              css={{
                "&:first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {data[0].buyer_company_name}
            </Text>
          </Flex>
          <Flex gap={8}>
            <Text>Buyer Juristic ID</Text>
            <Text fontWeight="bold">{data[0].buyer_juristic_id}</Text>
          </Flex>
        </Flex>
      )}
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        filter={filterTable}
        style={TableStyle.BOX}
        onRowClick={onRowClick}
        searchPlaceholder="Debtor Name/ Invoice id"
        onSearchChange={onSearchChange}
      />
      <InvoiceModalSponsor
        {...modalDetail}
        data={invoiceData}
        onClickClose={() => modalDetail.onClose()}
      />
    </Box>
  )
}

export default InvoiceSponsor
