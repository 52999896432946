// ** React **
import { FC, useRef, ChangeEvent, useEffect } from "react"

// ** Libs **
import { Flex, Box, Center } from "@chakra-ui/react"

import {
  numberFormatForCurrency,
  allowOnlyNumbersAndDecimals,
  roundupDecimal,
} from "common/utils/transform"

// ** Icons **
import { IncreaseIcon, DecreaseIcon } from "assets/icons"

type AmountInputProps = {
  value: string
  min?: number
  max?: number
  step?: number
  current?: number
  onChange: (val: string) => void
  unit: string
}

const AmountInput: FC<AmountInputProps> = (props) => {
  const { step = 1, min = 0.0, max, value, current, unit, onChange } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const decrement = () => {
    if (Number(value) <= min || Number(value) < step) return
    onChange((Number(value) - step).toFixed(2))
  }

  const increment = () => {
    if (max === 0) return
    if (max && Number(value) >= max) return
    onChange((Number(value) + step).toFixed(2))
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (max && Number(value) > max) {
      onChange(max.toFixed(2))
    } else {
      onChange(roundupDecimal(allowOnlyNumbersAndDecimals(event.target.value)))
    }
  }

  useEffect(() => {
    if (max) {
      if (parseFloat(value) > max) {
        onChange(max.toFixed(2))
      }
    }
  }, [value, max, onChange])

  let final = parseFloat(value).toFixed(2)
  if (current) {
    final = (parseFloat(value) - current).toFixed(2)
  }

  return (
    <>
      <Flex justify="space-evenly" alignItems="center" mb={4}>
        <Box onClick={decrement} className="decrease-amount" mr={4}>
          <DecreaseIcon
            color={
              Number(value) <= min || Number(value) < step ? "grey400" : "red"
            }
          />
        </Box>
        <input
          className="input-amount"
          ref={inputRef}
          onChange={handleOnChange}
          value={value}
        />
        <Box onClick={increment} className="increase-amount" ml={4}>
          <IncreaseIcon
            color={
              max === 0
                ? "grey400"
                : max && parseFloat(value) >= max
                ? "grey400"
                : "completed"
            }
          />
        </Box>
      </Flex>
      <Center
        fontSize="small"
        color={parseFloat(final) >= 0 ? (current ? "green" : "#2D2D2D") : "red"}
        fontWeight="bold"
      >
        {numberFormatForCurrency(final)} {unit}
      </Center>
    </>
  )
}

export default AmountInput
