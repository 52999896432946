import { Icon, IconProps } from "@chakra-ui/react"

const CreditCardIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 100 100" fill="none" {...props}>
    <g clipPath="url(#clip0_97_35621)">
      <path
        d="M69.5 56.5508V13.5508C69.5 10.6997 68.3674 7.9654 66.3514 5.94938C64.3354 3.93337 61.6011 2.80078 58.75 2.80078H48M48 2.80078H15.75C12.8989 2.80078 10.1646 3.93337 8.1486 5.94938C6.13259 7.9654 5 10.6997 5 13.5508V78.0508C5 80.9019 6.13259 83.6362 8.1486 85.6522C10.1646 87.6682 12.8989 88.8008 15.75 88.8008H53.375M48 2.80078V59.2383M26.5 24.3008V40.4258"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.9991 104.926V66.7633C90.998 61.8214 90.0234 56.9282 88.131 52.363C86.2385 47.7978 83.4652 43.6502 79.9696 40.157L69.4883 29.6758V65.0809C69.4883 69.8647 63.6994 72.2673 60.3132 68.881L53.3526 61.9258C50.6651 59.2383 44.2097 57.6258 39.9043 61.9258C35.6043 66.2258 37.2168 72.6758 39.9043 75.3633L69.4829 104.926"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_97_35621">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default CreditCardIcon
