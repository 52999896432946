import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  PutWalletNicknameData,
  PutWalletNicknameResponse,
} from "./types/putWalletNickname"

const putWalletNickname = async (
  id: number,
  body: PutWalletNicknameData
): Promise<AxiosResponse<PutWalletNicknameResponse>> => {
  const request = await $axios().put("/wallet_nickname/" + id, body)
  return request
}

export default putWalletNickname
