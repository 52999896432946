import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetUserData } from "../../api/types/getUserType";

export interface AuthState {
  user: GetUserData | null;
}

export const initialAuthState: AuthState = {
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setUser: (state, action: PayloadAction<GetUserData | null>) => {
      state.user = action.payload;
    },
    clearState: (state) => {
      state.user = null;
    },
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
