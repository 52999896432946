import { Box, Card, Flex, Heading, Spacer, Stack, Text } from "@chakra-ui/react"
import { ArcElement, Chart as ChartJS } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { numberFormatForCurrency } from "common/utils/transform"

ChartJS.register(ArcElement)

type ListGraphDetail = {
  key: string
  value: number
  color: string
}

export type CardGraphProps = {
  header: string
  value: string
  valueUnit: string
  list: ListGraphDetail[]
  isCurrency?: boolean
}

const CardGraph: React.FC<CardGraphProps> = ({
  header,
  value,
  valueUnit,
  list,
  isCurrency = false,
}) => {
  const data = {
    datasets: [
      {
        data: list.map((m) => m.value),
        backgroundColor: list.map((m) => m.color),
        cutout: "70%",
      },
    ],
  }

  return (
    <Card p={6} h="100%">
      <Flex gap={8} align="center">
        <Box flex={1} position="relative">
          <Doughnut
            data={data}
            style={{ width: "100%", minWidth: "200px", height: "auto" }}
          />
          <Flex
            w="100%"
            height="100%"
            position="absolute"
            top={0}
            direction="column"
            align="center"
            justify="center"
            color="primaryTextColor"
          >
            <Text fontSize="2rem" fontWeight="bold">
              {value}
            </Text>
            <Text fontSize="2xl" fontWeight="bold">
              {valueUnit}
            </Text>
          </Flex>
        </Box>
        <Box flex={1}>
          <Box mb={5}>
            <Heading color="primaryTextColor" size="sm">
              {header}
            </Heading>
          </Box>
          <Stack color="secondaryTextColor" fontSize="sm" fontWeight="bold">
            {list.map((m, i) => (
              <Flex rowGap={4} columnGap={2} key={i} align="center">
                <Box>
                  <Box w={4} h={4} bgColor={m.color} borderRadius={16}></Box>
                </Box>
                <Box>{m.key}</Box>
                <Spacer />
                <Box>
                  {isCurrency ? numberFormatForCurrency(`${m.value}`) : m.value}
                </Box>
              </Flex>
            ))}
          </Stack>
        </Box>
      </Flex>
    </Card>
  )
}

export default CardGraph
