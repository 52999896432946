import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  CreditTransaction,
  CreditGranted,
  CreditSponsor,
  ParticipantCreditItem,
  TotalSupply,
} from "types/Credit"
import { MyWallet } from "types/MyWallet"

export interface CreditState {
  creditTransactions: CreditTransaction[]
  creditGranteds: CreditGranted[]
  creditSponsors: CreditSponsor[]
  creditSponsorBalance: CreditSponsor[]
  allParticipants: ParticipantCreditItem[]
  participants: ParticipantCreditItem[]
  corporateWallet: MyWallet[]
  totalSupply: TotalSupply
}

export const initialCreditState: CreditState = {
  creditTransactions: [],
  creditGranteds: [],
  creditSponsors: [],
  creditSponsorBalance: [],
  allParticipants: [],
  participants: [],
  corporateWallet: [],
  totalSupply: {} as TotalSupply,
}

const creditSlice = createSlice({
  name: "credit",
  initialState: initialCreditState,
  reducers: {
    setCreditTransactions: (
      state,
      action: PayloadAction<CreditTransaction[]>
    ) => {
      state.creditTransactions = action.payload
    },
    setCreditGranteds: (state, action: PayloadAction<CreditGranted[]>) => {
      state.creditGranteds = action.payload
    },
    setCreditSponsors: (state, action: PayloadAction<CreditSponsor[]>) => {
      state.creditSponsors = action.payload
    },
    setCreditSponsorBalance: (state, action: PayloadAction<CreditSponsor[]>) => {
      state.creditSponsorBalance = action.payload
    },
    setAllParticipants: (
      state,
      action: PayloadAction<ParticipantCreditItem[]>
    ) => {
      state.allParticipants = action.payload
    },
    setParticipants: (
      state,
      action: PayloadAction<ParticipantCreditItem[]>
    ) => {
      state.participants = action.payload
    },
    setCorporateWallet: (state, action: PayloadAction<MyWallet[]>) => {
      state.corporateWallet = action.payload
    },
    setTotalSupply: (state, action: PayloadAction<TotalSupply>) => {
      state.totalSupply = action.payload
    },
  },
})

export const creditReducer = creditSlice.reducer
export const creditActions = creditSlice.actions
