// ** React **
import { useState } from "react"

// ** Components **
import {
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react"
import SuccessBadge from "common/components/SuccessBadge"
import {
  ParticipantDetailItem,
  ParticipantUserEditModal,
} from "modules/client_management"

// ** Types **
import { CompanyUser, CompanyDetail } from "types/Participant"

// ** Icons **
import { EditIcon, ArrowDownIcon, ArrowUpIcon } from "assets/icons"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

// ** Utils **
import { capitalizeFirstLetter } from "common/utils/transform"

const ParticipantUserItem = ({
  data,
  participantDetail,
}: {
  data: CompanyUser
  participantDetail: CompanyDetail
}) => {
  const editUserModalAction = useDisclosure()

  const [toggle, setToggle] = useState<boolean>(false)
  const { user, isAdmin } = useAuthHook()

  return (
    <Box border="1px solid" borderRadius={6} borderColor="emptyColor" my={4}>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={4}
        bgColor={toggle ? "emptyColor" : ""}
        p={4}
        px={8}
      >
        <GridItem>
          <Text>Username</Text>
        </GridItem>
        <GridItem>
          <Text>{data.email}</Text>
        </GridItem>
        <GridItem>
          <Flex justify="flex-end">
            <SuccessBadge label={data.is_active ? "active" : "inactive"} />
          </Flex>
        </GridItem>
        <GridItem>
          <Flex justify="flex-end">
            {((user && user.pt_id === participantDetail?.pt_created_id) ||
              isAdmin) && (
              <Box cursor="pointer">
                <EditIcon onClick={editUserModalAction.onOpen} />
              </Box>
            )}
            <Box cursor="pointer" ml={4} onClick={() => setToggle(!toggle)}>
              {toggle ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Box>
          </Flex>
        </GridItem>
      </Grid>
      {toggle && (
        <Box px={8}>
          <ParticipantDetailItem label="First Name" value={capitalizeFirstLetter(data.firstname)} />
          <ParticipantDetailItem label="Last Name" value={capitalizeFirstLetter(data.lastname)} />
          <ParticipantDetailItem label="Phone number" value={data.contact} />
          <ParticipantDetailItem
            label="Activate Status"
            value={data.activated ? "Activated" : "Unactivated"}
          />
          <ParticipantDetailItem label="Role" value="User" />
        </Box>
      )}
      <ParticipantUserEditModal {...editUserModalAction} user={data} />
    </Box>
  )
}

export default ParticipantUserItem
