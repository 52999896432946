// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Grid,
  GridItem,
  Box,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import SuccessBadge from "common/components/SuccessBadge"
import PrimaryButton from "common/components/PrimaryButton"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { AcceptedGuaranteedInvoice } from "types/Invoice"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import { UserRole } from "constants/Enum"

type InvoiceModalProps = {
  data?: AcceptedGuaranteedInvoice
  onClickClose: VoidFunction
} & ModalProps

const InvoiceModalSponsor: FC<InvoiceModalProps> = (props) => {
  const { getRole } = useAuthHook()
  const role = getRole()

  const { isOpen, onClose, data, onClickClose } = props

  if (data === undefined) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Center>
            <Heading size="md">Invoice detail</Heading>
          </Center>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4}>
            <GridItemPopupDetail title="Doctype" value="Invoice" />
            <GridItemPopupDetail title="Doc No." value={data.doc_number} />
            <GridItemPopupDetail
              title="Buyer Juristic Id"
              value={data.buyer_juristic_id}
            />
            <GridItemPopupDetail
              title="Buyer Name"
              value={capitalizeFirstLetter(data.buyer_company_name)}
            />
            <GridItemPopupDetail
              title="Seller Juristic Id"
              value={data.seller_juristic_id}
            />
            <GridItemPopupDetail
              title="Seller Name"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Doc Date"
              value={formatDateDD_MMMM_YYYY(data.doc_date)}
            />
            <GridItemPopupDetail
              title="Amount. (include VAT)"
              value={numberFormatForCurrency(data.amount)}
            />
            <GridItemPopupDetail title="Currency" value={data.currency} />
            <GridItemPopupDetail
              title="Schedule Payment Date"
              value={formatDateDD_MMMM_YYYY(data.payment_date)}
            />
            <GridItemPopupDetail
              title="Payment Guarantee Status"
              value={data.offer_option === 3 ? "Yes" : "No"}
            />
            <GridItemPopupDetail
              title="Owner"
              value={capitalizeFirstLetter(data.offer_company_name)}
            />
            <GridItemPopupDetail
              title="Offering Start Date"
              value={formatDateDD_MMMM_YYYY(data.offer_start_date)}
            />
            <GridItemPopupDetail
              title="Offering end Date"
              value={formatDateDD_MMMM_YYYY(data.offer_end_date)}
            />
            <GridItemPopupDetail
              title="Option"
              value={invoiceOptionIdToString(data.offer_option)}
            />
            <GridItemPopupDetail
              title="Guarantee Fee Rate (Per year)"
              value={
                data.guarantee_fee_rate
                  ? numberFormatForCurrency(data.guarantee_fee_rate) + " %"
                  : "- %"
              }
            />
            {role === UserRole.BUYER && (
              <>
                <GridItemPopupDetail
                  title="Discounting rate (per amount)"
                  value={
                    data.offer_interest_rate
                      ? numberFormatForCurrency(data.offer_interest_rate) + " %"
                      : "- %"
                  }
                />
                <GridItemPopupDetail
                  title="Total Discount (NEW)"
                  value={
                    data.offer_discount
                      ? numberFormatForCurrency(data.offer_discount)
                      : "-"
                  }
                />
              </>
            )}
            <GridItemPopupDetail
              title="Timestamp"
              value={formatDateDD_MMMM_YYYY(data.updated_at)}
            />
            <GridItemPopupDetail
              title="Overdue"
              value={data.overdue_day + " days"}
            />
          </Grid>
          <Grid templateColumns="repeat(1, 1fr)" gap={2} mt={4}>
            <GridItem
              w="100%"
              display="inline-flex"
              borderBottom="1px solid #E1F1FF"
              p={3}
            >
              <Box>
                <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                  Status:
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" fontWeight={700} ml={1}>
                  <SuccessBadge label={data.status} />
                </Text>
              </Box>
            </GridItem>
            {(role === UserRole.SPONSOR || role === UserRole.TCG) && (
              <GridItemPopupDetail
                title="Financial Amount (NEW)"
                value={numberFormatForCurrency(data.amount)}
              />
            )}
            {role === UserRole.BUYER ? (
              <GridItemPopupDetail
                title="Revenue"
                value={
                  numberFormatForCurrency(
                    `${
                      parseFloat(data.amount) - parseFloat(data.offer_discount)
                    }`
                  ) + " NEW"
                }
              />
            ) : (
              <GridItemPopupDetail
                title="Revenue"
                value={
                  numberFormatForCurrency(data.guarantee_flat_fee) + " NEW"
                }
              />
            )}
          </Grid>
          <Box flex={1} mt={4}>
            <PrimaryButton variant="outline" onClick={onClickClose}>
              Close
            </PrimaryButton>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InvoiceModalSponsor
