// ** React **
import { FC, useState } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Grid,
  GridItem,
  Box,
  Text,
  Center,
  Heading,
  Flex,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import SuccessBadge from "common/components/SuccessBadge"
import PrimaryButton from "common/components/PrimaryButton"
import ConfirmPassword from "common/components/ConfirmPassword"

// ** Types **
import { PostOfferRepayBody } from "api/types/postOfferRepayType"
import { ModalProps } from "types/ModalProps"
import { ArItem } from "types/Ar"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useArHook from "hooks/useArHook"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  invoiceOptionIdToString,
  formatDateTimeStamp,
  abbreviateHexString,
  exportToCSV,
  downloadCSV,
  capitalizeFirstLetter,
} from "common/utils/transform"

type PaymentDetailModalProps = {
  data?: ArItem
  onClickClose: VoidFunction
} & ModalProps

enum PaymentDetailModalBody {
  DETAIL,
  MAKE_PAYMENT,
  CONFIRM,
  CONFIRM_PASSWORD,
  SUCCESS,
}

const PaymentDetailModalForSellerCurrent: FC<PaymentDetailModalProps> = (
  props
) => {
  const { isOpen, onClose, data, onClickClose } = props
  const [modalBody, setModalBody] = useState<PaymentDetailModalBody>(
    PaymentDetailModalBody.DETAIL
  )

  const { postOfferRepayHook, getArCurrentHook } = useArHook()
  const { setAlert } = useAppHook()

  if (data === undefined) return <></>

  const clearState = () => {
    setModalBody(PaymentDetailModalBody.DETAIL)
  }

  const handleOnSelectClose = () => {
    clearState()
    onClose()
    onClickClose()
  }

  const handleMakePayment = async (password: string) => {
    let body: PostOfferRepayBody = {
      id: data.id,
      password,
    }
    const response = await postOfferRepayHook(body)
    let alert: Alert = {
      visible: true,
    }
    if (response?.statusCode === 200) {
      onClose()
      clearState()
      getArCurrentHook(data.offer_option)
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Payment has been confirmed!\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Payment was failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  const handleExport = () => {
    const content = exportToCSV([data], [])
    downloadCSV(content, `inoivce_${data.id}.csv`)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalBody === PaymentDetailModalBody.DETAIL ? "3xl" : "md"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          {modalBody === PaymentDetailModalBody.DETAIL && (
            <>
              <Center>
                <Heading size="md">Payment detail</Heading>
              </Center>
              <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4} mb={6}>
                <GridItemPopupDetail title="Doctype" value="Invoice" />
                <GridItemPopupDetail title="Doc No." value={data.doc_number} />
                <GridItemPopupDetail
                  title="Buyer Juristic Id"
                  value={data.buyer_juristic_id}
                />
                <GridItemPopupDetail
                  title="Buyer Name"
                  value={capitalizeFirstLetter(data.buyer_company_name)}
                />
                <GridItemPopupDetail
                  title="Seller Juristic Id"
                  value={data.seller_juristic_id}
                />
                <GridItemPopupDetail
                  title="Seller Name"
                  value={capitalizeFirstLetter(data.seller_company_name)}
                />
                <GridItemPopupDetail
                  title="Doc Date"
                  value={formatDateDD_MMMM_YYYY(data.doc_date)}
                />
                <GridItemPopupDetail
                  title="Amount. (include VAT)"
                  value={numberFormatForCurrency(data.amount)}
                />
                <GridItemPopupDetail title="Currency" value="THB" />
                <GridItemPopupDetail
                  title="Schedule Payment Date"
                  value={formatDateDD_MMMM_YYYY(data.payment_date)}
                />
                <GridItemPopupDetail
                  title="Payment Guarantee Status"
                  value={data.offer_option === 3 ? "Yes" : "No"}
                />
                <GridItemPopupDetail
                  title="Owner Name"
                  value={capitalizeFirstLetter(data.seller_company_name)}
                />
                <GridItemPopupDetail
                  title="Holder Name"
                  value={capitalizeFirstLetter(data.holder_company_name)}
                />
                <GridItemPopupDetail
                  title="Holder Wallet"
                  value={abbreviateHexString(data.holder_wallet_address)}
                />
                <GridItemPopupDetail
                  title="Offering Start Date"
                  value={formatDateDD_MMMM_YYYY(data.offer_start_date)}
                />
                <GridItemPopupDetail
                  title="Offering end Date"
                  value={formatDateDD_MMMM_YYYY(data.offer_end_date)}
                />
                <GridItemPopupDetail
                  title="Option"
                  value={invoiceOptionIdToString(data.offer_option)}
                />
                <GridItemPopupDetail
                  title="Total Discount (NEW)"
                  value={
                    data.offer_option === 3
                      ? numberFormatForCurrency(
                          `${
                            parseFloat(data.offer_discount) +
                            parseFloat(data.commitment_fee) +
                            parseFloat(data.guarantee_fee) +
                            parseFloat(data.guarantee_fee_extra)
                          }`
                        )
                      : data.offer_option !== 3
                      ? numberFormatForCurrency(
                          `${parseFloat(data.offer_discount)}`
                        )
                      : "0.00"
                  }
                />
                <GridItem
                  w="100%"
                  display="inline-flex"
                  borderBottom="1px solid #E1F1FF"
                  p={3}
                  justifyContent="space-between"
                >
                  <Box>
                    <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                      Status:
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="sm" fontWeight={700} ml={1}>
                      <SuccessBadge label={data.status} />
                    </Text>
                  </Box>
                </GridItem>
                <GridItemPopupDetail
                  title="Overdue"
                  value={data.overdue_day ? data.overdue_day + " days" : "- days"}
                />
                <GridItemPopupDetail
                  title="Timestamp"
                  value={formatDateTimeStamp(data.updated_at)}
                />
                <GridItemPopupDetail
                  title="Offer Name"
                  value={capitalizeFirstLetter(data.offer_company_name)}
                />
                <GridItemPopupDetail
                  title="Offer Wallet"
                  value={abbreviateHexString(data.offer_wallet_address)}
                />
                <GridItemPopupDetail
                  title="Finance Date"
                  value={formatDateDD_MMMM_YYYY(data.updated_at)}
                />
                <GridItemPopupDetail
                  title="Financial Amount (NEW)"
                  value={
                    data.offer_option === 3
                      ? numberFormatForCurrency(
                          (
                            parseFloat(data.amount) +
                            parseFloat(data.offer_discount)
                          ).toFixed(2)
                        )
                      : numberFormatForCurrency(
                          parseFloat(data.amount).toFixed(2)
                        )
                  }
                />
              </Grid>
              <Box
                flex={1}
                mt={4}
                w={
                  data.pay_status !== "success" && data.offer_option !== 2
                    ? "80%"
                    : "50%"
                }
                m="auto"
                mb={6}
              >
                <Flex justifyContent="space-between" gap={4}>
                  <PrimaryButton
                    variant="outline"
                    onClick={handleOnSelectClose}
                  >
                    Close
                  </PrimaryButton>
                  {data.pay_status !== "success" && data.offer_option !== 2 && (
                    <PrimaryButton
                      onClick={() =>
                        setModalBody(PaymentDetailModalBody.MAKE_PAYMENT)
                      }
                      bgColor="primary"
                      colorScheme="white"
                    >
                      Payment
                    </PrimaryButton>
                  )}
                </Flex>
              </Box>
              <Box flex={1} mt={4} w="50%" m="auto">
                <PrimaryButton
                  onClick={handleExport}
                  bgColor="primary"
                  colorScheme="white"
                >
                  Export to CSV
                </PrimaryButton>
              </Box>
            </>
          )}
          {modalBody === PaymentDetailModalBody.MAKE_PAYMENT && (
            <>
              <Center>
                <Heading size="md">Make a Payment</Heading>
              </Center>
              <Grid templateColumns="repeat(1, 1fr)" gap={2} mt={4} mb={8}>
                <GridItemPopupDetail
                  title="Amount (NEW)"
                  value={numberFormatForCurrency(data.amount)}
                />
                <GridItemPopupDetail
                  title="Discount (NEW)"
                  value={
                    data.offer_option === 3
                      ? numberFormatForCurrency(data.offer_discount)
                      : "0.00"
                  }
                />
                <GridItemPopupDetail
                  title="Total (NEW)"
                  value={
                    data.offer_option === 3
                      ? numberFormatForCurrency(
                          (
                            parseFloat(data.amount) +
                            parseFloat(data.offer_discount)
                          ).toFixed(2)
                        )
                      : numberFormatForCurrency(data.amount)
                  }
                />
              </Grid>
              <Text textAlign="center" mb={8}>
                Are you sure to a payment for this invoice?
              </Text>
              <PrimaryButton
                onClick={() => setModalBody(PaymentDetailModalBody.CONFIRM)}
                bgColor="green"
                colorScheme="white"
                mb={4}
              >
                Confirm Payment
              </PrimaryButton>
              <PrimaryButton
                variant="outline"
                onClick={() => setModalBody(PaymentDetailModalBody.DETAIL)}
                border="1px solid #00249c"
                colorScheme="primary"
              >
                Remind Later
              </PrimaryButton>
            </>
          )}
          {modalBody === PaymentDetailModalBody.CONFIRM && (
            <>
              <Center mb={6}>
                <Heading size="md">Confirm Payment</Heading>
              </Center>
              <Text textAlign="center" mb={6}>
                Are you sure to confirm this payment?
              </Text>
              <Box flex={1} mt={4}>
                <Flex justifyContent="space-between" gap={4}>
                  <PrimaryButton
                    variant="outline"
                    border="1px solid #00249c"
                    colorScheme="primary"
                    onClick={() =>
                      setModalBody(PaymentDetailModalBody.MAKE_PAYMENT)
                    }
                  >
                    Back
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={() =>
                      setModalBody(PaymentDetailModalBody.CONFIRM_PASSWORD)
                    }
                    bgColor="green"
                    colorScheme="white"
                  >
                    Confirm
                  </PrimaryButton>
                </Flex>
              </Box>
            </>
          )}
          {modalBody === PaymentDetailModalBody.CONFIRM_PASSWORD && (
            <ConfirmPassword
              header="Confirm"
              onClickBack={() => setModalBody(PaymentDetailModalBody.CONFIRM)}
              onClickConfirm={(password) => handleMakePayment(password)}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PaymentDetailModalForSellerCurrent
