import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  PostAdjustmentCreditTokenBody,
  PostAdjustmentCreditTokenResponse,
} from "./types/postAdjustmentCreditTokenType"

const postAdjustmentCreditToken = async (
  body: PostAdjustmentCreditTokenBody
): Promise<AxiosResponse<PostAdjustmentCreditTokenResponse>> => {
  const request = await $axios().post("/credit/adjust", body)
  return request
}

export default postAdjustmentCreditToken
