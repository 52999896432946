import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAppHook from "../../hooks/useAppHook";
import { AlertType } from "../../types/Alert";
import PrimaryButton from "./PrimaryButton";

const AppAlert = () => {
  const { alert, setAlert } = useAppHook();
  const navigate = useNavigate();
  const onClose = () => {
    setAlert({ visible: false });
  };

  const onActionClick = () => {
    const action = alert.data?.button.action;
    onClose();
    if (action) {
      if (typeof action === "function") {
        action();
      } else {
        navigate(action);
      }
    }
  };

  return (
    <Modal isOpen={alert.visible} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={10}>
          <Flex direction="column" gap={12}>
            <Center>
              {alert.data?.type === AlertType.SUCCESS && (
                <CheckCircleIcon color="#51BC68" boxSize={24} />
              )}
              {alert.data?.type === AlertType.ERROR && (
                <WarningIcon color="#E11B22" boxSize={24} />
              )}
            </Center>
            <Box>
              <Text
                textAlign="center"
                whiteSpace="pre-line"
                color="#353535"
                fontWeight={500}
                fontSize="small"
              >
                {alert.data?.description!}
              </Text>
            </Box>
            <Center>
              <PrimaryButton
                size="md"
                variant="outline"
                onClick={onActionClick}
                w={"230px"}
              >
                {alert.data?.button.label}
              </PrimaryButton>
            </Center>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AppAlert;
