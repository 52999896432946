// ** Libs **
import { Box, Text, Flex, Image } from "@chakra-ui/react"

// ** Icons **
import { CreditCardEditIcon } from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

const FIDashboard = ({ setTabIndex }: { setTabIndex: any }) => {
  const { getFIDashboardHook, fiDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initFIDashboard",
    queryFn: getFIDashboardHook,
  })

  if (isLoading) return <></>

  const { total_loan_option1, total_loan_option3 } = fiDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={4}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(2)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total amount of loan (Financing)</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option1.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/receive-amount.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (THB)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option1.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(2)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total amount of loan (Guaranteee Discounting)</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option3.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/coins-guarantee.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (New)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option3.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(2)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Default</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${fiDashboard.default.num}`)}
            </Text>
          </Box>
          <Box w="83px" m={4}>
            <CreditCardEditIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${fiDashboard.default.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default FIDashboard
