// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Text, Flex, Image } from "@chakra-ui/react"

// ** Icons **
import {
  ListIcon,
  CoinBalanceIcon,
  WaittingAuctionIcon,
  OfferIcon,
  CalendarIcon,
  CoinExchangeIcon,
} from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

// ** Router **
import {
  AR_MANAGEMENT_PAGE_PATH,
  MY_WALLET_PAGE_PATH,
  LISTING_AND_OFFERING_PAGE_PATH,
  DISCOUNTING_PAGE_PATH,
  MY_INVOICE_PAGE_PATH,
} from "router/AppPath"

const SellerDashboard = () => {
  const navigate = useNavigate()
  const { getSellerDashboardHook, sellerDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initSellerDashboard",
    queryFn: getSellerDashboardHook,
  })

  if (isLoading) return <></>

  const {
    invoice_itmx,
    invoice_listed,
    balance,
    invoice_wait,
    invoice_offer,
    invoice_due,
    invoice_discount,
  } = sellerDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={4}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(MY_INVOICE_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total Account Receivable on</Text>
          <Text>PromptBiz</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormat(`${invoice_itmx?.num}`)}
            </Text>
          </Box>
          <Box w="70px" m={4}>
            <ListIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (THB)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${invoice_itmx?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(LISTING_AND_OFFERING_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total Invoice Listed</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormat(`${invoice_listed?.num}`)}
            </Text>
          </Box>
          <Box w="90px" m={4}>
            <Image src="/assets/image/list-nft.png" ml={2} />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${invoice_listed?.amount.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={8}
        px={8}
        cursor="pointer"
        onClick={() => navigate(MY_WALLET_PAGE_PATH)}
      >
        <Flex justify="space-between">
          <Box w="400px"></Box>
          <Box w="90px" m={4}>
            <CoinBalanceIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Stable Coin Balance (NEW)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${balance?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex gap={4}>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() => navigate(LISTING_AND_OFFERING_PAGE_PATH)}
        >
          <Box fontSize={18} fontWeight="bold" mb={4}>
            <Text>Waiting List</Text>
          </Box>
          <Flex justify="space-between">
            <Box w="100px" m={2}>
              <WaittingAuctionIcon w="100%" h="100%" />
            </Box>
            <Box textAlign="right" w="200px">
              <Text fontSize={24}>Total Invoice</Text>
              <Text fontSize={30} fontWeight="bold">
                {numberFormat(`${invoice_wait?.num}`)}
              </Text>
            </Box>
            <Box textAlign="right" w="200px">
              <Text fontSize={24}>Total Value (NEW)</Text>
              <Text fontSize={30} fontWeight="bold">
                {numberFormatForCurrency(`${invoice_wait?.amount?.toFixed(2)}`)}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() => navigate(LISTING_AND_OFFERING_PAGE_PATH)}
        >
          <Box fontSize={18} fontWeight="bold" mb={4}>
            <Text>Offer</Text>
          </Box>
          <Flex justify="space-between">
            <Box w="100px" m={2}>
              <OfferIcon w="100%" h="100%" />
            </Box>
            <Box textAlign="right" w="200px">
              <Text fontSize={24}>Total Invoice</Text>
              <Text fontSize={30} fontWeight="bold">
                {numberFormat(`${invoice_offer?.num}`)}
              </Text>
            </Box>
            <Box textAlign="right" w="200px">
              <Text fontSize={24}>Total Offers</Text>
              <Text fontSize={30} fontWeight="bold">
                {numberFormat(`${invoice_offer?.activity}`)}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AR_MANAGEMENT_PAGE_PATH,
            search: "?tab=2",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Due this Month</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormat(`${invoice_due?.num}`)}
            </Text>
          </Box>
          <Box w="90px" m={4}>
            <CalendarIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={30} fontWeight="bold">
              {numberFormatForCurrency(`${invoice_due?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box borderRadius={16} bgColor="blue" color="white" p={4} px={8}>
        <Box
          textAlign="center"
          fontSize={18}
          fontWeight="bold"
          mb={4}
          cursor="pointer"
          onClick={() => navigate(DISCOUNTING_PAGE_PATH)}
        >
          <Flex gap={2} alignItems="center" justifyContent="center">
            <Box w="36px" m={4}>
              <CoinExchangeIcon w="100%" h="100%" />
            </Box>
            <Text fontSize={18}>Discounting</Text>
          </Flex>
        </Box>
        <Flex gap={4}>
          <Box
            borderRadius={16}
            bgColor="#054BB5"
            color="white"
            p={4}
            px={8}
            w="33%"
            cursor="pointer"
            onClick={() =>
              navigate({
                pathname: DISCOUNTING_PAGE_PATH,
                search: "?tab=1",
              })
            }
          >
            <Box textAlign="center" fontSize={24} fontWeight="bold" mb={4}>
              <Text>Financing</Text>
            </Box>
            <Flex justify="space-between">
              <Box w="50%">
                <Text fontSize={24}>Total Invoice</Text>
                <Text fontSize={30} fontWeight="bold">
                  {numberFormat(`${invoice_discount["1"]?.num}`)}
                </Text>
              </Box>
              <Box textAlign="right" w="50%">
                <Text fontSize={24}>Total Amount (NEW)</Text>
                <Text fontSize={30} fontWeight="bold">
                  {numberFormatForCurrency(
                    `${invoice_discount["1"]?.amount_after_discount?.toFixed(2)}`
                  )}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius={16}
            bgColor="#054BB5"
            color="white"
            p={4}
            px={8}
            w="33%"
            cursor="pointer"
            onClick={() =>
              navigate({
                pathname: DISCOUNTING_PAGE_PATH,
                search: "?tab=2",
              })
            }
          >
            <Box textAlign="center" fontSize={24} fontWeight="bold" mb={4}>
              <Text>Buyer Prepayment</Text>
            </Box>
            <Flex justify="space-between">
              <Box w="50%">
                <Text fontSize={24}>Total Invoice</Text>
                <Text fontSize={30} fontWeight="bold">
                  {numberFormat(`${invoice_discount["2"]?.num}`)}
                </Text>
              </Box>
              <Box textAlign="right" w="50%">
                <Text fontSize={24}>Total Amount (NEW)</Text>
                <Text fontSize={30} fontWeight="bold">
                  {numberFormatForCurrency(
                    `${invoice_discount["2"]?.amount_after_discount?.toFixed(2)}`
                  )}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius={16}
            bgColor="#054BB5"
            color="white"
            p={4}
            px={8}
            w="33%"
            cursor="pointer"
            onClick={() =>
              navigate({
                pathname: DISCOUNTING_PAGE_PATH,
                search: "?tab=3",
              })
            }
          >
            <Box textAlign="center" fontSize={24} fontWeight="bold" mb={4}>
              <Text>Guaranteed Discounting</Text>
            </Box>
            <Flex justify="space-between">
              <Box w="50%">
                <Text fontSize={24}>Total Invoice</Text>
                <Text fontSize={30} fontWeight="bold">
                  {numberFormat(`${invoice_discount["3"]?.num}`)}
                </Text>
              </Box>
              <Box textAlign="right" w="50%">
                <Text fontSize={24}>Total Amount (NEW)</Text>
                <Text fontSize={30} fontWeight="bold">
                  {numberFormatForCurrency(
                    `${invoice_discount["3"]?.amount_after_discount?.toFixed(2)}`
                  )}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default SellerDashboard
