// ** Libs **
import {
  Grid,
  GridItem,
  HStack,
  Heading,
  Text,
  Checkbox,
  Box,
} from "@chakra-ui/react"
import reactToText from "react-to-text"

// ** Icons **
import {
  SortItemIcon,
  SortItemDescIcon,
  SortNumberIcon,
  SortNumberDescIcon,
  SortStringIcon,
  SortStringDescIcon,
} from "../Icon"

// ** Utils **
import { removeObjectFromArray } from "common/utils/transform"

export type TableColumn = {
  title: string
  value: string
  customSuffixRender?: () => JSX.Element
  w?: string
  sortType?: TableSortType
  headerCenter?: boolean
  valueCenter?: boolean
  customRender?: (key: string, value: any) => JSX.Element
}

export enum TableSortType {
  STRING,
  INTEGER,
  ITEM,
}

export type ListDataTableProps = {
  column: Array<TableColumn>
  data: Array<object>
  hideIndex?: boolean
  currentPage: number
  pageSize: number
  selectedData: any[]
  setSelectedData: (data: any[]) => void
  sortBy: string
  setSortBy: (value: string) => void
  sortOrder: string
  setSortOrder: (value: string) => void
}

const ListDataTable = (props: ListDataTableProps) => {
  const MIN_WIDTH_COL = "150px"
  const {
    column,
    data,
    hideIndex,
    currentPage,
    pageSize,
    selectedData,
    setSelectedData,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
  } = props
  const buildColumn = () => {
    if (hideIndex) {
      return column
    }
    return [{ title: "#", value: "index" }, ...column]
  }

  const buildSortComponent = (
    column: string,
    sort: TableSortType | undefined
  ) => {
    let isSortColumn = sortBy === column
    let direction = isSortColumn && sortOrder === "desc" ? false : true

    switch (sort) {
      case undefined:
        return <></>
      case TableSortType.INTEGER:
        return direction ? <SortNumberIcon /> : <SortNumberDescIcon />
      case TableSortType.STRING:
        return direction ? <SortStringIcon /> : <SortStringDescIcon />
      case TableSortType.ITEM:
        return direction ? <SortItemIcon /> : <SortItemDescIcon />
    }
  }

  const handleClickSort = (column: string) => {
    if (sortBy === column) {
      if (sortOrder === "asc") {
        setSortOrder("desc")
      } else if (sortOrder === "desc") {
        setSortBy("")
        setSortOrder("")
      } else {
        setSortOrder("asc")
      }
    } else {
      setSortBy(column)
      setSortOrder("asc")
    }
  }

  const buildGridTemplateColumns = () => {
    let gridTemplateColumns = column.reduce(
      (prev, cur) => `${prev} minmax(${cur.w || MIN_WIDTH_COL}, 1fr)`,
      hideIndex ? "" : "60px"
    )
    return gridTemplateColumns
  }

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const _data = dataRenderToText(data[index])
    if (e.target.checked) {
      const temp = [...selectedData, _data]
      setSelectedData(temp)
    } else {
      const temp = [...selectedData]
      setSelectedData(removeObjectFromArray(temp, _data))
    }
  }

  const dataRenderToText = (data: any) => {
    let result: any = {}

    buildColumn().forEach((col, colIndex) => {
      if (colIndex > 0) {
        if (result[col.title] === undefined) {
          result[col.title] = null
        }

        if (col.customRender) {
          result[col.title] = reactToText(col.customRender(`${colIndex}`, data))
        } else {
          result[col.title] = data[col.value]
        }
      }
    })

    return result
  }

  return (
    <>
      <Grid
        borderTopRadius={8}
        gridTemplateColumns={buildGridTemplateColumns()}
        alignItems="center"
        overflowX="scroll"
      >
        {buildColumn().map((col, index) => (
          <GridItem
            key={`col_header_${index}`}
            p={4}
            bgColor="emptyColor"
            h={"100%"}
          >
            <HStack gap={1} justify={col.headerCenter ? "center" : undefined}>
              <Heading
                size="xs"
                color={col.value === sortBy ? "#0050C8" : "#1A202C"}
                fontWeight={col.value === sortBy ? "bold" : "normal"}
                textDecoration={col.value === sortBy ? "underline" : "none"}
                textDecorationColor={
                  col.value === sortBy ? "#0050C8" : undefined
                }
                textUnderlineOffset={col.value === sortBy ? "2px" : undefined}
              >
                {col.title}
              </Heading>
              {col.customSuffixRender && col.customSuffixRender()}
              {col.sortType !== undefined && (
                <Box
                  cursor="pointer"
                  onClick={() => handleClickSort(col.value)}
                >
                  {buildSortComponent(col.value, col.sortType)}
                </Box>
              )}
            </HStack>
          </GridItem>
        ))}
        {data.map((row, rowIndex) => {
          return buildColumn().map((col, colIndex) => {
            let node

            type rowKeyType = keyof typeof row
            const key = col.value as rowKeyType
            const value =
              col.title === "#"
                ? currentPage === 1
                  ? `${rowIndex + 1}.`
                  : `${rowIndex + 1 + (currentPage - 1) * pageSize}.`
                : row[key]

            if (col.title === "#") {
              const index = (currentPage - 1) * pageSize + rowIndex
              node = (
                <Checkbox onChange={(e) => handleCheckboxChange(e, index)} />
              )
            } else if (col.customRender) {
              node = col.customRender(`${rowIndex}_${key}`, row)
            } else {
              node = <Text fontSize="sm">{value}</Text>
            }

            return (
              <GridItem
                justifySelf={col.valueCenter ? "center" : undefined}
                key={`column_${rowIndex}_${colIndex}`}
                p={4}
              >
                {node}
              </GridItem>
            )
          })
        })}
      </Grid>
      {data.length === 0 && (
        <Text textAlign="center" fontWeight="bold" fontSize="md">
          Not found record.
        </Text>
      )}
    </>
  )
}

export default ListDataTable
