// ** React **
import { FC, useState } from "react"

// ** Libs **
import { Flex, Box } from "@chakra-ui/react"

// ** Components **
import { HistoryBadge } from "common/components/Icon"
import { TransactionList, TransactionInvoiceList } from "modules/user_wallet_management"

// ** Types **
import { MyWallet } from "types/MyWallet"

type TransactionListProps = {
  wallet: MyWallet
}
const TransactionContainer: FC<TransactionListProps> = ({ wallet }) => {
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box>
      <Flex gap={6}>
        <Box onClick={() => setTabIndex(0)} cursor="pointer">
          <HistoryBadge
            label="Historical Wallet Transaction"
            color={tabIndex === 0 ? "primary" : "grey400"}
          />
        </Box>
        <Box onClick={() => setTabIndex(1)} cursor="pointer">
          <HistoryBadge
            label="Historical Against Invoice Transaction"
            color={tabIndex === 1 ? "primary" : "grey400"}
          />
        </Box>
      </Flex>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <TransactionList wallet={wallet} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <TransactionInvoiceList wallet={wallet} />
      </Box>
    </Box>
  )
}

export default TransactionContainer
