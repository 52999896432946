import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { FC, useState } from "react"
import { PostMakeOfferBody } from "../../../api/types/postMakeOfferType"
import useAppHook from "../../../hooks/useAppHook"
import useInvoiceHook from "../../../hooks/useInvoiceHook"
import { Alert, AlertType } from "../../../types/Alert"
import { InvoiceListed } from "../../../types/Invoice"
import { ModalProps } from "../../../types/ModalProps"
import { MyWallet } from "../../../types/MyWallet"
import ConfirmPassword from "../ConfirmPassword"
import MakeBiddingInput from "./MakeBiddingInput"
import { CreditUsage } from "types/Credit"
import { invoiceOptionIdToString } from "common/utils/transform"

enum MakeBiddingModalBody {
  SELECT,
  CONFIRM,
}

type MakeBiddingModalContainerProps = {
  invoice?: InvoiceListed
  selectedWallet: MyWallet
  onHiddenInvoice: (id: number) => void
  onUnhiddenInvoice: (id: number) => void
} & ModalProps

export type MakeBiddingInputValue = {
  key: number
  value: number | null
}

const MakeBiddingModalContainer: FC<MakeBiddingModalContainerProps> = (
  props
) => {
  const {
    isOpen,
    onClose,
    invoice,
    selectedWallet,
    onHiddenInvoice,
    onUnhiddenInvoice,
  } = props
  const [makeBiddingValue, setMakeBiddingValue] = useState<
    MakeBiddingInputValue[]
  >([
    { key: 1, value: null },
    { key: 2, value: null },
    { key: 3, value: null },
  ])
  const [creditUsage, setCreditUsage] = useState<CreditUsage[]>([])
  const [modalBody, setModalBody] = useState<MakeBiddingModalBody>(
    MakeBiddingModalBody.SELECT
  )

  const { postMakeOfferHook } = useInvoiceHook()
  const { setAlert } = useAppHook()

  const clearState = () => {
    setMakeBiddingValue([
      { key: 1, value: null },
      { key: 2, value: null },
      { key: 3, value: null },
    ])
    setModalBody(MakeBiddingModalBody.SELECT)
  }

  const handleOnSelectClose = () => {
    clearState()
    onClose()
  }

  const handleOnSelectConfirm = (
    val: MakeBiddingInputValue[],
    creditUsage: CreditUsage[]
  ) => {
    setMakeBiddingValue(val)
    setModalBody(MakeBiddingModalBody.CONFIRM)
    setCreditUsage(creditUsage)
  }

  const handlePasswordBack = () => {
    setModalBody(MakeBiddingModalBody.SELECT)
  }

  const handleOnPasswordConfirm = async (pwd: string) => {
    let isSuccess = true
    let errorMessage = ""

    for (const item of makeBiddingValue) {
      if (item.value !== null) {
        let body: PostMakeOfferBody = {
          id: invoice!.id,
          interest_rate: item.value !== null ? item.value : 0,
          option: item.key,
          wallet_id: selectedWallet.id,
          password: pwd,
          credits: creditUsage,
        }

        if (body.option !== 3) {
          delete body.credits
        }

        try {
          const response: any = await postMakeOfferHook(body)
          if (response !== 200) {
            isSuccess = false
            errorMessage =
              "Option " +
              invoiceOptionIdToString(item.key) +
              " was failed : " +
              response?.response?.data?.message
            break
          }
        } catch (error: any) {
          isSuccess = false
          errorMessage = error
          break
        }
      }
    }

    let alert: Alert = { visible: true }

    if (isSuccess) {
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your offering was completed successfully.\nPlease wait about 5-20 minutes",
        button: { label: "Close" },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: errorMessage,
        button: { label: "Close" },
      }
    }

    onClose()
    clearState()
    setAlert(alert)
  }

  if (!invoice) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalBody === MakeBiddingModalBody.SELECT ? "3xl" : "lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          {modalBody === MakeBiddingModalBody.SELECT && (
            <MakeBiddingInput
              invoice={invoice}
              makeBiddingValue={makeBiddingValue}
              onClickClose={handleOnSelectClose}
              onClickConfirm={handleOnSelectConfirm}
              onHiddenInvoice={onHiddenInvoice}
              onUnhiddenInvoice={onUnhiddenInvoice}
              selectedWallet={selectedWallet}
            />
          )}
          {modalBody === MakeBiddingModalBody.CONFIRM && (
            <ConfirmPassword
              header="Confirm"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MakeBiddingModalContainer
