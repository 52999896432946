import { Heading, Box, Text } from "@chakra-ui/react"
import _, { debounce } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TableColumn } from "../../../common/components/Table/BoxDataTable"
import ContainerTable, {
  TableStyle,
} from "../../../common/components/Table/ContainerTable"
import {
  buildInvoiceListOption,
  combinePathWithId,
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "../../../common/utils/transform"
import useInvoiceHook from "../../../hooks/useInvoiceHook"
import { useQuery } from "../../../hooks/useQueryHook"
import { INVOICE_OFFERING_BY_ID_PAGE_PATH } from "../../../router/AppPath"
import { InvoiceListed } from "../../../types/Invoice"

const OfferingTab = () => {
  const navigate = useNavigate()
  const { getOwnListedInvoicesHook, listedInvoices } = useInvoiceHook()
  const { isLoading } = useQuery({ queryFn: getOwnListedInvoicesHook })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<InvoiceListed[]>([])

  const column: TableColumn[] = [
    {
      title: "heros",
      value: "",
    },
    {
      title: "DocNo",
      value: "doc_number",
    },
    {
      title: "DocType",
      value: "doc_number",
      customRender: (key) => {
        return (
          <Heading key={key} size="xs" color="#353535">
            Invoice
          </Heading>
        )
      },
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      customRender: (key, value) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.seller_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Buyer Name",
      value: "buyer_company_name",
      customRender: (key, value) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.buyer_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Payment Date",
      value: "payment_date",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="#353535">
            {formatDateDD_MMMM_YYYY(value.payment_date)}
          </Heading>
        )
      },
    },
    {
      title: "Offering end Date",
      value: "payment_date",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="#353535">
            {parseFloat(value.num_offering) > 0
              ? value.offer_end_date
                ? formatDateDD_MMMM_YYYY(value.offer_end_date)
                : formatDateDD_MMMM_YYYY(value.payment_date)
              : "-"}
          </Heading>
        )
      },
    },
    {
      title: "Option",
      value: "options",
      customRender(key, value) {
        return (
          <Heading
            key={key}
            size="xs"
            color="#353535"
            style={{ whiteSpace: "pre-line" }}
            h={45}
          >
            {buildInvoiceListOption(value.options)}
          </Heading>
        )
      },
    },
    {
      title: "Number of offering",
      value: "num_offering",
      isFooter: true,
      customRender(key, value) {
        return (
          <Heading
            key={key}
            size="md"
            color="primary"
            style={{ whiteSpace: "pre-line" }}
          >
            {value.num_offering} Total Bid
          </Heading>
        )
      },
    },
    {
      title: "Amount",
      value: "amount",
      isFooter: true,
      customRender(key, value) {
        return (
          <Box textAlign="right">
            <Text fontSize="sm">Amount</Text>
            <Heading key={key} size="md" color="primary">
              {numberFormatForCurrency(value.amount)} NEW
            </Heading>
          </Box>
        )
      },
    },
  ]

  const onRowClick = (data: InvoiceListed) => {
    navigate(
      combinePathWithId(INVOICE_OFFERING_BY_ID_PAGE_PATH, String(data.id))
    )
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    if (listedInvoices.length > 0) {
      let temp: InvoiceListed[] = [...listedInvoices]
      temp = temp.sort(
        (a, b) =>
          new Date(a.payment_date).getTime() -
          new Date(b.payment_date).getTime()
      )
      setData(temp)
    }
  }, [listedInvoices])

  useEffect(() => {
    const filterValue = listedInvoices
      .filter((m) =>
        (m.buyer_company_name + m.doc_number)
          .toLowerCase()
          .includes(fillValue.toLowerCase())
      )
      .sort(
        (a, b) =>
          new Date(a.payment_date).getTime() -
          new Date(b.payment_date).getTime()
      )
    setData(filterValue)
  }, [fillValue, listedInvoices])

  if (isLoading) return <></>

  return (
    <ContainerTable
      column={column}
      data={displayData}
      rawData={data}
      currentPage={currentPage}
      pageSize={pageSize}
      onViewSizeChange={(size) => {
        setPageSize(size)
        setCurrentPage(1)
      }}
      onPageChange={(page: number) => setCurrentPage(page)}
      totalCount={data.length}
      style={TableStyle.BOX}
      onRowClick={onRowClick}
      searchPlaceholder="Debtor Name / Invoice id"
      onSearchChange={onSearchChange}
    />
  )
}

export default OfferingTab
