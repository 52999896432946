// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Card, Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { RevenueByInvoice } from "modules/revenue"
import {
  Dashboard,
  ByBuyerList,
  BySellerList,
  History,
} from "modules/guarantee_management"

// ** Hooks **
import useQueryString from "hooks/useQueryString"

const GuaranteeManagementPage = () => {
  const tab = useQueryString("tab")
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    if (tab !== null) {
      setTabIndex(parseInt(tab))
    }
  }, [tab])

  return (
    <Card p={6}>
      <Box w="1000px">
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Dashboard",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Payment Guarantee",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
            {
              label: "By Buyer list",
              icon: null,
              activeIcon: null,
              active: tabIndex === 2,
            },
            {
              label: "By Seller list",
              icon: null,
              activeIcon: null,
              active: tabIndex === 3,
            },
            {
              label: "History",
              icon: null,
              activeIcon: null,
              active: tabIndex === 4,
            },
          ]}
        />
      </Box>
      <Box mt={4}>
        <Box display={tabIndex === 0 ? "block" : "none"}>
          <Dashboard setTabIndex={setTabIndex} />
        </Box>
        <Box display={tabIndex === 1 ? "block" : "none"}>
          <RevenueByInvoice />
        </Box>
        <Box display={tabIndex === 2 ? "block" : "none"}>
          <ByBuyerList />
        </Box>
        <Box display={tabIndex === 3 ? "block" : "none"}>
          <BySellerList />
        </Box>
        <Box display={tabIndex === 4 ? "block" : "none"}>
          <History />
        </Box>
      </Box>
    </Card>
  )
}

export default GuaranteeManagementPage
