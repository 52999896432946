import { AxiosResponse } from "axios"
import $axios from "./instance"
import { GetAcceptedGuaranteedInvoicesResponse } from "./types/getAcceptedGuaranteedInvoicesType"

const getAcceptedInvoicesOffererPtId = async (
  id: string
): Promise<AxiosResponse<GetAcceptedGuaranteedInvoicesResponse>> => {
  const request = await $axios().get("/get_accepted_invoices_of_offerer/" + id)
  return request
}

export default getAcceptedInvoicesOffererPtId
