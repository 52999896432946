import { Box, Text } from "@chakra-ui/react"

type StatusDotProps = {
  status: any
}

const StatusDot: React.FC<StatusDotProps> = ({ status }) => {
  const getColor = () => {
    switch (status) {
      case "offered":
        return "green"
      case "online":
        return "green.500"
      case "offline":
        return "gray.500"
      case "busy":
        return "red.500"
      case "Wait For Approval":
        return "orange"
      default:
        return "gray.500"
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <Box
        width="10px"
        height="10px"
        borderRadius="50%"
        bg={getColor()}
        marginRight="2"
      />
          <Text fontSize="sm" fontWeight="bold" color={getColor()}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Text>
    </Box>
  )
}

export default StatusDot
