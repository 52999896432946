import { AxiosResponse } from "axios";
import $axios from "./instance";
import {
  PostWithdrawWalletBody,
  PostWithdrawWalletResponse,
} from "./types/postWithdrawWalletType";

const postWithdrawWallet = async (
  body: PostWithdrawWalletBody
): Promise<AxiosResponse<PostWithdrawWalletResponse>> => {
  const request = await $axios().post("/withdraw", body);
  return request;
};

export default postWithdrawWallet;
