// ** React **
import { useState } from "react"

// ** Libs **
import { Box, Card, Flex, HStack, Stack } from "@chakra-ui/react"

// ** Components **
import CardDetail from "common/components/CardDetail"
import CardGraph, { CardGraphProps } from "common/components/CardGraph"
import PrimaryButton from "common/components/PrimaryButton"
import { Holder, Token } from "modules/dashboard/role"

// ** Constants **
import { btnActive, btnInactive } from "constants/AppThemeSchema"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

const AdminDashboard = () => {
  const [tab, setTab] = useState("holders")

  const { getAdminDashboardHook, adminDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initAdminDashboard",
    queryFn: getAdminDashboardHook,
  })

  if (isLoading) return <></>

  const {
    num_listing,
    num_trading,
    total_volume,
    transaction,
    transaction_24h,
    transaction_7d,
    holders,
    all_token,
  } = adminDashboard

  const transactionData = {
    header: "transaction",
    headerValue: numberFormat(`${transaction}`),
    list: [
      { key: "24 hours", value: numberFormat(`${transaction_24h}`) },
      { key: "7 days", value: numberFormat(`${transaction_7d}`) },
    ],
  }

  let dataAllHolders: CardGraphProps = {
    header: "All Wallet Under Holder Type",
    value: `${
      holders["Sponsoring"] +
      holders["Corporate"] +
      holders["FinancialInstitution"]
    }`,
    valueUnit: "holders",
    list: [
      {
        key: "Sponsoring",
        value: holders["Sponsoring"],
        color: "#51BC68",
      },
      {
        key: "Corporate",
        value: holders["Corporate"],
        color: "#00249c",
      },
      {
        key: "Financial Institution",
        value: holders["FinancialInstitution"],
        color: "#45A0F6",
      },
    ],
  }

  const dataAllTokens = {
    header: "All tokens",
    value: `${
      all_token["num_credit"] + all_token["num_stable"] + all_token["num_nft"]
    }`,
    valueUnit: "tokens",
    list: [
      {
        key: "Credit",
        value: all_token["num_credit"],
        color: "#00BE91",
      },
      {
        key: "Stable Coin",
        value: all_token["num_stable"],
        color: "#EAD300",
      },
      {
        key: "Invoice on PromptBiz",
        value: all_token["num_nft"],
        color: "#FFAC5F",
      },
    ],
  }

  const listData = {
    header: "List",
    headerValue: `${num_listing}`,
    list: [
      {
        key: "Total volume",
        value: numberFormatForCurrency(`${total_volume}`),
      },
      {
        key: "Total trading",
        value: numberFormat(`${num_trading}`),
      },
    ],
  }

  const propsBtn = (key: string) => {
    if (tab === key) return btnActive
    else return btnInactive
  }

  return (
    <Flex
      className="admin-dashboard"
      direction="column"
      align="stretch"
      gap={8}
      mt={4}
    >
      <Flex justify="space-between" gap={8} wrap="nowrap">
        <Box flex={1}>
          <CardDetail {...listData}></CardDetail>
        </Box>
        <Box flex={1}>
          <CardDetail {...transactionData}></CardDetail>
        </Box>
      </Flex>
      <Flex justify="space-between" gap={8} wrap="nowrap">
        <Box flex={1}>
          <CardGraph {...dataAllHolders}></CardGraph>
        </Box>
        <Box flex={1}>
          <CardGraph {...dataAllTokens}></CardGraph>
        </Box>
      </Flex>
      <Card p={6} w="100%">
        <Stack>
          <HStack w="200px" gap={4}>
            <PrimaryButton
              {...propsBtn("holders")}
              h={8}
              borderRadius={40}
              onClick={() => setTab("holders")}
            >
              Holders
            </PrimaryButton>
            <PrimaryButton
              {...propsBtn("tokens")}
              h={8}
              borderRadius={40}
              onClick={() => setTab("tokens")}
            >
              Tokens
            </PrimaryButton>
          </HStack>
          <Box display={tab === "holders" ? "block" : "none"}>
            <Holder />
          </Box>
          <Box display={tab === "tokens" ? "block" : "none"}>
            <Token />
          </Box>
        </Stack>
      </Card>
    </Flex>
  )
}

export default AdminDashboard
