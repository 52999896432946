// ** React **
import { useCallback, useMemo, useEffect, useState } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Text, useDisclosure } from "@chakra-ui/react"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useArHook from "hooks/useArHook"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import SuccessBadge from "common/components/SuccessBadge"
import { TableSortType } from "common/components/Table/ListDataTable"
import { DetailModalForSellerCurrent } from "modules/ap_management"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  formatDateTimeStamp,
  numberFormatForCurrency,
  invoiceOptionIdToString,
  filterDataByTimeframe,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Constants **
import { TIME_FRAME } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Types **
import type { ArItem } from "types/Ar"

const APHistoryForSeller = ({ option }: { option: number }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState<ArItem[]>([])
  const [paymentDetailData, setPaymentDetailData] = useState<ArItem>()
  const [fillValue, setFillValue] = useState<string>("")
  const [timeFrame, setTimeFrame] = useState("All Time")
  const modalDetail = useDisclosure()

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getArHistoryHook, arapHistoryOp1, arapHistoryOp2, arapHistoryOp3 } =
    useArHook()

  const { isLoading } = useQuery({
    queryKey: "initARHistoryOp1",
    queryFn: () => getArHistoryHook(1),
  })

  const { isLoading: isLoadingOp2 } = useQuery({
    queryKey: "initARHistoryOp2",
    queryFn: () => getArHistoryHook(2),
  })

  const { isLoading: isLoadingOp3 } = useQuery({
    queryKey: "initARHistoryOp3",
    queryFn: () => getArHistoryHook(3),
  })

  useEffect(() => {
    if (option === 3) {
      setData(arapHistoryOp3)
    } else if (option === 2) {
      setData(arapHistoryOp2)
    } else {
      setData(arapHistoryOp1)
    }
  }, [arapHistoryOp1, arapHistoryOp2, arapHistoryOp3, option])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  useEffect(() => {
    let temp = []

    switch (option) {
      case 3:
        temp = arapHistoryOp3
        break

      case 2:
        temp = arapHistoryOp2
        break

      default:
        temp = arapHistoryOp1
        break
    }

    temp = filterDataByTimeframe(temp, timeFrame, "created_at")
    const filterValue = temp.filter((m) =>
      (m.seller_company_name + m.doc_number)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [
    fillValue,
    arapHistoryOp1,
    arapHistoryOp2,
    arapHistoryOp3,
    option,
    timeFrame,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  if (isLoading || isLoadingOp2 || isLoadingOp3) return <></>

  const column = [
    {
      title: "Finance Date",
      value: "finance_date",
      w: "180px",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => {
        return <Box key={key}>{formatDateDD_MMMM_YYYY(value.updated_at)}</Box>
      },
    },
    {
      title: "DocType",
      value: "doc_type",
      customRender: (key: string, value: any) => {
        return <Box key={key}>Invoice</Box>
      },
    },
    {
      title: "DocNo.",
      value: "doc_no",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => {
        return <Box key={key}>{value.doc_number}</Box>
      },
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.seller_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Buyer Name",
      value: "buyer_company_name",
      w: "180px",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.buyer_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Amount (NEW)",
      value: "amount",
      w: "180px",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => {
        return (
          <Text key={key} textAlign="right">
            {numberFormatForCurrency(value.amount)} NEW
          </Text>
        )
      },
    },
    {
      title: "Total Discount (NEW)",
      value: "offer_discount",
      w: "200px",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {value.offer_option === 3
              ? numberFormatForCurrency(
                  `${
                    parseFloat(value.offer_discount) +
                    parseFloat(value.commitment_fee) +
                    parseFloat(value.guarantee_fee) +
                    parseFloat(value.guarantee_fee_extra)
                  }`
                )
              : value.offer_option !== 3
              ? numberFormatForCurrency(`${parseFloat(value.offer_discount)}`)
              : "0.00"}{" "}
            NEW
          </Box>
        )
      },
    },
    {
      title: "Option",
      value: "offer_option",
      w: "220px",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>{invoiceOptionIdToString(value.offer_option)}</Box>
        )
      },
    },
    {
      title: "Payment Date",
      value: "payment_date",
      w: "220px",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => {
        return <Box key={key}>{formatDateDD_MMMM_YYYY(value.payment_date)}</Box>
      },
    },
    {
      title: "Status",
      value: "status",
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.pay_status}</Text>
          <SuccessBadge label={value.pay_status} />
        </Box>
      ),
    },
    {
      title: "Timestamp",
      value: "updated_at",
      w: "230px",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => {
        return <Box key={key}>{formatDateTimeStamp(value.updated_at)}</Box>
      },
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key} cursor="pointer" onClick={() => onRowClick(value)}>
            View
          </Box>
        )
      },
    },
  ]

  const filterTable = [
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
  ]

  const onRowClick = (data: ArItem) => {
    setPaymentDetailData(data)
    modalDetail.onOpen()
  }

  return (
    <Box>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="seller name / Doc Number"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => {
          setCurrentPage(page)
        }}
        onSearchChange={onSearchChange}
      />
      <DetailModalForSellerCurrent
        {...modalDetail}
        data={paymentDetailData}
        onClickClose={() => modalDetail.onClose()}
        isPaymentHistory={true}
      />
    </Box>
  )
}

export default APHistoryForSeller
