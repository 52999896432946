// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Text, Link, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { TransactionTokenDetailModal } from "./"

// ** Constants **
import { TIME_FRAME } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useTransactionHook from "hooks/useTransactionHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  capitalizeFirstLetter,
  formatDateTimeStamp,
  removeDuplicates,
  filterDataByTimeframe,
} from "common/utils/transform"

// ** Types **
import { TransactionToken } from "types/Transaction"

const TransactionTokenTab = () => {
  const transactionTokenDetailModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [status, setStatus] = useState("")
  const [action, setAction] = useState("")
  const [currency, setCurrency] = useState("")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<TransactionToken[]>([])
  const [selected, setSelected] = useState<TransactionToken>()

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getTransactionTokensHook, transactionTokens } = useTransactionHook()

  const statusOpts = [
    { title: "All Status", value: "" },
    ...Array.from(new Set(transactionTokens.map((item) => item.status))).map(
      (m) => ({ title: capitalizeFirstLetter(m), value: m }),
    ),
  ]

  const actionOpts = [
    { title: "All Action", value: "" },
    ...Array.from(new Set(transactionTokens.map((item) => item.action))).map(
      (m) => ({
        title: capitalizeFirstLetter(m),
        value: m,
      }),
    ),
  ]

  const currencyOpts = [
    { title: "All Currency", value: "" },
    ...Array.from(new Set(transactionTokens.map((item) => item.currency))).map(
      (m) => ({ title: m, value: m }),
    ),
  ]

  const { isLoading } = useQuery({
    queryKey: "initTransactionTokens",
    queryFn: getTransactionTokensHook,
  })

  useEffect(() => {
    setData(transactionTokens)
  }, [transactionTokens])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    [],
  )

  useEffect(() => {
    let temp: TransactionToken[] = transactionTokens
    if (status) {
      temp = temp.filter((m) => m.status === status)
    }
    if (action) {
      temp = temp.filter((m) => m.action === action)
    }
    if (currency) {
      temp = temp.filter((m) => m.currency === currency)
    }
    temp = removeDuplicates(temp, "id")
    temp = filterDataByTimeframe(temp, timeFrame, "created_at")
    temp.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )
    const filterValue: TransactionToken[] = temp.filter((m) =>
      (
        m.from_bank_name +
        m.from_bank_account_number +
        m.from_company_name +
        m.from_wallet_nickname +
        m.from_address +
        m.to_bank_name + 
        m.to_bank_account_number +
        m.to_company_name +
        m.to_wallet_nickname +
        m.to_address
      )
        .toLowerCase()
        .includes(fillValue.toLowerCase()),
    )
    setData(filterValue)
  }, [fillValue, transactionTokens, status, action, currency, timeFrame])

  if (isLoading) return <></>

  const column = [
    {
      title: "From",
      value: "from",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {value.action === "top-up"
            ? `${value.from_bank_name} - ${value.from_bank_account_number}`
            : `${capitalizeFirstLetter(
                value.from_company_name,
              )} - ${capitalizeFirstLetter(
                value.from_wallet_nickname,
              )} (${abbreviateHexString(value.from_address)})`}
        </Text>
      ),
    },
    {
      title: "To",
      value: "to",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {value.action === "withdraw"
            ? `${value.to_bank_name} - ${value.to_bank_account_number}`
            : `${capitalizeFirstLetter(
                value.to_company_name,
              )} - ${capitalizeFirstLetter(
                value.to_wallet_nickname,
              )} (${abbreviateHexString(value.to_address)})`}
        </Text>
      ),
    },
    {
      title: "Amount",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.amount)}</Text>
      ),
    },
    {
      title: "Currency",
      value: "currency",
      sortType: TableSortType.STRING,
    },
    {
      title: "Time Stamp",
      value: "created_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.created_at)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Action",
      value: "action",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box
          css={{
            "&:first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {value.action}
        </Box>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Link
            key={key}
            onClick={() => {
              setSelected(value)
              transactionTokenDetailModalAction.onOpen()
            }}
          >
            View
          </Link>
        )
      },
    },
  ]

  const filterTable = [
    {
      options: statusOpts,
      onChange: (value: string) => setStatus(value),
      value: status,
      type: FilterType.DROPDOWN,
    },
    {
      options: actionOpts,
      onChange: (value: string) => setAction(value),
      value: action,
      type: FilterType.DROPDOWN,
    },
    {
      options: currencyOpts,
      onChange: (value: string) => setCurrency(value),
      value: currency,
      type: FilterType.DROPDOWN,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="From / To"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      {selected && (
        <TransactionTokenDetailModal
          {...transactionTokenDetailModalAction}
          data={selected}
        />
      )}
    </Box>
  )
}

export default TransactionTokenTab
