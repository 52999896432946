// ** APIs **
import getUser from "api/getUser"
import { updateUser, changePassword, uploadAvatar } from "api/getUser"
import postLogin from "api/postLogin"
import postActivatePassword from "api/postActivatePassword"
import postRecoverPassword from "api/postRecoverPassword"
import postSetPassword from "api/postSetPassword"
import postLogout from "api/postLogout"
import { switchRole } from "api/user"

// ** Store **
import { useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { authActions } from "store/slice/AuthSlice"

// ** Types **
import type {
  PostLoginBody,
  PostActivatePasswordBody,
  PostRecoveryPasswordBody,
  PostSetPasswordBody,
} from "api/types/postLoginType"
import { GetUserResponse } from "api/types/getUserType"
import { UpdateUserProfileBody, ChangePasswordBody } from "types/User"
import type { ResponseWithOutData } from "api/types/ApiResponse"

// ** Utils **
import { handleError } from "common/utils/Response"

const useAuthHook = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)

  //getter
  const getRole = () => user?.pt_role

  // Check isAdmin
  const isAdmin = user?.pt_id === 0 ? true : false

  //actions hook
  const clearAuthHook = async () => {
    localStorage.clear()
    dispatch(authActions.clearState())
  }

  const postLoginHook = async (
    body: PostLoginBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postLogin(body)
      if (response.data.data.access_token) {
        const accessToken = response.data.data.access_token
        window.localStorage.setItem("accessToken", accessToken)
      }
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postActivatePasswordHook = async (
    body: PostActivatePasswordBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postActivatePassword(body)
      return response.data
    } catch (err: any) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postRecoveryPasswordHook = async (
    body: PostRecoveryPasswordBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postRecoverPassword(body)
      return response.data
    } catch (err: any) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postSetPasswordHook = async (
    body: PostSetPasswordBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postSetPassword(body)
      return response.data
    } catch (err: any) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postLogoutHook = async () => {
    try {
      dispatch(appActions.showLoader())
      await postLogout()
    } catch (err) {
      console.log("postLoadingHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
      clearAuthHook()
    }
  }

  const getUserHook = async (): Promise<GetUserResponse | null> => {
    try {
      dispatch(appActions.showLoader())
      const response = await getUser()
      dispatch(authActions.setUser(response.data.data))
      return response.data
    } catch (err) {
      console.log("getUserHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const putUpdateUserProfileHook = async (
    body: UpdateUserProfileBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await updateUser(body)
      await getUserHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postChangePasswordHook = async (
    body: ChangePasswordBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await changePassword(body)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postUploadAvatarHook = async (
    type: string,
    file: File
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await uploadAvatar(type, file)
      await getUserHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const switchRoleHook = async (role_id: number): Promise<any | null> => {
    try {
      dispatch(appActions.showLoader())
      const response = await switchRole(role_id)
      await getUserHook()
      window.location.href = window.location.origin + "/dashboard"
      return response.data
    } catch (err) {
      console.log("switchRoleHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }


  return {
    postLoginHook,
    postActivatePasswordHook,
    postRecoveryPasswordHook,
    postSetPasswordHook,
    postLogoutHook,
    getUserHook,
    putUpdateUserProfileHook,
    postUploadAvatarHook,
    postChangePasswordHook,
    getUser,
    clearAuthHook,
    switchRoleHook,
    user,
    isAdmin,
    getRole,
  }
}

export default useAuthHook
