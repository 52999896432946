// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "../store"
import { appActions } from "store/slice/AppSlice"
import { arManagementActions } from "store/slice/ARManagementSlice"

// ** APIs **
import {
  getArapCurrent,
  getArapHistory,
  getGetAcceptedOfferBuyer,
  getGetAcceptedOfferBuyerList,
  getGetAcceptedOfferSeller,
  getGetAcceptedOfferSellerList,
} from "api/armanagement"

const useARManagementHook = () => {
  const dispatch = useAppDispatch()

  const {
    currents,
    histories,
    buyerInvoice,
    buyerInvoiceList,
    sellerInvoice,
    sellerInvoiceList,
  } = useAppSelector((state: RootState) => state.armanagement)

  const getArapCurrentHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getArapCurrent()
      dispatch(arManagementActions.setCurrents(response.data.data))
    } catch (err) {
      console.log("getArapCurrentHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getArapHistorytHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getArapHistory()
      dispatch(arManagementActions.setHistories(response.data.data))
    } catch (err) {
      console.log("getArapHistorytHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getGetAcceptedOfferBuyertHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getGetAcceptedOfferBuyer()
      dispatch(arManagementActions.setBuyerInvoice(response.data.data))
    } catch (err) {
      console.log("getGetAcceptedOfferBuyertHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getGetAcceptedOfferBuyerListHook = async (ptId: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getGetAcceptedOfferBuyerList(ptId)
      dispatch(arManagementActions.setBuyerInvoiceList(response.data.data))
    } catch (err) {
      console.log("getGetAcceptedOfferBuyerListHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getGetAcceptedOfferSellertHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getGetAcceptedOfferSeller()
      dispatch(arManagementActions.setSellerInvoice(response.data.data))
    } catch (err) {
      console.log("getGetAcceptedOfferSellertHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getGetAcceptedOfferSellerListHook = async (ptId: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getGetAcceptedOfferSellerList(ptId)
      dispatch(arManagementActions.setSellerInvoiceList(response.data.data))
    } catch (err) {
      console.log("getGetAcceptedOfferSellerListHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getArapCurrentHook,
    getArapHistorytHook,
    getGetAcceptedOfferBuyertHook,
    getGetAcceptedOfferBuyerListHook,
    getGetAcceptedOfferSellertHook,
    getGetAcceptedOfferSellerListHook,
    currents,
    histories,
    buyerInvoice,
    buyerInvoiceList,
    sellerInvoice,
    sellerInvoiceList,
  }
}

export default useARManagementHook
