import {
  Center,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Box,
  GridItem,
  Spacer,
} from "@chakra-ui/react"
import _, { debounce } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import GridItemPopupDetail from "../../../common/components/GridItemPopupDetail"
import PrimaryButton from "../../../common/components/PrimaryButton"
import {
  TableColumn,
  TableSortType,
} from "../../../common/components/Table/BoxDataTable"
import ContainerTable from "../../../common/components/Table/ContainerTable"
import {
  abbreviateHexString,
  invoiceOptionIdToString,
  formatDateDD_MMMM_YYYY,
  formatDateTimeStamp,
  numberFormatForCurrency,
  capitalizeFirstLetter,
  buildInvoiceListOption,
  exportToCSV,
  downloadCSV,
} from "../../../common/utils/transform"
import useInvoiceHook from "../../../hooks/useInvoiceHook"
import { useQuery } from "../../../hooks/useQueryHook"
import { InvoiceListing } from "../../../types/Invoice"
import SuccessBadge from "common/components/SuccessBadge"
import PopOver from "common/components/PopOver"

const ListingTab = () => {
  const { getOwnInvoicesHistoryHook, invoicesHistory } = useInvoiceHook()
  const { isLoading } = useQuery({ queryFn: getOwnInvoicesHistoryHook })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<InvoiceListing[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [viewData, setViewData] = useState<InvoiceListing>()

  const statusMapping: any = {
    accepted: "success",
    pending: "pending",
    cancelled: "rejected",
  }

  const popupStatus = () => {
    return (
      <Box ml={1} cursor="pointer">
        <PopOver header="Status Explanation" width="450px">
          <Box>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="minting" />
              </Text>
              <Text>: Mint Invoice to be NFT</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="listing" />
              </Text>
              <Text>: Listing Invoice</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="pending" />
              </Text>
              <Text>: Listing Waiting for Seller Accept</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="success" />
              </Text>
              <Text>: Accepted an offer</Text>
            </Flex>
            <Flex alignItems="center">
              <Text mr={2} fontWeight="bold">
                <SuccessBadge label="rejected" />
              </Text>
              <Text>: Timeout / Declined an offer</Text>
            </Flex>
          </Box>
        </PopOver>
      </Box>
    )
  }

  const column: TableColumn[] = [
    {
      title: "DocType",
      value: "doc_type",
      customRender: (key) => <div key={key}>Invoice</div>,
    },
    {
      title: "DocNo",
      value: "doc_number",
      sortType: TableSortType.STRING,
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      sortType: TableSortType.STRING,
      customRender(key, value) {
        return (
          <Text key={key}>
            {value.seller_company_name
              ? capitalizeFirstLetter(value.seller_company_name)
              : "-"}
          </Text>
        )
      },
    },
    {
      title: "Buyer Name",
      value: "buyer_company_name",
      sortType: TableSortType.STRING,
      customRender(key, value) {
        return (
          <Text key={key}>
            {value.buyer_company_name
              ? capitalizeFirstLetter(value.buyer_company_name)
              : "-"}
          </Text>
        )
      },
    },
    {
      title: "Amount (NEW)",
      value: "amount",
      sortType: TableSortType.INTEGER,
      customRender(key, value) {
        return (
          <Text key={key} align="right">
            {numberFormatForCurrency(value.amount)}
          </Text>
        )
      },
    },
    {
      title: "Total Discount (NEW)",
      value: "offer_discount",
      sortType: TableSortType.INTEGER,
      customRender(key, value) {
        return (
          <Text key={key} align="right">
            {value.offer_option === 3
              ? numberFormatForCurrency(
                  `${
                    parseFloat(value.offer_discount) +
                    parseFloat(value.commitment_fee) +
                    parseFloat(value.guarantee_fee) +
                    parseFloat(value.guarantee_fee_extra)
                  }`
                )
              : value.offer_option !== 3
              ? numberFormatForCurrency(value.offer_discount)
              : "0.00"}
          </Text>
        )
      },
    },
    {
      title: "Offer By",
      value: "offer_company_name",
      sortType: TableSortType.STRING,
      w: "250px",
      customRender(key, value) {
        return (
          <Text key={key}>
            {value.offer_company_name} - {value.offer_wallet_nickname}
            {value.offer_wallet_address
              ? ` (${abbreviateHexString(value.offer_wallet_address)})`
              : ""}
          </Text>
        )
      },
    },
    {
      title: "Option",
      value: "options",
      w: "210px",
      sortType: TableSortType.STRING,
      customRender(key, value) {
        return (
          <div key={key} style={{ whiteSpace: "pre-line" }}>
            {value.offer_option
              ? invoiceOptionIdToString(value.offer_option)
              : buildInvoiceListOption(value.options)}
          </div>
        )
      },
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.ITEM,
      customSuffixRender: popupStatus,
      customRender(key, value) {
        return (
          <div key={key}>
            <Text className="hidden">
              {value.offer_status === "rejected"
                ? value.offer_status
                : statusMapping[value.status] ?? value.status}
            </Text>
            <SuccessBadge
              label={
                value.offer_status === "rejected"
                  ? value.offer_status
                  : statusMapping[value.status] ?? value.status
              }
            />
          </div>
        )
      },
    },
    {
      title: "Timestamp",
      value: "timestamp",
      w: "250px",
      sortType: TableSortType.ITEM,
      customRender(key, value) {
        return (
          <div key={key}>
            {value.timestamp
              ? formatDateTimeStamp(value.timestamp)
              : formatDateTimeStamp(value.updated_at)}
          </div>
        )
      },
    },
    {
      title: "Detail",
      value: "id",
      valueCenter: true,
      headerCenter: true,
      customRender(key, value) {
        return (
          <Heading
            key={key}
            size="xs"
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => onClickShowDetail(value)}
          >
            View
          </Heading>
        )
      },
    },
  ]

  const onClickShowDetail = (data: InvoiceListing) => {
    onOpen()
    setViewData(data)
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    setData(invoicesHistory)
  }, [invoicesHistory])

  useEffect(() => {
    let temp = [...invoicesHistory]

    temp.sort(
      (a, b) =>
        new Date(
          b.timestamp
            ? formatDateTimeStamp(b.timestamp)
            : formatDateTimeStamp(b.updated_at)
        ).getTime() -
        new Date(
          a.timestamp
            ? formatDateTimeStamp(a.timestamp)
            : formatDateTimeStamp(a.updated_at)
        ).getTime()
    )

    const filterValue = temp.filter((m) =>
      (m.buyer_company_name + m.doc_number)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, invoicesHistory])

  if (isLoading) return <></>

  const handleExport = () => {
    if (viewData) {
      const content = exportToCSV([viewData], [])
      downloadCSV(content, `listing_${viewData.id}.csv`)
    }
  }

  return (
    <>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        searchPlaceholder="Debtor Name / Invoice id"
        onSearchChange={onSearchChange}
        canExport={true}
      />
      {viewData && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={8}>
              <Flex direction="column" gap={8}>
                <Center>
                  <Heading size="md">Invoice detail</Heading>
                </Center>
                <Grid templateColumns="repeat(2,1fr)" gap={5}>
                  <GridItemPopupDetail title="Doc Type" value="Invoice" />
                  <GridItemPopupDetail
                    title="Doc No."
                    value={viewData!.doc_number}
                  />
                  <GridItemPopupDetail
                    title="Buyer Juristic ID"
                    value={viewData!.buyer_juristic_id}
                  />
                  <GridItemPopupDetail
                    title="Buyer Name"
                    value={
                      viewData!.buyer_company_name
                        ? capitalizeFirstLetter(viewData!.buyer_company_name)
                        : "-"
                    }
                  />
                  <GridItemPopupDetail
                    title="Seller Juristic ID"
                    value={viewData!.seller_juristic_id}
                  />
                  <GridItemPopupDetail
                    title="Seller Name"
                    value={
                      viewData!.seller_company_name
                        ? capitalizeFirstLetter(viewData!.seller_company_name)
                        : "-"
                    }
                  />
                  <GridItemPopupDetail
                    title="Doc Date"
                    value={formatDateDD_MMMM_YYYY(viewData!.doc_date)}
                  />
                  <GridItemPopupDetail
                    title="Amount (Include VAT)"
                    value={numberFormatForCurrency(
                      viewData!.amount?.toString()
                    )}
                  />
                  <GridItemPopupDetail
                    title="Currency"
                    value={viewData!.currency}
                  />
                  <GridItemPopupDetail
                    title="Schedule Payment Date"
                    value={formatDateDD_MMMM_YYYY(viewData!.payment_date)}
                  />
                  <GridItemPopupDetail
                    title="Payment Guarantee Status"
                    value={
                      viewData?.offer_option === 3 &&
                      viewData?.offer_status === "accepted"
                        ? "Yes"
                        : "No"
                    }
                  />
                  <GridItemPopupDetail
                    title="Owner Name"
                    value={
                      viewData!.seller_company_name
                        ? capitalizeFirstLetter(viewData!.seller_company_name)
                        : "-"
                    }
                  />
                  <GridItemPopupDetail
                    title="Holder Name"
                    value={viewData!.holder_company_name ?? "-"}
                  />
                  <GridItemPopupDetail
                    title="Holder Wallet"
                    value={`${
                      viewData!.holder_wallet_nickname
                        ? viewData!.holder_wallet_nickname
                        : ""
                    } ${
                      viewData!.holder_wallet_address
                        ? abbreviateHexString(viewData!.holder_wallet_address)
                        : ""
                    }`}
                  />
                  <GridItemPopupDetail
                    title="Offering Start Date"
                    value={
                      viewData!.offer_start_date
                        ? formatDateDD_MMMM_YYYY(viewData!.offer_start_date)
                        : "-"
                    }
                  />
                  <GridItemPopupDetail
                    title="Offering end Date"
                    value={
                      viewData!.offer_end_date
                        ? formatDateDD_MMMM_YYYY(viewData!.offer_end_date)
                        : "-"
                    }
                  />
                  <GridItemPopupDetail
                    title="Option"
                    value={
                      viewData!.offer_option
                        ? invoiceOptionIdToString(viewData!.offer_option)
                        : buildInvoiceListOption(viewData!.options)
                    }
                  />
                  <GridItemPopupDetail
                    title="Total Discount (NEW)"
                    value={
                      viewData!.offer_option === 3
                        ? numberFormatForCurrency(
                            `${
                              parseFloat(viewData!.offer_discount) +
                              parseFloat(viewData!.commitment_fee) +
                              parseFloat(viewData!.guarantee_fee) +
                              parseFloat(viewData!.guarantee_fee_extra)
                            }`
                          )
                        : numberFormatForCurrency(viewData!.offer_discount)
                    }
                  />
                  <GridItem
                    w="100%"
                    display="inline-flex"
                    borderBottom="1px solid #E1F1FF"
                    p={3}
                  >
                    <Flex alignItems="center">
                      <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                        Status
                      </Text>
                      {popupStatus()}
                    </Flex>
                    <Spacer />
                    <Box>
                      <Text fontSize="sm" fontWeight={700} textAlign="right">
                        <SuccessBadge
                          label={
                            viewData!.offer_status === "rejected"
                              ? viewData!.offer_status
                              : statusMapping[viewData!.status] ??
                                viewData!.status
                          }
                        />
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItemPopupDetail
                    title="Time Stamp"
                    value={
                      viewData!.timestamp
                        ? formatDateTimeStamp(viewData!.timestamp)
                        : formatDateTimeStamp(viewData!.updated_at)
                    }
                  />
                  <GridItemPopupDetail
                    title="Offer Name"
                    value={
                      viewData!.offer_company_name
                        ? capitalizeFirstLetter(viewData!.offer_company_name)
                        : "-"
                    }
                  />
                  <GridItemPopupDetail
                    title="Offer Wallet"
                    value={
                      viewData!.offer_wallet_address
                        ? abbreviateHexString(viewData!.offer_wallet_address)
                        : ""
                    }
                  />
                </Grid>
                <Grid templateColumns="repeat(1,1fr)" gap={5}>
                  <GridItemPopupDetail
                    title="Financial Amount (NEW)"
                    value={numberFormatForCurrency(
                      viewData!.amount?.toString()
                    )}
                  />
                </Grid>
                <Flex mt={4} gap={4} justify="center">
                  <PrimaryButton
                    maxWidth="300px"
                    variant="outline"
                    onClick={onClose}
                  >
                    Close
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={handleExport}
                    bgColor="primary"
                    colorScheme="white"
                    w="250px"
                  >
                    Export to CSV
                  </PrimaryButton>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default ListingTab
