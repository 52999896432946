import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetOfferByInvoiceResponse } from "./types/getOfferByInvoiceType";

export const getOfferByInvoice = async (
  id: string
): Promise<AxiosResponse<GetOfferByInvoiceResponse>> => {
  const response = await $axios().get("/invoice_offering/" + id);
  return response;
};
