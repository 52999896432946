import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetCompanyWalletResponse } from "./types/getCompanyWalletType";

const getAllWallet = async (): Promise<
  AxiosResponse<GetCompanyWalletResponse>
> => {
  const request = await $axios().get("/wallet/all");
  return request;
};

export default getAllWallet;
