// ** React **
import { useState } from "react"

// ** Components **
import { Box, Text, Flex, useDisclosure, Grid } from "@chakra-ui/react"
import WalletClipBoard from "common/components/WalletClipBoard"
import SuccessBadge from "common/components/SuccessBadge"
import {
  ParticipantDetailItem,
  ParticipantDetailHeader,
  ParticipantWalletEditModal,
} from "modules/client_management"
import PrimaryButton from "common/components/PrimaryButton"
import {
  FreezeWalletModal,
  UnfreezeWalletModal,
  SuspendWalletModal,
  UnsuspendWalletModal,
} from "modules/user_wallet_management"

// ** Types **
import { CompanyWallet } from "types/Participant"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
} from "common/utils/transform"

// ** Icons **
import { EditIcon, ArrowDownIcon, ArrowUpIcon } from "assets/icons"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

import { UserRole } from "constants/Enum"

const ParticipantWalletItem = ({ data }: { data: CompanyWallet }) => {
  const editWalletModalAction = useDisclosure()
  const freezeWalletModalAction = useDisclosure()
  const unfreezeWalletModalAction = useDisclosure()
  const suspendWalletModalAction = useDisclosure()
  const unsuspendWalletModalAction = useDisclosure()
  const { isAdmin, getRole, user } = useAuthHook()
  const [toggle, setToggle] = useState<boolean>(isAdmin ? false : true)

  return (
    <Box
      border="1px solid"
      borderRadius={6}
      borderColor="emptyColor"
      p={6}
      px={8}
      my={4}
    >
      <Flex justify="space-between">
        <Flex alignItems="center" mb={2}>
          <Text mr={2} fontWeight="bold">
            Wallet
          </Text>{" "}
          <Text color="primary" fontWeight="bold">
            {data.company_name} - {data.nickname} (
            {abbreviateHexString(data.wallet_address)})
          </Text>
          <WalletClipBoard wallet_address={data.wallet_address} />
        </Flex>

        <Flex gap={4} alignItems="center">
          {isAdmin && (
            <PrimaryButton
              border="2px solid"
              borderColor={data.status === "suspend" ? "red" : "primary"}
              color={data.status === "suspend" ? "red" : "primary"}
              variant="outline"
              onClick={() => {
                if (data.status === "suspend") {
                  unsuspendWalletModalAction.onOpen()
                } else {
                  suspendWalletModalAction.onOpen()
                }
              }}
            >
              {data.status === "suspend" ? "Unsuspend" : "Suspend"}
            </PrimaryButton>
          )}
          {(isAdmin ||
            (!isAdmin &&
              `${data?.wallet_created_pt_id}` === `${user?.pt_id}` &&
              getRole() === UserRole.SPONSOR)) && (
            <PrimaryButton
              border="2px solid"
              borderColor={data.status === "freeze" ? "red" : "primary"}
              color={data.status === "freeze" ? "red" : "primary"}
              variant="outline"
              onClick={() => {
                if (data.status === "freeze") {
                  unfreezeWalletModalAction.onOpen()
                } else {
                  freezeWalletModalAction.onOpen()
                }
              }}
            >
              {data.status === "freeze" ? "Unfreeze" : "Freeze"}
            </PrimaryButton>
          )}
          <Box cursor="pointer">
            {(isAdmin ||
              (!isAdmin &&
                `${data?.wallet_created_pt_id}` === `${user?.pt_id}` &&
                getRole() === UserRole.SPONSOR)) && (
              <EditIcon onClick={editWalletModalAction.onOpen} />
            )}
          </Box>
          <Box cursor="pointer" onClick={() => setToggle(!toggle)}>
            {toggle ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Box>
        </Flex>
      </Flex>
      <SuccessBadge label={data.status} />
      {toggle && (
        <Box>
          <ParticipantDetailHeader
            label="Bank No.1"
            value={
              !isAdmin &&
              `${data?.wallet_created_pt_id}` === `${user?.pt_id}` &&
              getRole() === UserRole.SPONSOR ? (
                <Box cursor="pointer">
                  <EditIcon onClick={editWalletModalAction.onOpen} />
                </Box>
              ) : (
                ""
              )
            }
            align="right"
          />
          <ParticipantDetailItem
            label="Bank Account Number"
            value={data.bank_account_number}
          />
          <ParticipantDetailItem label="Bank Name" value={data.bank_name} />
          <ParticipantDetailHeader label="Info Wallet" value="" />
          <ParticipantDetailItem
            label="Total Balance"
            value={`${data.token_balance}`}
          />
          <ParticipantDetailHeader label="Credits" value="" />
          {data.credit.map((credit, index) => (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <ParticipantDetailItem
                label="Credit Amount"
                value={numberFormatForCurrency(`${credit.amount}`)}
              />
              <ParticipantDetailItem
                label="Guarantee Fee Rate"
                value={`${credit.interest_rate}%`}
              />
            </Grid>
          ))}
        </Box>
      )}
      <ParticipantWalletEditModal {...editWalletModalAction} wallet={data} />
      <FreezeWalletModal {...freezeWalletModalAction} wallet={data} />
      <UnfreezeWalletModal {...unfreezeWalletModalAction} wallet={data} />
      <SuspendWalletModal {...suspendWalletModalAction} wallet={data} />
      <UnsuspendWalletModal {...unsuspendWalletModalAction} wallet={data} />
    </Box>
  )
}

export default ParticipantWalletItem
