// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Grid,
  Flex,
  Box,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import SuccessBadge from "common/components/SuccessBadge"
import PrimaryButton from "common/components/PrimaryButton"

// ** Types **
import { ModalProps } from "types/ModalProps"
import type { ARItem } from "types/ARManagement"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
  diffDate,
  abbreviateHexString,
  formatDateTimeStamp,
} from "common/utils/transform"

type NFTsInvoiceModalProps = {
  data?: ARItem
  onClickClose: VoidFunction
} & ModalProps

const NFTsInvoiceModal: FC<NFTsInvoiceModalProps> = (props) => {
  const { isOpen, onClose, data, onClickClose } = props

  if (data === undefined) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Center>
            <Heading size="md">Invoice detail</Heading>
          </Center>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4}>
            <GridItemPopupDetail title="Doctype" value="Invoice" />
            <GridItemPopupDetail title="Doc No." value={data.doc_number} />
            <GridItemPopupDetail
              title="Buyer Juristic Id"
              value={data.buyer_juristic_id}
            />
            <GridItemPopupDetail
              title="Buyer Name"
              value={capitalizeFirstLetter(data.buyer_company_name)}
            />
            <GridItemPopupDetail
              title="Seller Juristic Id"
              value={data.seller_juristic_id}
            />
            <GridItemPopupDetail
              title="Seller Name"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Doc Date"
              value={formatDateDD_MMMM_YYYY(data.doc_date)}
            />
            <GridItemPopupDetail
              title="Amount (Include VAT)"
              value={numberFormatForCurrency(data.amount)}
            />
            <GridItemPopupDetail title="Currency" value={data.currency} />
            <GridItemPopupDetail
              title="Schedule Payment Date"
              value={formatDateDD_MMMM_YYYY(data.payment_date)}
            />
            <GridItemPopupDetail
              title="Payment Guarantee Status"
              value={
                data.offer_option === 3
                  ? "Yes"
                  : "No"
              }
            />
            <GridItemPopupDetail
              title="Owner Name"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Holder Name"
              value={data.offer_company_name}
            />
            <GridItemPopupDetail
              title="Holder Wallet"
              value={abbreviateHexString(data.offer_wallet_address)}
            />
            <GridItemPopupDetail
              title="Option"
              value={invoiceOptionIdToString(data.offer_option)}
            />
            <GridItemPopupDetail
              title="Interest rate(per year)"
              value={numberFormatForCurrency(data.offer_interest_rate) + " %"}
            />
            <GridItemPopupDetail
              title="Total Guarantee Fee (NEW)"
              value={
                data.offer_option === 3
                  ? numberFormatForCurrency( `${
                    parseFloat(data.commitment_fee) +
                    parseFloat(data.guarantee_fee) +
                    parseFloat(data.guarantee_fee_extra)
                  }`)
                  : "-"
              }
            />
            <GridItemPopupDetail
              title="Timestamp"
              value={formatDateTimeStamp(data.updated_at)}
            />
            <GridItemPopupDetail
              title="Overdue"
              value={
                diffDate(new Date(data.payment_date), new Date()) >= 0
                  ? "-"
                  : diffDate(new Date(data.payment_date), new Date()) * -1 +
                    " Days"
              }
            />
            <Flex
              w="100%"
              justify="space-between"
              borderBottom="1px solid #E1F1FF"
              p={3}
            >
              <Box>
                <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                  Status:
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" fontWeight={700} ml={1}>
                  <SuccessBadge label={data.status} />
                </Text>
              </Box>
            </Flex>
          </Grid>
          <Flex flex={1} mt={4} justify="center">
            <PrimaryButton variant="outline" onClick={onClickClose} w="250px">
              Close
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NFTsInvoiceModal
