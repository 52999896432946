import { useState, useEffect } from "react"
import {
  Box,
  Card,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import { PostCancelInvoiceBody } from "../../api/types/postCancelInvoiceType"
import BackButton from "../../common/components/BackButton"
import ConfirmPassword from "../../common/components/ConfirmPassword"
import HStackDetail from "../../common/components/HStackDetail"
import OfferDetail from "../../common/components/OfferDetail"
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "../../common/utils/transform"
import useAppHook from "../../hooks/useAppHook"
import useInvoiceHook from "../../hooks/useInvoiceHook"
import { useQuery } from "../../hooks/useQueryHook"
import { Alert, AlertType } from "../../types/Alert"
import { InvoiceListed, InvoiceOffering } from "../../types/Invoice"

const InvoiceOfferingByIdPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    getOwnListedInvoicesHook,
    getInvoiceByIdHook,
    getOfferByInvoiceHook,
  } = useInvoiceHook()
  const { listedInvoices } = useInvoiceHook()
  const [invoice, setInvoice] = useState<InvoiceListed>()
  const [offers, setOffers] = useState<InvoiceOffering[] | null>([])

  const { isLoading } = useQuery({
    queryKey: "initInvoiceOfferingByIdPage",
    queryFn: getOwnListedInvoicesHook,
  })

  const invoiceData = useQuery({
    queryKey: ["getInvoiceByIdHook", id],
    queryFn: () => getInvoiceByIdHook(id!),
    enabled: !!id,
  })

  const offeringData = useQuery({
    queryKey: ["getOfferByInvoiceHook", id],
    queryFn: () => getOfferByInvoiceHook(id!),
    enabled: !!id,
  })

  const { setAlert } = useAppHook()
  const { postCancelInvoiceHook } = useInvoiceHook()

  const { isOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (id) {
      const invoices = listedInvoices.filter((item) => item.id === parseInt(id))
      setInvoice(invoices[0])
    }
  }, [listedInvoices, id])

  useEffect(() => {
    if (offeringData && offeringData.status === "success" && invoice) {
      if (offeringData?.data) {
        let temp = offeringData.data.map((item) => {
          let total = 0
          if (item.option === 3) {
            total =
              parseFloat(item.discount) +
              parseFloat(invoice.commitment_flat_fee) +
              parseFloat(invoice.guarantee_flat_fee)
          } else {
            total = parseFloat(item.discount)
          }
          return {
            ...item,
            total_discount: total,
          }
        })
        temp = temp.sort(
          (a: any, b: any) => parseFloat(a.total_discount) - parseFloat(b.total_discount)
        )
        setOffers(temp)
      }
    }
  }, [offeringData, invoice])

  if (isLoading) return <></>

  const invoiceDetail = (title: string, value: string) => {
    return (
      <GridItem>
        <HStackDetail title={title} value={value} />
      </GridItem>
    )
  }

  const handlePasswordBack = () => {
    onClose()
  }

  const handleOnPasswordConfirm = async (pwd: string) => {
    const body: PostCancelInvoiceBody = {
      bid_id: invoiceData.data?.bid_id!,
      password: pwd,
    }
    const response = await postCancelInvoiceHook(body)
    let alert: Alert = {
      visible: true,
    }
    if (response?.statusCode === 200) {
      onClose()
      navigate(-1)
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your cancel listing completed successfully.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your cancel listing failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  if (!invoiceData.data || !offeringData.data) return <></>

  return (
    <>
      <Card p={6}>
        <Flex direction="column" gap={4}>
          <BackButton />
          <Card p={6}>
            <Flex gap={10}>
              <Box
                flex="0 0 80px"
                bgColor="emptyColor"
                h="80px"
                borderRadius={8}
              ></Box>
              <Box flex="1 1 auto">
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  {invoiceDetail("Doc Type", "Invoice")}
                  {invoiceDetail("Doc No.", invoiceData.data.doc_number)}
                  {invoiceDetail(
                    "Buyer Juristic id",
                    invoiceData.data.buyer_juristic_id
                  )}
                  {invoiceDetail(
                    "Buyer Name",
                    capitalizeFirstLetter(invoiceData.data.buyer_company_name)
                  )}
                  {invoiceDetail(
                    "Seller Juristic id",
                    invoiceData.data.seller_juristic_id
                  )}
                  {invoiceDetail(
                    "Seller Name",
                    capitalizeFirstLetter(invoiceData.data.seller_company_name)
                  )}
                  {invoiceDetail(
                    "Doc Date",
                    formatDateDD_MMMM_YYYY(invoiceData.data.doc_date)
                  )}
                  {invoiceDetail(
                    "Amount. (include VAT)",
                    numberFormatForCurrency(invoiceData.data.amount)
                  )}
                  {invoiceDetail("Currency", "NEW")}
                  {invoiceDetail(
                    "Payment Date",
                    formatDateDD_MMMM_YYYY(invoiceData.data.payment_date)
                  )}
                  {invoiceDetail(
                    "Owner Name",
                    capitalizeFirstLetter(invoiceData.data.seller_company_name)
                  )}
                </Grid>
              </Box>
            </Flex>
          </Card>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {offers != null && offers.map((m, i) => (
              <OfferDetail key={i} data={m} />
            ))}
          </Grid>
        </Flex>
      </Card>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={8}>
            <ConfirmPassword
              header="Cancel listing invoice"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InvoiceOfferingByIdPage
