export enum AlertType {
  ERROR,
  SUCCESS,
}

export type AlertButton = {
  label: string;
  action?: string | VoidFunction;
};

export type Alert = {
  visible: boolean;
  data?: AlertData;
};

export type AlertData = {
  type: AlertType;
  description: string;
  button: AlertButton;
};
