// ** APIs **
import getCreditTransaction from "api/getCreditTransaction"
import getCreditGranted from "api/getCreditGranted"
import getCreditSponsor from "api/getCreditSponsor"
import getCreditBalance from "api/getCreditBalance"
import {
  getAllParticipants,
  getParticipants,
  getCorporateWallet,
  getTotalSupply,
} from "api/credit"

// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { creditActions } from "store/slice/CreditSlice"

const useCreditHook = () => {
  const dispatch = useAppDispatch()
  const {
    creditTransactions,
    creditGranteds,
    creditSponsors,
    creditSponsorBalance,
    allParticipants,
    participants,
    corporateWallet,
    totalSupply,
  } = useAppSelector((state: RootState) => state.credit)

  const getCreditTransactionHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCreditTransaction()
      dispatch(creditActions.setCreditTransactions(response.data.data))
    } catch (err) {
      console.log("getCreditTransactionHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCreditGrantedHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCreditGranted()
      dispatch(creditActions.setCreditGranteds(response.data.data))
    } catch (err) {
      console.log("getCreditGrantedHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCreditSponsorHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCreditSponsor()
      dispatch(creditActions.setCreditSponsors(response.data.data))
    } catch (err) {
      console.log("getCreditSponsorHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCreditSponsorBalanceHook = async (id: string) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCreditBalance(id)
      dispatch(creditActions.setCreditSponsorBalance(response.data.data))
    } catch (err) {
      console.log("getCreditSponsorHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCreditBalanceHook = async (id: string) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCreditBalance(id)
      return response.data.data
    } catch (err) {
      console.log("getCreditSponsorHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAllParticipantsHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAllParticipants()
      dispatch(creditActions.setAllParticipants(response.data.data))
    } catch (err) {
      console.log("getAllParticipantsHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getParticipantsHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipants()
      dispatch(creditActions.setParticipants(response.data.data))
    } catch (err) {
      console.log("getAllParticipantsHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getCorporateWalletHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getCorporateWallet()
      dispatch(creditActions.setCorporateWallet(response.data.data))
    } catch (err) {
      console.log("getCorporateWalletHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getTotalSupplyHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getTotalSupply()
      dispatch(creditActions.setTotalSupply(response.data.data))
    } catch (err) {
      console.log("getTotalSupplyHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getCreditTransactionHook,
    getCreditGrantedHook,
    getCreditSponsorHook,
    getCreditSponsorBalanceHook,
    getCreditBalanceHook,
    getAllParticipantsHook,
    getParticipantsHook,
    getCorporateWalletHook,
    getTotalSupplyHook,
    creditTransactions,
    creditGranteds,
    creditSponsors,
    creditSponsorBalance,
    allParticipants,
    participants,
    corporateWallet,
    totalSupply,
  }
}

export default useCreditHook
