import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetCompanyWalletResponse } from "./types/getCompanyWalletType";

const getCompanyWallet = async (): Promise<
  AxiosResponse<GetCompanyWalletResponse>
> => {
  const request = await $axios().get("/participant_wallets");
  return request;
};

export default getCompanyWallet;
