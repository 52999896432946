import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  WalletCredit,
  TotalSupplyCredit,
  InvoiceWithHolder,
  MonthlyGuarantee,
} from "types/TokenManagement"

export interface TokenManagementState {
  stableCoinBalance: string
  stableCoinHolderList: WalletCredit[]
  totalSupplyCredit: TotalSupplyCredit
  allWalletWithCreditBalance: WalletCredit[]
  invoiceWithHolder: InvoiceWithHolder[],
  monthlyGuarantee: MonthlyGuarantee
}

export const initialTokenManagementState: TokenManagementState = {
  stableCoinBalance: "",
  stableCoinHolderList: [],
  totalSupplyCredit: {} as TotalSupplyCredit,
  allWalletWithCreditBalance: [],
  invoiceWithHolder: [],
  monthlyGuarantee: {} as MonthlyGuarantee
}

const tokenManagementSlice = createSlice({
  name: "tokenManagement",
  initialState: initialTokenManagementState,
  reducers: {
    setStableCoinBalance: (state, action: PayloadAction<string>) => {
      state.stableCoinBalance = action.payload
    },
    setStableCoinHolderList: (state, action: PayloadAction<WalletCredit[]>) => {
      state.stableCoinHolderList = action.payload
    },
    setTotalSupplyCredit: (state, action: PayloadAction<TotalSupplyCredit>) => {
      state.totalSupplyCredit = action.payload
    },
    setAllWalletWithCreditBalance: (
      state,
      action: PayloadAction<WalletCredit[]>
    ) => {
      state.allWalletWithCreditBalance = action.payload
    },
    setInvoiceWithHolder: (
      state,
      action: PayloadAction<InvoiceWithHolder[]>
    ) => {
      state.invoiceWithHolder = action.payload
    },
    setMonthlyGuarantee: (
      state,
      action: PayloadAction<MonthlyGuarantee>
    ) => {
      state.monthlyGuarantee = action.payload
    },
  },
})

export const tokenManagementReducer = tokenManagementSlice.reducer
export const tokenManagementActions = tokenManagementSlice.actions
