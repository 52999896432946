// ** React **
import { FC, useRef, ChangeEvent } from "react"

// ** Libs **
import { Flex, Box, Center } from "@chakra-ui/react"

import {
  allowOnlyNumbersAndDecimals,
  roundupDecimal,
} from "common/utils/transform"

// ** Icons **
import { IncreaseIcon, DecreaseIcon } from "assets/icons"

type AmountInputProps = {
  value: string
  step?: number
  current?: number
  onChange: (val: string) => void
}

const PercentInput: FC<AmountInputProps> = (props) => {
  const { step = 0.01, value, current, onChange } = props
  const min = 0
  const max = 100
  const inputRef = useRef<HTMLInputElement>(null)

  const decrement = () => {
    if (Number(value) <= min || Number(value) < step) return
    onChange((Number(value) - step).toFixed(2))
  }

  const increment = () => {
    if (max && Number(value) >= max) return
    onChange((Number(value) + step).toFixed(2))
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (Number(value) >= max) {
      onChange((max - 0.01).toFixed(2))
    } else {
      onChange(roundupDecimal(allowOnlyNumbersAndDecimals(event.target.value)))
    }
  }

  let final = parseFloat(value).toFixed(2)
  if (current) {
    final = (parseFloat(value) - current).toFixed(2)
  }

  return (
    <>
      <Flex justify="space-evenly" alignItems="center" mb={4}>
        <Box onClick={decrement} className="decrease-amount" mr={4}>
          <DecreaseIcon
            color={
              Number(value) <= min || Number(value) < step ? "grey400" : "red"
            }
          />
        </Box>
        <input
          className="input-amount"
          ref={inputRef}
          onChange={handleOnChange}
          value={value}
        />
        <Box onClick={increment} className="increase-amount" ml={4}>
          <IncreaseIcon
            color={max && Number(value) >= max ? "grey400" : "completed"}
          />
        </Box>
      </Flex>
      <Center
        fontSize="small"
        color={parseFloat(final) >= 0 ? "green" : "red"}
        fontWeight="bold"
      >
        {current ? `${final} %` : ""}
      </Center>
    </>
  )
}

export default PercentInput
