// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Flex,
  Text,
  Heading,
  Grid,
  GridItem,
  Spacer,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import PrimaryButton from "common/components/PrimaryButton"
import SuccessBadge from "common/components/SuccessBadge"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { HistoryItem } from "types/Guarantee"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateTimeStamp,
  formatDateDD_MMMM_YYYY,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
} from "common/utils/transform"

type HistoryDetailModalProps = {
  data: HistoryItem
} & ModalProps

const HistoryDetailModal: FC<HistoryDetailModalProps> = (props) => {
  const { isOpen, onClose, data } = props

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Box mb={4} textAlign="center">
            <Heading fontSize="24px" color="#353535">
              Payment Guarantee Detail
            </Heading>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4} mb={4}>
            <GridItemPopupDetail title="Doc Type" value="Invoice" />
            <GridItemPopupDetail title="Doc No." value={data.doc_number} />
            <GridItemPopupDetail
              title="Buyer Juristic id"
              value={data.buyer_juristic_id}
            />
            <GridItemPopupDetail
              title="Buyer Name"
              value={capitalizeFirstLetter(data.buyer_company_name)}
            />
            <GridItemPopupDetail
              title="Seller Juristic id"
              value={data.seller_juristic_id}
            />
            <GridItemPopupDetail
              title="Seller Name"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Doc Date"
              value={formatDateDD_MMMM_YYYY(data.doc_date)}
            />
            <GridItemPopupDetail
              title="Amount. (include VAT)"
              value={numberFormatForCurrency(data.amount)}
            />
            <GridItemPopupDetail title="Currency" value={data.currency} />
            <GridItemPopupDetail
              title="Schedule Payment Date"
              value={formatDateDD_MMMM_YYYY(data.payment_date)}
            />
            <GridItemPopupDetail
              title="Payment Guarantee Status"
              value={data.offer_option === 3 ? "Yes" : "No"}
            />
            <GridItemPopupDetail
              title="Owner"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Offering Start Date"
              value={formatDateDD_MMMM_YYYY(data.offer_start_date)}
            />
            <GridItemPopupDetail
              title="Offering End Date"
              value={formatDateDD_MMMM_YYYY(data.offer_end_date)}
            />
            <GridItemPopupDetail
              title="Option"
              value={invoiceOptionIdToString(data.offer_option)}
            />
            <GridItemPopupDetail
              title="Guarantee Fee"
              value={numberFormatForCurrency(data.guarantee_fee_rate) + " %"}
            />
            <GridItemPopupDetail
              title="Timestamp"
              value={formatDateTimeStamp(data.created_at)}
            />
            <GridItemPopupDetail
              title="Overdue"
              value={(data.overdue_day ?? "-") + " days"}
            />
            <GridItem
              w="100%"
              display="inline-flex"
              borderBottom="1px solid #E1F1FF"
              p={3}
            >
              <Box>
                <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                  Status
                </Text>
              </Box>
              <Spacer />
              <Box>
                <Text fontSize="sm" fontWeight={700}>
                  <SuccessBadge label={data.status} />
                </Text>
              </Box>
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(1, 1fr)" gap={2} mt={4} mb={4}>
            <GridItem
              w="100%"
              display="inline-flex"
              borderBottom="1px solid #E1F1FF"
              p={3}
            >
              <Box>
                <Text fontSize="md" color="#6A727A" fontWeight={700}>
                  Financial Amount (NEW)
                </Text>
              </Box>
              <Spacer />
              <Box>
                <Text fontSize="md" color="#212529" fontWeight={700}>
                  {numberFormatForCurrency(`${parseFloat(data.amount)}`)}
                </Text>
              </Box>
            </GridItem>
          </Grid>
          <Flex justify="center" gap={4}>
            <PrimaryButton variant="outline" onClick={onClose} w={250}>
              Close
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default HistoryDetailModal
