import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Alert } from "../../types/Alert";

export interface AppState {
  loader: boolean;
  alert: Alert;
}

export const initialAppState: AppState = {
  loader: false,
  alert: {
    visible: false,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    showLoader: (state) => {
      state.loader = true;
    },
    setAlert: (state, action: PayloadAction<Alert>) => {
      state.alert = action.payload;
    },
    hideLoader: (state) => {
      state.loader = false;
    },
  },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
