// ** Libs **
import { Box, Text, Flex, Image } from "@chakra-ui/react"

// ** Icons **
import { CalendarIcon, CreditCardIcon, CalendarDueIcon } from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

const BuyerDashboard = ({ setTabIndex }: { setTabIndex: any }) => {
  const { getBuyerDashboardHook, buyerDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initBuyerDashboard",
    queryFn: getBuyerDashboardHook,
  })

  if (isLoading) return <></>

  const { total_loan_option2, total_loan_option3, due, due_today } =
    buyerDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={4}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(1)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total Account Payable</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option2.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <CreditCardIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option2.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(1)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total amount of loan (Guaranteed Discounting)</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option3.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/coins-guarantee.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Payment Guarantee Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option3.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(2)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Due this Month</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${due.num}`)}
            </Text>
          </Box>
          <Box w="70px" m={4}>
            <CalendarIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${due.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => setTabIndex(2)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Due Today</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${due_today.num}`)}
            </Text>
          </Box>
          <Box w="84px" m={4}>
            <CalendarDueIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${due_today.amount}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default BuyerDashboard
