// ** React **
import { useState } from "react";

// ** Libs **
import { Box } from "@chakra-ui/react";
import TabCustom from "common/components/TabCustom";

// ** Components **
import { CreditTab, StableCoinTab, NFTsTab } from "./index";

const TransactionTab = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Box pt={4}>
      <Box w={375}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Credit",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Stable Coin",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
            {
              label: "NFTs",
              icon: null,
              activeIcon: null,
              active: tabIndex === 2,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"} pt={8}>
        <CreditTab />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"} pt={8}>
        <StableCoinTab />
      </Box>
      <Box display={tabIndex === 2 ? "block" : "none"} pt={8}>
        <NFTsTab />
      </Box>
    </Box>
  );
};

export default TransactionTab;
