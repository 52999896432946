import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Invoice,
  InvoiceListed,
  InvoiceListing,
  InvoiceOfferingHistory,
  AcceptedGuaranteedInvoice,
  AcceptedGuaranteedBuyer,
  AcceptedInvoiceOfOffererAndGuarantee,
  AcceptedOfferAndGuaranteeSeller,
} from "../../types/Invoice";

export interface InvoiceState {
  myInvoices: Invoice[];
  listedInvoices: InvoiceListed[];
  invoicesHistory: InvoiceListing[];
  offerHistory: InvoiceOfferingHistory[];
  acceptedGuaranteedInvoices: AcceptedGuaranteedInvoice[];
  acceptedGuaranteedBuyers: AcceptedGuaranteedBuyer[];
  acceptedInvoicesOfferer: AcceptedGuaranteedInvoice[];
  acceptedInvoicesOffererPtId: AcceptedGuaranteedInvoice[];
  acceptedOfferBuyer: AcceptedGuaranteedBuyer[];
  acceptedInvoicesOfOffererAndGuarantee: AcceptedInvoiceOfOffererAndGuarantee[];
  acceptedOfferAndGuaranteeSeller: AcceptedOfferAndGuaranteeSeller[];
}

export const initialInvoiceState: InvoiceState = {
  myInvoices: [],
  listedInvoices: [],
  invoicesHistory: [],
  offerHistory: [],
  acceptedGuaranteedInvoices: [],
  acceptedGuaranteedBuyers: [],
  acceptedInvoicesOfferer: [],
  acceptedInvoicesOffererPtId: [],
  acceptedOfferBuyer: [],
  acceptedInvoicesOfOffererAndGuarantee: [],
  acceptedOfferAndGuaranteeSeller: [],
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialInvoiceState,
  reducers: {
    setMyInvoices: (state, action: PayloadAction<Invoice[]>) => {
      state.myInvoices = action.payload;
    },
    setListedInvoices: (state, action: PayloadAction<InvoiceListed[]>) => {
      state.listedInvoices = action.payload;
    },
    setInvoicesHistory: (state, action: PayloadAction<InvoiceListing[]>) => {
      state.invoicesHistory = action.payload;
    },
    setOfferHistory: (
      state,
      action: PayloadAction<InvoiceOfferingHistory[]>
    ) => {
      state.offerHistory = action.payload;
    },
    setAcceptedGuaranteedInvoices: (state, action: PayloadAction<AcceptedGuaranteedInvoice[]>) => {
      state.acceptedGuaranteedInvoices = action.payload;
    },
    setAcceptedGuaranteedBuyers: (state, action: PayloadAction<AcceptedGuaranteedBuyer[]>) => {
      state.acceptedGuaranteedBuyers = action.payload;
    },
    setAcceptedInvoicesOfferer: (state, action: PayloadAction<AcceptedGuaranteedInvoice[]>) => {
      state.acceptedInvoicesOfferer = action.payload;
    },
    setAcceptedInvoicesOffererPtId: (state, action: PayloadAction<AcceptedGuaranteedInvoice[]>) => {
      state.acceptedInvoicesOffererPtId = action.payload;
    },
    setAcceptedOfferBuyer: (state, action: PayloadAction<AcceptedGuaranteedBuyer[]>) => {
      state.acceptedOfferBuyer = action.payload;
    },
    setAcceptedInvoicesOfOffererAndGuarantee: (state, action: PayloadAction<AcceptedInvoiceOfOffererAndGuarantee[]>) => {
      state.acceptedInvoicesOfOffererAndGuarantee = action.payload;
    },
    setAcceptedOfferAndGuaranteeSeller: (state, action: PayloadAction<AcceptedOfferAndGuaranteeSeller[]>) => {
      state.acceptedOfferAndGuaranteeSeller = action.payload;
    },
  },
});

export const invoiceReducer = invoiceSlice.reducer;
export const invoiceActions = invoiceSlice.actions;
