import { Flex, Spinner } from "@chakra-ui/react";
import useAppHook from "../../hooks/useAppHook";

const AppLoader = () => {
  const { loader } = useAppHook();

  if (!loader) return <></>;
  
  return (
    <Flex
      h={"100vh"}
      w={"100vw"}
      position="fixed"
      align="center"
      justify="center"
      zIndex={10000}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary"
        size="xl"
      />
    </Flex>
  );
};

export default AppLoader;
