// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Flex,
  Heading,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import PrimaryButton from "common/components/PrimaryButton"
import SuccessBadge from "common/components/SuccessBadge"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { TransactionInvoice } from "types/MyWallet"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  formatDateTimeStamp,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
  exportToCSV,
  downloadCSV,
} from "common/utils/transform"

type TransactionInvoiceDetailModalProps = {
  data: TransactionInvoice
} & ModalProps

const TransactionInvoiceDetailModal: FC<TransactionInvoiceDetailModalProps> = (
  props
) => {
  const { isOpen, onClose, data } = props

  const handleExport = () => {
    const content = exportToCSV([data], [])
    downloadCSV(content, `transaction_invoice_${data.id}.csv`)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Box mb={4} textAlign="center">
            <Heading fontSize="24px" color="#353535">
              Transaction Detail
            </Heading>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4} mb={4}>
            <GridItemPopupDetail title="Doctype" value="Invoice" />
            <GridItemPopupDetail title="Doc No." value={data.doc_number} />
            <GridItemPopupDetail
              title="Wallet From"
              value={`${capitalizeFirstLetter(
                data.from_wallet_nickname
              )} (${abbreviateHexString(data.from_wallet_address)})`}
            />
            <GridItemPopupDetail
              title="Company Name From"
              value={capitalizeFirstLetter(data.from_company_name)}
            />
            <GridItemPopupDetail
              title="Wallet to"
              value={`${capitalizeFirstLetter(
                data.to_wallet_nickname
              )} (${abbreviateHexString(data.to_wallet_address)})`}
            />
            <GridItemPopupDetail
              title="Company Name To"
              value={capitalizeFirstLetter(data.to_company_name)}
            />
            <GridItemPopupDetail
              title="Amount"
              value={numberFormatForCurrency(`${data.amount}`)}
            />
            <GridItemPopupDetail title="Currency" value="NEW" />
            <GridItemPopupDetail
              title="TimeStamp"
              value={formatDateTimeStamp(data.updated_at)}
            />
            <GridItem
              w="100%"
              display="inline-flex"
              borderBottom="1px solid #E1F1FF"
              p={3}
              justifyContent="space-between"
            >
              <Box>
                <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                  Status
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" fontWeight={700} ml={1}>
                  <SuccessBadge label={data.status} />
                </Text>
              </Box>
            </GridItem>
            <GridItemPopupDetail
              title="Option"
              value={invoiceOptionIdToString(data.option)}
            />
            <GridItemPopupDetail
              title="Action"
              value={capitalizeFirstLetter(data.action)}
            />
          </Grid>
          <Flex justify="center" gap={4}>
            <PrimaryButton variant="outline" onClick={() => onClose()} w={250}>
              Back
            </PrimaryButton>
            <PrimaryButton
              colorScheme="primary"
              backgroundColor="primary"
              color="white"
              onClick={handleExport}
              w={250}
            >
              Export
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TransactionInvoiceDetailModal
