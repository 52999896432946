import { Box, HStack, Heading } from "@chakra-ui/react"

const SuccessBadge = ({
  label,
  color = "green",
}: {
  label?: string
  color?: string
}) => {
  switch (label) {
    case "doing":
      color = "orange"
      break

    case "wait":
      color = "orange"
      break

    case "failed":
      color = "red"
      break

    case "inactive":
      color = "red"
      break

    case "unactivated":
      color = "red"
      break

    case "freeze":
      color = "red"
      break

    case "suspend":
      color = "red"
      break

    case "closing date":
      color = "orange"
      break

    case "overdue":
      color = "red"
      break

    case "default":
      color = "red"
      break

    case "pending":
      color = "primary"
      break

    case "cancelled":
      color = "orange"
      break

    case "rejected":
      color = "orange"
      break

    case "offered":
      color = "primary"
      break

    case "minting":
      color = "grey3"
      break
    
    case "listing":
      color = "grey3"
      break

    case "offering":
      color = "grey3"
      break

    default:
      color = "green"
      break
  }
  return (
    <HStack gap={2}>
      <Box w={3} h={3} borderRadius={12} bgColor={color}></Box>
      <Box
        css={{
          "&:first-letter": {
            textTransform: "uppercase",
          },
        }}
      >
        <Heading size="xs" color={color}>
          {label ?? "Success"}
        </Heading>
      </Box>
    </HStack>
  )
}

export default SuccessBadge
