// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import {
  Box,
  Text,
  HStack,
  Heading,
  Link,
  useDisclosure,
} from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import SuccessBadge from "common/components/SuccessBadge"
import PrimaryButton from "common/components/PrimaryButton"
import { TableSortType } from "common/components/Table/ListDataTable"
import { ClientDetail } from "modules/client_management"
import { CreateParticipantAdminModal } from "modules/user_wallet_management"

// ** Constants **
import { CLientManagementBody } from "constants/Enum"

// ** Types **
import { CompanyInfo } from "types/Participant"

// ** Icons **
import { PlusIcon } from "common/components/Icon"

const UserWalletManagement = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])

  const [selectCompany, setSelectCompany] = useState<CompanyInfo>()
  const [contentBody, setContentBody] = useState<CLientManagementBody>(
    CLientManagementBody.LIST
  )

  const createParticipantModalAction = useDisclosure()
  const {
    getParticipantsHook,
    getParticipantTypesHook,
    getParticipantRolesHook,
    participants,
  } = useParticipantHook()

  const { isLoading } = useQuery({
    queryKey: "initParticipantList",
    queryFn: getParticipantsHook,
  })

  const { isLoading: isLoadingTypes } = useQuery({
    queryKey: "initParticipantTypes",
    queryFn: getParticipantTypesHook,
  })

  const { isLoading: isLoadingRoles } = useQuery({
    queryKey: "initParticipantRoles",
    queryFn: getParticipantRolesHook,
  })

  useEffect(() => {
    if (participants.length > 0) {
      setData(participants)
    }
  }, [participants])

  const column = [
    {
      title: "Company name",
      value: "company_name",
      sortType: TableSortType.STRING,
    },
    {
      title: "Type",
      value: "type_name",
      sortType: TableSortType.STRING,
    },
    {
      title: "Total Wallet",
      value: "total_wallet",
      sortType: TableSortType.INTEGER,
    },
    {
      title: "Total Wallet (Freeze)",
      value: "total_wallet_freeze",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box key={key} color={value.total_wallet_freeze > 0 ? "red" : ""}>
          {value.total_wallet_freeze}
        </Box>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "",
      value: "id",
      customRender: (key: string, value: any) => (
        <Heading
          size="xs"
          key={key}
          color="primary"
          onClick={() => onRowClick(value)}
        >
          <Link color="teal.500">Manage</Link>
        </Heading>
      ),
    },
  ]

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  const onRowClick = (data: CompanyInfo) => {
    setSelectCompany(data)
    setContentBody(CLientManagementBody.DETAIL)
  }

  useEffect(() => {
    const filterValue = participants.filter((m) =>
      (m.company_name + m.juristic_id)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, participants])

  if (isLoading || isLoadingTypes || isLoadingRoles) return <></>

  return (
    <Box p={6}>
      <Box
        display={contentBody === CLientManagementBody.LIST ? "block" : "none"}
      >
        <ContainerTable
          headerTable="Participant list"
          column={column}
          data={displayData}
          rawData={data}
          currentPage={currentPage}
          pageSize={pageSize}
          onViewSizeChange={(size) => {
            setPageSize(size)
            setCurrentPage(1)
          }}
          onPageChange={(page: number) => setCurrentPage(page)}
          totalCount={data.length}
          searchPlaceholder="Corporate Name"
          onSearchChange={onSearchChange}
          canExport={false}
          actions={[
            <PrimaryButton
              size="lg"
              bgColor="#1B3BE1"
              colorScheme={"#1B3BE1"}
              onClick={() => createParticipantModalAction.onOpen()}
            >
              <HStack gap={2}>
                <PlusIcon />
                <Text fontSize="sm" color="white">
                  Create Participant
                </Text>
              </HStack>
            </PrimaryButton>,
          ]}
        />
      </Box>
      <Box
        display={contentBody === CLientManagementBody.DETAIL ? "block" : "none"}
      >
        {selectCompany && (
          <ClientDetail
            setContentBody={setContentBody}
            selectCompany={selectCompany}
          />
        )}
        <CreateParticipantAdminModal {...createParticipantModalAction} />
      </Box>
    </Box>
  )
}

export default UserWalletManagement
