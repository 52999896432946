import axios from "axios"

type ErrorResponse = {
  statusCode: number
  message: string
  error: string
}

const handleError = (err: unknown): ErrorResponse => {
  // Check if the error is an AxiosError
  if (axios.isAxiosError(err)) {
    // Handle AxiosError with a response (e.g., status code not 200)
    if (err.response) {
      // Assuming err.response.data is of type any. Use a more specific type if you know the structure.
      const responseData = err.response.data as any // or as ResponseData if you defined it
      return {
        statusCode: err.response.status,
        message: responseData.message || "An error occurred",
        error: err.response.statusText,
      }
    }

    // Handle AxiosError without a response (network error, etc.)
    return {
      statusCode: 500,
      message: err.message,
      error: "Network Error",
    }
  }

  // Handle non-Axios errors
  return {
    statusCode: 500,
    message: "An unknown error occurred",
    error: "Unknown Error",
  }
}

export {
  handleError
}
