import { Box, Stack, Text } from "@chakra-ui/react"

type InformationValueProps = {
  label: string
  value: string
  html?: string
}

const InformationValue: React.FC<InformationValueProps> = (props) => {
  return (
    <Stack gap={0}>
      <Box>
        <Text color="grey3" fontSize="sm">
          {props.label}
        </Text>
      </Box>
      <Box px={5} py={3}>
        <Text color="valueText" fontSize="md">
          {props.value}
        </Text>
        {props.html && <div dangerouslySetInnerHTML={{ __html: props.html }} />}
      </Box>
    </Stack>
  )
}
export default InformationValue
