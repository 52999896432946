import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { appReducer, AppState } from "./slice/AppSlice";
import { authReducer, AuthState } from "./slice/AuthSlice";
import { invoiceReducer, InvoiceState } from "./slice/InvoiceSlice";
import { walletReducer, WalletState } from "./slice/WalletSlice";
import { creditReducer, CreditState } from "./slice/CreditSlice";
import { arReducer, ArState } from "./slice/ArSlice";
import { participantReducer, ParticipantState } from "./slice/ParticipantSlice";
import { adminReducer, AdminState } from "./slice/AdminSlice";
import { campaignReducer, CampaignState } from "./slice/CampaignSlice";
import { dashboardReducer, DashboardState } from "./slice/DashboardSlice";
import { discountingReducer, DiscountingState } from "./slice/DiscountingSlice";
import { guaranteeReducer, GuaranteeState } from "./slice/GuaranteeSlice";
import { arManagementReducer, ARManagementState } from "./slice/ARManagementSlice";
import { tokenManagementReducer, TokenManagementState } from "./slice/TokenManagementSlice";
import { transactionReducer, TransactionState } from "./slice/TransactionSlice"
export interface RootState {
  app: AppState;
  auth: AuthState;
  wallet: WalletState;
  invoice: InvoiceState;
  credit: CreditState;
  ar: ArState;
  participant: ParticipantState;
  admin: AdminState;
  campaign: CampaignState;
  dashboard: DashboardState;
  discounting: DiscountingState;
  guarantee: GuaranteeState;
  armanagement: ARManagementState;
  tokenManagement: TokenManagementState;
  transaction: TransactionState;
}

const rootReducer: Reducer<RootState> = combineReducers({
  app: appReducer,
  auth: authReducer,
  wallet: walletReducer,
  invoice: invoiceReducer,
  credit: creditReducer,
  ar: arReducer,
  participant: participantReducer,
  admin: adminReducer,
  campaign: campaignReducer,
  dashboard: dashboardReducer,
  discounting: discountingReducer,
  guarantee: guaranteeReducer,
  armanagement: arManagementReducer,
  tokenManagement: tokenManagementReducer,
  transaction: transactionReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
