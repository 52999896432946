import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetCampaignsResponse,
  GetCampaignResponse,
  PostCreateCampaignResponse,
  PutUpdateCampaignResponse,
  CampaignResponse,
  GetAnnounceResponse,
  UpdateViewCountResponse,
} from "./types/campaign"
import { CampaignBody } from "types/Campaign"

const getCampaigns = async (): Promise<AxiosResponse<GetCampaignsResponse>> => {
  const request = await $axios().get("/news")
  return request
}

const getCampaign = async (
  id: number
): Promise<AxiosResponse<GetCampaignResponse>> => {
  const request = await $axios().get("/news/" + id)
  return request
}

const createCampaign = async (
  body: CampaignBody
): Promise<AxiosResponse<PostCreateCampaignResponse>> => {
  const { title, detail, date, cover, logo, is_highlight, owner_user_id } = body
  let data = new FormData()
  data.append("title", title)
  data.append("detail", detail)
  data.append("date", date)
  data.append("cover", cover)
  data.append("logo", logo)
  data.append("is_highlight", `${is_highlight}`)
  data.append("owner_user_id", `${owner_user_id}`)

  const request = await $axios().post("/news", data)
  return request
}

const updateCampaign = async (
  id: number,
  body: CampaignBody
): Promise<AxiosResponse<PutUpdateCampaignResponse>> => {
  const { title, detail, date, cover, logo, is_highlight, owner_user_id } = body
  let data = new FormData()
  data.append("title", title)
  data.append("detail", detail)
  data.append("date", date)
  data.append("cover", cover)
  data.append("logo", logo)
  data.append("is_highlight", `${is_highlight}`)
  data.append("owner_user_id", `${owner_user_id}`)

  const request = await $axios().put("/news/" + id, data)
  return request
}

const setHighlight = async (
  id: number,
  body: CampaignBody
): Promise<AxiosResponse<PutUpdateCampaignResponse>> => {
  const { is_highlight } = body
  let data = new FormData()
  data.append("is_highlight", `${is_highlight}`)

  const request = await $axios().put("/news/" + id, data)
  return request
}

const deleteCampaign = async (
  id: number
): Promise<AxiosResponse<CampaignResponse>> => {
  const request = await $axios().delete("/news/" + id)
  return request
}

const postActivateCampaign = async (
  id: number
): Promise<AxiosResponse<CampaignResponse>> => {
  const request = await $axios().post("/news/activate/" + id)
  return request
}

const postDeactivateCampaign = async (
  id: number
): Promise<AxiosResponse<CampaignResponse>> => {
  const request = await $axios().post("/news/deactivate/" + id)
  return request
}

const getAnnounces = async (): Promise<AxiosResponse<GetAnnounceResponse>> => {
  const request = await $axios().get("/announce")
  return request
}

const updateViewCount = async (
  id: number
): Promise<AxiosResponse<UpdateViewCountResponse>> => {
  const request = await $axios().patch("/news/view/" + id)
  return request
}

export {
  getCampaigns,
  getCampaign,
  createCampaign,
  updateCampaign,
  setHighlight,
  deleteCampaign,
  postActivateCampaign,
  postDeactivateCampaign,
  getAnnounces,
  updateViewCount,
}
