import { AxiosResponse } from "axios";
import $axios from "./instance";
import ApiResponse from "./types/ApiResponse";
import { PostMakeOfferBody } from "./types/postMakeOfferType";

const postMakeOffer = async (
  body: PostMakeOfferBody
): Promise<AxiosResponse<ApiResponse>> => {
  const response = await $axios().post("/make_offer", body);
  return response;
};

export default postMakeOffer;
