import { AxiosResponse } from "axios";
import $axios from "./instance";
import {
    PostTransferWalletBody,
    PostTransferWalletResponse,
} from "./types/postTransferWalletType";

const postTransferWallet = async (
  body: PostTransferWalletBody
): Promise<AxiosResponse<PostTransferWalletResponse>> => {
  const request = await $axios().post("/transfer", body);
  return request;
};

export default postTransferWallet;
