import { HStack, Heading, Text } from "@chakra-ui/react";
import { FC } from "react";

type HStackDetailProps = {
  title: string;
  value: string;
};

const HStackDetail: FC<HStackDetailProps> = ({ title, value }) => {
  return (
    <HStack>
      <Text color="#353535" fontSize="sm">
        {title} :
      </Text>
      <Heading size="xs" color="#353535">
        {value}
      </Heading>
    </HStack>
  );
};

export default HStackDetail;
