// ** React **
import { useEffect } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"

// ** Types **
import { CompanyInfo } from "types/Participant"

// ** Components **
import BackButton from "common/components/BackButton"
import {
  ParticipantDetail,
  ParticipantWallet,
  ParticipantUser,
} from "modules/client_management"

// ** Constants **
import { CLientManagementBody } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useParticipantHook from "hooks/useParticipantHook"

const ClientDetail = ({
  setContentBody,
  selectCompany,
}: {
  setContentBody: any
  selectCompany: CompanyInfo
}) => {
  const { getParticipantDetailHook, participantDetail } = useParticipantHook()

  const { isLoading } = useQuery({
    queryKey: "initClientDetail",
    queryFn: () => getParticipantDetailHook(selectCompany.id),
  })

  useEffect(() => {
    if (
      participantDetail !== null &&
      participantDetail.pt_id !== selectCompany.id
    ) {
      getParticipantDetailHook(selectCompany.id)
    }
  }, [selectCompany, getParticipantDetailHook, participantDetail])

  if (isLoading) return <></>

  return (
    <Box p={6}>
      <Box mb={6}>
        <BackButton
          customClick={() => setContentBody(CLientManagementBody.LIST)}
        />
      </Box>
      <ParticipantDetail />
      <ParticipantWallet />
      <ParticipantUser />
    </Box>
  )
}

export default ClientDetail
