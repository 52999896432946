import { Box, Flex, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useQuery } from "../../../hooks/useQueryHook";
import useWalletHook from "../../../hooks/useWalletHook";
import { MyWallet } from "../../../types/MyWallet";
import { abbreviateHexString } from "../../utils/transform";
import AmountInput from "../AmountInput";
import {
  BankIcon,
  CheckedIcon,
  PeopleIcon,
  WalletCreditCardIcon,
} from "../Icon";
import PrimaryButton from "../PrimaryButton";
import { WithdrawInputValue } from "./WithdrawModalContainer";

type WithdrawInputProps = {
  currentWallet: MyWallet;
  balance?: string;
  withdrawValue?: WithdrawInputValue;
  onClickBack: VoidFunction;
  onClickWithdraw: (val: WithdrawInputValue) => void;
};

const WithdrawInput: FC<WithdrawInputProps> = (props) => {
  const {
    currentWallet,
    balance,
    withdrawValue,
    onClickWithdraw,
    onClickBack,
  } = props;
  const { getAllWalletHook } = useWalletHook();
  const { data } = useQuery({ queryFn: () => getAllWalletHook() });
  const [amount, setAmount] = useState<string>(withdrawValue?.amount || "0.00");

  if (!data) return <></>;

  return (
    <Flex direction="column" justify="center" align="center" gap={4}>
      <Box>
        <Heading size="md">Withdraw</Heading>
      </Box>
      {/* From */}
      <Box w="100%">
        <Stack gap={2}>
          <Box>
            <Heading size="sm">From</Heading>
          </Box>
          <Box
            w={"100%"}
            border="1px solid #D8D8D8"
            borderRadius={8}
            px={4}
            py={2}
          >
            <Flex align="center" gap={3}>
              <Box>
                <CheckedIcon />
              </Box>
              <Box>
                <WalletCreditCardIcon boxSize={6} color="#818181" />
              </Box>
              <Box>
                <Text
                  textAlign="left"
                  color="#818181"
                  fontSize="small"
                  fontWeight="bold"
                >
                  {currentWallet?.company_name} - {currentWallet.nickname}{" "}(
                  {abbreviateHexString(currentWallet.wallet_address)})
                </Text>
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
      {/* To */}
      <Box w="100%">
        <Stack gap={2}>
          <Box>
            <Heading size="sm">To</Heading>
          </Box>
          <Box
            w={"100%"}
            border="1px solid #D8D8D8"
            borderRadius={8}
            px={4}
            py={2}
          >
            <Flex align="center" gap={3}>
              <Box>
                <CheckedIcon />
              </Box>
              <Box>
                <PeopleIcon color="primary" />
              </Box>
              <Box>
                <Text
                  textAlign="left"
                  color="primary"
                  fontSize="small"
                  fontWeight="bold"
                >
                  {currentWallet.bank_name}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            w={"100%"}
            border="1px solid #D8D8D8"
            borderRadius={8}
            px={4}
            py={2}
          >
            <Flex align="center" gap={3}>
              <Box>
                <CheckedIcon />
              </Box>
              <Box>
                <BankIcon color="primary" />
              </Box>
              <Box>
                <Text
                  textAlign="left"
                  color="primary"
                  fontSize="small"
                  fontWeight="bold"
                >
                  {currentWallet.bank_account_number}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
      {/* input */}
      <Box w="100%" mt={4}>
        <Stack gap={4}>
          <Box>
            <Heading size="sm">Withdraw Amount</Heading>
          </Box>
          <Box>
            <AmountInput
              value={amount}
              max={Number.parseFloat(balance!)}
              onChange={(val: string) => setAmount(val)}
              unit="NEW"
            />
          </Box>
        </Stack>
      </Box>
      {/* Button */}
      <Box w="100%" mt={6}>
        <HStack>
          <PrimaryButton variant="outline" onClick={onClickBack}>
            Back
          </PrimaryButton>
          <PrimaryButton
            colorScheme={parseFloat(amount) > 0 ? "#0050C8" : "grey400"}
            backgroundColor={parseFloat(amount) > 0 ? "#0050C8" : "grey400"}
            color={parseFloat(amount) > 0 ? "white" : "black"}
            onClick={() => {
              if (parseFloat(amount) > 0) {
                onClickWithdraw({
                  amount: amount,
                })
              }
            }}
          >
            Withdraw
          </PrimaryButton>
        </HStack>
      </Box>
    </Flex>
  );
};

export default WithdrawInput;
