// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { HistoryDetailModal } from "modules/guarantee_management"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useGuaranteeHook from "hooks/useGuaranteeHook"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateDD_MMMM_YYYY,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Types **
import { HistoryItem } from "types/Guarantee"

const History = () => {
  const detailModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<HistoryItem[]>([])
  const [selected, setSelected] = useState<HistoryItem>()

  const { getAcceptedGuaranteedForSponsorHook, histories } = useGuaranteeHook()

  const { isLoading } = useQuery({
    queryKey: "initGuaranteeHistory",
    queryFn: getAcceptedGuaranteedForSponsorHook,
  })

  useEffect(() => {
    setData(histories)
  }, [histories])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: HistoryItem[] = [...histories]

    temp.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )

    const filterValue: HistoryItem[] = temp.filter((m) =>
      (m.buyer_company_name + m.buyer_juristic_id)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, histories])

  if (isLoading) return <></>

  const column = [
    {
      title: "DocNo.",
      value: "doc_number",
      sortType: TableSortType.STRING,
    },
    {
      title: "DocType",
      value: "doc_type",
      customRender: (key: string, value: any) => <Text>Invoice</Text>,
    },
    {
      title: "Trading Partner Name",
      value: "buyer_company_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.buyer_company_name)}</Text>
      ),
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.seller_company_name)}</Text>
      ),
    },
    {
      title: "Schedule payment Date",
      value: "payment_date",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => (
        <Text>{formatDateDD_MMMM_YYYY(value.payment_date)}</Text>
      ),
    },
    {
      title: "Option",
      value: "offer_option",
      sortType: TableSortType.STRING,
      w: "210px",
      customRender: (key: string, value: any) => (
        <Text>{invoiceOptionIdToString(value.offer_option)}</Text>
      ),
    },
    {
      title: "Guarantee Fee Rate",
      value: "guarantee_fee_rate",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text align="right">
          {numberFormatForCurrency(value.guarantee_fee_rate) + " %"}
        </Text>
      ),
    },
    {
      title: "Amount (NEW)",
      value: "amount",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text align="right">{numberFormatForCurrency(value.amount)}</Text>
      ),
    },
    {
      title: "Overdue",
      value: "overdue_day",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text align="right">{value.overdue_day} days</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text align="right">
          <Text className="hidden">{value.offer_status}</Text>
          <SuccessBadge label={value.offer_status} />
        </Text>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Link
            key={key}
            onClick={() => {
              setSelected(value)
              detailModalAction.onOpen()
            }}
          >
            View
          </Link>
        )
      },
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        searchPlaceholder="Debtor Name/ Tax ID"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      {selected && (
        <HistoryDetailModal {...detailModalAction} data={selected} />
      )}
    </Box>
  )
}

export default History
