export const HOLDER_TYPE = ["Seller", "Buyer", "Finance", "Sponsor"];
export const TIME_FRAME = [
  "All Time",
  "24 Hr.",
  "1 Day",
  "3 Days",
  "1 Week",
  "2 Weeks",
  "1 Month",
  "3 Months",
];
export const TRX_TYPE = ["Top-up", "Withdraw"];
export const TRX_CREDIT_TYPE = ["Top-up Credit", "Credit Adjustment"];
export const TRX_AGAINST_INVOICE = ["Transfer", "LG"];
export const TRX_BIDDING_OFFER = ["Show All", "Hide"];
export const TRX_CREDIT_MINT = ["View All", "Top-up Credit", "Credit Adjustment"];
export const TRX_ALERT = ["Closing Date", "Overdue"];
export const CAMPAIGN_FILTER = ["View All", "Highlight"];
export const CREDIT_TYPE = ["Credit Type 1", "Credit Type 2", "Credit Type 3"];
export const TRANSACTION_VIEW = ["Credit", "NEW"];
export const TRANSACTION_CURRENCY = ["NEW", "THB", "TCGCT", "KTBCT", "TTBCT"];
export const TRANSACTION_TOKEN = ["Top-up", "Transfer", "Withdraw", "Received"];
export const TRANSACTION_OPTION_BUYER = ["View All", "Buyer Prepayment", "Buyer Guarantee"];
export const TRANSACTION_OPTION_FI = ["All Option", "Financing", "Buyer Guarantee"];
export const REVENUE_STATUS = ["All Status", "Success", "Default"];
export const TRANSACTION_INVOICE = ["Transfer", "Offer", "Received"];
export const TRANSACTION_INVOICE_SPONSOR = ["Transfer", "LG"];
export const TRANSACTION_INVOICE_FI = ["Transfer", "Received", "Offer"];
export const TRANSACTION_HOLDER = ["All Holders", "Corporate", "Finance", "Sponsor"];
export const TRANSACTION_TOKEN_LIST = ["All Token", "Credit", "Stable Coin", "NFT"];
export const AR_FILTER_OPTION = ["Normal", "Closing Date", "Default"];
export const WALLET_STATUS = ["All Status", "Active", "Frezze", "Inactive", "Suspend"];
export const SORT = ["Ascending", "Descending"];
export const ACTIVITY_TYPE = [
  "Create Wallet",
  "Create User",
  "Activate Collection",
  "Deactivate Collection",
];
