// ** React **
import { useEffect } from "react"

// ** Libs **
import { Box, Card, Center, Flex, Image, Spacer, Text } from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import BackButton from "common/components/BackButton"
import PrimaryButton from "common/components/PrimaryButton"
import TextAreaInput from "common/components/TextAreaInput"
import TextInput from "common/components/TextInput"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { ParticipantProfileBody } from "types/Participant"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { EMAIL_REGEX, PHONE_REGEX } from "constants/AppStrings"

const EditCompanyProfilePage = () => {
  const { setAlert } = useAppHook()
  const {
    getParticipantProfileHook,
    putParticipantProfileHook,
    postUploadCompanyImageHook,
    participantProfile,
  } = useParticipantHook()

  const { isLoading } = useQuery({
    queryKey: "initCompanyProfile",
    queryFn: getParticipantProfileHook,
  })

  const { control, handleSubmit, setError, reset } =
    useForm<ParticipantProfileBody>({
      mode: "onChange",
    })

  useEffect(() => {
    reset({
      address1: participantProfile.address1 ?? "",
      address2: participantProfile.address2 ?? "",
      email: participantProfile.email ?? "",
      tel_number2: participantProfile.tel_number2 ?? "",
      tel_number3: participantProfile.tel_number3
        ? participantProfile.tel_number3 === " "
          ? ""
          : participantProfile.tel_number3
        : "",
      description: participantProfile.description ?? "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantProfile])

  if (isLoading) return <></>

  const onSubmit: SubmitHandler<ParticipantProfileBody> = async (data) => {
    if (
      data.tel_number3 !== "" &&
      data.tel_number3 !== " " &&
      data.tel_number3 !== null &&
      !RegExp(PHONE_REGEX).test(data.tel_number3)
    ) {
      setError("tel_number3", {
        message: "Please enter valid phone number",
      })
    } else {
      if (data.tel_number2 === "") {
        data.tel_number2 = " "
      }
      if (data.tel_number3 === "") {
        data.tel_number3 = " "
      }
      const response = await putParticipantProfileHook(data)
      let alert: Alert = { visible: true }
      if (response.statusCode === 200) {
        alert.data = {
          type: AlertType.SUCCESS,
          description: "Update Company Profile Complete",
          button: {
            label: "Close",
          },
        }
      } else {
        alert.data = {
          type: AlertType.ERROR,
          description: response.message,
          button: {
            label: "Close",
          },
        }
      }
      setAlert(alert)
    }
  }

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]

      if (!file.type.startsWith("image/")) {
        alert("Please select an image file.")
        return
      }

      postUploadCompanyImageHook("logo", file)
    }
  }

  const handleBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]

      if (!file.type.startsWith("image/")) {
        alert("Please select an image file.")
        return
      }

      postUploadCompanyImageHook("banner", file)
    }
  }

  const onClickEditPhoto = () => {
    const node = document.getElementById("logo")
    node?.click()
  }

  const onClickEditBanner = () => {
    const node = document.getElementById("banner")
    node?.click()
  }

  return (
    <Card pt={2} pb={16}>
      <Flex direction="column" gap={8}>
        <Box pt={6} pl={6}>
          <BackButton />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex>
            <Box flex="0 0 275px">
              <Center>
                {participantProfile?.logo ? (
                  <Image
                    borderRadius="30px"
                    objectFit="contain"
                    boxSize="225px"
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/" +
                      participantProfile.logo
                    }
                  />
                ) : (
                  <Box
                    w={225}
                    h={225}
                    bgColor="grey400"
                    borderRadius={40}
                  ></Box>
                )}
              </Center>
              <Center mt={2}>
                <Text
                  fontWeight="semibold"
                  color="valueText"
                  fontSize="sm"
                  onClick={onClickEditPhoto}
                  cursor="pointer"
                >
                  Edit Photo
                </Text>
                <input
                  type="file"
                  onChange={handleLogoChange}
                  hidden
                  id="logo"
                />
              </Center>
            </Box>
            <Box flex="1 1 auto" px={8}>
              <Flex direction="column" gap={3}>
                <Box>
                  <Center>
                    {participantProfile?.banner ? (
                      <Image
                        w={"100%"}
                        height={"100%"}
                        minH="225px"
                        objectFit="cover"
                        src={
                          process.env.REACT_APP_BASE_URL +
                          "/" +
                          participantProfile.banner
                        }
                      />
                    ) : (
                      <Box w="100%" h={225} bgColor="grey400"></Box>
                    )}
                  </Center>
                  <Center mt={2}>
                    <Text
                      fontWeight="semibold"
                      color="valueText"
                      fontSize="sm"
                      onClick={onClickEditBanner}
                      cursor="pointer"
                    >
                      Edit Banner
                    </Text>
                    <input
                      type="file"
                      onChange={handleBannerChange}
                      hidden
                      id="banner"
                    />
                  </Center>
                </Box>
                <Box>
                  <TextInput
                    label="Company Name"
                    value={participantProfile.company_name}
                    labelSmall
                    isDisabled
                  />
                </Box>
                <Box>
                  <TextInput
                    label="Juristic ID"
                    value={participantProfile.juristic_id}
                    labelSmall
                    isDisabled
                  />
                </Box>
                <Box>
                  <TextInput
                    label="Type"
                    value={participantProfile.type}
                    labelSmall
                    isDisabled
                  />
                </Box>
                <Controller
                  control={control}
                  name="address1"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Address 1"
                      placeholder="Enter your Address Line 1"
                      error={error}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="address2"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Address 2"
                      placeholder="Enter your Address Line 2"
                      error={error}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      id="email"
                      type="email"
                      label="Email"
                      placeholder="Enter your email"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter your email",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(EMAIL_REGEX).test(v) ||
                        "The system supports only english lengauge and @ is required EX. NITMX123@gmail.com",
                    },
                  }}
                />
                <Flex gap={3}>
                  <Controller
                    control={control}
                    name="tel_number2"
                    render={({ field, fieldState: { error } }) => (
                      <TextInput
                        {...field}
                        id="tel_number2"
                        type="tel"
                        label="Tel. Number 1"
                        placeholder="Enter your Tel. Number 1"
                        error={error}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: "Enter your Tel. Number 1",
                      },
                      validate: {
                        format: (v: string) =>
                          RegExp(PHONE_REGEX).test(v) ||
                          "Please enter valid phone number",
                      },
                    }}
                  />
                  <Controller
                    control={control}
                    name="tel_number3"
                    render={({ field, fieldState: { error } }) => (
                      <TextInput
                        {...field}
                        id="tel_number3"
                        type="tel"
                        label="Tel. Number 2"
                        placeholder="Enter your Tel. Number 2"
                        error={error}
                      />
                    )}
                  />
                </Flex>
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState: { error } }) => (
                    <TextAreaInput
                      {...field}
                      label="Description"
                      labelSmall
                      placeholder="Enter your Description"
                      error={error}
                    />
                  )}
                />
                <Flex mt={8}>
                  <Spacer />
                  <PrimaryButton
                    size="md"
                    w="300px"
                    variant="outline"
                    type="submit"
                  >
                    Save Change
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </form>
      </Flex>
    </Card>
  )
}

export default EditCompanyProfilePage
