import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetAcceptedOffAndGuaranteeSellerResponse } from "./types/getAcceptedOfferAndGuaranteeSellerType";

const getAcceptedOfferAndGuaranteeSeller = async (): Promise<AxiosResponse<GetAcceptedOffAndGuaranteeSellerResponse>> => {
  const request = await $axios().get("/get_accepted_offer_and_guarantee_seller");
  return request;
};

export default getAcceptedOfferAndGuaranteeSeller;
