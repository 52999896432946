import { Center, Flex, Grid, Heading } from "@chakra-ui/react"
import { FC } from "react"
import { Invoice } from "../../../types/Invoice"
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "../../utils/transform"
import GridItemPopupDetail from "../GridItemPopupDetail"
import PrimaryButton from "../PrimaryButton"

type ListInvoicePreviewProps = {
  invoice: Invoice
  onClickClose: VoidFunction
  onClickConfirm: () => void
}

const ListInvoicePreview: FC<ListInvoicePreviewProps> = (props) => {
  const { onClickClose, onClickConfirm, invoice } = props

  return (
    <Flex direction="column" gap={8}>
      <Center>
        <Heading size="md">Invoice detail</Heading>
      </Center>
      <Grid templateColumns="repeat(2,1fr)" gap={5}>
        <GridItemPopupDetail title="Docstype." value="Invoice" />
        <GridItemPopupDetail title="Doc No." value={invoice.doc_number} />
        <GridItemPopupDetail
          title="Buyer Juristic ID."
          value={invoice.buyer_juristic_id}
        />
        <GridItemPopupDetail
          title="Buyer Name"
          value={capitalizeFirstLetter(invoice.buyer_name)}
        />
        <GridItemPopupDetail
          title="Seller Juristic ID."
          value={invoice.seller_juristic_id}
        />
        <GridItemPopupDetail
          title="Seller Name"
          value={capitalizeFirstLetter(invoice.seller_name)}
        />
        <GridItemPopupDetail
          title="Doc Date."
          value={formatDateDD_MMMM_YYYY(invoice.doc_date)}
        />
        <GridItemPopupDetail
          title="Amount"
          value={numberFormatForCurrency(invoice.amount.toString())}
        />
        <GridItemPopupDetail title="Currency" value={invoice.currency} />
        <GridItemPopupDetail
          title="Payment Date"
          value={formatDateDD_MMMM_YYYY(invoice.payment_date)}
        />
      </Grid>
      <GridItemPopupDetail
        title="Financial Amount (NEW)"
        value={numberFormatForCurrency(invoice.amount.toString())}
      />
      <Center>
        <PrimaryButton
          maxWidth="450px"
          colorScheme="primary"
          backgroundColor="primary"
          color="white"
          onClick={onClickConfirm}
        >
          Listing Invoice
        </PrimaryButton>
      </Center>
      <Center>
        <PrimaryButton
          maxWidth="300px"
          variant="outline"
          onClick={onClickClose}
        >
          Close
        </PrimaryButton>
      </Center>
    </Flex>
  )
}

export default ListInvoicePreview
