// ** React **
import { useState } from "react"

// ** Libs **
import { Card, Box } from "@chakra-ui/react"

// ** Components **
import { ClientList, ClientDetail } from "modules/client_management"

// ** Constants **
import { CLientManagementBody } from "constants/Enum"

// ** Types **
import { CompanyInfo } from "types/Participant"

const ClientManagementPage = () => {
  const [selectCompany, setSelectCompany] = useState<CompanyInfo>()
  const [contentBody, setContentBody] = useState<CLientManagementBody>(
    CLientManagementBody.LIST
  )

  return (
    <Card p={6}>
      <Box
        display={contentBody === CLientManagementBody.LIST ? "block" : "none"}
      >
        <ClientList
          setContentBody={setContentBody}
          setSelectCompany={setSelectCompany}
        />
      </Box>
      <Box
        display={contentBody === CLientManagementBody.DETAIL ? "block" : "none"}
      >
        {selectCompany && (
          <ClientDetail
            setContentBody={setContentBody}
            selectCompany={selectCompany}
          />
        )}
      </Box>
    </Card>
  )
}

export default ClientManagementPage
