// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Text, Flex, Image } from "@chakra-ui/react"

// ** Icons **
import {
  CoinBalanceIcon,
  WaittingAuctionIcon,
  OfferIcon,
  CalendarIcon,
  RevenueIcon,
  CreditCardEditIcon,
} from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

// ** Router **
import {
  AR_MANAGEMENT_PAGE_PATH,
  MY_WALLET_PAGE_PATH,
  BIDDING_AND_OFFERING_PAGE_PATH,
  REVENUE_PAGE_PATH,
} from "router/AppPath"

const FIDashboard = () => {
  const navigate = useNavigate()
  const { getFIDashboardHook, fiDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initFIDashboard",
    queryFn: getFIDashboardHook,
  })

  if (isLoading) return <></>

  const {
    total_loan_option1,
    total_loan_option3,
    stable_coin,
    bidding,
    offering,
    due,
    revenue,
  } = fiDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={4}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AR_MANAGEMENT_PAGE_PATH,
            search: "?tab=2",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total amount of loan (Financing)</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option1?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/receive-amount.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (THB)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option1?.amount?.toFixed()}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AR_MANAGEMENT_PAGE_PATH,
            search: "?tab=2",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total amount of loan (Guaranteee Discounting)</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${total_loan_option3?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/coins-guarantee.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (New)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${total_loan_option3?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(MY_WALLET_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Stable Coin</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Stable Coin (Hold)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${stable_coin?.hold?.toFixed(2)}`)}
            </Text>
          </Box>
          <Box w="83px" m={4}>
            <CoinBalanceIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Stable Coin (New)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${stable_coin?.balance?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(BIDDING_AND_OFFERING_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>New Bidding</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${bidding?.num}`)}
            </Text>
          </Box>
          <Box w="92px" m={4}>
            <WaittingAuctionIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Bidding Options</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${bidding?.activity}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(BIDDING_AND_OFFERING_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Offering</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${offering?.num}`)}
            </Text>
            <Text fontSize={24}>Total Offers</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${offering?.activity}`)}
            </Text>
          </Box>
          <Box w="83px" m={4}>
            <OfferIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${offering?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AR_MANAGEMENT_PAGE_PATH,
            search: "?tab=2",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Due this Month</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${due?.num}`)}
            </Text>
          </Box>
          <Box w="65px" m={4}>
            <CalendarIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${due?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: AR_MANAGEMENT_PAGE_PATH,
            search: "?tab=2",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Default</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormat(`${fiDashboard?.default?.num}`)}
            </Text>
          </Box>
          <Box w="83px" m={4}>
            <CreditCardEditIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${fiDashboard?.default?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(REVENUE_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Revenue</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px"></Box>
          <Box w="83px" m={4}>
            <RevenueIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Annual Revenue (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${revenue?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default FIDashboard
