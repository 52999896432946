import { AxiosResponse } from "axios"
import $axios from "./instance"
import { PostActivatePasswordBody } from "./types/postLoginType"

const postActivatePassword = async (
  body: PostActivatePasswordBody
): Promise<AxiosResponse> => {
  const request = await $axios().post("/activate_user", body)
  return request
}

export default postActivatePassword
