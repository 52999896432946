// ** React **
import { useEffect } from "react"

// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import CardContent from "common/components/CardContent"
import PasswordInput from "common/components/PasswordInput"
import PrimaryButton from "common/components/PrimaryButton"

// ** Routers **
import { AUTHENTICATE_LOGIN_PAGE_PATH } from "router/AppPath"

// ** Types **
import { PostActivatePasswordBody } from "api/types/postLoginType"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import useQueryString from "hooks/useQueryString"
import useAppHook from "hooks/useAppHook"
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import packageEnv from "../../../package.json"

const ActivatePage = () => {
  const token = useQueryString("token")

  const navigate = useNavigate()
  const { setAlert } = useAppHook()
  const { postActivatePasswordHook } = useAuthHook()

  const { control, watch, handleSubmit, setValue, reset } =
    useForm<PostActivatePasswordBody>({
      defaultValues: {
        activation_token: "",
        password: "",
        confirm_password: "",
      },
      mode: "onChange",
    })

  useEffect(() => {
    if (token !== "") {
      setValue("activation_token", `${token}`)
    }
  }, [token, setValue])

  const password = watch("password")

  const onSubmit: SubmitHandler<PostActivatePasswordBody> = async (data) => {
    const response = await postActivatePasswordHook(data)
    let alert: Alert = {
      visible: true,
    }
    if (response?.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Activate account successfully.",
        button: {
          label: "Back to login",
          action: () => {
            navigate(AUTHENTICATE_LOGIN_PAGE_PATH)
          },
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
    reset()
  }

  return (
    <div className="reset-password-page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Flex direction="column" gap={2}>
            <Box>
              <Heading size="md" textAlign="center">
                Activate Account
              </Heading>
            </Box>
            <Box mt={8}>
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <PasswordInput
                    {...field}
                    id="password"
                    type="password"
                    label="Password"
                    placeholder="Enter your password"
                    error={error}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter your password",
                  },
                }}
              />
            </Box>

            <Box>
              <Controller
                control={control}
                name="confirm_password"
                render={({ field, fieldState: { error } }) => (
                  <PasswordInput
                    {...field}
                    id="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    placeholder="Enter your confirm password"
                    error={error}
                    password={password}
                  />
                )}
                rules={{
                  validate: {
                    match: (v: string) => {
                      return v === password || "Passwords missmatch!"
                    },
                  },
                }}
              />
            </Box>

            <Box>
              <PrimaryButton
                colorScheme={"primary"}
                backgroundColor={"primary"}
                color={"white"}
                type="submit"
                w="100%"
              >
                Activate Account
              </PrimaryButton>
            </Box>
          </Flex>
        </CardContent>
        <Box mt={4} mb={4} color="white" textAlign="center">
          Version {packageEnv.version}
        </Box>
      </form>
    </div>
  )
}

export default ActivatePage
