import { Grid, GridItem } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DrawerBar from "../common/components/DrawerBar";
import NavBar from "../common/components/NavBar";
import useAuthHook from "../hooks/useAuthHook";
import { useQuery } from "../hooks/useQueryHook";
import { AUTHENTICATE_LOGIN_PAGE_PATH } from "../router/AppPath";

const ProtectedRoute: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bodyRef = useRef<HTMLDivElement>(null);
  const { user, getUserHook } = useAuthHook();

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  const scrollToTop = () => {
    if (bodyRef.current) {
      const firstChild = bodyRef.current.firstChild as HTMLDivElement;
      if (firstChild) {
        firstChild.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const redirectLogin = useCallback(() => {
    navigate(AUTHENTICATE_LOGIN_PAGE_PATH);
  }, [navigate]);

  const initApplication = useCallback(async () => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      redirectLogin();
      return;
    }

    const response = await getUserHook();
    if (!response) {
      redirectLogin();
      return;
    }
  }, [getUserHook, redirectLogin]);

  useQuery({
    queryKey: "initApplication",
    queryFn: initApplication,
  });

  if (!user) return <></>;

  return (
    <Grid bgColor="background" gridTemplateColumns={"16rem 1fr"} h="100%">
      <GridItem>
        <DrawerBar user={user} />
      </GridItem>
      <GridItem>
        <Grid gridTemplateRows={"6rem 1fr"} h={"100%"}>
          <GridItem bgColor="white">
            <NavBar user={user} />
          </GridItem>
          <GridItem
            ref={bodyRef}
            my={2}
            mx={8}
            h={"calc(100vh - 7rem)"}
            overflow="scroll"
          >
            <Outlet />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default ProtectedRoute;
