// ** React **
import { useState } from "react"

// ** Libs **
import { Card, Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import {
  AdminManagement,
  UserWalletManagement,
} from "modules/user_wallet_management"

const AccountManagementPage = () => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Card p={6}>
      <Box w={450}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Admin",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "User",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <AdminManagement />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <UserWalletManagement />
      </Box>
    </Card>
  )
}
export default AccountManagementPage
