import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetCreditGrantedResponse } from "./types/getCreditGrantedType";

const getCreditGranted = async (): Promise<
  AxiosResponse<GetCreditGrantedResponse>
> => {
  const response = await $axios().get("/credit/granted");
  return response;
};

export default getCreditGranted;
