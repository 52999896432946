// ** Libs **
import { Box, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"
import {
  ParticipantWalletItem,
  ParticipantCreateWalletModal,
  ParticipantCreateWalletModalForAdmin,
} from "modules/client_management"

// ** Hooks **
import useParticipantHook from "hooks/useParticipantHook"
import useAuthHook from "hooks/useAuthHook"

const ParticipantWallet = () => {
  const participantCreateWalletModalAction = useDisclosure()
  const { participantDetail } = useParticipantHook()

  const { getRole } = useAuthHook()

  let wallets = participantDetail?.wallets
    ? [...participantDetail?.wallets]
    : []

  if (wallets && wallets.length > 0) {
    wallets = wallets.sort((a, b) => b.id - a.id)
  }

  return (
    <Box mb={4}>
      <PrimaryButton
        border="2px solid"
        borderColor="primary"
        variant="outline"
        colorScheme="primary"
        color="primary"
        onClick={() => participantCreateWalletModalAction.onOpen()}
        w="140px"
      >
        Add Wallet
      </PrimaryButton>
      {wallets.map((wallet) => (
        <ParticipantWalletItem data={wallet} />
      ))}
      {participantDetail?.wallets.length === 0 && (
        <Box
          border="1px solid"
          borderRadius={6}
          borderColor="emptyColor"
          p={6}
          px={8}
          my={4}
        >
          <Text align="center">No found wallet</Text>
        </Box>
      )}
      {getRole() === null ? (
        <ParticipantCreateWalletModalForAdmin {...participantCreateWalletModalAction} />
      ) : (
        <ParticipantCreateWalletModal {...participantCreateWalletModalAction} />
      )}
    </Box>
  )
}

export default ParticipantWallet
