// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Flex, Box } from "@chakra-ui/react"

// ** Components **
import WalletSelect from "common/components/WalletSelect"
import BalanceBox from "common/components/BalanceBox"
import { HistoryBadge } from "common/components/Icon"
import {
  TransactionList,
  TransactionInvoiceList,
} from "modules/user_wallet_management"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"

// ** Types **
import { MyWallet } from "types/MyWallet"

const StableCoinContainer = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const { getCompanyWalletHook, myWallet } = useWalletHook()
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()

  const { isLoading } = useQuery({
    queryKey: "myWalletPageInit",
    queryFn: getCompanyWalletHook,
  })

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoading || !selectedWallet) return <></>

  return (
    <Box>
      <Box boxShadow="none" p={6} mb={6}>
        <Flex justifyContent="flex-end">
          <Box w={300}>
            <WalletSelect
              wallets={myWallet}
              onChange={(value) => setSelectedWallet(value)}
              value={selectedWallet}
            />
          </Box>
        </Flex>
        <BalanceBox unit="NEW" wallet={selectedWallet} />
      </Box>
      <Flex gap={6}>
        <Box onClick={() => setTabIndex(0)} cursor="pointer">
          <HistoryBadge
            label="Historical Wallet Transaction"
            color={tabIndex === 0 ? "primary" : "grey400"}
          />
        </Box>
        <Box onClick={() => setTabIndex(1)} cursor="pointer">
          <HistoryBadge
            label="Historical Against Invoice Transaction"
            color={tabIndex === 1 ? "primary" : "grey400"}
          />
        </Box>
      </Flex>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <TransactionList wallet={selectedWallet} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <TransactionInvoiceList wallet={selectedWallet} />
      </Box>
    </Box>
  )
}

export default StableCoinContainer
