import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { DropdownIcon } from "./Icon";

export type DropdownOptProps<T> = {
  title: string;
  value: T;
};

export type DropdownProps<T> = {
  options: DropdownOptProps<T>[];
  onChange: (value: T) => void;
  value: T;
};

const Dropdown = <T,>(props: DropdownProps<T>) => {
  const { options, onChange, value } = props;
  const [selected, setSelected] = useState<T>(value);

  const optSelected = useMemo(() => {
    return options.find((m) => m.value === selected);
  }, [options, selected]);

  const handleOnSelect = useCallback(
    (val: T) => {
      setSelected(val);
      onChange(val);
    },
    [onChange]
  );

  return (
    <Menu>
      <MenuButton
        size="sm"
        variant="outline"
        minW="110px"
        as={Button}
        rightIcon={<DropdownIcon />}
        borderRadius={4}
        borderColor="#CCC"
      >
        <Text color="textColor" fontWeight="bold">
          {optSelected?.title ?? ""}
        </Text>
      </MenuButton>
      <MenuList>
        {options.map((opt, i) => (
          <MenuItem key={"opt_" + i} onClick={() => handleOnSelect(opt.value)}>
            <Text color="textColor" fontWeight="bold" fontSize="sm">
              {opt.title}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
