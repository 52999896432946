import { Box, Flex, IconProps, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export type DrawerItemProps = {
  route: string;
  active: boolean;
  icon: React.FC<IconProps>;
  title: string;
  navTitle: string;
};

const DrawerItem: FC<DrawerItemProps> = (props) => {
  const navigate = useNavigate();
  const { route, active, icon, title, navTitle } = props;
  const Icon = icon;

  return (
    <Flex
      align="center"
      onClick={() =>
        navigate(route, {
          state: {
            navTitle: navTitle,
          },
        })
      }
      gap={2}
      cursor="pointer"
      p={active ? 4 : 0}
      marginLeft={active ? -4 : 0}
      marginRight={active ? -4 : 0}
      bgColor={active ? "white" : "primary"}
      borderRadius={8}
    >
      <Box lineHeight={1}>
        <Icon color={active ? "primary" : "white"} />
      </Box>
      <Box pt={0.5}>
        <Text
          lineHeight={1}
          fontSize="sm"
          fontWeight={active ? "bold" : "normal"}
          color={active ? "primary" : "white"}
        >
          {title}
        </Text>
      </Box>
    </Flex>
  );
};

export default DrawerItem;
