import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

type PrimaryButtonProps = {} & ButtonProps;

const PrimaryButton: FC<PrimaryButtonProps> = (props) => {
  return (
    <Button
      className="primary-button"
      _disabled={{
        background: "#D8D8D8",
        color: "white",
        border: "none",
      }}
      border={props.variant === "outline" ? "1px solid #6A727A;" : props.border}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
