import { AxiosResponse } from "axios";
import $axios from "./instance";
import ApiResponse from "./types/ApiResponse";
import { PostCancelInvoiceBody } from "./types/postCancelInvoiceType";

const postCancelInvoice = async (
  body: PostCancelInvoiceBody
): Promise<AxiosResponse<ApiResponse>> => {
  const response = await $axios().post("/cancel_invoice", body);
  return response;
};

export default postCancelInvoice;
