import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetUserResponse,
  PutUpdateUserProfileResponse,
  PostChangePasswordResponse,
} from "./types/getUserType"
import { UpdateUserProfileBody, ChangePasswordBody } from "types/User"
import type { ResponseWithOutData } from "api/types/ApiResponse"

const getUser = async (): Promise<AxiosResponse<GetUserResponse>> => {
  const request = await $axios().get("/user")
  return request
}

const updateUser = async (
  data: UpdateUserProfileBody
): Promise<AxiosResponse<PutUpdateUserProfileResponse>> => {
  const request = await $axios().put("/user", data)
  return request
}

const changePassword = async (
  data: ChangePasswordBody
): Promise<AxiosResponse<PostChangePasswordResponse>> => {
  const request = await $axios().post("/change_password", data)
  return request
}

const uploadAvatar = async (
  type: string,
  file: File
): Promise<AxiosResponse<ResponseWithOutData>> => {
  let data = new FormData()
  data.append("type", type)
  data.append("file", file)

  const request = await $axios().post("/user_profile/image", data)
  return request
}

export default getUser

export { updateUser, changePassword, uploadAvatar }
