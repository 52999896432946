import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { CompanyInfo, CompanyDetail, UserType, UserRole } from "types/Participant"

export interface ParticipantState {
  participants: CompanyInfo[]
  participantProfile: CompanyInfo
  participantTypes: UserType[]
  participantRoles: UserRole[]
  participantDetail: CompanyDetail | null
}

export const initialParticipantState: ParticipantState = {
  participants: [],
  participantProfile: {} as CompanyInfo,
  participantTypes: [],
  participantRoles: [],
  participantDetail: null,
}

const participantSlice = createSlice({
  name: "participant",
  initialState: initialParticipantState,
  reducers: {
    setParticipants: (state, action: PayloadAction<CompanyInfo[]>) => {
      state.participants = action.payload
    },
    setParticipantProfile: (state, action: PayloadAction<CompanyInfo>) => {
      state.participantProfile = action.payload
    },
    setParticipantTypes: (state, action: PayloadAction<UserType[]>) => {
      state.participantTypes = action.payload
    },
    setParticipantRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.participantRoles = action.payload
    },
    setParticipantDetail: (state, action: PayloadAction<CompanyDetail>) => {
      state.participantDetail = action.payload
    },
  },
})

export const participantReducer = participantSlice.reducer
export const participantActions = participantSlice.actions
