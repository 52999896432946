// ** React **
import { useState } from "react"

// ** Libs **
import { Flex, Box } from "@chakra-ui/react"

// ** Components **
import { HistoryBadge } from "common/components/Icon"
import { CreditTransaction, CreditTransactionInvoice } from "./"

const CreditTab = () => {
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Box>
      <Flex gap={6}>
        <Box onClick={() => setTabIndex(0)} cursor="pointer">
          <HistoryBadge
            label="Historical Wallet Transaction"
            color={tabIndex === 0 ? "primary" : "grey400"}
          />
        </Box>
        <Box onClick={() => setTabIndex(1)} cursor="pointer">
          <HistoryBadge
            label="Historical Againts Invoice Transaction"
            color={tabIndex === 1 ? "primary" : "grey400"}
          />
        </Box>
      </Flex>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <CreditTransaction />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <CreditTransactionInvoice />
      </Box>
    </Box>
  )
}

export default CreditTab
