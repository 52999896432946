// ** React **
import { FC, useEffect, useState } from "react"

// ** Libs **
import {
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Box,
  Stack,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  HStack,
  Grid,
  GridItem,
} from "@chakra-ui/react"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { MyWallet } from "types/MyWallet"
import { ParticipantCreditItem } from "types/Credit"
import { PostMintCreditTokenBody } from "api/types/postMintCreditTokenType"
import { Alert, AlertType } from "types/Alert"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useAuthHook from "hooks/useAuthHook"
import useWalletHook from "hooks/useWalletHook"
import useAppHook from "hooks/useAppHook"
import useCreditHook from "hooks/useCreditHook"

// ** Icons **
import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  CheckedIcon,
  SelectNoneIcon,
  PeopleIcon,
  WalletCreditCardIcon,
} from "../Icon"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
} from "common/utils/transform"

// ** Components **
import AmountInput from "../AmountInput"
import PercentInput from "../PercentInput"
import PrimaryButton from "../PrimaryButton"
import ConfirmPassword from "../ConfirmPassword"

// ** Constants **
import { UserRole } from "constants/Enum"

enum MintModalBody {
  MINT,
  REVIEW,
  CONFIRM,
}

type MintCreditTokenModalContainerProps = {
  defaultSelect?: ParticipantCreditItem
  refetch: any
} & ModalProps

const MintCreditTokenModalContainer: FC<MintCreditTokenModalContainerProps> = (
  props
) => {
  const { isOpen, onClose, defaultSelect, refetch } = props

  const { user, getRole } = useAuthHook()
  const { postMintCreditTokenHook, myWallet } = useWalletHook()
  const { getCorporateWalletHook, corporateWallet } = useCreditHook()
  const { setAlert } = useAppHook()

  // ** States **
  const [selectCompany, setSelectCompany] = useState<string>()
  const [selectedFromWallet, setSelectedFromWallet] = useState<MyWallet>()
  const [selectedToWallet, setSelectedToWallet] = useState<
    MyWallet | undefined
  >()
  const [amount, setAmount] = useState<string>("0.00")
  const [rate, setRate] = useState<string>("0.00")
  const [modalBody, setModalBody] = useState<MintModalBody>(MintModalBody.MINT)

  useEffect(() => {
    if (defaultSelect) {
      setSelectCompany(defaultSelect.company_name)
      setSelectedToWallet(undefined)
    }
  }, [defaultSelect])

  useEffect(() => {
    if (
      defaultSelect &&
      corporateWallet.length > 0 &&
      UserRole.TCG === getRole() &&
      selectCompany
    ) {
      if (
        corporateWallet.filter((m) => m.company_name === selectCompany).length >
        0
      ) {
        setSelectedToWallet(
          corporateWallet.filter((m) => m.company_name === selectCompany)[0]
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelect, corporateWallet, selectCompany])

  const { isLoading: isLoadingCorporateWallet } = useQuery({
    queryKey: "initCorporateWallet",
    queryFn: getCorporateWalletHook,
  })

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedFromWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoadingCorporateWallet || !selectedFromWallet) return <></>

  const clearState = () => {
    setSelectedToWallet(undefined)
    setModalBody(MintModalBody.MINT)
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  const onClickMint = () => {
    if (selectedToWallet && amount !== "0.00" && rate !== "0.00") {
      setModalBody(MintModalBody.REVIEW)
    }
  }

  const onConfirmMint = async (password: string) => {
    if (selectedToWallet) {
      const body: PostMintCreditTokenBody = {
        from_wallet_id: selectedFromWallet.id,
        to_wallet_id: selectedToWallet.id,
        amount: Number.parseFloat(amount),
        fee_rate: Number.parseFloat(rate),
        password,
      }
      const response = await postMintCreditTokenHook(body)
      let alert: Alert = {
        visible: true,
      }
      if (response?.data.statusCode === 200) {
        onClose()
        clearState()
        alert.data = {
          type: AlertType.SUCCESS,
          description: `+${numberFormatForCurrency(
            amount
          )} Credit has been granted.`,
          button: {
            label: "Close",
          },
        }
      } else {
        alert.data = {
          type: AlertType.ERROR,
          description:
            "Already grant this wallet id please select other wallet id.",
          button: {
            label: "Close",
          },
        }
        setModalBody(MintModalBody.MINT)
      }
      setAlert(alert)
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        {/* Mint  Credit Token */}
        <Box display={modalBody === MintModalBody.MINT ? "block" : "none"}>
          <Flex
            direction="column"
            justify="center"
            align="center"
            gap={4}
            p={6}
          >
            <Box>
              <Heading size="md">Mint Credit Token</Heading>
            </Box>
            {/* From */}
            <Box w="100%">
              <Stack gap={2}>
                <Box>
                  <Heading size="sm">From</Heading>
                </Box>
                <Box w={"100%"}>
                  <Menu>
                    <MenuButton
                      size="sm"
                      variant="outline"
                      minW="110px"
                      w="100%"
                      h="64px"
                      as={Button}
                      rightIcon={
                        myWallet.length > 1 ? <ChevronDownIcon /> : undefined
                      }
                      borderRadius={8}
                      borderColor="#D8D8D8"
                      px={4}
                      py={2}
                    >
                      <Flex align="center" gap={3}>
                        <Box>
                          <CheckedIcon />
                        </Box>
                        <Box>
                          <WalletCreditCardIcon
                            boxSize={6}
                            color="secondaryTextColor"
                          />
                        </Box>
                        <Box>
                          <Text
                            textAlign="left"
                            fontSize="small"
                            color="secondaryTextColor"
                          >
                            Company name: {selectedFromWallet?.company_name}
                          </Text>
                          <Text
                            textAlign="left"
                            fontSize="small"
                            color="secondaryTextColor"
                          >
                            {`${selectedFromWallet?.company_name} -
                        ${
                          selectedFromWallet.nickname || ""
                        } (${abbreviateHexString(
                              selectedFromWallet.wallet_address
                            )})`}
                          </Text>
                        </Box>
                      </Flex>
                    </MenuButton>
                    {myWallet.length > 1 && (
                      <MenuList>
                        {myWallet
                          .filter((m) => m.id !== selectedFromWallet.id)
                          .map((m, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => setSelectedFromWallet(m)}
                            >
                              <Text fontSize="sm" color="#212529">
                                {m.company_name} - {m.nickname || ""} (
                                {abbreviateHexString(m.wallet_address)})
                              </Text>
                            </MenuItem>
                          ))}
                      </MenuList>
                    )}
                  </Menu>
                </Box>
              </Stack>
            </Box>
            {/* To */}
            <Box w="100%">
              <Stack gap={2}>
                <Box>
                  <Heading size="sm">To</Heading>
                </Box>
                <Box w={"100%"}>
                  <Menu>
                    <MenuButton
                      size="sm"
                      variant="outline"
                      minW="110px"
                      w="100%"
                      h="42px"
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      borderRadius={8}
                      borderColor="#D8D8D8"
                      px={4}
                      py={2}
                    >
                      <Flex align="center" gap={3}>
                        <Box>
                          {selectCompany ? <CheckedIcon /> : <SelectNoneIcon />}
                        </Box>
                        <Box>
                          <PeopleIcon boxSize={6} color="secondaryTextColor" />
                        </Box>
                        <Box>
                          <Text
                            color="primary"
                            fontSize="small"
                            fontWeight="bold"
                          >
                            Company {selectCompany}
                          </Text>
                        </Box>
                      </Flex>
                    </MenuButton>
                    <MenuList>
                      {corporateWallet
                        .filter((m) =>
                          UserRole.TCG === getRole()
                            ? true
                            : user
                            ? user.pt_id === m.wallet_created_pt_id
                            : true
                        )
                        .reduce<MyWallet[]>((accumulator, current) => {
                          if (
                            !accumulator.find(
                              (item) =>
                                item.company_name === current.company_name
                            )
                          ) {
                            accumulator.push(current)
                          }
                          return accumulator
                        }, [])
                        .filter((m) => m.company_name !== selectCompany)
                        .map((m, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              setSelectCompany(m.company_name)
                              setSelectedToWallet(undefined)
                            }}
                          >
                            <Text fontSize="sm" color="#212529">
                              {m.company_name}
                            </Text>
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                </Box>
                {selectCompany &&
                corporateWallet
                  .filter((m) => m.company_name === selectCompany)
                  .filter((m) =>
                    UserRole.TCG === getRole()
                      ? true
                      : user
                      ? user.pt_id === m.wallet_created_pt_id
                      : true
                  ).length > 0 ? (
                  <Box w={"100%"}>
                    <Menu>
                      <MenuButton
                        size="sm"
                        variant="outline"
                        minW="110px"
                        w="100%"
                        h="42px"
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        borderRadius={8}
                        borderColor="#D8D8D8"
                        px={4}
                        py={2}
                      >
                        <Flex align="center" gap={3}>
                          <Box>
                            {selectedToWallet ? (
                              <CheckedIcon />
                            ) : (
                              <SelectNoneIcon />
                            )}
                          </Box>
                          <Box>
                            <WalletCreditCardIcon
                              boxSize={6}
                              color="secondaryTextColor"
                            />
                          </Box>
                          <Box>
                            <Text
                              color="primary"
                              fontSize="small"
                              fontWeight="bold"
                            >
                              {selectCompany} -{" "}
                              {selectedToWallet &&
                                `${
                                  selectedToWallet.nickname
                                    ? selectedToWallet.nickname
                                    : ""
                                } (${abbreviateHexString(
                                  selectedToWallet.wallet_address
                                )})`}
                            </Text>
                          </Box>
                        </Flex>
                      </MenuButton>
                      <MenuList>
                        {corporateWallet
                          .filter((m) =>
                            selectedToWallet
                              ? m.id !== selectedToWallet.id
                              : true
                          )
                          .filter((m) => m.company_name === selectCompany)
                          .filter((m) =>
                            UserRole.TCG === getRole()
                              ? true
                              : user
                              ? user.pt_id === m.wallet_created_pt_id
                              : true
                          )
                          .map((m, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => setSelectedToWallet(m)}
                            >
                              <Text fontSize="sm" color="#212529">
                                {m.company_name} - {m.nickname || ""} (
                                {abbreviateHexString(m.wallet_address)})
                              </Text>
                            </MenuItem>
                          ))}
                      </MenuList>
                    </Menu>
                  </Box>
                ) : (
                  <Text align="center" fontWeight="bold" mt={2}>
                    No Wallet belong to this sponsor.
                  </Text>
                )}
              </Stack>
            </Box>
            {/* Credit Amount */}
            <Box w="100%" mt={4}>
              <Stack gap={4}>
                <Box>
                  <Heading size="sm">Credit Amount</Heading>
                </Box>
                <Box>
                  <AmountInput
                    value={amount}
                    step={1000000}
                    onChange={(val: string) => setAmount(val)}
                    unit={defaultSelect ? defaultSelect.credit_type : "TCGCT"}
                  />
                </Box>
              </Stack>
            </Box>
            {/* Guarantee Fee Rate */}
            <Box w="100%" mt={4}>
              <Stack gap={4}>
                <Box>
                  <Heading size="sm">Guarantee Fee Rate</Heading>
                </Box>
                <Box>
                  <PercentInput
                    value={rate}
                    step={0.01}
                    onChange={(val: string) => setRate(val)}
                  />
                </Box>
              </Stack>
            </Box>
            {/* Button */}
            <Box w="100%">
              <HStack>
                <PrimaryButton variant="outline" onClick={onClose}>
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor={
                    selectedToWallet && amount !== "0.00" && rate !== "0.00"
                      ? "completed"
                      : "disable"
                  }
                  border="secondaryTextColor"
                  color="white"
                  onClick={onClickMint}
                >
                  Mint
                </PrimaryButton>
              </HStack>
            </Box>
          </Flex>
        </Box>
        {/* Mint  Credit Token confirm */}
        <Box display={modalBody === MintModalBody.REVIEW ? "block" : "none"}>
          <Flex
            direction="column"
            justify="center"
            align="center"
            gap={4}
            p={6}
          >
            <Box>
              <Heading size="md">Mint Credit Token confirm</Heading>
            </Box>
            <Box w="100%">
              <Grid templateColumns="140px 1fr" gap={4}>
                {/* From */}
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  From:
                </GridItem>
                <GridItem></GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Bank Name
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  บรรษัทประกันสินเชื่ออุตสาหกรรมขนาดย่อม (บสย.)
                </GridItem>

                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Wallet Address
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedFromWallet.company_name} -{" "}
                  {selectedFromWallet.nickname || ""} (
                  {abbreviateHexString(selectedFromWallet.wallet_address)})
                </GridItem>
                {/* To */}
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  To:
                </GridItem>
                <GridItem></GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Company name
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedToWallet && selectedToWallet?.company_name}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Juristic ID
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedToWallet && selectedToWallet?.juristic_id}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Wallet Address
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {selectedToWallet &&
                    `${selectedToWallet.company_name} - ${
                      selectedToWallet.nickname || ""
                    } (${abbreviateHexString(
                      selectedToWallet.wallet_address
                    )})`}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Credit Amount
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {numberFormatForCurrency(amount)}{" "}
                  {defaultSelect ? defaultSelect.credit_type : "TCGCT"}
                </GridItem>
                <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                  Guarantee Fee Rate
                </GridItem>
                <GridItem
                  textAlign="right"
                  color="#353535"
                  fontSize="small"
                  fontWeight="700"
                >
                  {rate}%
                </GridItem>
              </Grid>
            </Box>
            {/* Button */}
            <Box w="100%">
              <HStack>
                <PrimaryButton
                  variant="outline"
                  onClick={() => setModalBody(MintModalBody.MINT)}
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor="completed"
                  border="secondaryTextColor"
                  color="white"
                  onClick={() => setModalBody(MintModalBody.CONFIRM)}
                >
                  Confirm
                </PrimaryButton>
              </HStack>
            </Box>
          </Flex>
        </Box>
        {/* Confirm */}
        {modalBody === MintModalBody.CONFIRM && (
          <ModalBody p={8}>
            <ConfirmPassword
              header="Confirm"
              onClickBack={() => setModalBody(MintModalBody.REVIEW)}
              onClickConfirm={(password) => onConfirmMint(password)}
            />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default MintCreditTokenModalContainer
