import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { FC, useState } from "react";
import { PostTopupWalletBody } from "../../../api/types/postTopupWalletType";
import useAppHook from "../../../hooks/useAppHook";
import useWalletHook from "../../../hooks/useWalletHook";
import { Alert, AlertType } from "../../../types/Alert";
import { ModalProps } from "../../../types/ModalProps";
import { MyWallet } from "../../../types/MyWallet";
import ConfirmPassword from "../ConfirmPassword";
import TopupInput from "./TopupInput";
import TopupReview from "./TopupReview";

export type TopupInputValue = {
  amount: string;
  destination: MyWallet;
};

enum TopupModalBody {
  TRANSFER,
  REVIEW,
  CONFIRM,
}

type TopupModalContainerProps = {
  currentWallet: MyWallet;
  balance?: string;
} & ModalProps;

const TopupModalContainer: FC<TopupModalContainerProps> = (props) => {
  const { isOpen, onClose, currentWallet, balance } = props;
  const [topupValue, setTopupValue] = useState<TopupInputValue>();
  const [modalBody, setModalBody] = useState<TopupModalBody>(
    TopupModalBody.TRANSFER
  );
  const { postTopupWalletHook, getTransactionTokenHook } = useWalletHook();
  const { setAlert } = useAppHook();

  const handleOnTopupBack = () => {
    onClose();
    clearState();
  };
  const handleOnTopupSubmit = (val: TopupInputValue) => {
    setTopupValue(val);
    setModalBody(TopupModalBody.REVIEW);
  };

  const handleReviewBack = () => {
    setModalBody(TopupModalBody.TRANSFER);
  };
  const handleOnReviewConfirm = () => {
    setModalBody(TopupModalBody.CONFIRM);
  };

  const handlePasswordBack = () => {
    setModalBody(TopupModalBody.REVIEW);
  };
  const handleOnPasswordConfirm = async (val: string) => {
    const body: PostTopupWalletBody = {
      from_wallet_id: currentWallet.id,
      to_wallet_id: topupValue?.destination.id!,
      amount: Number.parseFloat(topupValue?.amount!),
      password: val,
    };
    const response = await postTopupWalletHook(body);
    let alert: Alert = {
      visible: true,
    };
    if (response?.data.statusCode === 200) {
      onClose();
      clearState();
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your top-up was completed successfully.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
        },
      };
      getTransactionTokenHook();
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your top-up was failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      };
    }
    setAlert(alert);
  };

  const clearState = () => {
    setTopupValue(undefined);
    setModalBody(TopupModalBody.TRANSFER);
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          {modalBody === TopupModalBody.TRANSFER && (
            <TopupInput
              currentWallet={currentWallet}
              topupValue={topupValue}
              balance={balance || "0"}
              onClickBack={handleOnTopupBack}
              onClickTopup={handleOnTopupSubmit}
            />
          )}
          {modalBody === TopupModalBody.REVIEW && (
            <TopupReview
              currentWallet={currentWallet}
              topupValue={topupValue}
              unit={"NEW"}
              onClickConfirm={handleOnReviewConfirm}
              onClickBack={handleReviewBack}
            />
          )}
          {modalBody === TopupModalBody.CONFIRM && (
            <ConfirmPassword
              header="Confirm"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TopupModalContainer;
