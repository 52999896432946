import { AxiosResponse } from "axios"
import $axios from "./instance"
import { GetAlertResponse } from "./types/getAlert"

const getAlert = async (): Promise<AxiosResponse<GetAlertResponse>> => {
  const request = await $axios().get("/get_invoices_warning")
  return request
}

export default getAlert
