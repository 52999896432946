import { Box, GridItem, Spacer, Text } from "@chakra-ui/react";
import { FC } from "react";

type GridItemPopupDetailProps = {
  title: string;
  value: string;
};

const GridItemPopupDetail: FC<GridItemPopupDetailProps> = ({
  title,
  value,
}) => {
  return (
    <GridItem
      w="100%"
      display="inline-flex"
      borderBottom="1px solid #E1F1FF"
      p={3}
    >
      <Box>
        <Text fontSize="sm" color="#6A727A" fontWeight={700} whiteSpace="nowrap">
          {title}
        </Text>
      </Box>
      <Spacer />
      <Box>
        <Text fontSize="sm" fontWeight={700} textAlign="right">
          {value}
        </Text>
      </Box>
    </GridItem>
  );
};

export default GridItemPopupDetail;
