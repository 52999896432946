import { Icon, IconProps } from "@chakra-ui/react"

const EditIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 18 16" fill="none" {...props}>
    <g clip-path="url(#clip0_97_67003)">
      <path
        d="M12.5719 10.7766L13.5719 9.77656C13.7281 9.62031 14 9.72969 14 9.95469V14.4984C14 15.3266 13.3281 15.9984 12.5 15.9984H1.5C0.671875 15.9984 0 15.3266 0 14.4984V3.49844C0 2.67031 0.671875 1.99844 1.5 1.99844H10.0469C10.2688 1.99844 10.3813 2.26719 10.225 2.42656L9.225 3.42656C9.17813 3.47344 9.11563 3.49844 9.04688 3.49844H1.5V14.4984H12.5V10.9516C12.5 10.8859 12.525 10.8234 12.5719 10.7766ZM17.4656 4.47031L9.25937 12.6766L6.43437 12.9891C5.61562 13.0797 4.91875 12.3891 5.00938 11.5641L5.32188 8.73906L13.5281 0.532812C14.2437 -0.182813 15.4 -0.182813 16.1125 0.532812L17.4625 1.88281C18.1781 2.59844 18.1781 3.75781 17.4656 4.47031ZM14.3781 5.43594L12.5625 3.62031L6.75625 9.42969L6.52812 11.4703L8.56875 11.2422L14.3781 5.43594ZM16.4031 2.94531L15.0531 1.59531C14.925 1.46719 14.7156 1.46719 14.5906 1.59531L13.625 2.56094L15.4406 4.37656L16.4062 3.41094C16.5312 3.27969 16.5312 3.07344 16.4031 2.94531Z"
        fill="#00249C"
      />
    </g>
    <defs>
      <clipPath id="clip0_97_67003">
        <rect width="18" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default EditIcon
