// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Text } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"

// ** Constants **
import { TIME_FRAME, WALLET_STATUS } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useTokenManagementHook from "hooks/useTokenManagementHook"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  formatDateTimeStamp,
  removeDuplicates,
  filterDataByTimeframe,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Types **
import { WalletCredit } from "types/TokenManagement"

const StableCoinHolder = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("All Status")
  const [timeFrame, setTimeFrame] = useState("All Time")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<WalletCredit[]>([])

  const tyxTypeOpts = WALLET_STATUS.map((m) => ({ title: m, value: m }))

  const timeFrameOpts = [...TIME_FRAME.map((m) => ({ title: m, value: m }))]

  const { getStableCoinHolderListHook, stableCoinHolderList } =
    useTokenManagementHook()

  const { isLoading } = useQuery({
    queryKey: "stableCoinHolderList",
    queryFn: getStableCoinHolderListHook,
  })

  useEffect(() => {
    setData(stableCoinHolderList)
  }, [stableCoinHolderList])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: any[] = []

    if (trxType === "All Status") {
      temp = stableCoinHolderList
    }

    if (trxType === "Active") {
      temp = stableCoinHolderList.filter((m: any) => m.status === "active")
    }

    if (trxType === "Frezze") {
      temp = stableCoinHolderList.filter((m: any) => m.status === "freeze")
    }

    if (trxType === "Inactive") {
      temp = stableCoinHolderList.filter((m: any) => m.status === "inactive")
    }

    if (trxType === "Suspend") {
      temp = stableCoinHolderList.filter((m: any) => m.status === "suspend")
    }

    temp = removeDuplicates(temp, "id")
    temp = filterDataByTimeframe(temp, timeFrame, "created_at")
    temp.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    )
    const filterValue: WalletCredit[] = temp.filter((m) =>
      (m.company_name + m.nickname + m.wallet_address)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, stableCoinHolderList, trxType, timeFrame])

  if (isLoading) return <></>

  const column = [
    {
      title: "Holder Name",
      value: "holder_name",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>
          {`${value.nickname ?? ""} (
                ${abbreviateHexString(value.wallet_address)})`}
        </Text>
      ),
    },
    {
      title: "Corporate Name",
      value: "company_name",
      sortType: TableSortType.STRING,
      w: "200px",
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.company_name)}</Text>
      ),
    },
    {
      title: "Amount",
      value: "balance",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(parseFloat(value.balance).toFixed(2))}</Text>
      ),
    },
    {
      title: "Currency",
      value: "currency",
      sortType: TableSortType.STRING,
    },
    {
      title: "Time Stamp",
      value: "updated_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.updated_at)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    }
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
    {
      options: timeFrameOpts,
      onChange: (value: string) => setTimeFrame(value),
      value: timeFrame,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <Text fontSize="20px" fontWeight="bold">Holder List</Text>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Holder Name / Corporate Name"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
    </Box>
  )
}

export default StableCoinHolder
