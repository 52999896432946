import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { GetParticipantProfileData } from "../../api/types/getParticipantProfileType"
import {
  MyWallet,
  TransactionToken,
  TransactionInvoice,
} from "../../types/MyWallet"

export interface WalletState {
  myWallet: MyWallet[]
  buyerWallet: MyWallet[]
  participantProfile: GetParticipantProfileData | null
  transactionTokens: TransactionToken[]
  transactionInvoices: TransactionInvoice[]
}

export const initialWalletState: WalletState = {
  myWallet: [],
  buyerWallet: [],
  participantProfile: null,
  transactionTokens: [],
  transactionInvoices: [],
}

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialWalletState,
  reducers: {
    setMyWallet: (state, action: PayloadAction<MyWallet[]>) => {
      state.myWallet = action.payload
    },
    setBuyerWallet: (state, action: PayloadAction<MyWallet[]>) => {
      state.buyerWallet = action.payload
    },
    setParticipantProfile: (
      state,
      action: PayloadAction<GetParticipantProfileData | null>
    ) => {
      state.participantProfile = action.payload
    },
    setTransactionToken: (state, action: PayloadAction<TransactionToken[]>) => {
      state.transactionTokens = action.payload
    },
    setTransactionInvoice: (
      state,
      action: PayloadAction<TransactionInvoice[]>
    ) => {
      state.transactionInvoices = action.payload
    },
  },
})

export const walletReducer = walletSlice.reducer
export const walletActions = walletSlice.actions
