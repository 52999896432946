// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Box, HStack, Text, Link, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useAdminHook from "hooks/useAdminHook"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import PrimaryButton from "common/components/PrimaryButton"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import {
  CreateAdminModal,
  EditAdminModal,
} from "modules/user_wallet_management"

// ** Icons **
import { GrommetIcon } from "common/components/Icon"

// ** Types **
import { Admin } from "types/Admin"

const AdminManagement = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState<Admin | null>(null)

  const createAdminModalAction = useDisclosure()
  const editAdminModalAction = useDisclosure()
  const { getAdminHook, admin } = useAdminHook()

  const { isLoading } = useQuery({
    queryKey: "initAdminList",
    queryFn: getAdminHook,
  })

  useEffect(() => {
    if (admin.length > 0) {
      setData(admin)
    }
  }, [admin])

  const column = [
    {
      title: "Email",
      value: "email",
      sortType: TableSortType.STRING,
    },
    {
      title: "Firstname",
      value: "firstname",
      sortType: TableSortType.STRING,
    },
    {
      title: "Lastname",
      value: "lastname",
      sortType: TableSortType.STRING,
    },
    {
      title: "Phone Number",
      value: "contact",
      sortType: TableSortType.STRING,
    },
    {
      title: "Activated",
      value: "activated",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => (
        <Box>
          <SuccessBadge label={value.activated ? "activated" : "unactivated"} />
          <Text className="hidden">
            {value.activated ? "activated" : "unactivated"}
          </Text>
        </Box>
      ),
    },
    {
      title: "Status",
      value: "is_active",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => (
        <Box>
          <SuccessBadge label={value.is_active ? "activated" : "unactivated"} />
          <Text className="hidden">
            {value.is_active ? "activated" : "unactivated"}
          </Text>
        </Box>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key} cursor="pointer" onClick={() => onRowClick(value)}>
            <Link color="teal.500">Edit</Link>
          </Box>
        )
      },
    },
  ]

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  const onRowClick = (data: Admin) => {
    setSelectedUser(data)
    editAdminModalAction.onOpen()
  }

  useEffect(() => {
    const filterValue = admin.filter((m) =>
      (m.email + m.firstname + m.lastname + m.contact)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, admin])

  if (isLoading) return <></>

  return (
    <Box p={6}>
      <ContainerTable
        headerTable="Admin account list"
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        searchPlaceholder="Email/Username"
        onSearchChange={onSearchChange}
        canExport={false}
        actions={[
          <PrimaryButton
            size="lg"
            bgColor="#4AB866"
            colorScheme={"#4AB866"}
            onClick={() => createAdminModalAction.onOpen()}
          >
            <HStack gap={2}>
              <GrommetIcon />
              <Text fontSize="sm" color="white">
                Generate Admin Account
              </Text>
            </HStack>
          </PrimaryButton>,
        ]}
      />
      <CreateAdminModal {...createAdminModalAction} />
      {selectedUser !== null && (
        <EditAdminModal {...editAdminModalAction} user={selectedUser} />
      )}
    </Box>
  )
}

export default AdminManagement
