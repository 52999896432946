export const NEWS_PAGE_PATH = "/";
export const AUTHENTICATE_ACTIVATE_PAGE_PATH = "/authenticate/activate";
export const AUTHENTICATE_FORGOT_PASSWORD_PAGE_PATH =
  "/authenticate/forgot-password";
export const AUTHENTICATE_LOGIN_PAGE_PATH = "/authenticate/login";
export const AUTHENTICATE_RESET_PASSWORD_PAGE_PATH =
  "/authenticate/reset-password";
export const DASHBOARD_PAGE_PATH = "/dashboard";
export const COMPANY_PROFILE_PAGE_PATH = "/company-profile";
export const EDIT_COMPANY_PROFILE_PAGE_PATH = "/edit-company-profile";
export const USER_PROFILE_PAGE_PATH = "/user-profile";
export const EDIT_USER_PROFILE_PAGE_PATH = "/edit-user-profile";
export const TOKEN_MANAGEMENT_PAGE_PATH = "/token-management";
export const MARKETPLACE_MANAGEMENT_PAGE_PATH = "/marketplace-management";
export const USER_WALLET_MANAGEMENT_PAGE_PATH = "/user-wallet-management";
export const TRANSACTION_AND_ACTIVITY_PAGE_PATH = "/transaction-n-activity";
export const CAMPAIGN_MANAGEMENT_PAGE_PATH = "/campaign-management";
export const MY_WALLET_PAGE_PATH = "/my-wallet";
export const MY_INVOICE_PAGE_PATH = "/my-invoice";
export const LISTING_AND_OFFERING_PAGE_PATH = "/listing-and-offering";
export const INVOICE_OFFERING_BY_ID_PAGE_PATH = "/invoice-offering/:id";
export const BIDDING_AND_OFFERING_PAGE_PATH = "/bidding-and-offering";
export const REVENUE_PAGE_PATH = "/revenue";
export const AP_MANAGEMENT_PAGE_PATH = "/ap-management";
export const AR_MANAGEMENT_PAGE_PATH = "/ar-management";
export const GUARANTEE_MANAGEMENT_PAGE_PATH = "/guarantee-management";
export const CLIENT_MANAGEMENT_PAGE_PATH = "/client-management";
export const DISCOUNTING_PAGE_PATH = "/discounting";