// ** Libs **
import { useNavigate } from "react-router-dom"
import { Box, Text, Flex, Image } from "@chakra-ui/react"

// ** Icons **
import {
  CoinBalanceIcon,
  CalendarIcon,
  RevenueIcon,
  CreditCardEditIcon,
  CreditCardIcon,
  CardCoinIcon,
  CreditCardAvatarIcon,
} from "assets/icons"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import { numberFormat, numberFormatForCurrency } from "common/utils/transform"

// ** Router **
import {
  TOKEN_MANAGEMENT_PAGE_PATH,
  GUARANTEE_MANAGEMENT_PAGE_PATH,
  REVENUE_PAGE_PATH,
} from "router/AppPath"

const SponsorDashboard = () => {
  const navigate = useNavigate()
  const { getSponsorDashboardHook, sponsorDashboard } = useDashboardHook()

  const { isLoading } = useQuery({
    queryKey: "initSponsorDashboard",
    queryFn: getSponsorDashboardHook,
  })

  if (isLoading) return <></>

  const {
    credit,
    payment_guarantee,
    balance,
    pending,
    total_guaranteed_buyer,
    due,
    revenue,
    credit_revise,
  } = sponsorDashboard

  return (
    <Flex gap={4} flexDirection="column" mt={4}>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: TOKEN_MANAGEMENT_PAGE_PATH,
            search: "?tab=1",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Credit</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Credit</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${credit?.total?.toFixed(2)}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <CardCoinIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Credit Spent</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${credit?.spent?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() => navigate(GUARANTEE_MANAGEMENT_PAGE_PATH)}
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Total payment Guarantee</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={48} fontWeight="bold">
              {numberFormat(`${payment_guarantee?.total}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <Image src="/assets/image/coins-guarantee.png" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Guaranteed Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${payment_guarantee?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex gap={4}>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() => navigate(TOKEN_MANAGEMENT_PAGE_PATH)}
        >
          <Flex justify="space-between" alignItems="center" h="100%">
            <Box w="83px" m={2}>
              <CoinBalanceIcon w="100%" h="100%" />
            </Box>
            <Box textAlign="right">
              <Text fontSize={24}>Stable Coins (NEW)</Text>
              <Text fontSize={36} fontWeight="bold">
                {numberFormatForCurrency(`${balance?.toFixed(2)}`)}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() =>
            navigate({
              pathname: TOKEN_MANAGEMENT_PAGE_PATH,
              search: "?tab=1",
            })
          }
        >
          <Flex justify="space-between">
            <Box w="87px" m={2}>
              <CreditCardAvatarIcon w="100%" h="100%" />
            </Box>
            <Flex
              flexDirection="column"
              justify="space-between"
              h="144px"
              textAlign="right"
              w="200px"
              borderRight="1px solid white"
              pr={6}
            >
              <Text fontSize={24}>Pending</Text>
              <Text fontSize={48} fontWeight="bold">
                {numberFormat(`${pending}`)}
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              justify="space-between"
              h="144px"
              textAlign="right"
              w="200px"
            >
              <Text fontSize={24}>Total Guaranteed Buyers</Text>
              <Text fontSize={48} fontWeight="bold">
                {numberFormat(`${total_guaranteed_buyer}`)}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Box borderRadius={16} bgColor="blue" color="white" p={4} px={8}>
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Due this Month</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={48} fontWeight="bold">
              {numberFormat(`${due?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <CalendarIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${due?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        borderRadius={16}
        bgColor="blue"
        color="white"
        p={4}
        px={8}
        cursor="pointer"
        onClick={() =>
          navigate({
            pathname: GUARANTEE_MANAGEMENT_PAGE_PATH,
            search: "?tab=1&subtab=1",
          })
        }
      >
        <Box textAlign="center" fontSize={18} fontWeight="bold" mb={4}>
          <Text>Default</Text>
        </Box>
        <Flex justify="space-between">
          <Box w="400px">
            <Text fontSize={24}>Total Invoice</Text>
            <Text fontSize={48} fontWeight="bold">
              {numberFormat(`${sponsorDashboard?.default?.num}`)}
            </Text>
          </Box>
          <Box w="100px" m={4}>
            <CreditCardEditIcon w="100%" h="100%" />
          </Box>
          <Box textAlign="right" w="400px">
            <Text fontSize={24}>Total Amount (NEW)</Text>
            <Text fontSize={40} fontWeight="bold">
              {numberFormatForCurrency(`${sponsorDashboard?.default?.amount?.toFixed(2)}`)}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex gap={4}>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() => navigate(REVENUE_PAGE_PATH)}
        >
          <Box fontSize={24} fontWeight="bold" mb={4}>
            <Text>Revenue</Text>
          </Box>
          <Flex justify="space-between" alignItems="center">
            <Box w="70px" m={2}>
              <RevenueIcon w="100%" h="100%" />
            </Box>
            <Box textAlign="right">
              <Text fontSize={24}>Annual Revenue (NEW)</Text>
              <Text fontSize={48} fontWeight="bold">
                {numberFormatForCurrency(`${revenue?.toFixed(2)}`)}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          borderRadius={16}
          bgColor="blue"
          color="white"
          p={4}
          px={8}
          w="50%"
          cursor="pointer"
          onClick={() =>
            navigate({
              pathname: TOKEN_MANAGEMENT_PAGE_PATH,
              search: "?tab=1",
            })
          }
        >
          <Box fontSize={24} fontWeight="bold" mb={4}>
            <Text>Credit Revise</Text>
          </Box>
          <Flex justify="space-between" alignItems="center" h="108px">
            <Box w="66px" m={2}>
              <CreditCardIcon w="100%" h="100%" />
            </Box>
            <Box textAlign="right">
              <Text fontSize={48} fontWeight="bold">
                {numberFormat(`${credit_revise}`)}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default SponsorDashboard
