// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Text } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useTransactionHook from "hooks/useTransactionHook"

// ** Utils **
import {
  capitalizeFirstLetter,
  formatDateTimeStamp,
  removeDuplicates,
} from "common/utils/transform"

// ** Types **
import { OffchainLogs } from "types/Transaction"

const ActivityTab = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<OffchainLogs[]>([])

  const { offchainLogs, getOffchainLogsHook  } =
    useTransactionHook()

  const { isLoading } = useQuery({
    queryKey: "initOffchainLogs",
    queryFn: getOffchainLogsHook,
  })

  useEffect(() => {
    setData(offchainLogs)
  }, [offchainLogs])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    [],
  )

  useEffect(() => {
    let temp: OffchainLogs[] = offchainLogs
    temp = removeDuplicates(temp, "id")
    temp.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )
    const filterValue: OffchainLogs[] = temp.filter((m) =>
      (
        m.reference
      )
        .toLowerCase()
        .includes(fillValue.toLowerCase()),
    )
    setData(filterValue)
  }, [fillValue, offchainLogs])

  if (isLoading) return <></>

  const column = [
    {
      title: "Activity id",
      value: "id",
      sortType: TableSortType.STRING,
    },
    {
      title: "Activity Type",
      value: "activity",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.activity)}</Text>
      ),
    },
    {
      title: "Reference",
      value: "reference",
      sortType: TableSortType.STRING,
    },
    {
      title: "Time Stamp",
      value: "created_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.created_at)}</Text>
      ),
    },
  ]

  return (
    <Box boxShadow="none">
      <Text fontSize="xl" fontWeight="bold" mb="20px" mt={8}>
        Activity
      </Text>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        searchPlaceholder="Reference"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
    </Box>
  )
}

export default ActivityTab
