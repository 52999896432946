import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react"
import { FC, useEffect, useState } from "react"
import useAuthHook from "../../../hooks/useAuthHook"
import { useQuery } from "../../../hooks/useQueryHook"
import useWalletHook from "../../../hooks/useWalletHook"
import useCreditHook from "../../../hooks/useCreditHook"
import { MyWallet } from "../../../types/MyWallet"
import {
  abbreviateHexString,
  numberFormatForCurrency,
} from "../../utils/transform"
import AmountInput from "../AmountInput"
import {
  CheckedIcon,
  CurrencyIcon,
  PeopleIcon,
  WalletCreditCardIcon,
} from "../Icon"
import PrimaryButton from "../PrimaryButton"

// ** Types **
import { CreditSponsor } from "types/Credit"

// ** Constants **
import { UserRole } from "constants/Enum"

export type TransferInputValue = {
  amount: string
  destination: MyWallet
  currency: string
}

type TransferInputProps = {
  transferValue?: TransferInputValue
  currentWallet: MyWallet
  balance?: string
  unit: string
  onClickTransfer: (val: TransferInputValue) => void
  onClickBack: VoidFunction
}

const TransferInput: FC<TransferInputProps> = ({
  transferValue,
  currentWallet,
  balance,
  unit,
  onClickBack,
  onClickTransfer,
}) => {
  const { getCompanyWallets, getWalletBalanceHook } = useWalletHook()
  const { getCreditBalanceHook } = useCreditHook()
  const [credit, setCredit] = useState<CreditSponsor[]>()
  const { getRole, user } = useAuthHook()
  const { data } = useQuery({ queryFn: () => getCompanyWallets() })
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()
  const [selectedCredit, setSelectedCredit] = useState<CreditSponsor>()
  const [amount, setAmount] = useState<string>(transferValue?.amount || "0.00")

  useEffect(() => {
    if (data) {
      if (transferValue) {
        setSelectedWallet(transferValue.destination)
      } else if (currentWallet) {
        setSelectedWallet(
          data.data.data.filter((m) => m.id !== currentWallet.id)[0]
        )
      } else {
        setSelectedWallet(data.data.data[0])
      }
    }
  }, [data, transferValue, currentWallet])

  useEffect(() => {
    if (currentWallet) {
      getCreditBalanceByWalletId(`${currentWallet.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWallet])

  const getCreditBalanceByWalletId = async (id: string) => {
    let balance = await getCreditBalanceHook(id)
    const response = await getWalletBalanceHook(currentWallet.wallet_address)
    if (balance && balance.length > 0 && response) {
      const newCurrency: CreditSponsor = {
        admin: "",
        token_address: "",
        name: "NEW",
        extra: true,
        credit: {
          id: 6,
          amount: Number(response.data).toFixed(2),
          interest_rate: 0,
          start_at: 1703240879,
        },
      }
      const temp = [
        newCurrency,
        ...balance.filter((item: CreditSponsor) => item.extra),
      ]

      if (temp.length > 0) setSelectedCredit(temp[0])

      setCredit(temp)
    }
  }

  if (!data) return <></>

  return (
    <Flex direction="column" justify="center" align="center" gap={4}>
      <Box>
        <Heading size="md">Transfer</Heading>
      </Box>
      {/* From */}
      <Box w="100%">
        <Stack gap={2}>
          <Box>
            <Heading size="sm">From</Heading>
          </Box>
          <Box
            w={"100%"}
            border="1px solid #D8D8D8"
            borderRadius={8}
            px={4}
            py={2}
          >
            <Flex align="center" gap={3}>
              <Box>
                <CheckedIcon />
              </Box>
              <Box>
                <WalletCreditCardIcon boxSize={6} color="#6A727A" />
              </Box>
              <Box>
                <Stack gap={0}>
                  <HStack>
                    <Text fontSize="small" color="#818181">
                      Company name:
                    </Text>
                    <Text fontSize="small" color="#818181" fontWeight="bold">
                      {currentWallet?.company_name}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="small" color="#818181">
                      Wallet:
                    </Text>
                    <Text fontSize="small" color="#818181" fontWeight="bold">
                      {`${currentWallet?.company_name} -
                      ${currentWallet.nickname ?? ""} (${abbreviateHexString(
                        currentWallet.wallet_address
                      )})`}
                    </Text>
                  </HStack>
                </Stack>
              </Box>
            </Flex>
          </Box>
          <Center>
            <Text color="primary" fontSize="12px" fontWeight="bold">
              Available Transfer :{" "}
              {selectedCredit
                ? numberFormatForCurrency(
                    parseFloat(selectedCredit?.credit.amount).toFixed(2)
                  )
                : numberFormatForCurrency(
                    parseFloat(balance ?? "0.00").toFixed(2) || "0.00"
                  )}{" "}
              {selectedCredit?.name || unit}
            </Text>
          </Center>
        </Stack>
      </Box>
      {/* To */}
      <Box w="100%">
        <Stack gap={2}>
          <Box>
            <Heading size="sm">To</Heading>
          </Box>
          <Box
            w={"100%"}
            border="1px solid #D8D8D8"
            borderRadius={8}
            px={4}
            py={2}
          >
            <Flex align="center" gap={3}>
              <Box>
                <CheckedIcon />
              </Box>
              <Box>
                <PeopleIcon color="primary" />
              </Box>
              <Box>
                <Text fontSize="small" color="primary" fontWeight="bold">
                  {currentWallet?.company_name}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box w={"100%"}>
            <Menu>
              <MenuButton
                size="sm"
                variant="outline"
                minW="110px"
                w="100%"
                h="42px"
                as={Button}
                rightIcon={
                  data.data.data
                    .filter((m) => m.id !== currentWallet.id)
                    .filter((m) => selectedWallet && m.id !== selectedWallet.id)
                    .length > 0 ? (
                    <ChevronDownIcon />
                  ) : (
                    <></>
                  )
                }
                borderRadius={8}
                borderColor="#D8D8D8"
                px={4}
                py={2}
              >
                <Flex align="center" gap={3}>
                  <Box>{selectedWallet && <CheckedIcon />}</Box>
                  <Box>
                    <WalletCreditCardIcon boxSize={6} color="primary" />
                  </Box>
                  <Box>
                    {selectedWallet && (
                      <Text color="primary" fontSize="small" fontWeight="bold">
                        {selectedWallet.company_name} -{" "}
                        {selectedWallet.nickname ?? ""} (
                        {abbreviateHexString(selectedWallet.wallet_address)})
                      </Text>
                    )}
                  </Box>
                </Flex>
              </MenuButton>
              {data.data.data
                .filter((m) => m.id !== currentWallet.id)
                .filter((m) => selectedWallet && m.id !== selectedWallet.id)
                .length > 0 && (
                <MenuList>
                  {data.data.data
                    .filter((m) => m.id !== currentWallet.id)
                    .filter((m) => selectedWallet && m.id !== selectedWallet.id)
                    .map((m, i) => (
                      <MenuItem key={i} onClick={() => setSelectedWallet(m)}>
                        <Text fontSize="sm" color="#212529">
                          {m.company_name} - {m.nickname ?? ""} (
                          {abbreviateHexString(m.wallet_address)})
                        </Text>
                      </MenuItem>
                    ))}
                </MenuList>
              )}
            </Menu>
          </Box>
        </Stack>
      </Box>
      {/* Currency Type */}
      {(getRole() === UserRole.BUYER || getRole() === UserRole.SELLER) && (
        <Box w="100%">
          <Stack gap={2}>
            <Box>
              <Heading size="sm">Currency Type</Heading>
            </Box>
            <Menu>
              <MenuButton
                size="sm"
                variant="outline"
                minW="110px"
                w="100%"
                h="42px"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                borderRadius={8}
                borderColor="#D8D8D8"
                px={4}
                py={2}
              >
                <Flex align="center" gap={3}>
                  <Box>{credit && credit.length > 0 && <CheckedIcon />}</Box>
                  <Box>
                    <CurrencyIcon boxSize={6} color="primary" />
                  </Box>
                  <Box>
                    <Text color="primary" fontSize="small" fontWeight="bold">
                      {selectedCredit?.name}
                    </Text>
                  </Box>
                </Flex>
              </MenuButton>
              <MenuList>
                {credit &&
                  credit.map((m, i) => (
                    <MenuItem key={i} onClick={() => setSelectedCredit(m)}>
                      <Text fontSize="sm" color="#212529">
                        {m.name}
                      </Text>
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </Stack>
        </Box>
      )}
      {/* input */}
      <Box w="100%" mt={4}>
        <Stack gap={4}>
          <Box>
            <Heading size="sm">Transfer Amount</Heading>
          </Box>
          <Box>
            {selectedCredit && (
              <AmountInput
                value={amount}
                max={parseFloat(selectedCredit?.credit?.amount)}
                onChange={(val: string) => setAmount(val)}
                unit={selectedCredit?.name || unit}
              />
            )}
          </Box>
        </Stack>
      </Box>
      {/* Review */}
      <Box w="100%" mt={2}>
        <Stack gap={4}>
          <Box>
            <Heading size="sm">Review Transaction</Heading>
          </Box>
          <Box w="100%">
            <Grid templateColumns="90px 1fr" gap={3}>
              {/* From */}
              <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                From
              </GridItem>
              <GridItem
                textAlign="right"
                color="#353535"
                fontSize="small"
                fontWeight="700"
              >
                {`${currentWallet?.company_name} - ${
                  currentWallet.nickname ?? ""
                } (${abbreviateHexString(currentWallet.wallet_address)})`}
              </GridItem>
              {/* To */}
              <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                To
              </GridItem>
              <GridItem
                textAlign="right"
                color="#353535"
                fontSize="small"
                fontWeight="700"
              >
                {selectedWallet &&
                  `${selectedWallet?.company_name} -
                      ${selectedWallet.nickname ?? ""} (
                      ${abbreviateHexString(selectedWallet.wallet_address)})`}
              </GridItem>
              {/* Total Amount */}
              <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                Total Amount
              </GridItem>
              <GridItem
                textAlign="right"
                color="primary"
                fontSize="small"
                fontWeight="700"
              >
                {numberFormatForCurrency(amount)} {selectedCredit?.name || unit}
              </GridItem>
              {/* Executor */}
              <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
                Executor
              </GridItem>
              <GridItem
                textAlign="right"
                color="#353535"
                fontSize="small"
                fontWeight="700"
              >
                {user?.firstname} {user?.lastname}
              </GridItem>
            </Grid>
          </Box>
        </Stack>
      </Box>
      {/* Button */}
      <Box w="100%" mt={6}>
        <HStack>
          <PrimaryButton variant="outline" onClick={onClickBack}>
            Back
          </PrimaryButton>
          <PrimaryButton
            colorScheme={selectedWallet && parseFloat(amount) > 0 ? "#0050C8" : "grey400"}
            backgroundColor={selectedWallet && parseFloat(amount) > 0 ? "#0050C8" : "grey400"}
            color="white"
            onClick={() =>
              selectedWallet && parseFloat(amount) > 0 &&
              onClickTransfer({
                amount: amount,
                destination: selectedWallet,
                currency: selectedCredit?.name || unit,
              })
            }
          >
            Transfer
          </PrimaryButton>
        </HStack>
      </Box>
    </Flex>
  )
}

export default TransferInput
