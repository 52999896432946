import {
  Box,
  Center,
  CheckboxProps,
  Flex,
  Grid,
  Heading,
} from "@chakra-ui/react"
import { FC, useState } from "react"
import { ListingOption, ListingOptionKey } from "../../../constants/Enum"
import { Invoice } from "../../../types/Invoice"
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "../../utils/transform"
import CheckBoxInput from "../CheckboxInput"
import GridItemPopupDetail from "../GridItemPopupDetail"
import PrimaryButton from "../PrimaryButton"

type ListInvoiceInputProps = {
  invoice: Invoice
  listInvoiceValue: string[]
  onClickClose: VoidFunction
  onClickConfirm: (val: string[]) => void
}

const ListInvoiceInput: FC<ListInvoiceInputProps> = (props) => {
  const { onClickClose, onClickConfirm, invoice, listInvoiceValue } = props
  const [value, setValue] = useState<string[]>(listInvoiceValue)
  const options: CheckboxProps[] = Object.keys(ListingOption).map((m, i) => ({
    title: `${String(i + 1)} : ${ListingOption[m as ListingOptionKey]}`,
    name: "option",
    value: String(i + 1),
    isDisabled: false,
  }))

  const handleOnSubmit = () => {
    if (value.length <= 0) return
    onClickConfirm(value)
  }

  return (
    <Flex direction="column" gap={8}>
      <Center>
        <Heading size="md">Invoice detail</Heading>
      </Center>
      <Grid templateColumns="repeat(2,1fr)" gap={5}>
        <GridItemPopupDetail title="Docstype." value="Invoice" />
        <GridItemPopupDetail title="Doc No." value={invoice.doc_number} />
        <GridItemPopupDetail
          title="Buyer Juristic ID."
          value={invoice.buyer_juristic_id}
        />
        <GridItemPopupDetail
          title="Buyer Name"
          value={capitalizeFirstLetter(invoice.buyer_name)}
        />
        <GridItemPopupDetail
          title="Seller Juristic ID."
          value={invoice.seller_juristic_id}
        />
        <GridItemPopupDetail
          title="Seller Name"
          value={capitalizeFirstLetter(invoice.seller_name)}
        />
        <GridItemPopupDetail
          title="Doc Date."
          value={formatDateDD_MMMM_YYYY(invoice.doc_date)}
        />
        <GridItemPopupDetail
          title="Amount"
          value={numberFormatForCurrency(invoice.amount.toString())}
        />
        <GridItemPopupDetail title="Currency" value={invoice.currency} />
        <GridItemPopupDetail
          title="Payment Date"
          value={formatDateDD_MMMM_YYYY(invoice.payment_date)}
        />
      </Grid>
      <Box>
        <Box>
          <Heading size="sm">Open for Options:</Heading>
        </Box>
        <Box mt={4}>
          <CheckBoxInput
            onChange={(value: string[]) => setValue(value)}
            direction={"column"}
            options={options}
          />
        </Box>
      </Box>
      <Center>
        <PrimaryButton
          maxWidth="450px"
          colorScheme="primary"
          backgroundColor="primary"
          color="white"
          isDisabled={value?.length === 0}
          onClick={handleOnSubmit}
        >
          Listing Invoice
        </PrimaryButton>
      </Center>
      <Center>
        <PrimaryButton
          maxWidth="300px"
          variant="outline"
          onClick={onClickClose}
        >
          Close
        </PrimaryButton>
      </Center>
    </Flex>
  )
}

export default ListInvoiceInput
