import {
  useQuery as originalUseQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

export const useQuery = <T>(options: UseQueryOptions<T>): UseQueryResult<T> => {
  return originalUseQuery<T>({
    ...options,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
