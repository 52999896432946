// ** React **
import { ReactNode } from "react"

// ** Libs **
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
} from "@chakra-ui/react"

// ** Icons **
import { InfoIcon } from "@chakra-ui/icons"

const PopoverComponent = ({
  header,
  width = "400px",
  children,
}: {
  header: string
  width: string
  children: ReactNode
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Box cursor="pointer">
          <InfoIcon color="grey3" />
        </Box>
      </PopoverTrigger>
      <PopoverContent width={width}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
export default PopoverComponent
