// ** React **
import { useEffect, useState } from "react"

// ** Libs **
import { Box, Card, Flex, Spacer } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import BalanceBox from "common/components/BalanceBox"
import WalletSelect from "common/components/WalletSelect"
import CreditSponserBox from "common/components/CreditSponserBox"
import { TransactionContainer, NFTs } from "modules/user_wallet_management"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import { UserRole } from "constants/Enum"

// ** Types **
import { MyWallet } from "types/MyWallet"

// ** Icons **
import { NFTsIcon, StableCoinIcon } from "assets/icons"

const MyWalletPage = () => {
  const { getRole } = useAuthHook()
  const [tabIndex, setTabIndex] = useState(0)
  const { getCompanyWalletHook, myWallet } = useWalletHook()
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()

  const { isLoading } = useQuery({
    queryKey: "myWalletPageInit",
    queryFn: getCompanyWalletHook,
  })

  useEffect(() => {
    if (myWallet.length > 0) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  if (isLoading || !selectedWallet) return <></>

  if (getRole() === UserRole.FI) {
    return (
      <Card p={6}>
        <Flex direction="column" align="left" gap={8}>
          <Box w={350}>
            <TabCustom
              tabIndex={tabIndex}
              onChange={(index) => setTabIndex(index)}
              tabOpt={[
                {
                  label: "Stable Coin",
                  icon: <StableCoinIcon />,
                  activeIcon: <StableCoinIcon color="primary" />,
                  active: tabIndex === 0,
                },
                {
                  label: "NFTs",
                  icon: <NFTsIcon />,
                  activeIcon: <NFTsIcon color="primary" />,
                  active: tabIndex === 1,
                },
              ]}
            />
          </Box>
          <Box display={tabIndex === 0 ? "block" : "none"}>
            <Flex mb={10}>
              <Spacer />
              <Box w={"300px"}>
                <WalletSelect
                  wallets={myWallet}
                  onChange={(value) => setSelectedWallet(value)}
                  value={selectedWallet}
                />
              </Box>
            </Flex>
            <BalanceBox unit="NEW" wallet={selectedWallet} />
            <CreditSponserBox wallet={selectedWallet} />
            <TransactionContainer wallet={selectedWallet} />
          </Box>
          <Box display={tabIndex === 1 ? "block" : "none"}>
            <NFTs />
          </Box>
        </Flex>
      </Card>
    )
  } else {
    return (
      <Card p={6}>
        <Flex mb={10}>
          <Spacer />
          <Box w={"300px"}>
            <WalletSelect
              wallets={myWallet}
              onChange={(value) => setSelectedWallet(value)}
              value={selectedWallet}
            />
          </Box>
        </Flex>
        <BalanceBox unit="NEW" wallet={selectedWallet} />
        <CreditSponserBox wallet={selectedWallet} />
        <TransactionContainer wallet={selectedWallet} />
      </Card>
    )
  }
}

export default MyWalletPage
