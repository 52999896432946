// ** React **
import { FC, useEffect, useState } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { ParticipantDetailEditBody } from "types/Participant"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { FormWithConfirmPasswordBody } from "constants/Enum"
import { COMPANY_NAME_REGEX, JURISTIC_ID_REGEX } from "constants/AppStrings"

type ParticipantDetailEditModalProps = {} & ModalProps

const ParticipantDetailEditModal: FC<ParticipantDetailEditModalProps> = (
  props
) => {
  const { isOpen, onClose } = props
  const { setAlert } = useAppHook()

  const [contentBody, setContentBody] = useState<FormWithConfirmPasswordBody>(
    FormWithConfirmPasswordBody.FORM
  )

  const { putParticipantDetailHook, participantDetail } = useParticipantHook()

  const { control, handleSubmit, reset, watch, setError, setValue } =
    useForm<ParticipantDetailEditBody>({
      mode: "onChange",
    })

  useEffect(() => {
    if (participantDetail !== null) {
      reset({
        company_name: participantDetail.company_name,
        type: participantDetail.type,
        bank_code: participantDetail.bank_code,
        juristic_id: participantDetail.juristic_id,
      })
    }
  }, [participantDetail, reset])

  const validateForm = () => {
    const company_name = watch("company_name")
    const bank_code = watch("bank_code")
    const juristic_id = watch("juristic_id")
    if (company_name === "")
      setError("company_name", { message: "Please enter your company name" })
    if (company_name !== "" && !RegExp(COMPANY_NAME_REGEX).test(company_name)) {
      setError("company_name", {
        message: "Please enter valid company name without special character",
      })
    }
    if (bank_code === "")
      setError("bank_code", {
        message: "Please enter your bank account number",
      })
    if (juristic_id === "")
      setError("juristic_id", { message: "Please enter your juristic id" })
    if (juristic_id && RegExp(JURISTIC_ID_REGEX).test(juristic_id))
      setError("juristic_id", { message: "Please enter valid Juristic ID" })
    if (juristic_id !== "" && !RegExp(JURISTIC_ID_REGEX).test(juristic_id)) {
      setError("juristic_id", { message: "Please enter valid Juristic ID" })
    }
    if (
      company_name !== "" &&
      RegExp(COMPANY_NAME_REGEX).test(company_name) &&
      bank_code !== "" &&
      juristic_id !== "" &&
      RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setContentBody(FormWithConfirmPasswordBody.PASSWORD)
    }
  }

  const onSubmit: SubmitHandler<ParticipantDetailEditBody> = async (data) => {
    if (participantDetail) {
      let temp = { ...data }
      temp.status = participantDetail.status
      const response = await putParticipantDetailHook(
        participantDetail.pt_id,
        temp
      )
      let alert: Alert = { visible: true }
      if (response.statusCode === 200) {
        alert.data = {
          type: AlertType.SUCCESS,
          description: "Edit Participant Complete",
          button: {
            label: "Close",
          },
        }
        reset()
        onClose()
        setContentBody(FormWithConfirmPasswordBody.FORM)
      } else {
        alert.data = {
          type: AlertType.ERROR,
          description: response.message,
          button: {
            label: "Close",
          },
        }
        if (response.statusCode === 400) {
          setContentBody(FormWithConfirmPasswordBody.PASSWORD)
        } else {
          setContentBody(FormWithConfirmPasswordBody.FORM)
        }
      }
      setAlert(alert)
      setValue("password", "")
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.FORM
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="sm">Edit Corporate</Heading>
                </Box>
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Company Name"
                      placeholder="Enter your company name"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your company name",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(COMPANY_NAME_REGEX).test(v) ||
                        "Please enter valid company name without special character",
                    },
                  }}
                />
                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Type"
                      error={error}
                      isDisabled={true}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Controller
                  control={control}
                  name="juristic_id"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Juristic ID"
                      placeholder="Enter your Juristic ID"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your Juristic ID",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(JURISTIC_ID_REGEX).test(v) ||
                        "Please enter valid Juristic ID",
                    },
                  }}
                />
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor="#0050C8"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Update
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ParticipantDetailEditModal
