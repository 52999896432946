import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Admin } from "types/Admin"

export interface AdminState {
  admin: Admin[]
}

export const initialAdminState: AdminState = {
  admin: [],
}

const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminState,
  reducers: {
    setAdmin: (state, action: PayloadAction<Admin[]>) => {
      state.admin = action.payload
    },
  },
})

export const adminReducer = adminSlice.reducer
export const adminActions = adminSlice.actions
