import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  SellerDashboard,
  SponsorDashboard,
  BuyerDashboard,
  FIDashboard,
  AdminDashboard,
} from "types/Dashboard"

export interface DashboardState {
  sellerDashboard: SellerDashboard
  sponsorDashboard: SponsorDashboard
  buyerDashboard: BuyerDashboard
  fiDashboard: FIDashboard
  adminDashboard: AdminDashboard
}

export const initialDashboardState: DashboardState = {
  sellerDashboard: {} as SellerDashboard,
  sponsorDashboard: {} as SponsorDashboard,
  buyerDashboard: {} as BuyerDashboard,
  fiDashboard: {} as FIDashboard,
  adminDashboard: {} as AdminDashboard,
}

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    setSellerDashboard: (state, action: PayloadAction<SellerDashboard>) => {
      state.sellerDashboard = action.payload
    },
    setSponsorDashboard: (state, action: PayloadAction<SponsorDashboard>) => {
      state.sponsorDashboard = action.payload
    },
    setBuyerDashboard: (state, action: PayloadAction<BuyerDashboard>) => {
      state.buyerDashboard = action.payload
    },
    setFIDashboard: (state, action: PayloadAction<FIDashboard>) => {
      state.fiDashboard = action.payload
    },
    setAdminDashboard: (state, action: PayloadAction<AdminDashboard>) => {
      state.adminDashboard = action.payload
    },
  },
})

export const dashboardReducer = dashboardSlice.reducer
export const dashboardActions = dashboardSlice.actions
