// ** React **
import { useState } from "react"

// ** Libs **
import { Box, Flex } from "@chakra-ui/react"

// ** Icons **
import { HistoryBadge } from "common/components/Icon"

// ** Components **
import { APCurrentForSeller, APHistoryForSeller } from "modules/ap_management"

const AROptionTab = ({
  option,
  isPayment = false,
}: {
  option: number
  isPayment?: boolean
}) => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Box>
      <Flex gap={6}>
        <Box onClick={() => setTabIndex(0)} cursor="pointer">
          <HistoryBadge
            label="Current A/R"
            color={tabIndex === 0 ? "primary" : "grey400"}
          />
        </Box>
        <Box onClick={() => setTabIndex(1)} cursor="pointer">
          <HistoryBadge
            label="A/R History"
            color={tabIndex === 1 ? "primary" : "grey400"}
          />
        </Box>
      </Flex>

      <Box display={tabIndex === 0 ? "block" : "none"}>
        <APCurrentForSeller option={option} isPayment={isPayment} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <APHistoryForSeller option={option} />
      </Box>
    </Box>
  )
}

export default AROptionTab
