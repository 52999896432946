// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Text } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"

// ** Constants **
import { TRANSACTION_TOKEN_LIST } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import useDashboardHook from "hooks/useDashboardHook"

// ** Utils **
import {
  formatDateTimeStamp,
  numberFormatForCurrency,
  removeDuplicates,
} from "common/utils/transform"

// ** Types **
import { TokenDatum } from "types/Dashboard"

const Token = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [type, setType] = useState<string>("All Token")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<TokenDatum[]>([])

  const { adminDashboard } = useDashboardHook()

  useEffect(() => {
    setData(adminDashboard?.token_data)
  }, [adminDashboard])
  
  const tyxTypeOpts = TRANSACTION_TOKEN_LIST.map((m) => ({
    title: m,
    value: m,
  }))

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: TokenDatum[] = []
    if (type.indexOf("All Token") > -1) {
      temp = adminDashboard?.token_data
    }
    if (type.indexOf("Credit") > -1) {
      temp = temp.concat(
        temp,
        adminDashboard?.token_data.filter((m) => m.type === "Credit")
      )
    }
    if (type.indexOf("Stable Coin") > -1) {
      temp = temp.concat(
        temp,
        adminDashboard?.token_data.filter((m) => m.type === "Stable Coin")
      )
    }
    if (type.indexOf("NFT") > -1) {
      temp = temp.concat(
        temp,
        adminDashboard?.token_data.filter((m) => m.type === "NFT")
      )
    }
    temp = removeDuplicates(temp, "timestamp")
    temp.sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )
    const filterValue: TokenDatum[] = temp.filter((m) =>
      m.name.toLowerCase().includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, type, adminDashboard])

  const column = [
    {
      title: "Tokens Type",
      value: "type",
      sortType: TableSortType.STRING,
    },
    {
      title: "Token Name",
      value: "name",
      sortType: TableSortType.STRING,
    },
    {
      title: "Token Supply",
      value: "total_supply",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text align="right">{numberFormatForCurrency(value.total_supply)}</Text>
      ),
    },
    {
      title: "Token Holder",
      value: "holder_num",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text align="right">{numberFormatForCurrency(value.total_supply)}</Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">{value.status}</Text>
          <SuccessBadge label={value.status} />
        </Box>
      ),
    },
    {
      title: "Time Stamp",
      value: "timestamp",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.timestamp)}</Text>
      ),
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setType(value),
      value: type,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Token Name"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
    </Box>
  )
}

export default Token
