import { Icon, IconProps } from "@chakra-ui/react"

const CreditCardAvatarIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 88 76" fill="none" {...props}>
    <path
      d="M13.8757 0.832031C10.2842 0.832031 6.8398 2.25874 4.30025 4.79829C1.76069 7.33785 0.333984 10.7822 0.333984 14.3737V45.6237C0.333984 49.2152 1.76069 52.6595 4.30025 55.1991C6.8398 57.7387 10.2842 59.1654 13.8757 59.1654H46.6215C47.3581 56.6322 48.9385 54.4269 51.1007 52.9154H13.8757C11.9418 52.9154 10.0871 52.1471 8.71966 50.7797C7.35221 49.4122 6.58398 47.5576 6.58398 45.6237V25.832H83.6673V14.3737C83.6673 12.5954 83.3171 10.8345 82.6365 9.19153C81.956 7.54858 80.9585 6.05575 79.7011 4.79829C78.4436 3.54083 76.9508 2.54336 75.3078 1.86283C73.6649 1.1823 71.904 0.832031 70.1256 0.832031H13.8757ZM77.4173 14.3737V19.582H6.58398V14.3737C6.58398 10.3487 9.85065 7.08203 13.8757 7.08203H70.1256C74.1507 7.08203 77.4173 10.3487 77.4173 14.3737ZM79.5006 40.4154C79.5006 43.178 78.4032 45.8276 76.4497 47.7811C74.4962 49.7346 71.8467 50.832 69.084 50.832C66.3213 50.832 63.6718 49.7346 61.7183 47.7811C59.7648 45.8276 58.6673 43.178 58.6673 40.4154C58.6673 37.6527 59.7648 35.0032 61.7183 33.0497C63.6718 31.0962 66.3213 29.9987 69.084 29.9987C71.8467 29.9987 74.4962 31.0962 76.4497 33.0497C78.4032 35.0032 79.5006 37.6527 79.5006 40.4154ZM87.834 62.8112C87.834 69.2945 82.4756 75.832 69.084 75.832C55.6923 75.832 50.334 69.3195 50.334 62.8112V62.382C50.334 58.2987 53.6423 54.9987 57.7215 54.9987H80.4465C84.5298 54.9987 87.834 58.3029 87.834 62.382V62.8112Z"
      fill="white"
    />
  </Icon>
)

export default CreditCardAvatarIcon
