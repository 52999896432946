// ** React **
import { useCallback, useEffect, useState, useMemo } from "react"

// ** Libs **
import _, { debounce } from "lodash"
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { HistoryDetailModal } from "modules/ap_management/FI"

// ** Constants **
import { TRANSACTION_OPTION_FI } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useARManagementHook from "hooks/useARManagementHook"
import useAuthHook from "hooks/useAuthHook"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateTimeStamp,
  formatDateDD_MMMM_YYYY,
  removeDuplicates,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Types **
import type { ARItem } from "types/ARManagement"

// ** Constants **
import { UserRole } from "constants/Enum"

const HistoryTab = () => {
  const detailModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("All Option")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<ARItem[]>([])
  const [selected, setSelected] = useState<ARItem>()
  const { getRole } = useAuthHook()

  const tyxTypeOpts = TRANSACTION_OPTION_FI.map((m) => ({ title: m, value: m }))

  const { getArapHistorytHook, histories } = useARManagementHook()

  const { isLoading } = useQuery({
    queryKey: "initGetArapHistorytForFI",
    queryFn: getArapHistorytHook,
  })

  useEffect(() => {
    setData(histories)
  }, [histories])

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: ARItem[] = []

    if (trxType.indexOf("All Option") > -1) {
      temp = histories
    }

    if (trxType.indexOf("Financing") > -1) {
      temp = temp.concat(
        temp,
        histories.filter((m) => m.offer_option === 1)
      )
    }

    if (trxType.indexOf("Buyer Prepayment") > -1) {
      temp = temp.concat(
        temp,
        histories.filter((m) => m.offer_option === 2)
      )
    }

    if (trxType.indexOf("Buyer Guarantee") > -1) {
      temp = temp.concat(
        temp,
        histories.filter((m) => m.offer_option === 3)
      )
    }

    temp = removeDuplicates(temp, "id")

    const filterValue: ARItem[] = temp.filter((m) =>
      (m.buyer_company_name + m.buyer_juristic_id)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, histories, trxType])

  if (isLoading) return <></>

  const column = [
    {
      title: "Finance Date",
      value: "finance_date",
      sortType: TableSortType.ITEM,
      w: "180px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateDD_MMMM_YYYY(value.updated_at)}</Text>
      ),
    },
    {
      title: "Amount",
      value: "amount",
      headerCenter: true,
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.amount)}</Text>
      ),
    },
    {
      title: "Option",
      value: "offer_option",
      sortType: TableSortType.ITEM,
      w: "220px",
      customRender: (key: string, value: any) => (
        <Text>{invoiceOptionIdToString(value.offer_option)}</Text>
      ),
    },
    {
      title: "DocType",
      value: "doc_type",
      customRender: (key: string, value: any) => <Text>Invoice</Text>,
    },
    {
      title: "Doc No.",
      value: "doc_number",
      sortType: TableSortType.STRING,
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.seller_company_name)}</Text>
      ),
    },
    {
      title: "Trading Partners",
      value: "buyer_company_name",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => (
        <Text>{capitalizeFirstLetter(value.buyer_company_name)}</Text>
      ),
    },
    {
      title: "Payment Date",
      value: "payment_date",
      sortType: TableSortType.ITEM,
      w: "180px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateDD_MMMM_YYYY(value.payment_date)}</Text>
      ),
    },
    {
      title: "Interest rate (per year)",
      value: "offer_interest_rate",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">{numberFormatForCurrency(value.offer_interest_rate)} %</Text>
      ),
    },
    {
      title: "Total Guarantee Fee(NEW)",
      value: "offer_discount",
      sortType: TableSortType.INTEGER,
      customRender: (key: string, value: any) => (
        <Text textAlign="right">
          {value.offer_option === 3
            ? numberFormatForCurrency(
                `${
                  parseFloat(value.commitment_fee) +
                  parseFloat(value.guarantee_fee) +
                  parseFloat(value.guarantee_fee_extra)
                }`
              )
            : "0.00"}
        </Text>
      ),
    },
    {
      title: "Status",
      value: "status",
      sortType: TableSortType.ITEM,
      customRender: (key: string, value: any) => (
        <Box>
          <Text className="hidden">
            {getRole() === UserRole.FI ? value.pay_status : value.status}
          </Text>
          <SuccessBadge
            label={getRole() === UserRole.FI ? value.pay_status : value.status}
          />
        </Box>
      ),
    },
    {
      title: "Timestamp",
      value: "updated_at",
      sortType: TableSortType.ITEM,
      w: "230px",
      customRender: (key: string, value: any) => (
        <Text>{formatDateTimeStamp(value.updated_at)}</Text>
      ),
    },
    {
      title: "Detail",
      value: "detail",
      customRender: (key: string, value: any) => {
        return (
          <Link
            key={key}
            onClick={() => {
              setSelected(value)
              detailModalAction.onOpen()
            }}
          >
            View
          </Link>
        )
      },
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box boxShadow="none">
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Debtor Name/ Tax ID"
        canExport={true}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        onSearchChange={onSearchChange}
      />
      {selected && (
        <HistoryDetailModal
          {...detailModalAction}
          data={selected}
          onClickClose={() => detailModalAction.onClose()}
        />
      )}
    </Box>
  )
}

export default HistoryTab
