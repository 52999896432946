import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetTransactionTokenResponse,
  GetTransactionsInvoiceStableResponse,
  GetCreditTransactionResponse,
  GetTransactionsInvoiceCreditResponse,
  GetTransactionsInvoiceNftResponse,
  GetOffchainLogsResponse,
} from "./types/transaction"

const getTransactionToken = async (): Promise<
  AxiosResponse<GetTransactionTokenResponse>
> => {
  const request = await $axios().get("/transaction_token")
  return request
}

const getTransactionsInvoiceStable = async (): Promise<
  AxiosResponse<GetTransactionsInvoiceStableResponse>
> => {
  const request = await $axios().get("/transactions_invoice/stable")
  return request
}

const getCreditTransaction = async (): Promise<
  AxiosResponse<GetCreditTransactionResponse>
> => {
  const request = await $axios().get("/credit/transaction")
  return request
}

const getTransactionsInvoiceCredit = async (): Promise<
  AxiosResponse<GetTransactionsInvoiceCreditResponse>
> => {
  const request = await $axios().get("/transactions_invoice/credit")
  return request
}

const getTransactionsInvoiceNft = async (): Promise<
  AxiosResponse<GetTransactionsInvoiceNftResponse>
> => {
  const request = await $axios().get("/transactions_invoice/nft")
  return request
}

const getOffchainLogs = async (): Promise<
  AxiosResponse<GetOffchainLogsResponse>
> => {
  const request = await $axios().get("/get_offchain_logs")
  return request
}

export {
  getTransactionToken,
  getTransactionsInvoiceStable,
  getCreditTransaction,
  getTransactionsInvoiceCredit,
  getTransactionsInvoiceNft,
  getOffchainLogs,
}
