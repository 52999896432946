import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Campaign, Announce } from "types/Campaign"

export interface CampaignState {
  campaigns: Campaign[]
  campaign: Campaign
  announces: Announce[]
}

export const initialCampaignState: CampaignState = {
  campaigns: [],
  campaign: {} as Campaign,
  announces: [],
}

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialCampaignState,
  reducers: {
    setCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.campaigns = action.payload
    },
    setCampaign: (state, action: PayloadAction<Campaign>) => {
      state.campaign = action.payload
    },
    setAnnounces: (state, action: PayloadAction<Announce[]>) => {
      state.announces = action.payload
    },
  },
})

export const campaignReducer = campaignSlice.reducer
export const campaignActions = campaignSlice.actions
