export const setLocalStorageData = (
  prefix: string,
  userId: number,
  data: number[]
): void => {
  const key = `${prefix}_${userId}`
  localStorage.setItem(key, JSON.stringify(data))
}

export const getLocalStorageData = (
  prefix: string,
  userId: number
): number[] | null => {
  const key = `${prefix}_${userId}`
  const storedData = localStorage.getItem(key)
  if (storedData) {
    return JSON.parse(storedData)
  }
  return null
}

export const removeLocalStorageData = (
  prefix: string,
  userId: number
): void => {
  const key = `${prefix}_${userId}`
  localStorage.removeItem(key)
}

export const unhideInvoiceLocalStorage = (
  prefix: string,
  userId: number,
  id: number
): void => {
  const key = `${prefix}_${userId}`
  const storedData = localStorage.getItem(key)
  if (storedData) {
    const data = JSON.parse(storedData) as number[]
    const updatedData = data.filter((_id) => _id !== id)
    localStorage.setItem(key, JSON.stringify(updatedData))
  }
}

export const hideInvoiceLocalStorage = (
  prefix: string,
  userId: number,
  id: number
): void => {
  const key = `${prefix}_${userId}`
  const storedData = localStorage.getItem(key)
  let data = storedData ? (JSON.parse(storedData) as number[]) : []

  if (!data.includes(id)) {
    data.push(id)
    localStorage.setItem(key, JSON.stringify(data))
  }
}
