// ** APIs **
import {
  getAcceptedInvoicesBySeller,
  getAcceptedInvoicesByCompany,
} from "api/discounting"

// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { discountingActions } from "store/slice/DiscountingSlice"

const useDiscountingHook = () => {
  const dispatch = useAppDispatch()
  const {
    discountingsOp1,
    discountingsOp2,
    discountingsOp3,
    companiesOp1,
    companiesOp2,
    companiesOp3,
  } = useAppSelector((state: RootState) => state.discounting)

  const getDiscountingHook = async (option: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedInvoicesBySeller(option)
      switch (option) {
        case 1:
          dispatch(discountingActions.setDiscountingsOp1(response.data.data))
          break
        case 2:
          dispatch(discountingActions.setDiscountingsOp2(response.data.data))
          break
        case 3:
          dispatch(discountingActions.setDiscountingsOp3(response.data.data))
          break
        default:
          break
      }
    } catch (err) {
      console.log("getDiscountingHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getDiscountByComapnyHook = async (option: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAcceptedInvoicesByCompany(option)
      switch (option) {
        case 1:
          dispatch(discountingActions.setCompaniesOp1(response.data.data))
          break
        case 2:
          dispatch(discountingActions.setCompaniesOp2(response.data.data))
          break
        case 3:
          dispatch(discountingActions.setCompaniesOp3(response.data.data))
          break
        default:
          break
      }
    } catch (err) {
      console.log("getDiscountByComapnyHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getDiscountingHook,
    getDiscountByComapnyHook,
    discountingsOp1,
    discountingsOp2,
    discountingsOp3,
    companiesOp1,
    companiesOp2,
    companiesOp3,
  }
}

export default useDiscountingHook
