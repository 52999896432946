// ** Libs **
import { components } from "react-select"
import { Box, Flex } from "@chakra-ui/react"

// ** Icons **
import { ReactComponent as Ibaac } from "./baac.svg"
import { ReactComponent as Ibay } from "./bay.svg"
import { ReactComponent as Ibbl } from "./bbl.svg"
import { ReactComponent as Ibnp } from "./bnp.svg"
import { ReactComponent as Iboa } from "./boa.svg"
import { ReactComponent as Icacib } from "./cacib.svg"
import { ReactComponent as Icimb } from "./cimb.svg"
import { ReactComponent as Iciti } from "./citi.svg"
import { ReactComponent as Idb } from "./db.svg"
import { ReactComponent as Ighb } from "./ghb.svg"
import { ReactComponent as Igsb } from "./gsb.svg"
import { ReactComponent as Ihsbc } from "./hsbc.svg"
import { ReactComponent as Iibank } from "./ibank.svg"
import { ReactComponent as Iicbc } from "./icbc.svg"
import { ReactComponent as Ijpm } from "./jpm.svg"
import { ReactComponent as Ikbank } from "./kbank.svg"
import { ReactComponent as Ikk } from "./kk.svg"
import { ReactComponent as Iktb } from "./ktb.svg"
import { ReactComponent as Ilhb } from "./lhb.svg"
import { ReactComponent as Imb } from "./mb.svg"
import { ReactComponent as Imega } from "./mega.svg"
import { ReactComponent as Imufg } from "./mufg.svg"
import { ReactComponent as Irbs } from "./rbs.svg"
import { ReactComponent as Isc } from "./sc.svg"
import { ReactComponent as Iscb } from "./scb.svg"
import { ReactComponent as Ismbc } from "./smbc.svg"
import { ReactComponent as Itbank } from "./tbank.svg"
import { ReactComponent as Itcrb } from "./tcrb.svg"
import { ReactComponent as Itisco } from "./tisco.svg"
import { ReactComponent as Itmb } from "./tmb.svg"
import { ReactComponent as Ittb } from "./ttb.svg"
import { ReactComponent as Iuob } from "./uob.svg"

const { Option } = components
const IconOption = (props: any) => {
  let icon = null
  switch (props.data.value) {
    case "baac":
      icon = <Ibaac />
      break
    case "bay":
      icon = <Ibay />
      break
    case "bbl":
      icon = <Ibbl />
      break
    case "bnp":
      icon = <Ibnp />
      break
    case "boa":
      icon = <Iboa />
      break
    case "cacib":
      icon = <Icacib />
      break
    case "cimb":
      icon = <Icimb />
      break
    case "citi":
      icon = <Iciti />
      break
    case "db":
      icon = <Idb />
      break
    case "ghb":
      icon = <Ighb />
      break
    case "gsb":
      icon = <Igsb />
      break
    case "hsbc":
      icon = <Ihsbc />
      break
    case "ibank":
      icon = <Iibank />
      break
    case "icbc":
      icon = <Iicbc />
      break
    case "jpm":
      icon = <Ijpm />
      break
    case "kbank":
      icon = <Ikbank />
      break
    case "kk":
      icon = <Ikk />
      break
    case "ktb":
      icon = <Iktb />
      break
    case "lhb":
      icon = <Ilhb />
      break
    case "mb":
      icon = <Imb />
      break
    case "mega":
      icon = <Imega />
      break
    case "mufg":
      icon = <Imufg />
      break
    case "rbs":
      icon = <Irbs />
      break
    case "sc":
      icon = <Isc />
      break
    case "scb":
      icon = <Iscb />
      break
    case "smbc":
      icon = <Ismbc />
      break
    case "tbank":
      icon = <Itbank />
      break
    case "tcrb":
      icon = <Itcrb />
      break
    case "tisco":
      icon = <Itisco />
      break
    case "tmb":
      icon = <Itmb />
      break
    case "ttb":
      icon = <Ittb />
      break
    case "uob":
      icon = <Iuob />
      break
    default:
      icon = null
      break
  }
  return (
    <Option {...props}>
      <Flex>
        <Box
          w={7}
          borderRadius={8}
          p={1}
          mr={3}
          bgColor={props.data.color}
        >
          <Flex alignItems="center" justifyContent="center">{icon}</Flex>
        </Box>
        {props.data.label}
      </Flex>
    </Option>
  )
}

export default IconOption
