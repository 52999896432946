import {
  BIDDING_AND_OFFERING_PAGE_PATH,
  CAMPAIGN_MANAGEMENT_PAGE_PATH,
  COMPANY_PROFILE_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  EDIT_COMPANY_PROFILE_PAGE_PATH,
  EDIT_USER_PROFILE_PAGE_PATH,
  INVOICE_OFFERING_BY_ID_PAGE_PATH,
  LISTING_AND_OFFERING_PAGE_PATH,
  MY_INVOICE_PAGE_PATH,
  MY_WALLET_PAGE_PATH,
  TOKEN_MANAGEMENT_PAGE_PATH,
  TRANSACTION_AND_ACTIVITY_PAGE_PATH,
  USER_PROFILE_PAGE_PATH,
  USER_WALLET_MANAGEMENT_PAGE_PATH,
  REVENUE_PAGE_PATH,
  AP_MANAGEMENT_PAGE_PATH,
  AR_MANAGEMENT_PAGE_PATH,
  GUARANTEE_MANAGEMENT_PAGE_PATH,
  CLIENT_MANAGEMENT_PAGE_PATH,
  DISCOUNTING_PAGE_PATH,
} from "../../router/AppPath"
import {
  AssuredWorkloadOutlineIcon,
  CampaignIcon,
  CoinSwap02Icon,
  DashboardIcon,
  DocumentOutlineIcon,
  HandMoneyBrokenIcon,
  HandMoneyBrokenIcon2,
  HistoryIcon,
  PriceChangeOutlineIcon,
  TokenSwapIcon,
  UserEditLineIcon,
  UserIcon,
  WalletCreditCardIcon,
} from "./Icon"

const DrawerBarByRole = () => {
  const ADMIN_ITEMS = [
    {
      route: DASHBOARD_PAGE_PATH,
      icon: DashboardIcon,
      title: "Dashboard",
      navTitle: "Dashboard",
      activeRoute: [
        DASHBOARD_PAGE_PATH,
        EDIT_USER_PROFILE_PAGE_PATH,
        USER_PROFILE_PAGE_PATH,
      ],
    },
    {
      route: COMPANY_PROFILE_PAGE_PATH,
      icon: UserIcon,
      title: "Company Profile",
      navTitle: "Profile",
      activeRoute: [COMPANY_PROFILE_PAGE_PATH, EDIT_COMPANY_PROFILE_PAGE_PATH],
    },
    {
      route: TOKEN_MANAGEMENT_PAGE_PATH,
      icon: TokenSwapIcon,
      title: "Token Management",
      navTitle: "Token Management",
      activeRoute: [TOKEN_MANAGEMENT_PAGE_PATH],
    },
    {
      route: USER_WALLET_MANAGEMENT_PAGE_PATH,
      icon: DashboardIcon,
      title: "User & Wallet Management",
      navTitle: "User & Wallet Management",
      activeRoute: [USER_WALLET_MANAGEMENT_PAGE_PATH],
    },
    {
      route: TRANSACTION_AND_ACTIVITY_PAGE_PATH,
      icon: HistoryIcon,
      title: "Transaction",
      navTitle: "Transaction & Activity",
      activeRoute: [TRANSACTION_AND_ACTIVITY_PAGE_PATH],
    },
    {
      route: CAMPAIGN_MANAGEMENT_PAGE_PATH,
      icon: CampaignIcon,
      title: "Campaign Management",
      navTitle: "Campaign Management",
      activeRoute: [CAMPAIGN_MANAGEMENT_PAGE_PATH],
    },
  ]

  const SELLER_ITEMS = [
    {
      route: DASHBOARD_PAGE_PATH,
      icon: DashboardIcon,
      title: "Dashboard",
      navTitle: "Dashboard",
      activeRoute: [
        DASHBOARD_PAGE_PATH,
        EDIT_USER_PROFILE_PAGE_PATH,
        USER_PROFILE_PAGE_PATH,
      ],
    },
    {
      route: COMPANY_PROFILE_PAGE_PATH,
      icon: UserIcon,
      title: "Company Profile",
      navTitle: "Profile",
      activeRoute: [COMPANY_PROFILE_PAGE_PATH, EDIT_COMPANY_PROFILE_PAGE_PATH],
    },
    {
      route: MY_WALLET_PAGE_PATH,
      icon: WalletCreditCardIcon,
      title: "My Wallet",
      navTitle: "My Wallet",
      activeRoute: [MY_WALLET_PAGE_PATH],
    },
    {
      route: MY_INVOICE_PAGE_PATH,
      icon: DocumentOutlineIcon,
      title: "My Invoice",
      navTitle: "My Invoice",
      activeRoute: [MY_INVOICE_PAGE_PATH],
    },
    {
      route: LISTING_AND_OFFERING_PAGE_PATH,
      icon: PriceChangeOutlineIcon,
      title: "My Listing & Offering",
      navTitle: "My Listing & Offering",
      activeRoute: [
        LISTING_AND_OFFERING_PAGE_PATH,
        INVOICE_OFFERING_BY_ID_PAGE_PATH,
      ],
    },
    {
      route: AR_MANAGEMENT_PAGE_PATH,
      icon: HandMoneyBrokenIcon,
      title: "A/R Management",
      navTitle: "A/R Management",
      activeRoute: [AR_MANAGEMENT_PAGE_PATH],
    },
    {
      route: DISCOUNTING_PAGE_PATH,
      icon: CoinSwap02Icon,
      title: "Discounting",
      navTitle: "Discounting",
      activeRoute: [DISCOUNTING_PAGE_PATH],
    },
  ]

  const FI_ITEMS = [
    {
      route: DASHBOARD_PAGE_PATH,
      icon: DashboardIcon,
      title: "Dashboard",
      navTitle: "Dashboard",
      activeRoute: [
        DASHBOARD_PAGE_PATH,
        EDIT_USER_PROFILE_PAGE_PATH,
        USER_PROFILE_PAGE_PATH,
      ],
    },
    {
      route: COMPANY_PROFILE_PAGE_PATH,
      icon: UserIcon,
      title: "Company Profile",
      navTitle: "Profile",
      activeRoute: [COMPANY_PROFILE_PAGE_PATH, EDIT_COMPANY_PROFILE_PAGE_PATH],
    },
    {
      route: MY_WALLET_PAGE_PATH,
      icon: TokenSwapIcon,
      title: "Token Management",
      navTitle: "Token Management",
      activeRoute: [MY_WALLET_PAGE_PATH],
    },
    {
      route: AR_MANAGEMENT_PAGE_PATH,
      icon: HandMoneyBrokenIcon,
      title: "A/R Management",
      navTitle: "A/R Management",
      activeRoute: [AR_MANAGEMENT_PAGE_PATH],
    },
    {
      route: BIDDING_AND_OFFERING_PAGE_PATH,
      icon: PriceChangeOutlineIcon,
      title: "Bidding & Offering",
      navTitle: "Bidding & Offering",
      activeRoute: [BIDDING_AND_OFFERING_PAGE_PATH],
    },
    {
      route: REVENUE_PAGE_PATH,
      icon: HandMoneyBrokenIcon2,
      title: "Revenue",
      navTitle: "Revenue",
      activeRoute: [REVENUE_PAGE_PATH],
    },
  ]

  const BUYER_ITEMS = [
    {
      route: DASHBOARD_PAGE_PATH,
      icon: DashboardIcon,
      title: "Dashboard",
      navTitle: "Dashboard",
      activeRoute: [
        DASHBOARD_PAGE_PATH,
        EDIT_USER_PROFILE_PAGE_PATH,
        USER_PROFILE_PAGE_PATH,
      ],
    },
    {
      route: COMPANY_PROFILE_PAGE_PATH,
      icon: UserIcon,
      title: "Company Profile",
      navTitle: "Profile",
      activeRoute: [COMPANY_PROFILE_PAGE_PATH, EDIT_COMPANY_PROFILE_PAGE_PATH],
    },
    {
      route: MY_WALLET_PAGE_PATH,
      icon: WalletCreditCardIcon,
      title: "My Wallet",
      navTitle: "My Wallet",
      activeRoute: [MY_WALLET_PAGE_PATH],
    },
    {
      route: BIDDING_AND_OFFERING_PAGE_PATH,
      icon: PriceChangeOutlineIcon,
      title: "Bidding & Offering",
      navTitle: "Bidding & Offering",
      activeRoute: [BIDDING_AND_OFFERING_PAGE_PATH],
    },
    {
      route: AP_MANAGEMENT_PAGE_PATH,
      icon: HandMoneyBrokenIcon,
      title: "A/P Management",
      navTitle: "A/P Management",
      activeRoute: [AP_MANAGEMENT_PAGE_PATH],
    },
    {
      route: REVENUE_PAGE_PATH,
      icon: HandMoneyBrokenIcon2,
      title: "Revenue",
      navTitle: "Revenue",
      activeRoute: [REVENUE_PAGE_PATH],
    },
  ]

  const SPONSOR_ITEMS = [
    {
      route: DASHBOARD_PAGE_PATH,
      icon: DashboardIcon,
      title: "Dashboard",
      navTitle: "Dashboard",
      activeRoute: [
        DASHBOARD_PAGE_PATH,
        EDIT_USER_PROFILE_PAGE_PATH,
        USER_PROFILE_PAGE_PATH,
      ],
    },
    {
      route: COMPANY_PROFILE_PAGE_PATH,
      icon: UserIcon,
      title: "Company Profile",
      navTitle: "Profile",
      activeRoute: [COMPANY_PROFILE_PAGE_PATH, EDIT_COMPANY_PROFILE_PAGE_PATH],
    },
    {
      route: TOKEN_MANAGEMENT_PAGE_PATH,
      icon: TokenSwapIcon,
      title: "Token Management",
      navTitle: "Token Management",
      activeRoute: [TOKEN_MANAGEMENT_PAGE_PATH],
    },
    {
      route: REVENUE_PAGE_PATH,
      icon: HandMoneyBrokenIcon2,
      title: "Revenue",
      navTitle: "Revenue",
      activeRoute: [REVENUE_PAGE_PATH],
    },
    {
      route: CLIENT_MANAGEMENT_PAGE_PATH,
      icon: UserEditLineIcon,
      title: "Client Management",
      navTitle: "Client Management",
      activeRoute: [CLIENT_MANAGEMENT_PAGE_PATH],
    },
    {
      route: GUARANTEE_MANAGEMENT_PAGE_PATH,
      icon: AssuredWorkloadOutlineIcon,
      title: "Guarantee Management",
      navTitle: "Guarantee Management",
      activeRoute: [GUARANTEE_MANAGEMENT_PAGE_PATH],
    },
  ]

  const TCG_ITEMS = [
    {
      route: DASHBOARD_PAGE_PATH,
      icon: DashboardIcon,
      title: "Dashboard",
      navTitle: "Dashboard",
      activeRoute: [
        DASHBOARD_PAGE_PATH,
        EDIT_USER_PROFILE_PAGE_PATH,
        USER_PROFILE_PAGE_PATH,
      ],
    },
    {
      route: COMPANY_PROFILE_PAGE_PATH,
      icon: UserIcon,
      title: "Company Profile",
      navTitle: "Profile",
      activeRoute: [COMPANY_PROFILE_PAGE_PATH, EDIT_COMPANY_PROFILE_PAGE_PATH],
    },
    {
      route: TOKEN_MANAGEMENT_PAGE_PATH,
      icon: TokenSwapIcon,
      title: "Token Management",
      navTitle: "Token Management",
      activeRoute: [TOKEN_MANAGEMENT_PAGE_PATH],
    },
    {
      route: REVENUE_PAGE_PATH,
      icon: HandMoneyBrokenIcon2,
      title: "Revenue",
      navTitle: "Revenue",
      activeRoute: [REVENUE_PAGE_PATH],
    },
    {
      route: GUARANTEE_MANAGEMENT_PAGE_PATH,
      icon: AssuredWorkloadOutlineIcon,
      title: "Guarantee Management",
      navTitle: "Guarantee Management",
      activeRoute: [GUARANTEE_MANAGEMENT_PAGE_PATH],
    },
  ]

  return {
    ADMIN_ITEMS,
    SELLER_ITEMS,
    BUYER_ITEMS,
    FI_ITEMS,
    SPONSOR_ITEMS,
    TCG_ITEMS,
  }
}

export default DrawerBarByRole
