// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Flex,
  Heading,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react"

// ** Components **
import GridItemPopupDetail from "common/components/GridItemPopupDetail"
import PrimaryButton from "common/components/PrimaryButton"
import SuccessBadge from "common/components/SuccessBadge"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { TransactionsInvoiceCredit } from "types/Transaction"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateTimeStamp,
  invoiceOptionIdToString,
  formatDateDD_MMMM_YYYY,
  capitalizeFirstLetter,
  exportToCSV,
  downloadCSV,
} from "common/utils/transform"

type CreditTransactionInvoiceDetailModalProps = {
  data: TransactionsInvoiceCredit
} & ModalProps

const CreditTransactionInvoiceDetailModal: FC<
  CreditTransactionInvoiceDetailModalProps
> = (props) => {
  const { isOpen, onClose, data } = props

  const handleExport = () => {
    const content = exportToCSV([data], [])
    downloadCSV(content, `credit_transaction_invoice_${data.id}.csv`)
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Box mb={4} textAlign="center">
            <Heading fontSize="24px" color="#353535">
              Invoice Detail
            </Heading>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={2} mt={4} mb={4}>
            <GridItemPopupDetail title="Doctype" value="Invoice" />
            <GridItemPopupDetail title="Doc No." value={data.doc_number} />
            <GridItemPopupDetail
              title="Buyer Juristic ID."
              value={data.buyer_juristic_id}
            />
            <GridItemPopupDetail
              title="Buyer Name"
              value={capitalizeFirstLetter(data.buyer_company_name)}
            />
            <GridItemPopupDetail
              title="Seller Juristic ID."
              value={data.seller_juristic_id}
            />
            <GridItemPopupDetail
              title="Seller Name"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Doc Date."
              value={formatDateDD_MMMM_YYYY(data.doc_date)}
            />
            <GridItemPopupDetail
              title="Amount. (include VAT)"
              value={numberFormatForCurrency(data.amount.toString())}
            />
            <GridItemPopupDetail title="Currency" value={data.currency} />
            <GridItemPopupDetail
              title="Schedule Payment Date"
              value={formatDateDD_MMMM_YYYY(data.payment_date)}
            />
            <GridItemPopupDetail
              title="Payment Guarantee Status"
              value={data.offer_option === 3 ? "Yes" : "No"}
            />
            <GridItemPopupDetail
              title="Owner Name"
              value={capitalizeFirstLetter(data.seller_company_name)}
            />
            <GridItemPopupDetail
              title="Offering Start Date"
              value={formatDateDD_MMMM_YYYY(data.offer_start_date)}
            />
            <GridItemPopupDetail
              title="Offering end Date"
              value={formatDateDD_MMMM_YYYY(data.offer_end_date)}
            />
            <GridItemPopupDetail
              title="Option"
              value={invoiceOptionIdToString(data.offer_option)}
            />
            <GridItemPopupDetail
              title="Commitment Fee (Per year)"
              value={
                data.offer_option === 3
                  ? numberFormatForCurrency(data.commitment_fee_rate) + "%"
                  : "-%"
              }
            />
            <GridItemPopupDetail
              title="Guarantee Fee (Per year)"
              value={
                data.offer_option === 3
                  ? numberFormatForCurrency(data.guarantee_fee_rate) + "%"
                  : "-%"
              }
            />
            <GridItemPopupDetail
              title="Guarantee Fee Extra (per amount)"
              value={
                data.offer_option === 3
                  ? numberFormatForCurrency(data.guarantee_fee_rate_extra) +
                    "%"
                  : "-%"
              }
            />
            <GridItemPopupDetail
              title="Discounting rate (per amount)"
              value={
                data.offer_option === 2
                  ? numberFormatForCurrency(data.offer_interest_rate) + "%"
                  : "-%"
              }
            />
            <GridItemPopupDetail
              title="Interest rate(per year)"
              value={numberFormatForCurrency(data.offer_interest_rate) + "%"}
            />
            <GridItemPopupDetail
              title="Overdue"
              value={
                data.overdue_day ? data.overdue_day + " days" : "- days"
              }
            />
            <GridItemPopupDetail
              title="Total Discount (NEW)"
              value={
                data.offer_option === 3
                  ? numberFormatForCurrency(
                      `${
                        parseFloat(data.commitment_fee) +
                        parseFloat(data.guarantee_fee) +
                        parseFloat(data.guarantee_fee_extra)
                      }`
                    )
                  : data.offer_option === 2
                  ? numberFormatForCurrency(
                      `${parseFloat(data.offer_discount)}`
                    )
                  : "0.00"
              }
            />
            <GridItemPopupDetail
              title="Timestamp"
              value={formatDateTimeStamp(data.created_at)}
            />
            <GridItem
              w="100%"
              display="inline-flex"
              borderBottom="1px solid #E1F1FF"
              p={3}
              justifyContent="space-between"
            >
              <Box>
                <Text fontSize="sm" color="#6A727A" fontWeight={700}>
                  Status:
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" fontWeight={700} ml={1}>
                  <SuccessBadge label={data.status} />
                </Text>
              </Box>
            </GridItem>
            <GridItemPopupDetail
              title="Finance Date"
              value={formatDateDD_MMMM_YYYY(data.updated_at)}
            />
            <GridItemPopupDetail
              title="Financial Amount (NEW)"
              value={numberFormatForCurrency(
                parseFloat(data.amount).toFixed(2)
              )}
            />
          </Grid>
          <Flex justify="center" gap={4}>
            <PrimaryButton variant="outline" onClick={() => onClose()} w={250}>
              Back
            </PrimaryButton>
            <PrimaryButton
              colorScheme="primary"
              backgroundColor="primary"
              color="white"
              onClick={handleExport}
              w={250}
            >
              Export
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreditTransactionInvoiceDetailModal
