import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { HistoryItem, BuyerCompany, SellerCompany } from "types/Guarantee"

export interface GuaranteeState {
  histories: HistoryItem[]
  buyerCompanies: BuyerCompany[]
  buyerInvoices: HistoryItem[]
  sellerCompanies: SellerCompany[]
  sellerInvoices: HistoryItem[]
}

export const initialGuaranteeState: GuaranteeState = {
  histories: [],
  buyerCompanies: [],
  buyerInvoices: [],
  sellerCompanies: [],
  sellerInvoices: [],
}

const guaranteeSlice = createSlice({
  name: "guarantee",
  initialState: initialGuaranteeState,
  reducers: {
    setHistories: (state, action: PayloadAction<HistoryItem[]>) => {
      state.histories = action.payload
    },
    setBuyerCompanies: (state, action: PayloadAction<BuyerCompany[]>) => {
      state.buyerCompanies = action.payload
    },
    setBuyerInvoices: (state, action: PayloadAction<HistoryItem[]>) => {
      state.buyerInvoices = action.payload
    },
    setSellerCompanies: (state, action: PayloadAction<SellerCompany[]>) => {
      state.sellerCompanies = action.payload
    },
    setSellerInvoices: (state, action: PayloadAction<HistoryItem[]>) => {
      state.sellerInvoices = action.payload
    },
  },
})

export const guaranteeReducer = guaranteeSlice.reducer
export const guaranteeActions = guaranteeSlice.actions
