import { Icon, IconProps } from "@chakra-ui/react"

const StableCoinIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 15 18" fill="none" {...props}>
    <path
      d="M9.125 0.5625H5.375C2.39609 0.5625 0.0625 4.26875 0.0625 9C0.0625 13.7312 2.39609 17.4375 5.375 17.4375H9.125C12.1039 17.4375 14.4375 13.7312 14.4375 9C14.4375 4.26875 12.1039 0.5625 9.125 0.5625ZM12.032 12.4375H10.243C10.4637 11.62 10.6021 10.7825 10.6562 9.9375H12.5258C12.4642 10.7879 12.2984 11.6275 12.032 12.4375ZM10.6562 8.0625C10.6021 7.21748 10.4637 6.37998 10.243 5.5625H12.032C12.2984 6.37246 12.4642 7.21209 12.5258 8.0625H10.6562ZM11.0961 3.6875H9.53359C9.31388 3.24674 9.05225 2.82814 8.75234 2.4375H9.125C9.54453 2.4375 10.3164 2.61328 11.0961 3.6875ZM3.09141 13.8312C2.34687 12.5555 1.9375 10.8398 1.9375 9C1.9375 7.16016 2.34687 5.44453 3.09141 4.16875C3.96875 2.6625 4.89922 2.4375 5.375 2.4375C5.85078 2.4375 6.78125 2.6625 7.65859 4.16875C8.40312 5.44453 8.8125 7.16016 8.8125 9C8.8125 10.8398 8.40312 12.5555 7.65859 13.8312C6.78125 15.3375 5.85078 15.5625 5.375 15.5625C4.89922 15.5625 3.96875 15.3375 3.09141 13.8312ZM9.125 15.5625H8.75078C9.05069 15.1719 9.31231 14.7533 9.53203 14.3125H11.0945C10.3164 15.3867 9.54453 15.5625 9.125 15.5625Z"
      fill="currentColor"
    />
  </Icon>
)

export default StableCoinIcon
