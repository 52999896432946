// ** Components **
import { BuyerDashboard, SellerDashboard, FIDashboard } from "./dashboardByRole"

// ** Constants **
import { UserRole } from "constants/Enum"

// ** Hooks **
import useAuthHook from "hooks/useAuthHook"

const Dashboard = ({ setTabIndex }: { setTabIndex: any }) => {
  const { getRole } = useAuthHook()
  return (
    <>
      {getRole() === UserRole.BUYER && (
        <BuyerDashboard setTabIndex={setTabIndex} />
      )}
      {getRole() === UserRole.SELLER && (
        <SellerDashboard setTabIndex={setTabIndex} />
      )}
      {getRole() === UserRole.FI && <FIDashboard setTabIndex={setTabIndex} />}
    </>
  )
}

export default Dashboard
