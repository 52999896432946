import { AxiosResponse } from "axios"
import $axios from "./instance"
import { GetArCurrentResponse } from "./types/getArCurrent"

const getArHistory = async (
  option: number
): Promise<AxiosResponse<GetArCurrentResponse>> => {
  const request = await $axios().get("/arap/history/" + option)
  return request
}

export default getArHistory
