// ** React **
import { FC, useState } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"
import { ParticipantDetailItem } from "modules/client_management"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { CreateParticipantBody } from "types/Participant"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { FormReviewWithConfirmPasswordBody } from "constants/Enum"
import { COMPANY_NAME_REGEX, JURISTIC_ID_REGEX } from "constants/AppStrings"

type ParticipantDetailEditModalProps = {} & ModalProps

const CreateParticipantModal: FC<ParticipantDetailEditModalProps> = (props) => {
  const { isOpen, onClose } = props
  const { setAlert } = useAppHook()

  const [contentBody, setContentBody] =
    useState<FormReviewWithConfirmPasswordBody>(
      FormReviewWithConfirmPasswordBody.FORM
    )

  const { createParticipantHook } = useParticipantHook()

  const { control, handleSubmit, reset, watch, setError, setValue } =
    useForm<CreateParticipantBody>({
      defaultValues: {
        type: "Corporate",
      },
      mode: "onChange",
    })

  const validateForm = () => {
    const company_name = watch("company_name")
    const juristic_id = watch("juristic_id")
    if (company_name === undefined)
      setError("company_name", { message: "Please enter your company name" })
    if (
      company_name !== undefined &&
      RegExp(COMPANY_NAME_REGEX).test(company_name)
    ) {
      setError("company_name", {
        message: "Please enter valid company name without special character",
      })
    }
    if (juristic_id === undefined)
      setError("juristic_id", { message: "Please enter your juristic id" })
    if (
      juristic_id === undefined &&
      RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setError("juristic_id", { message: "Please enter valid Juristic ID" })
    }
    if (
      company_name !== undefined &&
      RegExp(COMPANY_NAME_REGEX).test(company_name) &&
      juristic_id !== undefined &&
      RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setContentBody(FormReviewWithConfirmPasswordBody.REVIEW)
    }
  }

  const onSubmit: SubmitHandler<CreateParticipantBody> = async (data) => {
    const response = await createParticipantHook(data)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Create Participant Complete",
        button: {
          label: "Close",
        },
      }
      reset()
      onClose()
      setContentBody(FormReviewWithConfirmPasswordBody.FORM)
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
      if (response.statusCode === 400) {
        setContentBody(FormReviewWithConfirmPasswordBody.PASSWORD)
      } else {
        setContentBody(FormReviewWithConfirmPasswordBody.FORM)
      }
    }
    setAlert(alert)
    setValue("password", "")
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormReviewWithConfirmPasswordBody.FORM
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="md">Create Participant</Heading>
                </Box>
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Company Name"
                      placeholder="Enter your company name"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your company name",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(COMPANY_NAME_REGEX).test(v) ||
                        "Please enter valid company name without special character",
                    },
                  }}
                />
                <Controller
                  control={control}
                  name="juristic_id"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Juristic ID"
                      placeholder="Enter your Juristic ID"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your Juristic ID",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(JURISTIC_ID_REGEX).test(v) ||
                        "Please enter valid Juristic ID",
                    },
                  }}
                />
                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Type"
                      error={error}
                      isDisabled={true}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor="#0050C8"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Create
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormReviewWithConfirmPasswordBody.REVIEW
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center" mb={6}>
                <Box mb={4}>
                  <Heading size="md">Create Participant</Heading>
                </Box>
                <Box w="100%">
                  <ParticipantDetailItem
                    label="Company Name"
                    value={watch("company_name")}
                    align="right"
                  />
                  <ParticipantDetailItem
                    label="Type"
                    value={watch("type")}
                    align="right"
                  />
                  <ParticipantDetailItem
                    label="Juristic ID"
                    value={watch("juristic_id")}
                    align="right"
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormReviewWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  onClick={() =>
                    setContentBody(FormReviewWithConfirmPasswordBody.PASSWORD)
                  }
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormReviewWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormReviewWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateParticipantModal
