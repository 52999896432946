import { AxiosResponse } from "axios";
import $axios from "./instance";
import {
  PostTopupWalletBody,
  PostTopupWalletResponse,
} from "./types/postTopupWalletType";

const postTopupWallet = async (
  body: PostTopupWalletBody
): Promise<AxiosResponse<PostTopupWalletResponse>> => {
  const request = await $axios().post("/topup", body);
  return request;
};

export default postTopupWallet;
