// ** React **
import { useState, useEffect } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { AROptionTab } from "modules/ap_management"

// ** Hooks **
import useQueryString from "hooks/useQueryString"

const ARListTabForSeller = () => {
  const subtab = useQueryString("subtab")
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    if (subtab !== null) {
      setTabIndex(parseInt(subtab))
    }
  }, [subtab])

  return (
    <Box>
      <Box w={700}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Financing",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Buyer Prepayment",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
            {
              label: "Guaranteed Discounting",
              icon: null,
              activeIcon: null,
              active: tabIndex === 2,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"} py={4}>
        <AROptionTab option={1} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"} py={4}>
        <AROptionTab option={2} />
      </Box>
      <Box display={tabIndex === 2 ? "block" : "none"} py={4}>
        <AROptionTab option={3} />
      </Box>
    </Box>
  )
}

export default ARListTabForSeller
