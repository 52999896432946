// ** React **
import { FC, useEffect, useState } from "react"

// ** Libs **
import {
  Box,
  Card,
  GridItem,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import dayjs from "dayjs"

// ** API **
import { PostAcceptOfferBody } from "api/types/postAcceptOfferType"

// ** Components **
import ConfirmPassword from "./ConfirmPassword"
import PrimaryButton from "./PrimaryButton"
import StatusDot from "common/components/StatusDot"
import DeclineAnOfferModal from "modules/listing_and_offering/DeclineAnOfferModal"

// ** Utils **
import {
  abbreviateHexString,
  numberFormatForCurrency,
  capitalizeFirstLetter,
} from "common/utils/transform"
import { invoiceOptionIdToString } from "common/utils/transform"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useInvoiceHook from "hooks/useInvoiceHook"

// ** Types **
import { Alert, AlertType } from "types/Alert"
import { InvoiceOffering, InvoiceListed } from "types/Invoice"

type OfferDetailProps = {
  data: InvoiceOffering
}

const OfferDetail: FC<OfferDetailProps> = ({ data }) => {
  const {
    id,
    invoice_id,
    option,
    operator_company_name,
    operator_wallet_address,
    operator_wallet_nickname,
    discount,
    amount,
    expire_at,
  } = data

  const [expDateTime, setExpDateTime] = useState(0)
  const [invoice, setInvoice] = useState<InvoiceListed>()

  const { setAlert } = useAppHook()
  const { postAcceptOfferHook } = useInvoiceHook()
  const { listedInvoices } = useInvoiceHook()

  useEffect(() => {
    if (listedInvoices.length > 0) {
      const invoices = listedInvoices.filter((item) => item.id === invoice_id)
      if (invoices.length > 0) {
        setInvoice(invoices[0])
      }
    }
  }, [listedInvoices, invoice_id])

  const { isOpen, onClose, onOpen } = useDisclosure()
  const modalDecline = useDisclosure()

  const calExp = (date: string) => {
    // Get the current date and time
    const now = dayjs()
    const to = dayjs(date)

    // Calculate the difference in milliseconds
    const seconds = to.diff(now, "second")

    return seconds
  }

  const displayExp = (seconds: number) => {
    // Calculate hours, minutes, and seconds from seconds
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    // Format the time difference
    const formattedDiff = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
    return formattedDiff
  }

  const handlePasswordBack = () => {
    onClose()
  }

  const handleOnPasswordConfirm = async (pwd: string) => {
    const body: PostAcceptOfferBody = {
      id: id,
      password: pwd,
    }
    const response = await postAcceptOfferHook(body)
    let alert: Alert = {
      visible: true,
    }
    if (response?.statusCode === 200) {
      onClose()
      alert.data = {
        type: AlertType.SUCCESS,
        description:
          "Your accept an offer completed successfully.\nPlease wait about 5-20 minute",
        button: {
          label: "Close",
          action: () => {
            window.location.href = "/listing-and-offering"
          },
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: "Your accept an offer failed.\nPlease try again.",
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setExpDateTime(calExp(expire_at))
    setTimeout(() => {
      setExpDateTime(calExp(expire_at))
    }, 1000)
  })

  return (
    <>
      <GridItem>
        <Card p={6}>
          <Stack>
            {option === 1 && (
              <>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold">
                    {invoiceOptionIdToString(option)}
                  </Text>
                  <StatusDot status="Wait For Approval" />
                </Flex>
                <Flex justifyContent="space-between" fontWeight="bold" mt={2}>
                  <Text fontWeight="bold">Offer by</Text>
                  <Text>{`${operator_company_name} - ${capitalizeFirstLetter(operator_wallet_nickname)} (${abbreviateHexString(
                    operator_wallet_address
                  )})`}</Text>
                </Flex>
                <Flex justifyContent="space-between" fontWeight="bold" mt={2}>
                  <Text>Total Discounting</Text>
                  <Text>{numberFormatForCurrency(discount)} NEW</Text>
                </Flex>
                <Flex justifyContent="space-between" mt={2}>
                  <Box textAlign="center" fontWeight="bold">
                    <Text fontSize="sm">Offer Amount</Text>
                    <Text>
                      {numberFormatForCurrency(`${parseFloat(amount) - parseFloat(discount)}`)} NEW
                    </Text>
                  </Box>
                  <Box textAlign="center" fontWeight="bold">
                    <Text fontSize="sm">Expire in</Text>
                    <Text fontSize="sm">{displayExp(expDateTime)}</Text>
                  </Box>
                </Flex>
              </>
            )}
            {option === 2 && (
              <>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold">
                    {invoiceOptionIdToString(option)}
                  </Text>
                  <StatusDot status="Wait For Approval" />
                </Flex>
                <Flex justifyContent="space-between" fontWeight="bold" mt={2}>
                  <Text fontWeight="bold">Offer by</Text>
                  <Text>{`${operator_company_name} - ${capitalizeFirstLetter(operator_wallet_nickname)} (${abbreviateHexString(
                    operator_wallet_address
                  )})`}</Text>
                </Flex>
                <Flex justifyContent="space-between" mt={1}>
                  <Text fontWeight="bold">Total Discounting</Text>
                  <Text fontWeight="bold" fontSize="sm">
                    {numberFormatForCurrency(discount)} NEW
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" mt={1}>
                  <Box textAlign="center" fontWeight="bold">
                    <Text fontSize="sm">Offer Amount</Text>
                    <Text>
                      {numberFormatForCurrency(
                        `${parseFloat(amount) - parseFloat(discount)}`
                      )}{" "}
                      NEW
                    </Text>
                  </Box>
                  <Box textAlign="center" fontWeight="bold">
                    <Text fontSize="sm">Expire in</Text>
                    <Text fontSize="sm">{displayExp(expDateTime)}</Text>
                  </Box>
                </Flex>
              </>
            )}
            {option === 3 && (
              <>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold">
                    {invoiceOptionIdToString(option)}
                  </Text>
                  <StatusDot status="Wait For Approval" />
                </Flex>
                <Flex justifyContent="space-between" fontWeight="bold" mt={2}>
                  <Text fontWeight="bold">Offer by</Text>
                  <Text>{`${operator_company_name} - ${capitalizeFirstLetter(operator_wallet_nickname)} (${abbreviateHexString(
                    operator_wallet_address
                  )})`}</Text>
                </Flex>
                <Flex justifyContent="space-between" mt={1}>
                  <Text fontWeight="bold">Total Discounting</Text>
                  <Text fontWeight="bold" fontSize="sm">
                    {invoice &&
                      numberFormatForCurrency(
                        `${
                          parseFloat(discount) +
                          parseFloat(invoice.commitment_flat_fee) +
                          parseFloat(invoice.guarantee_flat_fee)
                        }`
                      )}{" "}
                    NEW
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" mt={1}>
                  <Box textAlign="center" fontWeight="bold">
                    <Text fontSize="sm">Offer Amount</Text>
                    <Text>
                      {invoice &&
                        numberFormatForCurrency(
                          `${
                            parseFloat(amount) -
                            parseFloat(discount) -
                            parseFloat(invoice.commitment_flat_fee) -
                            parseFloat(invoice.guarantee_flat_fee)
                          }`
                        )}{" "}
                      NEW
                    </Text>
                  </Box>
                  <Box textAlign="center" fontWeight="bold">
                    <Text fontSize="sm">Expire in</Text>
                    <Text fontSize="sm">{displayExp(expDateTime)}</Text>
                  </Box>
                </Flex>
              </>
            )}
            <Flex gap={4}>
              <PrimaryButton
                variant="outline"
                color="#FF6B6B"
                borderColor="#FF6B6B"
                isDisabled={expDateTime <= 0}
                onClick={() => modalDecline.onOpen()}
              >
                Decline an Offer
              </PrimaryButton>
              <PrimaryButton
                colorScheme="#0050C8"
                background="#0050C8"
                isDisabled={expDateTime <= 0}
                onClick={() => onOpen()}
              >
                Accept an Offer
              </PrimaryButton>
            </Flex>
          </Stack>
        </Card>
      </GridItem>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={8}>
            <ConfirmPassword
              header="Accept an Offer"
              onClickBack={handlePasswordBack}
              onClickConfirm={handleOnPasswordConfirm}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <DeclineAnOfferModal {...modalDecline} id={id} />
    </>
  )
}

export default OfferDetail
