import { diffDate } from "common/utils/transform"

/**
 * Calculates the interest fee.
 * @param {Date} contractDate - The date when the contract was made.
 * @param {Date} paymentDate - The date of the payment.
 * @param {number} rate - The annual interest rate.
 * @param {number} amount - The principal amount.
 * @returns {number} The calculated interest fee.
 */
export const calculateInterestFee = (
  contractDate: Date,
  paymentDate: Date,
  rate: number,
  amount: number
): number => {
  const daysBetween = diffDate(paymentDate, contractDate)
  const dailyRate = rate / 365
  return (amount * dailyRate * daysBetween) / 100
}

/**
 * Calculates by percent.
 * @param {number} rate - The rate.
 * @param {number} amount - The principal amount.
 * @returns {number} The calculated fee.
 */
export const calculateByPercent = (rate: number, amount: number): number => {
  return (amount * rate) / 100
}
