import { Icon, IconProps } from "@chakra-ui/react"

const CalendarDueIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 84 84" fill="none" {...props}>
    <path
      d="M18.666 23.3346C18.666 22.097 19.1577 20.91 20.0329 20.0348C20.908 19.1596 22.095 18.668 23.3327 18.668H60.666C61.9037 18.668 63.0907 19.1596 63.9659 20.0348C64.841 20.91 65.3327 22.097 65.3327 23.3346C65.3327 24.5723 64.841 25.7593 63.9659 26.6345C63.0907 27.5096 61.9037 28.0013 60.666 28.0013H23.3327C22.095 28.0013 20.908 27.5096 20.0329 26.6345C19.1577 25.7593 18.666 24.5723 18.666 23.3346ZM41.9994 60.668C44.4747 60.668 46.8487 59.6846 48.599 57.9343C50.3494 56.184 51.3327 53.81 51.3327 51.3346C51.3327 48.8593 50.3494 46.4853 48.599 44.735C46.8487 42.9846 44.4747 42.0013 41.9994 42.0013C39.524 42.0013 37.15 42.9846 35.3997 44.735C33.6493 46.4853 32.666 48.8593 32.666 51.3346C32.666 53.81 33.6493 56.184 35.3997 57.9343C37.15 59.6846 39.524 60.668 41.9994 60.668Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 0C10.287 0 6.72601 1.475 4.10051 4.10051C1.475 6.72601 0 10.287 0 14V70C0 73.713 1.475 77.274 4.10051 79.8995C6.72601 82.525 10.287 84 14 84H70C73.713 84 77.274 82.525 79.8995 79.8995C82.525 77.274 84 73.713 84 70V14C84 10.287 82.525 6.72601 79.8995 4.10051C77.274 1.475 73.713 0 70 0H14ZM70 9.33333H14C12.7623 9.33333 11.5753 9.825 10.7002 10.7002C9.825 11.5753 9.33333 12.7623 9.33333 14V70C9.33333 71.2377 9.825 72.4247 10.7002 73.2998C11.5753 74.175 12.7623 74.6667 14 74.6667H70C71.2377 74.6667 72.4247 74.175 73.2998 73.2998C74.175 72.4247 74.6667 71.2377 74.6667 70V14C74.6667 12.7623 74.175 11.5753 73.2998 10.7002C72.4247 9.825 71.2377 9.33333 70 9.33333Z"
      fill="white"
    />
  </Icon>
)

export default CalendarDueIcon
