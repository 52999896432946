// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Flex, Box, Text, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { SellerInvoiceModal } from "modules/guarantee_management"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useGuaranteeHook from "hooks/useGuaranteeHook"

// ** Types **
import { HistoryItem } from "types/Guarantee"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateDD_MMMM_YYYY,
  invoiceOptionIdToString,
  removeDuplicates,
  capitalizeFirstLetter,
} from "common/utils/transform"

// ** Icons **
import { ArrowRightIcon } from "assets/icons"

const BySellerCompanyInvoiceList = ({
  ptId,
  isNormal,
}: {
  ptId: number
  isNormal: boolean
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [invoiceData, setInvoiceData] = useState<HistoryItem>()
  const modalDetail = useDisclosure()

  const {
    getAcceptedGuaranteedGroupForSponsorSellerInvoiceHook,
    sellerInvoices,
  } = useGuaranteeHook()

  useEffect(() => {
    setData(
      sellerInvoices.filter((m) =>
        isNormal ? m.status !== "overdue" : m.status === "overdue"
      )
    )
  }, [sellerInvoices, isNormal])

  const { isLoading, refetch } = useQuery({
    queryKey: "initGetAcceptedGuaranteedGroupForSponsorSellerInvoice",
    queryFn: () => getAcceptedGuaranteedGroupForSponsorSellerInvoiceHook(ptId),
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptId])

  const column: TableColumn[] = [
    {
      title: "Custom",
      value: "custom",
      isFooter: true,
      customRender: (key: string, value: any) => {
        return (
          <Flex key={key} w="100%">
            <Box
              flex="0 0 80px"
              bgColor="emptyColor"
              h="80px"
              borderRadius={8}
              mr={4}
            />
            <Box w="100%" fontSize="14">
              <Flex>
                <Text fontWeight="bold" mr={2}>
                  DocNo. :
                </Text>
                <Text fontWeight="bold">{value.doc_number}</Text>
              </Flex>
              <Flex>
                <Text mr={2}>DocType :</Text>
                <Text fontWeight="bold">Invoice</Text>
              </Flex>
              <Flex>
                <Text mr={2}>Seller Name :</Text>
                <Text fontWeight="bold">
                  {capitalizeFirstLetter(value.seller_company_name)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Buyer Name :</Text>
                <Text fontWeight="bold">
                  {capitalizeFirstLetter(value.buyer_company_name)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Schedule payment Date :</Text>
                <Text fontWeight="bold">
                  {formatDateDD_MMMM_YYYY(value.payment_date)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Option :</Text>
                <Text fontWeight="bold">
                  {invoiceOptionIdToString(value.offer_option)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Guarantee Fee Rate :</Text>
                <Text fontWeight="bold">
                  {numberFormatForCurrency(value.guarantee_fee_rate) + "%"}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Amount (NEW) :</Text>
                <Text fontWeight="bold">
                  {numberFormatForCurrency(value.amount)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Overdue :</Text>
                <Text fontWeight="bold">{value.overdue_day} days</Text>
              </Flex>
              <Flex>
                <Text mr={2}>Status :</Text>
                <Text fontWeight="bold">
                  <SuccessBadge label={value.status} />
                </Text>
              </Flex>
            </Box>
            <Flex alignItems="center">
              <ArrowRightIcon />
            </Flex>
          </Flex>
        )
      },
    },
  ]

  const onRowClick = (data: HistoryItem) => {
    setInvoiceData(data)
    modalDetail.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: HistoryItem[] =
      sellerInvoices.filter((m) =>
        isNormal ? m.status !== "overdue" : m.status === "overdue"
      ) ?? []

    temp = removeDuplicates(temp, "id")

    const filterValue = temp.filter((m) =>
      (m.doc_number + m.offer_company_name)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, sellerInvoices, isNormal, ptId])

  if (isLoading) return <></>

  return (
    <Box>
      {ptId !== undefined && data && data.length > 0 && (
        <Flex p={8} py={4} gap={8} fontSize={16}>
          <Flex gap={8}>
            <Text>Seller Name</Text>
            <Text
              fontWeight="bold"
              css={{
                "&:first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {data[0].seller_company_name}
            </Text>
          </Flex>
          <Flex gap={8}>
            <Text>Seller Juristic ID</Text>
            <Text fontWeight="bold">{data[0].seller_juristic_id}</Text>
          </Flex>
        </Flex>
      )}
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        style={TableStyle.BOX}
        onRowClick={onRowClick}
        searchPlaceholder="Search"
        onSearchChange={onSearchChange}
        isCustom={true}
      />
      <SellerInvoiceModal
        {...modalDetail}
        data={invoiceData}
        onClickClose={() => modalDetail.onClose()}
      />
    </Box>
  )
}

export default BySellerCompanyInvoiceList
