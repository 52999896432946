import { Icon, IconProps } from "@chakra-ui/react"

const IncreaseIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M20 40C10.572 40 5.858 40 2.928 37.07C0 34.144 0 29.428 0 20C0 10.572 0 5.858 2.928 2.928C5.86 0 10.572 0 20 0C29.428 0 34.142 0 37.07 2.928C40 5.86 40 10.572 40 20C40 29.428 40 34.142 37.07 37.07C34.144 40 29.428 40 20 40Z"
      fill="currentColor"
    />
    <path
      d="M20 12.5C20.3978 12.5 20.7794 12.658 21.0607 12.9393C21.342 13.2206 21.5 13.6022 21.5 14V18.5H26C26.3978 18.5 26.7794 18.658 27.0607 18.9393C27.342 19.2206 27.5 19.6022 27.5 20C27.5 20.3978 27.342 20.7794 27.0607 21.0607C26.7794 21.342 26.3978 21.5 26 21.5H21.5V26C21.5 26.3978 21.342 26.7794 21.0607 27.0607C20.7794 27.342 20.3978 27.5 20 27.5C19.6022 27.5 19.2206 27.342 18.9393 27.0607C18.658 26.7794 18.5 26.3978 18.5 26V21.5H14C13.6022 21.5 13.2206 21.342 12.9393 21.0607C12.658 20.7794 12.5 20.3978 12.5 20C12.5 19.6022 12.658 19.2206 12.9393 18.9393C13.2206 18.658 13.6022 18.5 14 18.5H18.5V14C18.5 13.6022 18.658 13.2206 18.9393 12.9393C19.2206 12.658 19.6022 12.5 20 12.5Z"
      fill="#FFFFFF"
    />
  </Icon>
)

export default IncreaseIcon
