// ** React **
import { FC, useState, useEffect } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Image,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"
import TextInput from "common/components/TextInput"
import TextAreaInput from "common/components/TextAreaInput"
import Select from "react-select"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import { useQuery } from "hooks/useQueryHook"
import useAdminHook from "hooks/useAdminHook"
import useCampaignHook from "hooks/useCampaignHook"

// ** Types **
import { ModalProps } from "types/ModalProps"
import { CampaignBody } from "types/Campaign"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { FormReviewBody } from "constants/Enum"

type CampaignEditModalProps = { id: number } & ModalProps

const CampaignEditModal: FC<CampaignEditModalProps> = (props) => {
  const { isOpen, onClose, id } = props
  const { setAlert } = useAppHook()
  const [logoFile, setLogoFile] = useState<File>()
  const [coverFile, setCoverFile] = useState<File>()
  const [imageLogoSrc, setImageLogoSrc] = useState<any>()
  const [imageCoverSrc, setImageCoverSrc] = useState<any>()

  const [contentBody, setContentBody] = useState<FormReviewBody>(
    FormReviewBody.FORM
  )

  const { updateCampaignHook, getCampaignHook, campaign } = useCampaignHook()

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CampaignBody>({
    mode: "onChange",
  })

  const title = watch("title")
  const detail = watch("detail")
  const date = watch("date")
  const ownerOption = watch("ownerOption")

  useEffect(() => {
    if (ownerOption) {
      setValue("owner_user_id", parseInt(ownerOption?.value))
    }
  }, [ownerOption, setValue])

  useEffect(() => {
    if (campaign) {
      const _date = campaign?.date ? campaign?.date?.split("T")[0] : ""
      reset({
        title: campaign.title,
        detail: campaign.detail,
        date: _date,
        is_highlight: campaign.is_highlight,
      })
      setValue("ownerOption", {
        label: `${campaign.owner_first_name} ${campaign.owner_last_name}`,
        value: `${campaign.owner_user_id}`,
      })
      setImageLogoSrc(process.env.REACT_APP_BASE_URL + "/" + campaign.logo)
      setImageCoverSrc(process.env.REACT_APP_BASE_URL + "/" + campaign.cover)
    }
  }, [campaign, reset, setValue])

  const validateForm = () => {
    const ownerOption = watch("ownerOption")
    if (title === undefined)
      setError("title", { message: "Please enter title" })
    if (detail === undefined)
      setError("detail", {
        message: "Please enter detail",
      })
    if (ownerOption === undefined)
      setError("ownerOption", { message: "Please enter owner" })
    if (
      title !== undefined &&
      detail !== undefined &&
      ownerOption !== undefined
    ) {
      setContentBody(FormReviewBody.REVIEW)
    }
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (file) {
        setLogoFile(file)
        const reader = new FileReader()
        reader.onload = (readEvent) => {
          setImageLogoSrc(readEvent.target?.result as string)
        }
        reader.readAsDataURL(file)
      }
    }
  }
  const handleImageCoverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      if (file) {
        setCoverFile(file)
        const reader = new FileReader()
        reader.onload = (readEvent) => {
          setImageCoverSrc(readEvent.target?.result as string)
        }
        reader.readAsDataURL(file)
      }
    }
  }

  const onSubmit: SubmitHandler<CampaignBody> = async (data) => {
    let body: any = { ...data }
    delete body.statusOption
    if (logoFile) body.logo = logoFile
    if (coverFile) body.cover = coverFile

    const response = await updateCampaignHook(id, body)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "News & Campaign has been updated.",
        button: {
          label: "Close",
        },
      }
      reset()
      onClose()
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
    setContentBody(FormReviewBody.FORM)
  }

  const { getAdminHook, admin } = useAdminHook()

  const { isLoading } = useQuery({
    queryKey: "initAdminList",
    queryFn: getAdminHook,
  })

  const { isLoading: isLoadingCampaign } = useQuery({
    queryKey: "initCampaignDetail",
    queryFn: () => getCampaignHook(id),
  })

  if (isLoading || isLoadingCampaign) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={contentBody === FormReviewBody.FORM ? "block" : "none"}
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="md">Edit News & Campaign</Heading>
                </Box>
                <Controller
                  control={control}
                  name="title"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Title"
                      placeholder="Enter your title"
                      error={error}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Box mb={3} w="100%">
                  <Controller
                    control={control}
                    name="detail"
                    render={({ field, fieldState: { error } }) => (
                      <TextAreaInput
                        {...field}
                        label="Detail"
                        labelSmall
                        placeholder="Enter your detail"
                        error={error}
                      />
                    )}
                  />
                </Box>
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm" color="black">
                      Owner
                    </Heading>
                  </Box>
                  <Controller
                    name="ownerOption"
                    render={({ field }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select Company Type"
                          options={admin.map((item) => {
                            return {
                              label: `${item.firstname ? item.firstname : ""} ${
                                item.lastname ? item.lastname : ""
                              }`,
                              value: `${item.id}`,
                            }
                          })}
                          onChange={(value) => field.onChange(value)}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                  {errors.ownerOption && (
                    <Text fontSize="sm" color="error">
                      {errors.ownerOption.message}
                    </Text>
                  )}
                </Box>
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm" color="black">
                      Date
                    </Heading>
                  </Box>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => <input type="date" {...field} />}
                  />
                  {errors.date && (
                    <Text fontSize="sm" color="error">
                      {errors.date.message}
                    </Text>
                  )}
                </Box>
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm" color="black">
                      Feature Image
                    </Heading>
                  </Box>
                  <Box>
                    <Controller
                      name="logo"
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <input
                          {...field}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            onChange(e)
                            handleImageChange(e)
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm" color="black">
                      Banner Image
                    </Heading>
                  </Box>
                  <Box>
                    <Controller
                      name="cover"
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <input
                          {...field}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            onChange(e)
                            handleImageCoverChange(e)
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="primary"
                  backgroundColor="primary"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Publish
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={contentBody === FormReviewBody.REVIEW ? "block" : "none"}
            >
              <Box mb={4} textAlign="center">
                <Heading fontSize="24px" color="#353535">
                  Confirm to Create News & Campaign
                </Heading>
              </Box>
              <Box
                borderBottom="1px solid"
                borderColor="emptyColor"
                mb={4}
                pb={4}
              >
                <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
                  Title
                </Text>
                <Text fontWeight="bold">{title}</Text>
              </Box>
              <Box
                borderBottom="1px solid"
                borderColor="emptyColor"
                mb={4}
                pb={4}
              >
                <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
                  Details
                </Text>
                <Text fontWeight="bold">{detail}</Text>
              </Box>
              <Box
                borderBottom="1px solid"
                borderColor="emptyColor"
                mb={4}
                pb={4}
              >
                <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
                  Owner
                </Text>
                <Text fontWeight="bold">{ownerOption?.label}</Text>
              </Box>
              <Box
                borderBottom="1px solid"
                borderColor="emptyColor"
                mb={4}
                pb={4}
              >
                <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
                  Date
                </Text>
                <Text fontWeight="bold">{date}</Text>
              </Box>
              <Box
                borderBottom="1px solid"
                borderColor="emptyColor"
                mb={4}
                pb={4}
              >
                <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
                  Feature Image
                </Text>
                <Box>
                  {imageLogoSrc ? (
                    <Image w={40} src={imageLogoSrc} />
                  ) : (
                    <Box w={40} h={40} bgColor="grey400" borderRadius={8} />
                  )}
                </Box>
              </Box>
              <Box
                borderBottom="1px solid"
                borderColor="emptyColor"
                mb={4}
                pb={4}
              >
                <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
                  Banner Image
                </Text>
                <Box>
                  {imageCoverSrc ? (
                    <Image w={40} src={imageCoverSrc} />
                  ) : (
                    <Box w={40} h={40} bgColor="grey400" borderRadius={8} />
                  )}
                </Box>
              </Box>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() => setContentBody(FormReviewBody.FORM)}
                >
                  Back to edit
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CampaignEditModal
