// ** React **
import { FC } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Image,
  Heading,
} from "@chakra-ui/react"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useCampaignHook from "hooks/useCampaignHook"

// ** Types **
import { ModalProps } from "types/ModalProps"

type CampaignDetailModalProps = {
  id: number
} & ModalProps

const CampaignDetailModal: FC<CampaignDetailModalProps> = (props) => {
  const { isOpen, onClose, id } = props

  const { getCampaignHook, campaign } = useCampaignHook()

  const { isLoading } = useQuery({
    queryKey: "initCampaignDetail",
    queryFn: () => getCampaignHook(id),
  })

  if (isLoading) return <></>

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <Box mb={4} textAlign="center">
            <Heading fontSize="24px" color="#353535">
              News & Campaign Detail
            </Heading>
          </Box>
          <Box borderBottom="1px solid" borderColor="emptyColor" mb={4} pb={4}>
            <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
              Title
            </Text>
            <Text fontWeight="bold">{campaign.title}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="emptyColor" mb={4} pb={4}>
            <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
              Details
            </Text>
            <Text fontWeight="bold">{campaign.detail}</Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="emptyColor" mb={4} pb={4}>
            <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
              Owner
            </Text>
            <Text fontWeight="bold">
              {campaign.owner_first_name} {campaign.owner_last_name}
            </Text>
          </Box>
          <Box borderBottom="1px solid" borderColor="emptyColor" mb={4} pb={4}>
            <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
              Feature Image
            </Text>
            <Box>
              {campaign.logo ? (
                <Image
                  w={40}
                  src={process.env.REACT_APP_BASE_URL + "/" + campaign.logo}
                />
              ) : (
                <Box w={40} h={40} bgColor="grey400" borderRadius={8} />
              )}
            </Box>
          </Box>
          <Box borderBottom="1px solid" borderColor="emptyColor" mb={4} pb={4}>
            <Text color="secondaryTextColor" fontWeight="bold" mb={2}>
              Banner Image
            </Text>
            <Box>
              {campaign.cover ? (
                <Image
                  w={40}
                  src={process.env.REACT_APP_BASE_URL + "/" + campaign.cover}
                />
              ) : (
                <Box w={40} h={40} bgColor="grey400" borderRadius={8} />
              )}
            </Box>
          </Box>
          <Flex justify="center">
            <PrimaryButton variant="outline" onClick={() => onClose()} w={250}>
              Back
            </PrimaryButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CampaignDetailModal
