import { FormControl, FormLabel, Heading, Switch } from "@chakra-ui/react";
import { useState } from "react";

const SwitchInput = () => {
  const [activate, setActivate] = useState(false);
  return (
    <FormControl display="flex" alignItems="center" gap={4}>
      <Switch
        id="activate"
        colorScheme="primary500"
        isChecked={activate}
        onChange={() => setActivate(!activate)}
      />
      <FormLabel htmlFor="activate" m={0} p={0}>
        <Heading size="xs" color="primary">
          {activate ? "Activate" : "Deactivate"}
        </Heading>
      </FormLabel>
    </FormControl>
  );
};

export default SwitchInput;
