import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetAcceptedGuaranteedBuyersResponse } from "./types/getAcceptedGuaranteedBuyersType";

const getAcceptedGuaranteedBuyer = async (): Promise<AxiosResponse<GetAcceptedGuaranteedBuyersResponse>> => {
  const request = await $axios().get("/get_accepted_guaranteed_buyer");
  return request;
};

export default getAcceptedGuaranteedBuyer;
