// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { appActions } from "store/slice/AppSlice";
import { transactionActions } from "store/slice/TransactionSlice";

// ** APIs **
import {
  getTransactionToken,
  getTransactionsInvoiceStable,
  getCreditTransaction,
  getTransactionsInvoiceCredit,
  getTransactionsInvoiceNft,
  getOffchainLogs,
} from "api/transaction";

const useTransactionHook = () => {
  const dispatch = useAppDispatch();

  const {
    transactionTokens,
    transactionsInvoiceStables,
    creditTransactions,
    transactionsInvoiceCredits,
    transactionsInvoiceNfts,
    offchainLogs,
  } = useAppSelector((state: RootState) => state.transaction);

  const getTransactionTokensHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getTransactionToken();
      dispatch(transactionActions.setTransactionTokens(response.data.data));
    } catch (err) {
      console.log("getTransactionTokensHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getTransactionsInvoiceStablesHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getTransactionsInvoiceStable();
      dispatch(
        transactionActions.setTransactionsInvoiceStables(response.data.data),
      );
    } catch (err) {
      console.log("getTransactionsInvoiceStablesHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getCreditTransactionsHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getCreditTransaction();
      dispatch(transactionActions.setCreditTransactions(response.data.data));
    } catch (err) {
      console.log("getCreditTransactionsHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getTransactionsInvoiceCreditsHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getTransactionsInvoiceCredit();
      dispatch(
        transactionActions.setTransactionsInvoiceCredits(response.data.data),
      );
    } catch (err) {
      console.log("getTransactionsInvoiceCreditsHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getTransactionsInvoiceNftsHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getTransactionsInvoiceNft();
      dispatch(
        transactionActions.setTransactionsInvoiceNfts(response.data.data),
      );
    } catch (err) {
      console.log("getTransactionsInvoiceNftsHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getOffchainLogsHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getOffchainLogs();
      dispatch(transactionActions.setOffchainLogs(response.data.data));
    } catch (err) {
      console.log("getOffchainLogsHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  return {
    transactionTokens,
    transactionsInvoiceStables,
    creditTransactions,
    transactionsInvoiceCredits,
    transactionsInvoiceNfts,
    offchainLogs,
    getTransactionTokensHook,
    getTransactionsInvoiceStablesHook,
    getCreditTransactionsHook,
    getTransactionsInvoiceCreditsHook,
    getTransactionsInvoiceNftsHook,
    getOffchainLogsHook,
  };
};

export default useTransactionHook;
