import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetAcceptedGuaranteedForSponsorResponse,
  GetAcceptedGuaranteedGroupForSponsorBuyerResponse,
  GetAcceptedGuaranteedGroupForSponsorBuyerInvoiceResponse,
  GetAcceptedGuaranteedGroupForSponsorSellerResponse,
  GetAcceptedGuaranteedGroupForSponsorSellerInvoiceResponse,
} from "./types/gunrantee"

const getAcceptedGuaranteedForSponsor = async (): Promise<
  AxiosResponse<GetAcceptedGuaranteedForSponsorResponse>
> => {
  const request = await $axios().get("/get_accepted_guaranteed_for_sponsor")
  return request
}

const getAcceptedGuaranteedGroupForSponsorBuyer = async (): Promise<
  AxiosResponse<GetAcceptedGuaranteedGroupForSponsorBuyerResponse>
> => {
  const request = await $axios().get(
    "/get_accepted_guaranteed_group_for_sponsor/buyer"
  )
  return request
}

const getAcceptedGuaranteedGroupForSponsorBuyerInvoice = async (
  ptId: number
): Promise<
  AxiosResponse<GetAcceptedGuaranteedGroupForSponsorBuyerInvoiceResponse>
> => {
  const request = await $axios().get(
    "/get_accepted_guaranteed_for_sponsor/buyer/" + ptId
  )
  return request
}

const getAcceptedGuaranteedGroupForSponsorSeller = async (): Promise<
  AxiosResponse<GetAcceptedGuaranteedGroupForSponsorSellerResponse>
> => {
  const request = await $axios().get(
    "/get_accepted_guaranteed_group_for_sponsor/seller"
  )
  return request
}

const getAcceptedGuaranteedGroupForSponsorSellerInvoice = async (
  ptId: number
): Promise<
  AxiosResponse<GetAcceptedGuaranteedGroupForSponsorSellerInvoiceResponse>
> => {
  const request = await $axios().get(
    "/get_accepted_guaranteed_for_sponsor/seller/" + ptId
  )
  return request
}

export {
  getAcceptedGuaranteedForSponsor,
  getAcceptedGuaranteedGroupForSponsorBuyer,
  getAcceptedGuaranteedGroupForSponsorBuyerInvoice,
  getAcceptedGuaranteedGroupForSponsorSeller,
  getAcceptedGuaranteedGroupForSponsorSellerInvoice,
}
