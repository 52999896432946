// ** Libs **
import { Flex, Box, Text } from "@chakra-ui/react"

// ** Hook **
import { useQuery } from "hooks/useQueryHook"
import useTokenManagementHook from "hooks/useTokenManagementHook"

// ** Utils **
import { numberFormatForCurrency } from "common/utils/transform"

const StableCoinBalance = () => {
  const { getStableCoinBalanceHook, stableCoinBalance } =
    useTokenManagementHook()

  const { isLoading } = useQuery({
    queryKey: "getStableCoinBalance",
    queryFn: getStableCoinBalanceHook,
  })

  if (isLoading) return <></>

  return (
    <Flex justify="center">
      <Box
        border="1px solid #EDEDED"
        borderRadius="16px"
        p="24px"
        textAlign="center"
      >
        <Flex alignItems="center">
          <Text fontSize="24px" fontWeight="bold">
            {numberFormatForCurrency(stableCoinBalance)}
          </Text>
          <Text fontSize="16px" fontWeight="bold" ml="16px">
            Total Stable Coin
          </Text>
        </Flex>
      </Box>
    </Flex>
  )
}

export default StableCoinBalance
