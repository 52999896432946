import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetTransactionTokensResponse,
  GetTransactionInvoiceResponse,
} from "./types/getTransactionToken"

const getTransactionToken = async (): Promise<
  AxiosResponse<GetTransactionTokensResponse>
> => {
  const request = await $axios().get("/transaction_token")
  return request
}

const getTransactionInvoice = async (): Promise<
  AxiosResponse<GetTransactionInvoiceResponse>
> => {
  const request = await $axios().get("/invoice_transaction")
  return request
}

export { getTransactionToken, getTransactionInvoice }
