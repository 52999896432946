import {
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  CheckboxProps,
  Stack,
  StackDirection,
} from "@chakra-ui/react";
import { FC } from "react";

export type CheckBoxInputProps = {
  direction: StackDirection;
  options: CheckboxProps[];
} & CheckboxGroupProps;

const CheckBoxInput: FC<CheckBoxInputProps> = (props, ref) => {
  const { options, direction, ...propsInput } = props;

  return (
    <CheckboxGroup {...propsInput}>
      <Stack direction={direction} gap={4}>
        {options.map((opt, i) => (
          <Checkbox key={i} {...opt}>
            {opt.title}
          </Checkbox>
        ))}
      </Stack>
    </CheckboxGroup>
  );
};

export default CheckBoxInput;
