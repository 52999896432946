import { Icon, IconProps } from "@chakra-ui/react"

const CashIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 91 73" fill="none" {...props}>
    <path
      d="M20.5007 32.3327V48.9993M70.5006 23.9993V40.666M66.334 3.16602C76.5369 3.16602 82.0554 4.72752 84.8011 5.93869C85.1668 6.09999 85.3496 6.18064 85.8773 6.68423C86.1936 6.98609 86.771 7.87182 86.9195 8.28305C87.1673 8.96909 87.1673 9.3441 87.1673 10.0941V54.8791C87.1673 58.6656 87.1673 60.5589 86.5995 61.5321C86.0219 62.522 85.4649 62.9822 84.3837 63.3626C83.3209 63.7366 81.1755 63.3244 76.8847 62.5C73.8814 61.9229 70.3195 61.4993 66.334 61.4993C53.834 61.4993 41.334 69.8327 24.6673 69.8327C14.4644 69.8327 8.94593 68.2712 6.20016 67.06C5.8345 66.8987 5.65166 66.818 5.12398 66.3145C4.80768 66.0126 4.23031 65.1269 4.08178 64.7156C3.83398 64.0296 3.83398 63.6546 3.83398 62.9046L3.83399 18.1196C3.83399 14.333 3.83399 12.4398 4.40181 11.4666C4.97941 10.4767 5.53645 10.0165 6.61756 9.63606C7.68039 9.26206 9.82579 9.67428 14.1166 10.4987C17.1199 11.0758 20.6818 11.4993 24.6673 11.4993C37.1673 11.4993 49.6673 3.16602 66.334 3.16602ZM55.9173 36.4993C55.9173 42.2523 51.2536 46.916 45.5006 46.916C39.7477 46.916 35.084 42.2523 35.084 36.4993C35.084 30.7464 39.7477 26.0827 45.5006 26.0827C51.2536 26.0827 55.9173 30.7464 55.9173 36.4993Z"
      stroke="#FBFDFF"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CashIcon
