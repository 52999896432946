// ** React **
import { useState } from "react";

// ** Libs **
import TabCustom from "common/components/TabCustom";

// ** Components **
import { Card, Box } from "@chakra-ui/react";
import { TransactionTab, ActivityTab } from "./tab";

const TransactionAndActivityPage = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Card p={6}>
      <Box w={530}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Transaction",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Activity",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
          ]}
        />
      </Box>

      <Box display={tabIndex === 0 ? "block" : "none"}>
        <TransactionTab />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <ActivityTab />
      </Box>
    </Card>
  );
};

export default TransactionAndActivityPage;
