// ** React **
import { useState } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import {
  PaymentGuarantee,
  WithoutPaymentGuarantee,
} from "modules/ap_management"

const PaymentTab = ({ isPayment }: { isPayment: boolean }) => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Box>
      <Box w={550}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Payment Guarantee",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: "Wtihout Payment Guarantee",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"} py={4}>
        <PaymentGuarantee isPayment={isPayment} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"} py={4}>
        <WithoutPaymentGuarantee isPayment={isPayment} />
      </Box>
    </Box>
  )
}

export default PaymentTab
