import { RootState, useAppDispatch, useAppSelector } from "../store";
import { appActions } from "../store/slice/AppSlice";
import { Alert } from "../types/Alert";

const useAppHook = () => {
  const dispatch = useAppDispatch();
  const { loader, alert } = useAppSelector((state: RootState) => state.app);

  const showLoader = () => {
    dispatch(appActions.showLoader());
  };

  const hideLoader = () => {
    dispatch(appActions.hideLoader());
  };

  const setAlert = (data: Alert) => {
    dispatch(appActions.setAlert(data));
  };

  return { showLoader, hideLoader, setAlert, loader, alert };
};

export default useAppHook;
