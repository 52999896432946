import { Icon, IconProps } from "@chakra-ui/react"

const CoinReceiveIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 91 90" fill="none" {...props}>
    <path
      d="M51.8735 29.8001C49.4884 31.5004 46.5697 32.5006 43.4173 32.5006C35.3632 32.5006 28.834 25.9715 28.834 17.9173C28.834 9.86316 35.3632 3.33398 43.4173 3.33398C48.6381 3.33398 53.2181 6.07739 55.7944 10.2012M20.5007 78.6971H31.3769C32.7949 78.6971 34.2043 78.8659 35.579 79.2033L47.071 81.996C49.5646 82.6034 52.1622 82.6625 54.6818 82.1732L67.388 79.7011C70.7446 79.0473 73.8322 77.44 76.2521 75.0861L85.2419 66.3411C87.8091 63.848 87.8091 59.8024 85.2419 57.3051C82.9304 55.0566 79.2703 54.8035 76.6554 56.7103L66.1781 64.3542C64.6777 65.451 62.852 66.0416 60.9742 66.0416H50.8569L57.2968 66.0413C60.9265 66.0413 63.8667 63.1812 63.8667 59.6503V58.3721C63.8667 55.4402 61.8155 52.8838 58.8926 52.1751L48.9531 49.7579C47.3356 49.3656 45.679 49.1673 44.0137 49.1673C39.9937 49.1673 32.7169 52.4957 32.7169 52.4957L20.5007 57.6043M78.834 22.084C78.834 30.1381 72.3048 36.6673 64.2506 36.6673C56.1965 36.6673 49.6673 30.1381 49.6673 22.084C49.6673 14.0298 56.1965 7.50065 64.2506 7.50065C72.3048 7.50065 78.834 14.0298 78.834 22.084ZM3.83398 55.834L3.83398 80.0006C3.83398 82.3342 3.83398 83.501 4.28812 84.3923C4.6876 85.1763 5.32501 85.8137 6.10902 86.2132C7.00032 86.6673 8.1671 86.6673 10.5006 86.6673H13.834C16.1675 86.6673 17.3343 86.6673 18.2256 86.2132C19.0096 85.8137 19.647 85.1763 20.0465 84.3923C20.5007 83.501 20.5007 82.3342 20.5007 80.0006V55.834C20.5007 53.5004 20.5007 52.3337 20.0465 51.4424C19.647 50.6583 19.0096 50.0209 18.2256 49.6215C17.3343 49.1673 16.1675 49.1673 13.834 49.1673L10.5007 49.1673C8.1671 49.1673 7.00032 49.1673 6.10902 49.6215C5.32502 50.0209 4.6876 50.6583 4.28812 51.4424C3.83398 52.3337 3.83398 53.5004 3.83398 55.834Z"
      stroke="#FBFDFF"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CoinReceiveIcon
