import { SearchIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading, Spacer, Wrap, WrapItem } from "@chakra-ui/react"
import { PropsWithChildren, useState } from "react"
import { FilterType } from "../../../constants/Enum"
import CheckBoxInput, { CheckBoxInputProps } from "../CheckboxInput"
import Dropdown, { DropdownProps } from "../Dropdown"
import TextInput from "../TextInput"
import PrimaryButton from "../PrimaryButton"
import { allowOnlyNumbers } from "common/utils/transform"

export type FilterOption = { type: FilterType } & (
  | DropdownProps<any>
  | CheckBoxInputProps
)

type HeaderTableProps = {
  onViewSizeChange: (value: number) => void
  onSearchChange?: (value: string) => void
  headerTable?: string
  searchPlaceholder?: string
  pageSize: number
  filter?: FilterOption[]
  actions?: React.ReactElement[]
  isSearchOnlyNumber?: boolean
} & PropsWithChildren

const HeaderTable: React.FC<HeaderTableProps> = ({
  onViewSizeChange,
  onSearchChange,
  searchPlaceholder,
  pageSize,
  filter,
  headerTable,
  actions,
  isSearchOnlyNumber = false,
}) => {
  const [isError, setIsError] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>()
  const viewPage = [10, 20, 50, 100]
  const mapToOption = viewPage.map((m) => ({
    title: "View " + m,
    value: m,
  }))

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onSearchChange) {
      setKeyword(
        isSearchOnlyNumber ? allowOnlyNumbers(e.target.value) : e.target.value
      )
      if (e.target.value.length > 0) {
        setIsError(false)
      } else {
        onSearchChange(
          isSearchOnlyNumber ? allowOnlyNumbers(e.target.value) : e.target.value
        )
      }
    }
  }

  const handleOnSearch = () => {
    if (!keyword) {
      setIsError(true)
    } else {
      if (onSearchChange) {
        onSearchChange(keyword || "")
      }
    }
  }

  const filterCheckbox = filter?.filter(
    (m) => m.type === FilterType.CHECKBOX
  ) as CheckBoxInputProps[]

  const filterDropdown = filter?.filter(
    (m) => m.type === FilterType.DROPDOWN
  ) as DropdownProps<any>[]

  return (
    <Flex gap={4} direction="column">
      <Wrap
        align="center"
        spacing={4}
        justify={actions ? "space-between" : "flex-start"}
      >
        {headerTable && (
          <WrapItem>
            <Heading size="md">{headerTable}</Heading>
          </WrapItem>
        )}
        {actions && (
          <Wrap align="center" spacing={4}>
            {actions.map((action, i) => (
              <WrapItem key={i}>{action}</WrapItem>
            ))}
          </Wrap>
        )}
      </Wrap>
      <Wrap align="center" spacing={4} justify="flex-end">
        {onSearchChange ? (
          <WrapItem>
            <TextInput
              w={"300px"}
              labelSmall
              size="sm"
              placeholder={searchPlaceholder}
              onChange={handleOnSearchChange}
              value={keyword}
              mr={2}
              leftIcon={
                <SearchIcon color="#cccccc" boxSize={4} mb={1.5} mr={1} />
              }
              error={
                isError
                  ? {
                      type: "error",
                      message: "Please enter a keyword",
                    }
                  : undefined
              }
            />
            <PrimaryButton
              h="32px"
              variant="outline"
              colorScheme="primary"
              onClick={handleOnSearch}
            >
              Search
            </PrimaryButton>
          </WrapItem>
        ) : (
          <></>
        )}
        <Spacer />
        {filterCheckbox ? (
          filterCheckbox.map((fil, filKey) => (
            <WrapItem key={"cb_" + filKey}>
              <Box>
                <CheckBoxInput {...fil} />
              </Box>
            </WrapItem>
          ))
        ) : (
          <></>
        )}
        {filterDropdown ? (
          filterDropdown.map((fil, filKey) => (
            <WrapItem key={"dd_" + filKey}>
              <Box>
                <Dropdown {...fil} />
              </Box>
            </WrapItem>
          ))
        ) : (
          <></>
        )}
        <WrapItem>
          <Box>
            <Dropdown<number>
              options={mapToOption}
              value={pageSize}
              onChange={onViewSizeChange}
            />
          </Box>
        </WrapItem>
      </Wrap>
    </Flex>
  )
}

export default HeaderTable
