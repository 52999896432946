// ** React **
import { useState } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { SubTab, DiscountingByCompany } from "modules/discounting"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useDiscountingHook from "hooks/useDiscountingHook"

const MainTab = ({ option }: { option: number }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const { getDiscountingHook, getDiscountByComapnyHook } = useDiscountingHook()

  const { isLoading } = useQuery({
    queryKey: "initDiscounting" + option,
    queryFn: () => getDiscountingHook(option),
  })

  const { isLoading: isLoadingCompany } = useQuery({
    queryKey: "initDiscountingCompany" + option,
    queryFn: () => getDiscountByComapnyHook(option),
  })

  if (isLoading || isLoadingCompany) return <></>

  return (
    <Box py="6">
      <Box w={450}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Discount by Invoice",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
            {
              label: option === 2 ? "by Buyer" : "by Financing Bank",
              icon: null,
              activeIcon: null,
              active: tabIndex === 1,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"}>
        <SubTab option={option} />
      </Box>
      <Box display={tabIndex === 1 ? "block" : "none"}>
        <DiscountingByCompany option={option} />
      </Box>
    </Box>
  )
}

export default MainTab
