import { AxiosResponse } from "axios"
import $axios from "./instance"
import { GetCreditSponsorResponse } from "api/types/getCreditSponsorType"

const getCreditSponsor = async (): Promise<
  AxiosResponse<GetCreditSponsorResponse>
> => {
  const response = await $axios().get("/credit/sponsors")
  return response
}

export default getCreditSponsor
