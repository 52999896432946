import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react"
import { FC, useEffect, useState } from "react"
import { useQuery } from "../../../hooks/useQueryHook"
import useWalletHook from "../../../hooks/useWalletHook"
import { MyWallet } from "../../../types/MyWallet"
import { abbreviateHexString } from "../../utils/transform"
import AmountInput from "../AmountInput"
import { CheckedIcon, WalletCreditCardIcon } from "../Icon"
import PrimaryButton from "../PrimaryButton"
import { TopupInputValue } from "./TopupModalContainer"

type TopupInputProps = {
  currentWallet: MyWallet
  balance?: string
  topupValue?: TopupInputValue
  onClickBack: VoidFunction
  onClickTopup: (val: TopupInputValue) => void
}

const TopupInput: FC<TopupInputProps> = (props) => {
  const { currentWallet, topupValue, onClickTopup, onClickBack } = props
  const { getCompanyWallets } = useWalletHook()
  const { data } = useQuery({ queryFn: () => getCompanyWallets() })
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()
  const [amount, setAmount] = useState<string>(topupValue?.amount || "0.00")

  useEffect(() => {
    if (data) {
      if (topupValue) {
        setSelectedWallet(topupValue.destination)
      } else if (currentWallet) {
        setSelectedWallet(currentWallet)
      } else {
        setSelectedWallet(data?.data?.data[0])
      }
    }
  }, [data, topupValue, currentWallet])

  if (!data || !selectedWallet) return <></>

  return (
    <Flex direction="column" justify="center" align="center" gap={4}>
      <Box>
        <Heading size="md">Top-up</Heading>
      </Box>
      {/* From */}
      <Box w="100%">
        <Stack gap={2}>
          <Box>
            <Heading size="sm">From</Heading>
          </Box>
          <Box
            w={"100%"}
            border="1px solid #D8D8D8"
            borderRadius={8}
            px={4}
            py={2}
          >
            <Flex align="center" gap={3}>
              <Box>
                <CheckedIcon />
              </Box>
              <Box>
                <WalletCreditCardIcon boxSize={6} color="#6A727A" />
              </Box>
              <Box>
                <Stack gap={0}>
                  <HStack>
                    <Text fontSize="small" color="#818181">
                      Bank Name:
                    </Text>
                    <Text fontSize="small" color="#818181" fontWeight="bold">
                      {selectedWallet?.bank_name}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize="small" color="#818181">
                      Bank Account:
                    </Text>
                    <Text fontSize="small" color="#818181" fontWeight="bold">
                      {selectedWallet?.bank_account_number}
                    </Text>
                  </HStack>
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
      {/* To */}
      <Box w="100%">
        <Stack gap={2}>
          <Box>
            <Heading size="sm">To</Heading>
          </Box>
          <Box w={"100%"}>
            <Menu>
              <MenuButton
                size="sm"
                variant="outline"
                minW="110px"
                w="100%"
                h="42px"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                borderRadius={8}
                borderColor="#D8D8D8"
                px={4}
                py={2}
              >
                <Flex align="center" gap={3}>
                  <Box>
                    <CheckedIcon />
                  </Box>
                  <Box>
                    <WalletCreditCardIcon boxSize={6} color="primary" />
                  </Box>
                  <Box>
                    <Text
                      textAlign="left"
                      color="primary"
                      fontSize="small"
                      fontWeight="bold"
                    >
                      {selectedWallet.company_name} - {selectedWallet.nickname}{" "}
                    </Text>
                    <Text textAlign="left" color="primary" fontSize="x-small">
                      {abbreviateHexString(selectedWallet.wallet_address)}
                    </Text>
                  </Box>
                </Flex>
              </MenuButton>
              {data?.data?.data.filter(
                (m) => selectedWallet && m.id !== selectedWallet.id
              ).length > 0 && (
                <MenuList>
                  {data?.data?.data
                    .filter((m) => selectedWallet && m.id !== selectedWallet.id)
                    .map((m, i) => (
                      <MenuItem key={i} onClick={() => setSelectedWallet(m)}>
                        <Text fontSize="sm" color="#212529">
                          {m.company_name} - {m.nickname ?? ""} (
                          {abbreviateHexString(m.wallet_address)})
                        </Text>
                      </MenuItem>
                    ))}
                </MenuList>
              )}
            </Menu>
          </Box>
        </Stack>
      </Box>
      {/* input */}
      <Box w="100%" mt={4}>
        <Stack gap={4}>
          <Box>
            <Heading size="sm">Top-up Amount</Heading>
          </Box>
          <Box>
            <AmountInput
              value={amount}
              onChange={(val: string) => setAmount(val)}
              unit="NEW"
            />
          </Box>
        </Stack>
      </Box>
      {/* Button */}
      <Box w="100%" mt={6}>
        <HStack>
          <PrimaryButton variant="outline" onClick={onClickBack}>
            Back
          </PrimaryButton>
          <PrimaryButton
            colorScheme={parseFloat(amount) > 0 ? "#0050C8" : "grey400"}
            backgroundColor={parseFloat(amount) > 0 ? "#0050C8" : "grey400"}
            color={parseFloat(amount) > 0 ? "white" : "black"}
            onClick={() => {
              if (parseFloat(amount) > 0) {
                onClickTopup({
                  amount: amount,
                  destination: selectedWallet,
                })
              }
            }}
          >
            Top-up
          </PrimaryButton>
        </HStack>
      </Box>
    </Flex>
  )
}

export default TopupInput
