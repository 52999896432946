// ** React **
import { useCallback, useEffect, useMemo, useState } from "react"

// ** Libs **
import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import MakeBiddingModalContainer from "common/components/MakeBiddingModal/MakeBiddingModalContainer"
import { TableColumn } from "common/components/Table/BoxDataTable"
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import WalletSelect from "common/components/WalletSelect"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  numberFormatForCurrency,
  getTotalBiddingOptions,
  getDisplayBiddingOptions,
  removeDuplicates,
  capitalizeFirstLetter,
} from "common/utils/transform"
import {
  getLocalStorageData,
  hideInvoiceLocalStorage,
  unhideInvoiceLocalStorage,
} from "common/utils/localStorage"

// ** Types **
import { InvoiceListed } from "types/Invoice"
import { MyWallet } from "types/MyWallet"

// ** Hooks **
import useInvoiceHook from "hooks/useInvoiceHook"
import { useQuery } from "hooks/useQueryHook"
import useWalletHook from "hooks/useWalletHook"
import useAuthHook from "hooks/useAuthHook"

// ** Constants **
import { TRX_BIDDING_OFFER } from "constants/AppOptions"
import { FilterType, UserRole } from "constants/Enum"

const BiddingTab = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string[]>(TRX_BIDDING_OFFER)
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceListed>()
  const [data, setData] = useState<InvoiceListed[]>([])
  const [hidden, setHidden] = useState<number[]>([])
  const [selectedWallet, setSelectedWallet] = useState<MyWallet>()
  const [fillValue, setFillValue] = useState<string>("")
  const modalAction = useDisclosure()

  const tyxTypeOpts = TRX_BIDDING_OFFER.map((m) => ({ title: m, value: m }))

  const { getRole, user } = useAuthHook()
  const userRole = getRole()

  const { getAllListedInvoicesHook, listedInvoices } = useInvoiceHook()
  const { getCompanyWalletHook, myWallet } = useWalletHook()

  const initPage = async () => {
    return {
      myInvoices: await getAllListedInvoicesHook(),
      myWallet: await getCompanyWalletHook(),
    }
  }

  useEffect(() => {
    if (user) {
      setHidden(getLocalStorageData("user_invoice", user.id) ?? [])
    }
  }, [user])

  const { isLoading, refetch } = useQuery({ queryFn: initPage })

  const column: TableColumn[] = [
    {
      title: "heros",
      value: "",
    },
    {
      title: "DocNo",
      value: "doc_number",
    },
    {
      title: "DocType",
      value: "doc_number",
      customRender: (key) => {
        return (
          <Heading key={key} size="xs" color="#353535">
            Invoice
          </Heading>
        )
      },
    },
    {
      title: "Seller Name",
      value: "seller_company_name",
      customRender: (key, value) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.seller_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Buyer Name",
      value: "buyer_company_name",
      customRender: (key, value) => {
        return (
          <Text key={key}>
            {capitalizeFirstLetter(value.buyer_company_name)}
          </Text>
        )
      },
    },
    {
      title: "Schedule payment Date",
      value: "payment_date",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="#353535">
            {formatDateDD_MMMM_YYYY(value.payment_date)}
          </Heading>
        )
      },
    },
    {
      title: "Offering end Date",
      value: "payment_date",
      customRender(key, value) {
        return (
          <Heading key={key} size="xs" color="#353535">
            {value.offer_end_date ? formatDateDD_MMMM_YYYY(value.offer_end_date) : "-"}
          </Heading>
        )
      },
    },
    {
      title: "Option",
      value: "options_general",
      customRender(key, value) {
        return (
          <Heading
            key={key}
            size="xs"
            color="#353535"
            style={{ whiteSpace: "pre-line" }}
            h={45}
          >
            {getDisplayBiddingOptions(userRole ?? "", value.options)}
          </Heading>
        )
      },
    },
    {
      title: "Bidding Options",
      value: "options",
      isFooter: true,
      customRender(key, value) {
        return (
          <Heading
            key={key}
            size="md"
            color="primary"
            style={{ whiteSpace: "pre-line" }}
          >
            {getTotalBiddingOptions(userRole ?? "", value.options)} Bidding
            Options
          </Heading>
        )
      },
    },
    {
      title: "Amount",
      value: "amount",
      isFooter: true,
      customRender(key, value) {
        return (
          <Box textAlign="right" w="100%">
            <Text fontSize="sm">Amount</Text>
            <Heading key={key} size="sm" color="primary">
              {numberFormatForCurrency(value.amount)} NEW
            </Heading>
          </Box>
        )
      },
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string[]) => setTrxType(value),
      value: trxType,
      type: FilterType.CHECKBOX,
    },
  ]

  const onRowClick = (data: InvoiceListed) => {
    setSelectedInvoice(data)
    modalAction.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    if (userRole === UserRole.FI) {
      setData(
        listedInvoices.filter(
          (item) => item.options[0] === true || item.options[2] === true
        )
      )
    } else if (userRole === UserRole.BUYER) {
      setData(
        listedInvoices.filter(
          (item) => item.options[1] === true || item.options[2] === true
        )
      )
    } else {
      setData(listedInvoices)
    }
  }, [listedInvoices, userRole])

  useEffect(() => {
    if (myWallet[0]) {
      setSelectedWallet(myWallet[0])
    }
  }, [myWallet])

  useEffect(() => {
    if (!modalAction.isOpen) {
      refetch()
    }
  }, [modalAction.isOpen, refetch])

  useEffect(() => {
    let temp: InvoiceListed[] = []

    if (trxType.indexOf("Show All") > -1) {
      temp = listedInvoices.filter((m) => hidden.indexOf(m.id) === -1)
    }

    if (trxType.indexOf("Hide") > -1) {
      temp = temp.concat(
        temp,
        listedInvoices.filter((m) => hidden.indexOf(m.id) > -1)
      )
    }

    if (userRole === UserRole.FI) {
      temp = temp.filter(
        (item) => item.options[0] === true || item.options[2] === true
      )
    } else if (userRole === UserRole.BUYER) {
      temp = temp.filter(
        (item) => item.options[1] === true || item.options[2] === true
      )
    }
    temp = removeDuplicates(temp, "id")

    temp.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )

    const filterValue = temp.filter((m) =>
      (m.seller_company_name + m.doc_number + m.buyer_company_name)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, listedInvoices, userRole, trxType, hidden])

  const onHiddenInvoice = (id: number) => {
    if (user) {
      hideInvoiceLocalStorage("user_invoice", user.id, id)
      setHidden(getLocalStorageData("user_invoice", user.id) ?? [])
    }
  }

  const onUnhiddenInvoice = (id: number) => {
    if (user) {
      unhideInvoiceLocalStorage("user_invoice", user.id, id)
      setHidden(getLocalStorageData("user_invoice", user.id) ?? [])
    }
  }

  if (isLoading || !selectedWallet) return <></>

  return (
    <>
      <Flex direction="column" align="flex-end" mt={"-63px"}>
        <Box w={"300px"}>
          <WalletSelect
            wallets={myWallet}
            onChange={setSelectedWallet}
            value={selectedWallet}
          />
        </Box>
        <Box w="100%">
          <ContainerTable
            column={column}
            data={displayData}
            rawData={data}
            currentPage={currentPage}
            pageSize={pageSize}
            onViewSizeChange={(size) => {
              setPageSize(size)
              setCurrentPage(1)
            }}
            onPageChange={(page: number) => setCurrentPage(page)}
            totalCount={data.length}
            filter={filterTable}
            style={TableStyle.BOX}
            onRowClick={onRowClick}
            onSearchChange={onSearchChange}
            searchPlaceholder="Search"
          />
        </Box>
      </Flex>
      <MakeBiddingModalContainer
        {...modalAction}
        selectedWallet={selectedWallet}
        invoice={selectedInvoice}
        onHiddenInvoice={onHiddenInvoice}
        onUnhiddenInvoice={onUnhiddenInvoice}
      />
    </>
  )
}

export default BiddingTab
