// ** React **
import { useState } from "react"

// ** Libs **
import { Box } from "@chakra-ui/react"
import TabCustom from "common/components/TabCustom"

// ** Components **
import { AROptionTab } from "modules/ap_management"

const PaymentTabForBuyer = () => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Box>
      <Box w={150}>
        <TabCustom
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
          tabOpt={[
            {
              label: "Financing",
              icon: null,
              activeIcon: null,
              active: tabIndex === 0,
            },
          ]}
        />
      </Box>
      <Box display={tabIndex === 0 ? "block" : "none"} py={4}>
        <AROptionTab option={1} isPayment={true} />
      </Box>
    </Box>
  )
}

export default PaymentTabForBuyer
