import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetInvoiceWithHolderResponse,
  GetTotalSupplyResponse,
  GetTotalSupplyCreditResponse,
  GetAllWalletBalanceResponse,
  GetAllWalletsWithCreditBalanceResponse,
  GetStableCoinBalanceResponse,
  GetStableCoinHolderListResponse,
  GetMonthlyGuaranteeResponse,
} from "./types/tokenManagement"

const getInvoiceWithHolder = async (): Promise<
  AxiosResponse<GetInvoiceWithHolderResponse>
> => {
  const request = await $axios().get("/get_invoices_with_holder")
  return request
}

const getTotalSupplyNew = async (): Promise<
  AxiosResponse<GetTotalSupplyResponse>
> => {
  const request = await $axios().get("/total_supply/NEW")
  return request
}

const getTotalSupplyCredit = async (): Promise<
  AxiosResponse<GetTotalSupplyCreditResponse>
> => {
  const request = await $axios().get("/total_supply/credit")
  return request
}

const getAllWalletBalance = async (): Promise<
  AxiosResponse<GetAllWalletBalanceResponse>
> => {
  const request = await $axios().get("/all_wallet_balance")
  return request
}

const getAllWalletWithCreditBalance = async (): Promise<
  AxiosResponse<GetAllWalletsWithCreditBalanceResponse>
> => {
  const request = await $axios().get("/all_wallets_with_credit_balance")
  return request
}

const getStableCoinBalance = async (): Promise<
  AxiosResponse<GetStableCoinBalanceResponse>
> => {
  const request = await $axios().get("/total_supply/NEW")
  return request
}

const getStableCoinHolderList = async (): Promise<
  AxiosResponse<GetStableCoinHolderListResponse>
> => {
  const request = await $axios().get("/all_wallet_balance")
  return request
}

const getMonthlyGuarantee = async (): Promise<
  AxiosResponse<GetMonthlyGuaranteeResponse>
> => {
  const request = await $axios().get("/get_monthly_guarantee")
  return request
}

export {
  getInvoiceWithHolder,
  getTotalSupplyNew,
  getTotalSupplyCredit,
  getAllWalletBalance,
  getAllWalletWithCreditBalance,
  getStableCoinBalance,
  getStableCoinHolderList,
  getMonthlyGuarantee,
}
