// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "../store"
import { appActions } from "store/slice/AppSlice"
import { tokenManagementActions } from "store/slice/TokenManagementSlice"

// ** APIs **
import {
  getTotalSupplyCredit,
  getAllWalletWithCreditBalance,
  getStableCoinBalance,
  getStableCoinHolderList,
  getInvoiceWithHolder,
  getMonthlyGuarantee,
} from "api/tokenManagement"

const useTokenManagementHook = () => {
  const dispatch = useAppDispatch()

  const {
    stableCoinBalance,
    stableCoinHolderList,
    totalSupplyCredit,
    allWalletWithCreditBalance,
    invoiceWithHolder,
    monthlyGuarantee,
  } = useAppSelector((state: RootState) => state.tokenManagement)

  const getStableCoinBalanceHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getStableCoinBalance()
      dispatch(tokenManagementActions.setStableCoinBalance(response.data.data))
    } catch (err) {
      console.log("getStableCoinBalanceHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getStableCoinHolderListHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getStableCoinHolderList()
      dispatch(
        tokenManagementActions.setStableCoinHolderList(response.data.data)
      )
    } catch (err) {
      console.log("getStableCoinHolderListHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getTotalSupplyCreditHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getTotalSupplyCredit()
      dispatch(tokenManagementActions.setTotalSupplyCredit(response.data.data))
    } catch (err) {
      console.log("getTotalSupplyCreditHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAllWalletWithCreditBalanceHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAllWalletWithCreditBalance()
      dispatch(
        tokenManagementActions.setAllWalletWithCreditBalance(response.data.data)
      )
    } catch (err) {
      console.log("getAllWalletWithCreditBalanceHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getInvoiceWithHolderHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getInvoiceWithHolder()
      dispatch(tokenManagementActions.setInvoiceWithHolder(response.data.data))
    } catch (err) {
      console.log("getInvoiceWithHolderHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getMonthlyGuaranteeHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getMonthlyGuarantee()
      dispatch(tokenManagementActions.setMonthlyGuarantee(response.data.data))
    } catch (err) {
      console.log("getInvoiceWithHolderHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getStableCoinBalanceHook,
    getStableCoinHolderListHook,
    getTotalSupplyCreditHook,
    getAllWalletWithCreditBalanceHook,
    getInvoiceWithHolderHook,
    getMonthlyGuaranteeHook,
    stableCoinBalance,
    stableCoinHolderList,
    totalSupplyCredit,
    allWalletWithCreditBalance,
    invoiceWithHolder,
    monthlyGuarantee,
  }
}

export default useTokenManagementHook
