// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Flex, Box, Text, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Constants **
import { TRANSACTION_OPTION_FI, REVENUE_STATUS } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

// ** Components **
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import SuccessBadge from "common/components/SuccessBadge"
import { InvoiceModalFI } from "modules/revenue"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useInvoiceHook from "hooks/useInvoiceHook"

// ** Types **
import { AcceptedGuaranteedInvoice } from "types/Invoice"

// ** Utils **
import {
  numberFormatForCurrency,
  formatDateDD_MMMM_YYYY,
  invoiceOptionIdToString,
  capitalizeFirstLetter,
  removeDuplicates,
} from "common/utils/transform"

// ** Icons **
import { ArrowRightIcon } from "assets/icons"

const RevenueByInvoiceForFI = ({ ptId }: { ptId?: number }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("All Option")
  const [trxStatus, setTrxStatus] = useState<string>("All Status")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [invoiceData, setInvoiceData] = useState<AcceptedGuaranteedInvoice>()
  const modalDetail = useDisclosure()

  const tyxStatusOpts = REVENUE_STATUS.map((m) => ({ title: m, value: m }))
  const tyxTypeOpts = TRANSACTION_OPTION_FI.map((m) => ({ title: m, value: m }))

  const { getAcceptedInvoicesOffererHook, acceptedInvoicesOfferer } =
    useInvoiceHook()

  useEffect(() => {
    if (acceptedInvoicesOfferer.length > 0) {
      setData(
        acceptedInvoicesOfferer.filter((item: any) =>
          ptId !== undefined ? item.buyer_pt_id === ptId : true
        )
      )
    }
  }, [acceptedInvoicesOfferer, ptId])

  const { isLoading } = useQuery({
    queryKey: "initRevenueOfferer",
    queryFn: getAcceptedInvoicesOffererHook,
  })

  const column: TableColumn[] = [
    {
      title: "Custom",
      value: "custom",
      isFooter: true,
      customRender: (key: string, value: any) => {
        return (
          <Flex key={key} w="100%">
            <Box
              flex="0 0 80px"
              bgColor="emptyColor"
              h="80px"
              borderRadius={8}
              mr={4}
            />
            <Box w="100%" fontSize="14">
              <Flex>
                <Text fontWeight="bold" mr={2}>
                  DocNo. :
                </Text>
                <Text fontWeight="bold">{value.doc_number}</Text>
              </Flex>
              <Flex>
                <Text mr={2}>DocType :</Text>
                <Text fontWeight="bold">Invoice</Text>
              </Flex>
              <Flex>
                <Text mr={2}>Seller Name :</Text>
                <Text fontWeight="bold">
                  {capitalizeFirstLetter(value.seller_company_name)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Buyer Name :</Text>
                <Text fontWeight="bold">
                  {capitalizeFirstLetter(value.buyer_company_name)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Schedule payment Date :</Text>
                <Text fontWeight="bold">
                  {formatDateDD_MMMM_YYYY(value.payment_date)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Option :</Text>
                <Text fontWeight="bold">
                  {invoiceOptionIdToString(value.offer_option)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Interest rate(per year) :</Text>
                <Text fontWeight="bold">
                  {numberFormatForCurrency(value.offer_interest_rate)} %
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Total Guarantee Fee (NEW) :</Text>
                <Text fontWeight="bold">
                  {value.offer_option === 3
                    ? numberFormatForCurrency(
                        `${
                          parseFloat(value.commitment_flat_fee) +
                          parseFloat(value.guarantee_flat_fee) +
                          parseFloat(value.guarantee_flat_fee_extra)
                        }`
                      )
                    : "0.00"}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Amount (NEW) :</Text>
                <Text fontWeight="bold">
                  {numberFormatForCurrency(value.amount)}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Revenue (NEW) :</Text>
                <Text fontWeight="bold" color="blue">
                  {numberFormatForCurrency(
                    `${parseFloat(value.offer_discount)}`
                  )}
                </Text>
              </Flex>
              <Flex>
                <Text mr={2}>Overdue :</Text>
                <Text fontWeight="bold">{value.overdue_day ?? "-"} days</Text>
              </Flex>
              <Flex>
                <Text mr={2}>Status :</Text>
                <Text fontWeight="bold">
                  <SuccessBadge label={value.pay_status} />
                </Text>
              </Flex>
            </Box>
            <Flex alignItems="center">
              <ArrowRightIcon />
            </Flex>
          </Flex>
        )
      },
    },
  ]

  const filterTable = [
    {
      direction: "row",
      options: tyxStatusOpts,
      onChange: (value: string) => setTrxStatus(value),
      value: trxStatus,
      type: FilterType.DROPDOWN,
    },
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
  ]

  const onRowClick = (data: AcceptedGuaranteedInvoice) => {
    setInvoiceData(data)
    modalDetail.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: AcceptedGuaranteedInvoice[] = []

    if (trxType.indexOf("All Option") > -1) {
      temp = acceptedInvoicesOfferer
    }

    if (trxType.indexOf("Financing") > -1) {
      temp = temp.concat(
        temp,
        acceptedInvoicesOfferer.filter((m) => m.offer_option === 1)
      )
    }

    if (trxType.indexOf("Buyer Prepayment") > -1) {
      temp = temp.concat(
        temp,
        acceptedInvoicesOfferer.filter((m) => m.offer_option === 2)
      )
    }

    if (trxType.indexOf("Buyer Guarantee") > -1) {
      temp = temp.concat(
        temp,
        acceptedInvoicesOfferer.filter((m) => m.offer_option === 3)
      )
    }

    if (trxStatus.indexOf("Success") > -1) {
      temp = temp.filter((m) => m.pay_status === "success")
    }

    if (trxStatus.indexOf("Default") > -1) {
      temp = temp.filter((m) => m.pay_status === "default")
    }

    temp = removeDuplicates(temp, "id")

    const filterValue = temp
      .filter((item: any) =>
        ptId !== undefined ? item.buyer_pt_id === ptId : true
      )
      .filter((m) =>
        (m.doc_number + m.offer_company_name)
          .toLowerCase()
          .includes(fillValue.toLowerCase())
      )
    setData(filterValue)
  }, [fillValue, acceptedInvoicesOfferer, ptId, trxType, trxStatus])

  if (isLoading) return <></>

  return (
    <Box>
      {ptId !== undefined && data && data.length > 0 && (
        <Flex p={8} py={4} gap={8} fontSize={16}>
          <Flex gap={8}>
            <Text>Debtor Name</Text>
            <Text
              fontWeight="bold"
              css={{
                "&:first-letter": {
                  textTransform: "uppercase",
                },
              }}
            >
              {data[0].buyer_company_name}
            </Text>
          </Flex>
          <Flex gap={8}>
            <Text>Debtor Juristic ID</Text>
            <Text fontWeight="bold">{data[0].buyer_juristic_id}</Text>
          </Flex>
        </Flex>
      )}
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        filter={filterTable}
        style={TableStyle.BOX}
        onRowClick={onRowClick}
        searchPlaceholder="Search"
        onSearchChange={onSearchChange}
        isCustom={true}
      />
      <InvoiceModalFI
        {...modalDetail}
        data={invoiceData}
        onClickClose={() => modalDetail.onClose()}
      />
    </Box>
  )
}

export default RevenueByInvoiceForFI
