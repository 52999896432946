import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import useAuthHook from "../../hooks/useAuthHook"
import {
  AUTHENTICATE_LOGIN_PAGE_PATH,
  USER_PROFILE_PAGE_PATH,
} from "../../router/AppPath"

type AccountMenuProps = {
  image: string | null
  name: string
  email: string
}

const AccountMenu: FC<AccountMenuProps> = ({ image, name, email }) => {
  const navigate = useNavigate()
  const { postLogoutHook } = useAuthHook()
  const onClickEditProfile = () => {
    navigate(USER_PROFILE_PAGE_PATH, {
      state: {
        navTitle: "Profile",
      },
    })
  }

  const onClickLogout = async () => {
    await postLogoutHook()
    navigate(AUTHENTICATE_LOGIN_PAGE_PATH)
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon color="#16161D" fontSize="2xl" />}
        bgColor="white"
        border="1px solid"
        borderRadius={4}
        borderColor="grey400"
        h="auto"
        w="auto"
        minW="250px"
        _hover={{ bg: "white" }}
        _focus={{ boxShadow: "white" }}
        _expanded={{ bg: "white" }}
      >
        <Flex gap={2} p={2} px={0} align="center">
          <Box>
            {image && (
              <Avatar
                h="50px"
                w="50px"
                name={name}
                src={process.env.REACT_APP_BASE_URL + "/" + image}
              />
            )}
            {image === null && (
              <Box h={50} w={50} bgColor="grey400" borderRadius={50}></Box>
            )}
          </Box>
          <Stack gap={1} textAlign="left">
            <Box>
              <Text fontSize="md" fontWeight="bold">
                {name}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                {email}
              </Text>
            </Box>
          </Stack>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onClickEditProfile}>
          Edit Profile / Change Password
        </MenuItem>
        <MenuItem onClick={onClickLogout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default AccountMenu
