import { Card, HStack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { useState } from "react";
import { EditInfIcon, PlusIcon, TrashIcon } from "../../common/components/Icon";
import PrimaryButton from "../../common/components/PrimaryButton";
import SwitchInput from "../../common/components/SwitchInput";
import ContainerTable from "../../common/components/Table/ContainerTable";
import { TableSortType } from "../../common/components/Table/ListDataTable";

const MarketplaceManagementPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const column = [
    {
      title: "Collection Name",
      value: "collectionName",
      sortType: TableSortType.STRING,
      w: "170px",
    },
    {
      title: "Docs Type",
      value: "docsType",
      sortType: TableSortType.ITEM,
    },
    {
      title: "Seller Name",
      value: "sellerName",
      sortType: TableSortType.STRING,
    },
    {
      title: "Buyer Name",
      value: "buyerName",
      sortType: TableSortType.STRING,
    },
    {
      title: "Collection Holder",
      value: "collectionHolder",
      sortType: TableSortType.STRING,
      w: "180px",
    },
    {
      title: "Buyer Name",
      value: "buyerName",
      sortType: TableSortType.STRING,
    },
    {
      title: "Status",
      value: "id",
      w: "221px",
      customRender: (key: string, value: any) => (
        <Wrap key={key} align="center" spacing={4}>
          <WrapItem>
            <SwitchInput />
          </WrapItem>
          <WrapItem>
            <TrashIcon />
          </WrapItem>
          <WrapItem>
            <EditInfIcon />
          </WrapItem>
        </Wrap>
      ),
    },
  ];

  const data = [
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
    {
      collectionName: "INV987654321",
      docsType: "Invoice",
      sellerName: "Brian Anderson",
      buyerName: "Josh Harris",
      issuer: "George Hernandez",
      collectionHolder: "Laura Brown",
    },
  ];

  return (
    <Card p={6}>
      <ContainerTable
        headerTable="Collection List"
        column={column}
        data={data}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        canExport={false}
        actions={[
          <PrimaryButton size="lg" bgColor="primary" colorScheme="primary">
            <HStack gap={2}>
              <PlusIcon />
              <Text fontSize="sm" color="white">
                Add Collection
              </Text>
            </HStack>
          </PrimaryButton>,
        ]}
        onViewSizeChange={(size) => {
          setPageSize(size);
          setCurrentPage(1);
        }}
        onPageChange={(page: number) => {
          setCurrentPage(page);
        }}
      />
    </Card>
  );
};

export default MarketplaceManagementPage;
