// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import {
  Card,
  Box,
  Flex,
  HStack,
  Heading,
  Image,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import {
  EditInfIcon,
  PlusIcon,
  PrimaryViewIcon,
  TrashIcon,
} from "common/components/Icon"
import PrimaryButton from "common/components/PrimaryButton"
import ContainerTable from "common/components/Table/ContainerTable"
import { TableSortType } from "common/components/Table/ListDataTable"
import {
  CampaignDetailModal,
  CampaignAddModal,
  CampaignEditModal,
  CampaignConfirmDeleteModal,
} from "modules/campaign_management"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useCampaignHook from "hooks/useCampaignHook"

// ** Utils **
import { formatDateDD_MMMM_YYYY } from "common/utils/transform"

// ** Constants **
import { CAMPAIGN_FILTER } from "constants/AppOptions"
import { FilterType } from "constants/Enum"

const CampaignManagementPage = () => {
  const detailModalAction = useDisclosure()
  const addModalAction = useDisclosure()
  const editModalAction = useDisclosure()
  const deleteModalAction = useDisclosure()

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [trxType, setTrxType] = useState<string>("View All")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [id, setId] = useState<number>()

  const tyxTypeOpts = CAMPAIGN_FILTER.map((m) => ({ title: m, value: m }))

  const {
    getCampaignsHook,
    setCampaignHighlightHook,
    activateCampaignHook,
    deactivateCampaignHook,
    campaigns,
  } = useCampaignHook()

  const { isLoading: isLoadingInit } = useQuery({
    queryKey: "initCampaignsList",
    queryFn: getCampaignsHook,
  })

  const column = [
    {
      title: "Title",
      value: "title",
      sortType: TableSortType.STRING,
      w: "300px",
      customRender: (key: string, value: any) => {
        return (
          <Flex key={key} gap={4} align="center">
            <Image
              borderRadius={8}
              boxSize="40px"
              src={process.env.REACT_APP_BASE_URL + "/" + value.logo}
              alt={key}
            />
            <Heading size="xs">{value.title}</Heading>
          </Flex>
        )
      },
      headerCenter: true,
    },
    {
      title: "Publish Date",
      value: "date",
      w: "180px",
      sortType: TableSortType.ITEM,
      headerCenter: true,
      valueCenter: true,
      customRender: (key: string, value: any) => {
        return <Box key={key}>{formatDateDD_MMMM_YYYY(value.date)}</Box>
      },
    },
    {
      title: "View",
      value: "view_count",
      w: "80px",
      sortType: TableSortType.INTEGER,
      headerCenter: true,
      valueCenter: true,
    },
    {
      title: "Publisher",
      value: "owner_user_id",
      sortType: TableSortType.STRING,
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {value.owner_first_name} {value.owner_last_name}
          </Box>
        )
      },
    },
    {
      title: "Highlight",
      value: "is_highlight",
      w: "100px",
      headerCenter: true,
      valueCenter: true,
      customRender: (key: string, value: any) => {
        return (
          <Box
            onClick={() =>
              handleToggleHighlight(value.id, {
                ...value,
                is_highlight: !value.is_highlight,
              })
            }
          >
            <Switch
              key={key}
              isChecked={value.is_highlight}
              colorScheme="primary500"
            />
          </Box>
        )
      },
    },
    {
      title: "Status",
      value: "is_active",
      w: "100px",
      headerCenter: true,
      valueCenter: true,
      customRender: (key: string, value: any) => {
        return (
          <Box onClick={() => handleToggleStatus(value.id, value.is_active)}>
            <Switch
              key={key}
              isChecked={value.is_active}
              colorScheme="primary500"
            />
          </Box>
        )
      },
    },
    {
      title: "Customize",
      value: "customize",
      w: "100px",
      headerCenter: true,
      valueCenter: true,
      customRender: (key: string, value: any) => {
        return (
          <HStack gap={4}>
            <Box
              onClick={() => {
                setId(value.id)
                deleteModalAction.onOpen()
              }}
              cursor="pointer"
            >
              <TrashIcon />
            </Box>
            <Box
              onClick={() => {
                setId(value.id)
                editModalAction.onOpen()
              }}
              cursor="pointer"
            >
              <EditInfIcon />
            </Box>
          </HStack>
        )
      },
    },
    {
      title: "Detail",
      value: "detail",
      headerCenter: true,
      valueCenter: true,
      customRender: (key: string, value: any) => {
        return (
          <PrimaryButton
            variant="outline"
            size="sm"
            key={key}
            color="primary"
            borderColor="primary"
            w={"128px"}
            onClick={() => {
              setId(value.id)
              detailModalAction.onOpen()
            }}
          >
            <HStack gap={2}>
              <PrimaryViewIcon />
              <Text mt={0.5} fontSize="xs">
                View Detail
              </Text>
            </HStack>
          </PrimaryButton>
        )
      },
    },
  ]

  useEffect(() => {
    setData(campaigns)
  }, [campaigns])

  const handleToggleHighlight = async (id: number, body: any) => {
    if (isLoading) return
    setIsLoading(true)
    await setCampaignHighlightHook(id, body)
    setIsLoading(false)
  }

  const handleToggleStatus = async (id: number, status: boolean) => {
    if (isLoading) return
    setIsLoading(true)
    if (status) {
      await deactivateCampaignHook(id)
    } else {
      await activateCampaignHook(id)
    }
    setIsLoading(false)
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp = campaigns
    if (trxType.indexOf("Highlight") > -1) {
      temp = temp.filter((m) => m.is_highlight === true)
    }
    const filterValue = temp.filter((m) =>
      m.title.toLowerCase().includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, campaigns, trxType])

  if (isLoadingInit) return <></>

  const filterTable = [
    {
      direction: "row",
      options: tyxTypeOpts,
      onChange: (value: string) => setTrxType(value),
      value: trxType,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Card p={6}>
      <ContainerTable
        hideIndex={true}
        headerTable="News & Campaign List"
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={data.length}
        filter={filterTable}
        searchPlaceholder="Title"
        onSearchChange={onSearchChange}
        canExport={false}
        actions={[
          <PrimaryButton
            size="lg"
            bgColor="#0050C8"
            colorScheme="#0050C8"
            onClick={() => addModalAction.onOpen()}
          >
            <HStack gap={2}>
              <PlusIcon />
              <Text fontSize="sm" color="white">
                Create news/Campaign
              </Text>
            </HStack>
          </PrimaryButton>,
        ]}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => {
          setCurrentPage(page)
        }}
      />
      {id && <CampaignDetailModal {...detailModalAction} id={id} />}
      <CampaignAddModal {...addModalAction} />
      {id && <CampaignEditModal {...editModalAction} id={id} />}
      {id && <CampaignConfirmDeleteModal {...deleteModalAction} id={id} />}
    </Card>
  )
}

export default CampaignManagementPage
