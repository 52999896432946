// ** Libs **
import { Flex, Box, Text } from "@chakra-ui/react"

// ** Components **
import {
  CreditTokens,
  CreditMonthlyReport,
  CreditHolder,
} from "modules/token_management/admin"

const CreditTab = () => {
  return (
    <Box>
      <Flex gap={4} w="100%">
        <Box w="50%">
          <CreditTokens />
        </Box>
        <Box w="50%">
          <CreditMonthlyReport />
        </Box>
      </Flex>
      <Text fontSize="20px" fontWeight="bold" mt={4}>
        Holder Credit List
      </Text>
      <CreditHolder />
    </Box>
  )
}

export default CreditTab
