/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import useWalletHook from "../../hooks/useWalletHook"
import { MyWallet } from "../../types/MyWallet"
import { numberFormatForCurrency } from "../utils/transform"
import { TopUpIcon, TransferIcon, WithdrawIcon } from "./Icon"
import TopupModalContainer from "./TopupModal/TopupModalContainer"
import TransferWalletModalContainer from "./TransferModal/TransferModalContainer"
import WithdrawModalContainer from "./WithdrawModal/WithdrawModalContainer"

type BalanceBoxProps = {
  unit: string
  wallet: MyWallet
}

const BalanceBox: FC<BalanceBoxProps> = ({ unit, wallet }) => {
  const transferModalAction = useDisclosure()
  const topUpModalAction = useDisclosure()
  const withdrawModalAction = useDisclosure()
  const { getWalletBalanceHook, transactionTokens } = useWalletHook()
  const [balance, setBalance] = useState<string>("0.00")

  const inquiryWalletById = useCallback(async () => {
    const response = await getWalletBalanceHook(wallet.wallet_address)
    if (response) {
      setBalance(Number(response.data).toFixed(2))
    }
  }, [getWalletBalanceHook, wallet])

  useEffect(() => {
    inquiryWalletById()
  }, [inquiryWalletById, wallet, transactionTokens])

  return (
    <>
      <Stack align="center" gap={6}>
        <Heading size="sm">Total Balance</Heading>
        <Heading fontSize="40px">
          {numberFormatForCurrency(balance)} {unit}
        </Heading>
        <Heading fontSize="24px" color="#D8D8D8">
          {numberFormatForCurrency(balance)} THB
        </Heading>
        <Flex gap={6} mt={4}>
          <Stack align="center" w="75px" gap={2}>
            <IconButton
              isRound={true}
              backgroundColor="#0050C8"
              aria-label="top-up"
              icon={<TopUpIcon />}
              _hover={{ background: "#0050C8" }}
              onClick={topUpModalAction.onOpen}
            />
            <Text color="primaryTextColor">Top-up</Text>
          </Stack>
          <Stack align="center" w="75px" gap={2}>
            <IconButton
              isRound={true}
              backgroundColor="#0050C8"
              aria-label="transfer"
              icon={<TransferIcon />}
              _hover={{ background: "#0050C8" }}
              onClick={transferModalAction.onOpen}
            />
            <Text color="primaryTextColor">Transfer</Text>
          </Stack>
          <Stack align="center" w="75px" gap={2}>
            <IconButton
              isRound={true}
              backgroundColor="#0050C8"
              aria-label="withdraw"
              icon={<WithdrawIcon />}
              _hover={{ background: "#0050C8" }}
              onClick={withdrawModalAction.onOpen}
            />
            <Text color="primaryTextColor">Withdraw</Text>
          </Stack>
        </Flex>
      </Stack>
      <TopupModalContainer
        currentWallet={wallet}
        balance={balance}
        {...topUpModalAction}
      />
      <TransferWalletModalContainer
        currentWallet={wallet}
        balance={balance}
        {...transferModalAction}
      />
      <WithdrawModalContainer
        currentWallet={wallet}
        balance={balance}
        {...withdrawModalAction}
      />
    </>
  )
}

export default BalanceBox
