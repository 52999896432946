// ** Components **
import {
  Box,
  Card,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react"

// ** Icons **
import { ArrowRightIcon } from "assets/icons"

export type TableColumn = {
  title: string
  value: string
  customSuffixRender?: () => JSX.Element
  w?: string
  sortType?: TableSortType
  headerCenter?: boolean
  valueCenter?: boolean
  customRender?: (key: string, value: any) => JSX.Element
  isFooter?: boolean
}

export enum TableSortType {
  STRING,
  INTEGER,
  ITEM,
}

export type BoxDataTableProps = {
  column: Array<TableColumn>
  data: Array<object>
  hideIndex?: boolean
  onRowClick?: (data: any) => void
  isCustom?: boolean
}

const BoxDataTable = (props: BoxDataTableProps) => {
  const { column, data, onRowClick, isCustom } = props

  const footerColumns = column
    .filter((col) => col.isFooter !== undefined && col.isFooter === true)
    .map((col) => col.value)

  return (
    <>
      <Grid
        borderTopRadius={8}
        gridTemplateColumns={"repeat(2, minmax(400px, 1fr))"}
        alignItems="center"
        overflow="scroll"
        gap={8}
        px={8}
        py={4}
        className="disable-scroll"
      >
        {data.map((row, index) => (
          <GridItem
            key={`row_header_${index}`}
            h={"100%"}
            onClick={onRowClick ? () => onRowClick(row) : undefined}
            cursor={onRowClick ? "pointer" : undefined}
          >
            <Card h={"100%"}>
              <Flex gap={4} p={!isCustom ? 4 : 0}>
                {!isCustom && (
                  <Box
                    flex="0 0 80px"
                    bgColor="emptyColor"
                    h="80px"
                    borderRadius={8}
                  ></Box>
                )}
                <Box flex="1 1 auto">
                  <Stack>
                    {column
                      .filter((m) => m.title !== "heros")
                      .filter((m) => !footerColumns.includes(m.value))
                      .map((col, colIndex) => {
                        let node

                        type rowKeyType = keyof typeof row
                        const key = col.value as rowKeyType
                        const value = row[key]

                        if (col.customRender) {
                          node = col.customRender(`${index}_${key}`, row)
                        } else {
                          node = (
                            <Heading size="xs" color="#353535">
                              {value}
                            </Heading>
                          )
                        }
                        return (
                          <HStack key={"col_" + colIndex} align="baseline">
                            <Box>
                              <Text color="#353535" fontSize="sm">
                                {col.title} :
                              </Text>
                            </Box>
                            <Box>{node}</Box>
                          </HStack>
                        )
                      })}
                  </Stack>
                </Box>
                {!isCustom && (
                  <Flex alignItems="center">
                    <ArrowRightIcon />
                  </Flex>
                )}
              </Flex>
              {column.filter((m) => footerColumns.includes(m.value)).length >
                0 && (
                <Flex
                  ml={4}
                  mr={4}
                  p={4}
                  borderTop={isCustom ? "0px" : "1px solid"}
                  borderColor="grey400"
                  justify="space-between"
                  alignItems="center"
                >
                  {column
                    .filter((m) => footerColumns.includes(m.value))
                    .map((col, colIndex) => {
                      let node

                      type rowKeyType = keyof typeof row
                      const key = col.value as rowKeyType
                      const value = row[key]

                      if (col.customRender) {
                        node = col.customRender(`${index}_${key}`, row)
                      } else {
                        node = (
                          <Heading size="xs" color="#353535">
                            {value}
                          </Heading>
                        )
                      }
                      return node
                    })}
                </Flex>
              )}
            </Card>
          </GridItem>
        ))}
      </Grid>
      {data.length === 0 && (
        <Text textAlign="center" fontWeight="bold" fontSize="md">
          Not found record.
        </Text>
      )}
    </>
  )
}

export default BoxDataTable
