import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetArapCurrentResponse,
  GetArapHistoryResponse,
  GetAcceptedOfferBuyerResponse,
  GetAcceptedOfferBuyerListResponse,
  GetAcceptedOfferSellerResponse,
  GetAcceptedOfferSellerListResponse,
} from "./types/armanagement"

const getArapCurrent = async (): Promise<
  AxiosResponse<GetArapCurrentResponse>
> => {
  const request = await $axios().get("/arap/current")
  return request
}

const getArapHistory = async (): Promise<
  AxiosResponse<GetArapHistoryResponse>
> => {
  const request = await $axios().get("/arap/history")
  return request
}

const getGetAcceptedOfferBuyer = async (): Promise<
  AxiosResponse<GetAcceptedOfferBuyerResponse>
> => {
  const request = await $axios().get("/get_accepted_offer_buyer")
  return request
}

const getGetAcceptedOfferBuyerList = async (
  ptId: number
): Promise<AxiosResponse<GetAcceptedOfferBuyerListResponse>> => {
  const request = await $axios().get(
    "/get_accepted_invoices_of_offerer/" + ptId
  )
  return request
}

const getGetAcceptedOfferSeller = async (): Promise<
  AxiosResponse<GetAcceptedOfferSellerResponse>
> => {
  const request = await $axios().get("/get_accepted_offer_buyer/seller")
  return request
}

const getGetAcceptedOfferSellerList = async (
  ptId: number
): Promise<AxiosResponse<GetAcceptedOfferSellerListResponse>> => {
  const request = await $axios().get(
    "/get_accepted_invoices_of_offerer/" + ptId + "/seller"
  )
  return request
}

export {
  getArapCurrent,
  getArapHistory,
  getGetAcceptedOfferBuyer,
  getGetAcceptedOfferBuyerList,
  getGetAcceptedOfferSeller,
  getGetAcceptedOfferSellerList,
}
