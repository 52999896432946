import { Icon, IconProps } from "@chakra-ui/react"

const CoinExchangeIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 32 33" fill="none" {...props}>
    <path
      d="M7 7.5L10 4.5M10 4.5L7 1.5M10 4.5H7C3.68629 4.5 1 7.18629 1 10.5M25 25.5L22 28.5M22 28.5L25 31.5M22 28.5H25C28.3137 28.5 31 25.8137 31 22.5M13.2835 8.25C14.2826 4.36824 17.8064 1.5 22 1.5C26.9706 1.5 31 5.52944 31 10.5C31 14.6936 28.1318 18.2173 24.2501 19.2164M19 22.5C19 27.4706 14.9706 31.5 10 31.5C5.02944 31.5 1 27.4706 1 22.5C1 17.5294 5.02944 13.5 10 13.5C14.9706 13.5 19 17.5294 19 22.5Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CoinExchangeIcon
