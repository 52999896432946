import { Icon, IconProps } from "@chakra-ui/react"

const CardCoinIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 90 74" fill="none" {...props}>
    <path
      d="M78.3359 3.66406H11.6693C7.0669 3.66406 3.33594 7.39502 3.33594 11.9974V61.9974C3.33594 66.5998 7.0669 70.3307 11.6693 70.3307H78.3359C82.9383 70.3307 86.6693 66.5998 86.6693 61.9974V11.9974C86.6693 7.39502 82.9383 3.66406 78.3359 3.66406Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0013 28.7294C43.9146 27.4121 42.5513 26.3499 41.0082 25.6183C39.4651 24.8867 37.7799 24.5036 36.0721 24.4961C29.5013 24.4961 24.168 30.0919 24.168 36.9961C24.168 43.9003 29.5013 49.4961 36.0721 49.4961C39.6305 49.4961 42.8221 47.8586 45.0013 45.2628M65.8346 36.9961C65.8346 43.9003 60.5013 49.4961 53.9305 49.4961C47.3555 49.4961 42.0263 43.9003 42.0263 36.9961C42.0263 30.0919 47.3555 24.4961 53.9305 24.4961C60.5013 24.4961 65.8346 30.0919 65.8346 36.9961Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CardCoinIcon
