import {
  Box,
  Card,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

type ListDetail = {
  key: string;
  value: string;
};

type CardDetailProps = {
  header: string;
  headerValue: string;
  list: ListDetail[];
};

const CardDetail: React.FC<CardDetailProps> = ({
  header,
  headerValue,
  list,
}) => {
  return (
    <Card h={"100%"} w={"100%"} p={6}>
      <HStack gap={4}>
        <Box>
          <Heading size="lg">{headerValue}</Heading>
        </Box>
        <Box>
          <Heading size="md">{header}</Heading>
        </Box>
      </HStack>
      <Stack mt={4} gap={4}>
        {list.map((row, i) => (
          <Flex key={"list_detail_" + i} gap={4} justify="space-between">
            <Box>
              <Text fontSize="sm" fontWeight="bold">
                {row.key}
              </Text>
            </Box>
            <Box>
              <Text fontSize="sm" fontWeight="bold">
                {row.value}
              </Text>
            </Box>
          </Flex>
        ))}
      </Stack>
    </Card>
  );
};

export default CardDetail;
