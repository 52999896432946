// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Box, Text, Flex } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import BackButton from "common/components/BackButton"
import { RevenueByInvoiceForBuyer } from "modules/revenue"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useInvoiceHook from "hooks/useInvoiceHook"

// ** Types **
import {
  AcceptedGuaranteedInvoice,
  AcceptedOfferAndGuaranteeSeller,
} from "types/Invoice"

// ** Utils **
import { numberFormatForCurrency } from "common/utils/transform"

// ** Icons **
import { ArrowRightIcon } from "assets/icons"

// ** Constants **
import { SORT } from "constants/AppOptions"
import { RevenueByBuyerBody, FilterType } from "constants/Enum"

const RevenueByBuyerOffer = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sortRevenue, setSortRevenue] = useState<string>("")
  const [sortAmount, setSortAmount] = useState<string>("")
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])
  const [ptId, setPtId] = useState<number>()
  const [body, setBody] = useState<RevenueByBuyerBody>(
    RevenueByBuyerBody.DEFAULT
  )

  const sortRevenueOpts = [
    { title: "Revenue Amount", value: "" },
    ...SORT.map((m) => ({ title: m, value: m })),
  ]
  const sortAmountOpts = [
    { title: "Default Amount", value: "" },
    ...SORT.map((m) => ({ title: m, value: m })),
  ]

  const {
    getAcceptedOfferAndGuaranteeSellerHook,
    acceptedOfferAndGuaranteeSeller,
  } = useInvoiceHook()

  useEffect(() => {
    if (acceptedOfferAndGuaranteeSeller.length > 0) {
      setData(acceptedOfferAndGuaranteeSeller)
    }
  }, [acceptedOfferAndGuaranteeSeller])

  const { isLoading } = useQuery({
    queryKey: "initRevenueByBuyerOffer",
    queryFn: getAcceptedOfferAndGuaranteeSellerHook,
  })

  const column: TableColumn[] = [
    {
      title: "Custom",
      value: "custom",
      isFooter: true,
      customRender: (key: string, value: any) => {
        return (
          <Flex w="100%" alignItems="center">
            <Box
              flex="0 0 80px"
              bgColor="emptyColor"
              h="80px"
              borderRadius="50%"
              mr={3}
            ></Box>
            <Box w="100%" mr={4}>
              <Flex fontSize={16}>
                <Text mr={2}>Debtor ID : </Text>
                <Text>{value.juristic_id}</Text>
              </Flex>
              <Text
                fontSize={20}
                fontWeight="bold"
                className="capital"
              >
                {value.company_name}
              </Text>
              <Flex w="100%" justify="space-between" mb={2}>
                <Box>
                  <Box>
                    <Text>Total No. of Invoice</Text>
                  </Box>
                  <Box>
                    <Text color="primary">{value.revenue_num}</Text>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Text>Revenue Amount</Text>
                  </Box>
                  <Box textAlign="right">
                    <Text color="primary">
                      {numberFormatForCurrency(value.revenue_amount)} NEW
                    </Text>
                  </Box>
                </Box>
              </Flex>
              <Flex
                w="100%"
                justify="space-between"
                borderTop="1px solid #CCCCCC"
                pt={2}
              >
                <Box>
                  <Box>
                    <Text>Total No. of Invoice</Text>
                  </Box>
                  <Box>
                    <Text color="primary">{value.default_num}</Text>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Text>Default Amount</Text>
                  </Box>
                  <Box textAlign="right">
                    <Text color="primary">
                      {numberFormatForCurrency(value.default_amount)} NEW
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Flex alignItems="center">
              <ArrowRightIcon />
            </Flex>
          </Flex>
        )
      },
    },
  ]

  const onRowClick = (data: AcceptedGuaranteedInvoice) => {
    setPtId(data.seller_pt_id)
    setBody(RevenueByBuyerBody.LIST)
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    let temp: AcceptedOfferAndGuaranteeSeller[] =
      [...acceptedOfferAndGuaranteeSeller]

    if (sortRevenue.indexOf("Ascending") > -1) {
      temp = temp.sort(
        (a, b) => parseFloat(a.revenue_amount) - parseFloat(b.revenue_amount)
      )
    }

    if (sortRevenue.indexOf("Descending") > -1) {
      temp = temp.sort(
        (a, b) => parseFloat(b.revenue_amount) - parseFloat(a.revenue_amount)
      )
    }

    if (sortAmount.indexOf("Ascending") > -1) {
      temp = temp.sort(
        (a, b) => parseFloat(a.default_amount) - parseFloat(b.default_amount)
      )
    }

    if (sortAmount.indexOf("Descending") > -1) {
      temp = temp.sort(
        (a, b) => parseFloat(b.default_amount) - parseFloat(a.default_amount)
      )
    }

    const filterValue = temp.filter((m) =>
      (m.company_name + m.juristic_id)
        .toLowerCase()
        .includes(fillValue.toLowerCase())
    )
    setData(filterValue)
  }, [fillValue, acceptedOfferAndGuaranteeSeller, sortRevenue, sortAmount])

  if (isLoading) return <></>

  const filterTable = [
    {
      options: sortRevenueOpts,
      onChange: (value: string) => setSortRevenue(value),
      value: sortRevenue,
      type: FilterType.DROPDOWN,
    },
    {
      options: sortAmountOpts,
      onChange: (value: string) => setSortAmount(value),
      value: sortAmount,
      type: FilterType.DROPDOWN,
    },
  ]

  return (
    <Box>
      <Box display={body === RevenueByBuyerBody.DEFAULT ? "block" : "none"}>
        <ContainerTable
          column={column}
          data={displayData}
          rawData={data}
          currentPage={currentPage}
          pageSize={pageSize}
          onViewSizeChange={(size) => {
            setPageSize(size)
            setCurrentPage(1)
          }}
          onPageChange={(page: number) => setCurrentPage(page)}
          totalCount={data.length}
          style={TableStyle.BOX}
          onRowClick={onRowClick}
          filter={filterTable}
          searchPlaceholder="Seller Name/ Tax ID"
          onSearchChange={onSearchChange}
          isCustom={true}
        />
      </Box>
      <Box display={body === RevenueByBuyerBody.LIST ? "block" : "none"} pt={8}>
        <BackButton customClick={() => setBody(RevenueByBuyerBody.DEFAULT)} />
        {ptId && <RevenueByInvoiceForBuyer ptId={ptId} />}
      </Box>
    </Box>
  )
}

export default RevenueByBuyerOffer
