import { AxiosResponse } from "axios";
import $axios from "./instance";
import {
    PostListInvoiceBody,
    PostListInvoiceResponse,
} from "./types/postListInvoiceType";

const postListInvoice = async (
  body: PostListInvoiceBody
): Promise<AxiosResponse<PostListInvoiceResponse>> => {
  const request = await $axios().post("/invoice", body);
  return request;
};

export default postListInvoice;
