import { Icon, IconProps } from "@chakra-ui/react"

const RevenueIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 84 80" fill="none" {...props}>
    <path
      d="M62.7537 31.9203C65 29.674 65 26.063 65 18.8333C65 11.6037 65 7.99267 62.7537 5.74633M62.7537 31.9203C60.5073 34.1667 56.8963 34.1667 49.6667 34.1667H34.3333C27.1037 34.1667 23.4927 34.1667 21.2463 31.9203M62.7537 5.74633C60.5073 3.5 56.8963 3.5 49.6667 3.5H34.3333C27.1037 3.5 23.4927 3.5 21.2463 5.74633M21.2463 5.74633C19 7.99267 19 11.6037 19 18.8333C19 26.063 19 29.674 21.2463 31.9203M45.8333 18.8333C45.8333 19.85 45.4295 20.825 44.7106 21.5439C43.9917 22.2628 43.0167 22.6667 42 22.6667C40.9833 22.6667 40.0083 22.2628 39.2894 21.5439C38.5705 20.825 38.1667 19.85 38.1667 18.8333C38.1667 17.8167 38.5705 16.8416 39.2894 16.1228C40.0083 15.4039 40.9833 15 42 15C43.0167 15 43.9917 15.4039 44.7106 16.1228C45.4295 16.8416 45.8333 17.8167 45.8333 18.8333Z"
      stroke="white"
      strokeWidth="6"
    />
    <path
      d="M65.0013 15C61.9513 15 59.0262 13.7884 56.8696 11.6317C54.7129 9.47506 53.5013 6.54999 53.5013 3.5M65.0013 22.6667C61.9513 22.6667 59.0262 23.8783 56.8696 26.0349C54.7129 28.1916 53.5013 31.1167 53.5013 34.1667M19.0013 15C22.0513 15 24.9764 13.7884 27.133 11.6317C29.2897 9.47506 30.5013 6.54999 30.5013 3.5M19.0013 22.6667C22.0513 22.6667 24.9764 23.8783 27.133 26.0349C29.2897 28.1916 30.5013 31.1167 30.5013 34.1667M15.168 70.154H23.8313C27.703 70.154 31.6245 70.5603 35.3926 71.3347C42.1201 72.7167 49.0422 72.87 55.8243 71.787C59.1516 71.2503 62.4176 70.4262 65.377 69.0002C68.045 67.7083 71.3148 65.8952 73.5113 63.8597C75.704 61.828 77.9886 58.5045 79.6063 55.9055C81.0016 53.6745 80.327 50.9413 78.1266 49.2815C76.8902 48.3821 75.4006 47.8977 73.8716 47.8977C72.3427 47.8977 70.8531 48.3821 69.6166 49.2815L62.6898 54.514C60.0065 56.5457 57.074 58.4125 53.5818 58.9683C53.1601 59.0335 52.7193 59.0948 52.2593 59.1485M52.2593 59.1485C52.1189 59.1648 51.9783 59.1801 51.8376 59.1945M52.2593 59.1485C52.8729 58.9826 53.4354 58.6664 53.8961 58.2285C54.4738 57.7288 54.9455 57.1184 55.2834 56.4334C55.6213 55.7484 55.8186 55.0027 55.8636 54.2402C55.9086 53.4778 55.8005 52.714 55.5455 51.994C55.2905 51.274 54.894 50.6124 54.3791 50.0482C53.8796 49.4936 53.2978 49.0191 52.6541 48.6413C41.9323 42.2435 25.2458 47.1157 15.168 54.2648M52.2593 59.1485C52.1208 59.1787 51.9794 59.1941 51.8376 59.1945M51.8376 59.1945C49.5258 59.4268 47.1968 59.432 44.884 59.2098"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <path
      d="M15.168 51.418C15.168 48.2423 12.5936 45.668 9.41797 45.668C6.24233 45.668 3.66797 48.2423 3.66797 51.418V70.5846C3.66797 73.7603 6.24233 76.3346 9.41797 76.3346C12.5936 76.3346 15.168 73.7603 15.168 70.5846V51.418Z"
      stroke="white"
      strokeWidth="6"
    />
  </Icon>
)

export default RevenueIcon
