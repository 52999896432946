import axios from "axios";

const commonHeader = () => {
  let headers = {};
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    headers = { ...headers, access_token: accessToken };
  }
  return headers;
};

const $axios = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: commonHeader(),
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      console.log("error req => ", error);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log("error response => ", error);
      return Promise.reject(error);
    }
  );
  return instance;
};

export default $axios;
