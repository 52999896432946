// ** React **
import { FC } from "react"

// ** Lis **
import { Box, Text, Image } from "@chakra-ui/react"

// ** Types **
import { Announce } from "types/Campaign"

// ** Utils **
import {
  formatDateDD_MMMM_YYYY,
  createShortDescription,
} from "common/utils/transform"

const NewsCard: FC<Announce> = ({
  title,
  owner_first_name,
  owner_last_name,
  detail,
  date,
  logo,
}) => {
  return (
    <Box
      borderRadius="lg"
      boxShadow="md"
      sx={{
        boxShadow:
          "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
      ml={4}
      mr={4}
    >
      <Box position="relative">
        <Image
          width="100%"
          borderRadius="8px 8px 0 0"
          src={process.env.REACT_APP_BASE_URL + "/" + logo}
          alt="logo"
        />
        <Box
          p="16px"
          bgColor="rgba(77, 157, 179, 0.60)"
          position="absolute"
          bottom="0"
          width="100%"
        >
          <Text fontSize="sm" color="white">
            Title
          </Text>
          <Text fontWeight="bold" color="white" height="42px" overflow="hidden">
            {title}
          </Text>
        </Box>
      </Box>
      <Box bgColor="white" borderRadius="0 0 8px 8px" p="16px">
        <Box mb={2}>
          <Text fontSize="sm" color="secondaryTextColor">
            Owner
          </Text>
          <Text height="22px" overflow="hidden">
            {owner_first_name} {owner_last_name}
          </Text>
        </Box>
        <Box mb={2}>
          <Text fontSize="sm" color="secondaryTextColor">
            Short Description
          </Text>
          <Text height="42px" overflow="hidden">
            {createShortDescription(detail, 200)}
          </Text>
        </Box>
        <Box>
          <Text fontSize="sm" color="secondaryTextColor">
            Date
          </Text>
          <Text>{formatDateDD_MMMM_YYYY(date)}</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default NewsCard
