import { Icon, IconProps } from "@chakra-ui/react"

const CreditCardEditIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 90 74" fill="none" {...props}>
    <path
      d="M3.33301 24.6673H86.6663V17.1673C86.6663 12.5002 86.6663 10.1667 85.7581 8.38407C84.9591 6.81605 83.6843 5.54122 82.1163 4.74227C80.3337 3.83398 78.0001 3.83398 73.333 3.83398H16.6663C11.9992 3.83398 9.66569 3.83398 7.88309 4.74226C6.31507 5.54121 5.04023 6.81605 4.24129 8.38406C3.33301 10.1667 3.33301 12.5002 3.33301 17.1673V48.834C3.33301 53.5011 3.33301 55.8346 4.24129 57.6172C5.04023 59.1852 6.31507 60.4601 7.88309 61.259C9.66568 62.1673 11.9992 62.1673 16.6663 62.1673H40.833M55.4163 70.5006L63.8537 68.8132C64.5894 68.666 64.9572 68.5925 65.3003 68.4579C65.6047 68.3385 65.8941 68.1837 66.1623 67.9966C66.4645 67.7858 66.7298 67.5205 67.2603 66.99L84.583 49.6673C86.8842 47.3661 86.8842 43.6352 84.583 41.334C82.2818 39.0328 78.5509 39.0328 76.2497 41.334L58.927 58.6567C58.3965 59.1872 58.1312 59.4525 57.9204 59.7547C57.7333 60.0229 57.5785 60.3123 57.4591 60.6167C57.3245 60.9597 57.2509 61.3276 57.1038 62.0633L55.4163 70.5006Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default CreditCardEditIcon
