import { Box, Center, Flex, HStack, Heading, Text } from "@chakra-ui/react";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import PasswordInput from "./PasswordInput";
import PrimaryButton from "./PrimaryButton";

type ConfirmPasswordProps = {
  header: string;
  onClickBack: VoidFunction;
  onClickConfirm: (val: string) => void;
};

type ConfirmPasswordForm = {
  password: string;
};

const ConfirmPassword: FC<ConfirmPasswordProps> = (props) => {
  const { header, onClickBack, onClickConfirm } = props;
  const { control, watch, trigger } = useForm<ConfirmPasswordForm>({
    defaultValues: { password: "" },
  });

  const currentPwd = watch("password");

  const onsubmit = async () => {
    trigger("password");
    if (currentPwd) {
      onClickConfirm(currentPwd);
    }
  };

  return (
    <Flex direction="column" gap={4} py={2}>
      <Center>
        <Heading fontSize="24px" color="#353535">
          {header}
        </Heading>
      </Center>
      <Center>
        <Text color="#353535">Enter your password to confirm.</Text>
      </Center>
      <Box mt={4}>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <PasswordInput
              label="Password"
              placeholder="Enter your password"
              type="text"
              {...field}
              error={fieldState.error}
              autoComplete="current-password"
            />
          )}
          rules={{ required: "Please enter your password" }}
        />
      </Box>
      {/* Button */}
      <Box w="100%">
        <HStack>
          <PrimaryButton size="md" variant="outline" onClick={onClickBack}>
            Back
          </PrimaryButton>
          <PrimaryButton
            size="md"
            colorScheme="#51BC68"
            backgroundColor="#51BC68"
            color="white"
            onClick={onsubmit}
          >
            Confirm
          </PrimaryButton>
        </HStack>
      </Box>
    </Flex>
  );
};

export default ConfirmPassword;
