import { Box, Center, Image, Stack } from "@chakra-ui/react"

type ProfilePictureProps = {
  bgUrl: string
  profileUrl: string
}

const ProfilePicture: React.FC<ProfilePictureProps> = (props) => {
  return (
    <Stack w={"100%"}>
      <Box>
        {props.bgUrl ? (
          <Image
            w={"100%"}
            h="225px"
            objectFit="cover"
            src={process.env.REACT_APP_BASE_URL + "/" + props.bgUrl}
          />
        ) : (
          <Box w="100%" h={225} bgColor="grey400"></Box>
        )}
      </Box>
      <Box mt="-112.5px">
        <Center
          bg="white"
          w={"225px"}
          m="0 auto"
          borderRadius="40px"
          border="1px solid #6A727A"
        >
          {props.profileUrl ? (
            <Image
              boxSize="225px"
              objectFit="contain"
              borderRadius="40px"
              src={process.env.REACT_APP_BASE_URL + "/" + props.profileUrl}
            />
          ) : (
            <Box w={225} h={225} bgColor="grey400" borderRadius={40}></Box>
          )}
        </Center>
      </Box>
    </Stack>
  )
}

export default ProfilePicture
