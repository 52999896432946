import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetDiscountingResponse,
  GetDiscountingCompanyResponse,
} from "./types/discounting"

const getAcceptedInvoicesBySeller = async (
  option: number
): Promise<AxiosResponse<GetDiscountingResponse>> => {
  const request = await $axios().get(
    "/get_accepted_invoices_by_seller_for_dashboard/" + option
  )
  return request
}

const getAcceptedInvoicesByCompany = async (
  option: number
): Promise<AxiosResponse<GetDiscountingCompanyResponse>> => {
  const request = await $axios().get(
    "/get_accepted_invoices_for_dashboard_group_operator/" + option
  )
  return request
}

export { getAcceptedInvoicesBySeller, getAcceptedInvoicesByCompany }
