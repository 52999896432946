import { RootState, useAppDispatch, useAppSelector } from "../store"
import { appActions } from "../store/slice/AppSlice"
import { arActions } from "../store/slice/ArSlice"

// ** API **
import getArCurrent from "api/getArCurrent"
import getArHistory from "api/getArHistory"
import getAlert from "api/getAlert"
import postOfferRepay from "api/postOfferRepay"

// ** Types **
import type { PostOfferRepayBody } from "api/types/postOfferRepayType"

const useArHook = () => {
  const dispatch = useAppDispatch()

  const {
    arapCurrentOp1,
    arapCurrentOp2,
    arapCurrentOp3,
    arapHistoryOp1,
    arapHistoryOp2,
    arapHistoryOp3,
    alert,
  } = useAppSelector((state: RootState) => state.ar)

  const getArCurrentHook = async (option: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getArCurrent(option)
      if (option === 1) {
        dispatch(arActions.setArCurrentOp1(response.data.data))
      }
      if (option === 2) {
        dispatch(arActions.setArCurrentOp2(response.data.data))
      }
      if (option === 3) {
        dispatch(arActions.setArCurrentOp3(response.data.data))
      }
    } catch (err) {
      console.log("getInvoicesHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getArHistoryHook = async (option: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getArHistory(option)
      if (option === 1) {
        dispatch(arActions.setArHistoryOp1(response.data.data))
      }
      if (option === 2) {
        dispatch(arActions.setArHistoryOp2(response.data.data))
      }
      if (option === 3) {
        dispatch(arActions.setArHistoryOp3(response.data.data))
      }
    } catch (err) {
      console.log("getInvoicesHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postOfferRepayHook = async (body: PostOfferRepayBody) => {
    try {
      dispatch(appActions.showLoader())
      const response = await postOfferRepay(body)
      return response.data
    } catch (err) {
      console.log("postOfferRepayHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getAlertHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getAlert()
      dispatch(arActions.setAlert(response.data.data))
    } catch (err) {
      console.log("getInvoicesHook err => ", err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getArCurrentHook,
    getArHistoryHook,
    postOfferRepayHook,
    getAlertHook,
    arapCurrentOp1,
    arapCurrentOp2,
    arapCurrentOp3,
    arapHistoryOp1,
    arapHistoryOp2,
    arapHistoryOp3,
    alert,
  }
}

export default useArHook
