import getAllListedInvoices from "../api/getAllListedInvoices";
import getInvoiceById from "../api/getInvoiceById";
import getInvoices from "../api/getInvoices";
import getAcceptedGuaranteedInvoices from "../api/getAcceptedGuaranteedInvoices";
import getAcceptedInvoicesOfOffererAndGuarantee from "api/getAcceptedInvoicesOfOffererAndGuarantee";
import getAcceptedGuaranteedBuyer from "../api/getAcceptedGuaranteedBuyer";
import getAcceptedInvoicesOfferer from "api/getAcceptedInvoicesOfferer";
import getAcceptedInvoicesOffererPtId from "api/getAcceptedInvoicesOffererPtId";
import getAcceptedOfferBuyer from "api/getAcceptedOfferBuyer";
import getAcceptedOfferAndGuaranteeSeller from "api/getAcceptedOfferAndGuaranteeSeller";
import { getOfferByInvoice } from "../api/getOfferByInvoice";
import getOwnInvoicesHistory from "../api/getOwnInvoicesHistory";
import getOwnListedInvoices from "../api/getOwnListedInvoices";
import getOwnOfferHistory from "../api/getOwnOfferHistory";
import { postAcceptOffer, postDeclineOffer } from "../api/postAcceptOffer";
import postCancelInvoice from "../api/postCancelInvoice";
import postListInvoice from "../api/postListInvoice";
import postMakeOffer from "../api/postMakeOffer";
import { PostAcceptOfferBody } from "../api/types/postAcceptOfferType";
import { PostCancelInvoiceBody } from "../api/types/postCancelInvoiceType";
import { PostListInvoiceBody } from "../api/types/postListInvoiceType";
import { PostMakeOfferBody } from "../api/types/postMakeOfferType";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { appActions } from "../store/slice/AppSlice";
import { invoiceActions } from "../store/slice/InvoiceSlice";
import { DeclineOffer } from "types/Invoice"

const useInvoiceHook = () => {
  const dispatch = useAppDispatch();
  const {
    myInvoices,
    listedInvoices,
    invoicesHistory,
    offerHistory,
    acceptedGuaranteedInvoices,
    acceptedGuaranteedBuyers,
    acceptedInvoicesOfferer,
    acceptedInvoicesOffererPtId,
    acceptedOfferBuyer,
    acceptedInvoicesOfOffererAndGuarantee,
    acceptedOfferAndGuaranteeSeller,
  } = useAppSelector((state: RootState) => state.invoice);

  const getInvoicesHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getInvoices();
      dispatch(invoiceActions.setMyInvoices(response.data.data));
    } catch (err) {
      console.log("getInvoicesHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getInvoiceByIdHook = async (id: string) => {
    try {
      dispatch(appActions.showLoader());
      const response = await getInvoiceById(id);
      return response.data.data;
    } catch (err) {
      console.log("getInvoiceByIdHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const postListInvoiceHook = async (body: PostListInvoiceBody) => {
    try {
      dispatch(appActions.showLoader());
      const response = await postListInvoice(body);
      return response;
    } catch (err) {
      console.log("postListInvoiceHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getOwnListedInvoicesHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getOwnListedInvoices();
      dispatch(invoiceActions.setListedInvoices(response.data.data));
    } catch (err) {
      console.log("getOwnListedInvoicesHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAllListedInvoicesHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAllListedInvoices();
      dispatch(invoiceActions.setListedInvoices(response.data.data));
    } catch (err) {
      console.log("getAllListedInvoicesHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getOwnInvoicesHistoryHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getOwnInvoicesHistory();
      dispatch(invoiceActions.setInvoicesHistory(response.data.data));
    } catch (err) {
      console.log("getOwnInvoicesHistoryHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getOwnOfferHistoryHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getOwnOfferHistory();
      dispatch(invoiceActions.setOfferHistory(response.data.data));
    } catch (err) {
      console.log("getOwnOfferHistoryHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedGuaranteedInvoicesHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedGuaranteedInvoices();
      dispatch(invoiceActions.setAcceptedGuaranteedInvoices(response.data.data));
    } catch (err) {
      console.log("getAcceptedGuaranteedInvoicesHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedGuaranteedBuyerHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedGuaranteedBuyer();
      dispatch(invoiceActions.setAcceptedGuaranteedBuyers(response.data.data));
    } catch (err) {
      console.log("getAcceptedGuaranteedBuyerHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedInvoicesOffererHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedInvoicesOfferer();
      dispatch(invoiceActions.setAcceptedInvoicesOfferer(response.data.data));
    } catch (err) {
      console.log("getAcceptedInvoicesOffererHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedInvoicesOffererPtIdHook = async (id: string) => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedInvoicesOffererPtId(id);
      dispatch(invoiceActions.setAcceptedInvoicesOffererPtId(response.data.data));
    } catch (err) {
      console.log("getAcceptedInvoicesOffererPtIdHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedOfferBuyerHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedOfferBuyer();
      dispatch(invoiceActions.setAcceptedOfferBuyer(response.data.data));
    } catch (err) {
      console.log("getAcceptedOfferBuyerHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedInvoicesOfOffererAndGuaranteeHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedInvoicesOfOffererAndGuarantee();
      dispatch(invoiceActions.setAcceptedInvoicesOfOffererAndGuarantee(response.data.data));
    } catch (err) {
      console.log("getAcceptedInvoicesOfOffererAndGuaranteeHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getAcceptedOfferAndGuaranteeSellerHook = async () => {
    try {
      dispatch(appActions.showLoader());
      const response = await getAcceptedOfferAndGuaranteeSeller();
      dispatch(invoiceActions.setAcceptedOfferAndGuaranteeSeller(response.data.data));
    } catch (err) {
      console.log("getAcceptedOfferAndGuaranteeSellerHook err => ", err);
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const postMakeOfferHook = async (body: PostMakeOfferBody) => {
    try {
      dispatch(appActions.showLoader());
      const response = await postMakeOffer(body);
      return response.data.statusCode;
    } catch (err) {
      console.log("postMakeOfferHook err => ", err);
      return err;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const getOfferByInvoiceHook = async (id: string) => {
    try {
      dispatch(appActions.showLoader());
      const response = await getOfferByInvoice(id);
      return response.data.data;
    } catch (err) {
      console.log("getOfferByInvoiceHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const postAcceptOfferHook = async (body: PostAcceptOfferBody) => {
    try {
      dispatch(appActions.showLoader());
      const response = await postAcceptOffer(body);
      await getOfferByInvoiceHook(`${body.id}`);
      return response.data;
    } catch (err) {
      console.log("postAcceptOfferHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const postDeclineOfferHook = async (body: DeclineOffer) => {
    try {
      dispatch(appActions.showLoader());
      const response = await postDeclineOffer(body);
      await getOfferByInvoiceHook(`${body.id}`);
      return response.data;
    } catch (err) {
      console.log("postDeclineOfferHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  const postCancelInvoiceHook = async (body: PostCancelInvoiceBody) => {
    try {
      dispatch(appActions.showLoader());
      const response = await postCancelInvoice(body);
      return response.data;
    } catch (err) {
      console.log("postCancelInvoiceHook err => ", err);
      return null;
    } finally {
      dispatch(appActions.hideLoader());
    }
  };

  return {
    getInvoicesHook,
    getInvoiceByIdHook,
    postListInvoiceHook,
    getOwnListedInvoicesHook,
    getAllListedInvoicesHook,
    postMakeOfferHook,
    getOfferByInvoiceHook,
    postAcceptOfferHook,
    postDeclineOfferHook,
    postCancelInvoiceHook,
    getOwnInvoicesHistoryHook,
    getOwnOfferHistoryHook,
    getAcceptedGuaranteedInvoicesHook,
    getAcceptedGuaranteedBuyerHook,
    getAcceptedInvoicesOffererHook,
    getAcceptedInvoicesOffererPtIdHook,
    getAcceptedOfferBuyerHook,
    getAcceptedInvoicesOfOffererAndGuaranteeHook,
    getAcceptedOfferAndGuaranteeSellerHook,
    myInvoices,
    listedInvoices,
    invoicesHistory,
    offerHistory,
    acceptedGuaranteedInvoices,
    acceptedGuaranteedBuyers,
    acceptedInvoicesOfferer,
    acceptedInvoicesOffererPtId,
    acceptedOfferBuyer,
    acceptedInvoicesOfOffererAndGuarantee,
    acceptedOfferAndGuaranteeSeller,
  };
};

export default useInvoiceHook;
