import { Box, Flex, Grid, GridItem, HStack, Heading } from "@chakra-ui/react";
import { FC } from "react";
import useAuthHook from "../../../hooks/useAuthHook";
import { MyWallet } from "../../../types/MyWallet";
import {
  abbreviateHexString,
  numberFormatForCurrency,
} from "../../utils/transform";
import PrimaryButton from "../PrimaryButton";
import { TopupInputValue } from "./TopupModalContainer";

type TopupReviewProps = {
  topupValue?: TopupInputValue;
  currentWallet: MyWallet;
  unit: string;
  onClickConfirm: VoidFunction;
  onClickBack: VoidFunction;
};

const TopupReview: FC<TopupReviewProps> = (props) => {
  const { topupValue, unit, onClickConfirm, onClickBack } =
    props;
  const { user } = useAuthHook();

  if (!topupValue) return <></>;

  return (
    <Flex direction="column" justify="center" align="center" gap={10}>
      <Box>
        <Heading size="md">Top-up Confirm</Heading>
      </Box>
      {/* Review */}
      <Box w="100%">
        <Grid templateColumns="115px 1fr" gap={4}>
          {/* From */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            From
          </GridItem>
          <GridItem></GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Bank Name
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {topupValue.destination.bank_name}
          </GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Bank Account
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {topupValue.destination.bank_account_number}
          </GridItem>
          {/* To */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            To
          </GridItem>
          <GridItem></GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Company name
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {topupValue.destination?.company_name}
          </GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Wallet address
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {`${topupValue.destination.nickname ?? ""} (${abbreviateHexString(
              topupValue.destination.wallet_address
            )})`}
          </GridItem>
          {/* Email */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Email
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {user?.email}
          </GridItem>
          {/* Amount */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Amount
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {numberFormatForCurrency(topupValue.amount)} THB
          </GridItem>
          {/* Total Amount */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Received Amount
          </GridItem>
          <GridItem
            textAlign="right"
            color="primary"
            fontSize="small"
            fontWeight="700"
          >
            {numberFormatForCurrency(topupValue.amount)} {unit}
          </GridItem>
        </Grid>
      </Box>
      {/* Button */}
      <Box w="100%">
        <HStack>
          <PrimaryButton
            variant="outline"
            onClick={onClickBack}
          >
            Back
          </PrimaryButton>
          <PrimaryButton
            colorScheme="#51BC68"
            backgroundColor="#51BC68"
            color="white"
            onClick={onClickConfirm}
          >
            Confirm
          </PrimaryButton>
        </HStack>
      </Box>
    </Flex>
  );
};

export default TopupReview;
