import { Icon, IconProps } from "@chakra-ui/react"

const ArrowUpIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 12 8" fill="none" {...props}>
    <path
      d="M11.5326 5.97073L6.53255 0.97073C6.46287 0.90081 6.38008 0.845333 6.28892 0.807479C6.19775 0.769625 6.10001 0.750138 6.0013 0.750138C5.90259 0.750138 5.80485 0.769625 5.71369 0.807479C5.62252 0.845333 5.53973 0.90081 5.47005 0.97073L0.470051 5.97073C0.329155 6.11163 0.25 6.30272 0.25 6.50198C0.25 6.70124 0.329155 6.89233 0.470051 7.03323C0.610947 7.17413 0.802044 7.25328 1.0013 7.25328C1.20056 7.25328 1.39165 7.17413 1.53255 7.03323L6.00193 2.56386L10.4713 7.03385C10.6122 7.17475 10.8033 7.25391 11.0026 7.25391C11.2018 7.25391 11.3929 7.17475 11.5338 7.03385C11.6747 6.89296 11.7539 6.70186 11.7539 6.5026C11.7539 6.30335 11.6747 6.11225 11.5338 5.97135L11.5326 5.97073Z"
      fill="#00249C"
    />
  </Icon>
)

export default ArrowUpIcon
