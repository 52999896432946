// ** React **
import { useCallback, useState, useEffect, useMemo } from "react"

// ** Libs **
import { Box, Center, Heading, useDisclosure } from "@chakra-ui/react"
import _, { debounce } from "lodash"

// ** Components **
import BackButton from "common/components/BackButton"
import ContainerTable, {
  TableStyle,
} from "common/components/Table/ContainerTable"
import { TableColumn } from "common/components/Table/BoxDataTable"
import { AdjustmentCreditTokenModalContainer } from "common/components/MintCreditTokenModal"

// ** Constants **
import { CreditBody } from "constants/Enum"

// ** Hooks **
import { useQuery } from "hooks/useQueryHook"
import useCreditHook from "hooks/useCreditHook"

// ** Types **
import { ParticipantCreditItem } from "types/Credit"

// ** Utils **
import {
  numberFormatForCurrency,
  findFirstPositiveValueIndex,
  formatDateTimeStamp,
} from "common/utils/transform"

const CreditAdjustment = ({ setBody }: { setBody: any }) => {
  const adjustmentCreditTokenModalAction = useDisclosure()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selected, setSelected] = useState<ParticipantCreditItem>()
  const [fillValue, setFillValue] = useState<string>("")
  const [data, setData] = useState<any[]>([])

  const { getAllParticipantsHook, allParticipants } = useCreditHook()

  const { isLoading, refetch: refetchAllParticipants } = useQuery({
    queryKey: "initAllParticipants",
    queryFn: getAllParticipantsHook,
  })

  useEffect(() => {
    setData(allParticipants.filter((w) => w.guarantee_fee.length > 0))
  }, [allParticipants])

  const column: TableColumn[] = [
    {
      title: "Corporate Name",
      value: "company_name",
    },
    {
      title: "Juristic ID",
      value: "juristic_id",
    },
    {
      title: "Credit Amount",
      value: "amount",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {value.amount
              ? numberFormatForCurrency(
                  value.amount.reduce((a: string, b: string) => {
                    return parseFloat(b) + parseFloat(a)
                  }, 0)
                )
              : ""}
          </Box>
        )
      },
    },
    {
      title: "Total Wallet",
      value: "total_wallet",
    },
    {
      title: "Credit type",
      value: "credit_type",
    },
    {
      title: "Guarantee Fee",
      value: "guarantee_fee",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {findFirstPositiveValueIndex(value.amount) > -1
              ? value.guarantee_fee[findFirstPositiveValueIndex(value.amount)]
              : "-"}
            %
          </Box>
        )
      },
    },
    {
      title: "Vaild Until",
      value: "valid_until",
      customRender: (key: string, value: any) => {
        return (
          <Box key={key}>
            {value.valid_until && value.valid_until.length > 0
              ? formatDateTimeStamp(value.valid_until[0])
              : "-"}
          </Box>
        )
      },
    },
  ]

  const onRowClick = (data: ParticipantCreditItem) => {
    setSelected(data)
    adjustmentCreditTokenModalAction.onOpen()
  }

  const chunkData = useMemo(() => _.chunk(data, pageSize), [data, pageSize])

  const displayData = useMemo(
    () => chunkData[currentPage - 1] ?? [],
    [chunkData, currentPage]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchChange = useCallback(
    debounce((fillValue: string) => {
      setFillValue(fillValue)
    }, 1000),
    []
  )

  useEffect(() => {
    const filterValue = allParticipants
      .filter((w) => w.guarantee_fee.length > 0)
      .filter((m) =>
        (m.company_name + m.juristic_id)
          .toLowerCase()
          .includes(fillValue.toLowerCase())
      )
    setData(filterValue)
  }, [fillValue, allParticipants])

  if (isLoading) return <></>

  return (
    <Box>
      <BackButton customClick={() => setBody(CreditBody.DEFAULT)} />
      <Center>
        <Heading size="lg">Credit Adjustment</Heading>
      </Center>
      <ContainerTable
        column={column}
        data={displayData}
        rawData={data}
        currentPage={currentPage}
        pageSize={pageSize}
        onViewSizeChange={(size) => {
          setPageSize(size)
          setCurrentPage(1)
        }}
        onPageChange={(page: number) => setCurrentPage(page)}
        totalCount={data.length}
        style={TableStyle.BOX}
        onRowClick={onRowClick}
        searchPlaceholder="Search by Corporate Name or Jurisdic ID"
        onSearchChange={onSearchChange}
      />
      <AdjustmentCreditTokenModalContainer
        {...adjustmentCreditTokenModalAction}
        defaultSelect={selected}
        refetch={refetchAllParticipants}
      />
    </Box>
  )
}

export default CreditAdjustment
