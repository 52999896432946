import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetParticipantProfileResponse } from "./types/getParticipantProfileType";

const getParticipantProfile = async (): Promise<
  AxiosResponse<GetParticipantProfileResponse>
> => {
  const request = await $axios().get("/participant_profile");
  return request;
};

export default getParticipantProfile;
