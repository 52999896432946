// ** Store **
import { RootState, useAppDispatch, useAppSelector } from "store"
import { appActions } from "store/slice/AppSlice"
import { participantActions } from "store/slice/ParticipantSlice"

// ** APIs **
import {
  getParticipant,
  getParticipantProfile,
  putParticipantProfile,
  getParticipantType,
  getParticipantRole,
  getParticipantDetail,
  createParticipant,
  putParticipantDetail,
  createParticipantWallet,
  putParticipantWallet,
  createParticipantUser,
  putParticipantUser,
  postFreezeWallet,
  postUnfreezeWallet,
  postSuspendWallet,
  postUnsuspendWallet,
  uploadCompanyImage,
} from "api/getParticipant"

// ** Types **
import {
  ParticipantDetailEditBody,
  CreateWalletPostBody,
  EditWalletPostBody,
  CreateUserBody,
  EditUserBody,
  CreateParticipantAdminBody,
  WalletPutBody,
  ParticipantProfileBody,
} from "types/Participant"
import type { ResponseWithOutData } from "api/types/ApiResponse"

// ** Utils **
import { handleError } from "common/utils/Response"

const useParticipantHook = () => {
  const dispatch = useAppDispatch()

  const {
    participants,
    participantProfile,
    participantTypes,
    participantRoles,
    participantDetail,
  } = useAppSelector((state: RootState) => state.participant)

  const getParticipantsHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipant()
      dispatch(participantActions.setParticipants(response.data.data))
    } catch (err) {
      console.log("getParticipantsHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getParticipantProfileHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipantProfile()
      dispatch(participantActions.setParticipantProfile(response.data.data))
    } catch (err) {
      console.log("getParticipantProfileHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const putParticipantProfileHook = async (
    body: ParticipantProfileBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await putParticipantProfile(body)
      await getParticipantProfileHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getParticipantTypesHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipantType()
      dispatch(participantActions.setParticipantTypes(response.data.data))
    } catch (err) {
      console.log("getParticipantTypesHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getParticipantRolesHook = async () => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipantRole()
      dispatch(participantActions.setParticipantRoles(response.data.data))
    } catch (err) {
      console.log("getParticipantRolesHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const getParticipantDetailHook = async (id: number) => {
    try {
      dispatch(appActions.showLoader())
      const response = await getParticipantDetail(id)
      dispatch(participantActions.setParticipantDetail(response.data.data))
    } catch (err) {
      console.log("getParticipantDetailHook err => ", err)
      return null
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const createParticipantHook = async (
    body: ParticipantDetailEditBody | CreateParticipantAdminBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await createParticipant(body)
      await getParticipantsHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const putParticipantDetailHook = async (
    id: number,
    body: ParticipantDetailEditBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await putParticipantDetail(id, body)
      await getParticipantDetailHook(id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const createParticipantWalletHook = async (
    body: CreateWalletPostBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await createParticipantWallet(body)
      await getParticipantDetailHook(body.pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const putParticipantWalletHook = async (
    id: number,
    pt_id: number,
    body: EditWalletPostBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await putParticipantWallet(id, body)
      await getParticipantDetailHook(pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postFreezeWalletHook = async (
    pt_id: number,
    body: WalletPutBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postFreezeWallet(body)
      await getParticipantDetailHook(pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postUnfreezeWalletHook = async (
    pt_id: number,
    body: WalletPutBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postUnfreezeWallet(body)
      await getParticipantDetailHook(pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postSuspendWalletHook = async (
    pt_id: number,
    body: WalletPutBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postSuspendWallet(body)
      await getParticipantDetailHook(pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postUnsuspendWalletHook = async (
    pt_id: number,
    body: WalletPutBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await postUnsuspendWallet(body)
      await getParticipantDetailHook(pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const createParticipantUserHook = async (
    body: CreateUserBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await createParticipantUser(body)
      await getParticipantDetailHook(body.pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const putParticipantUserHook = async (
    id: number,
    pt_id: number,
    body: EditUserBody
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await putParticipantUser(id, body)
      await getParticipantDetailHook(pt_id)
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  const postUploadCompanyImageHook = async (
    type: string,
    file: File
  ): Promise<ResponseWithOutData> => {
    try {
      dispatch(appActions.showLoader())
      const response = await uploadCompanyImage(type, file)
      await getParticipantProfileHook()
      return response.data
    } catch (err) {
      return handleError(err)
    } finally {
      dispatch(appActions.hideLoader())
    }
  }

  return {
    getParticipantsHook,
    getParticipantProfileHook,
    putParticipantProfileHook,
    getParticipantTypesHook,
    getParticipantRolesHook,
    getParticipantDetailHook,
    createParticipantHook,
    putParticipantDetailHook,
    createParticipantWalletHook,
    putParticipantWalletHook,
    createParticipantUserHook,
    putParticipantUserHook,
    postFreezeWalletHook,
    postUnfreezeWalletHook,
    postSuspendWalletHook,
    postUnsuspendWalletHook,
    postUploadCompanyImageHook,
    participants,
    participantProfile,
    participantTypes,
    participantRoles,
    participantDetail,
  }
}

export default useParticipantHook
