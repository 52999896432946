import { AxiosResponse } from "axios"
import $axios from "./instance"
import { PostRecoveryPasswordBody } from "./types/postLoginType"

const postRecoverPassword = async (
  body: PostRecoveryPasswordBody
): Promise<AxiosResponse> => {
  const request = await $axios().post("/recover_password", body)
  return request
}

export default postRecoverPassword
