import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetInvoicesResponse } from "./types/getInvoicesType";

const getInvoices = async (): Promise<AxiosResponse<GetInvoicesResponse>> => {
  const request = await $axios().get("/invoices");
  return request;
};

export default getInvoices;
