import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetInvoiceByIdResponse } from "./types/getInvoiceByIdType";

const getInvoiceById = async (
  id: string
): Promise<AxiosResponse<GetInvoiceByIdResponse>> => {
  const request = await $axios().get("/invoice/" + id);
  return request;
};

export default getInvoiceById;
