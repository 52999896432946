// ** Components **
import CardGraph, { CardGraphProps } from "common/components/CardGraph"

// ** Hook **
import { useQuery } from "hooks/useQueryHook"
import useTokenManagementHook from "hooks/useTokenManagementHook"

// ** Utils **
import { numberFormatForCurrency } from "common/utils/transform"

const CreditTokens = () => {
  const { getTotalSupplyCreditHook, totalSupplyCredit } =
    useTokenManagementHook()

  const { isLoading } = useQuery({
    queryKey: "getTotalSupplyCreditHook",
    queryFn: getTotalSupplyCreditHook,
  })

  const mapping = ["#00BE91", "#EAD300", "#FFAC5F"]

  const total = Object.keys(totalSupplyCredit)
    .map((index: string) => parseFloat(totalSupplyCredit[index]))
    .reduce((acc: number, cur: number) => acc + cur, 0)

  if (isLoading) return <></>

  const dataAllTokens: CardGraphProps = {
    header: "All tokens",
    value: numberFormatForCurrency(`${total}`),
    valueUnit: "tokens",
    isCurrency: true,
    list: Object.keys(totalSupplyCredit).map(
      (credit: string, index: number) => {
        return {
          key: credit,
          value: parseFloat(totalSupplyCredit[credit]),
          color: mapping[index] ?? "#00BE91",
        }
      }
    ),
  }

  return <CardGraph {...dataAllTokens}></CardGraph>
}

export default CreditTokens
