import { AxiosResponse } from "axios"
import $axios from "./instance"
import {
  GetAdminResponse,
  PostCreateAdminDataResponse,
  PutUpdateAdminDataResponse,
} from "./types/admin"
import {
  AdminBody,
} from "types/Admin"

const getAdmin = async (): Promise<
  AxiosResponse<GetAdminResponse>
> => {
  const request = await $axios().get("/users/admin")
  return request
}

const createAdmin = async (
  body: AdminBody
): Promise<AxiosResponse<PostCreateAdminDataResponse>> => {
  const request = await $axios().post("/user/admin", body)
  return request
}

const updateAdmin = async (
  id: number,
  body: AdminBody
): Promise<AxiosResponse<PutUpdateAdminDataResponse>> => {
  const request = await $axios().put("/user/admin/" + id, body)
  return request
}

export {
  getAdmin,
  createAdmin,
  updateAdmin,
}
