// ** Libs **
import { Heading, Box, Text, useDisclosure } from "@chakra-ui/react"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"
import {
  ParticipantUserItem,
  ParticipantCreateUserModal,
} from "modules/client_management"

// ** Hooks **
import useParticipantHook from "hooks/useParticipantHook"
import useAuthHook from "hooks/useAuthHook"

const ParticipantUser = () => {
  const participantCreateUserModalAction = useDisclosure()
  const { participantDetail } = useParticipantHook()
  const { user, isAdmin } = useAuthHook()

  return (
    <Box>
      {((user && user.pt_id === participantDetail?.pt_created_id) ||
        isAdmin) && (
        <PrimaryButton
          border="2px solid"
          borderColor="primary"
          variant="outline"
          colorScheme="primary"
          color="primary"
          onClick={() => participantCreateUserModalAction.onOpen()}
          w="140px"
          mb={4}
        >
          Add User
        </PrimaryButton>
      )}
      <Box p={6} border="1px solid" borderRadius={6} borderColor="emptyColor">
        {participantDetail && participantDetail?.users.length > 0 && (
          <Heading size="md" color="primary">
            User list & Detail
          </Heading>
        )}
        {participantDetail?.users.map((user) => (
          <ParticipantUserItem
            data={user}
            participantDetail={participantDetail}
          />
        ))}
        {participantDetail?.users.length === 0 && (
          <Text align="center">No found user</Text>
        )}
      </Box>
      <ParticipantCreateUserModal {...participantCreateUserModalAction} />
    </Box>
  )
}

export default ParticipantUser
