import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetBuyerWalletResponse } from "./types/getBuyerWalletType";

const getBuyerWallet = async (): Promise<
  AxiosResponse<GetBuyerWalletResponse>
> => {
  const request = await $axios().get("/wallet/buyer");
  return request;
};

export default getBuyerWallet;
