// ** React **
import { FC, useEffect, useState } from "react"

// ** Libs **
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import TextInput from "common/components/TextInput"
import PrimaryButton from "common/components/PrimaryButton"
import PasswordInput from "common/components/PasswordInput"
import Select from "react-select"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useParticipantHook from "hooks/useParticipantHook"

// ** Types **
import type { CreateParticipantAdminBody } from "types/Participant"
import { ModalProps } from "types/ModalProps"
import { Alert, AlertType } from "types/Alert"
import { Option } from "types/Global"

// ** Constants **
import { FormWithConfirmPasswordBody } from "constants/Enum"
import {
  COMPANY_NAME_REGEX,
  JURISTIC_ID_REGEX,
} from "constants/AppStrings"

// ** Utils **
import { bankOptions } from "common/utils/bank"

// ** Icons **
import IconOption from "assets/icons/bank"

type ParticipantDetailEditModalProps = {} & ModalProps

const UpdateParticipantDetailModal: FC<ParticipantDetailEditModalProps> = (
  props
) => {
  const { isOpen, onClose } = props
  const { setAlert } = useAppHook()

  const [optionTypes, setOptionTypes] = useState<Option[]>([])
  const [optionRoles, setOptionRoles] = useState<Option[]>([])
  const [selectedRole, setSelectedRole] = useState<Option | null>(null)
  const [contentBody, setContentBody] = useState<FormWithConfirmPasswordBody>(
    FormWithConfirmPasswordBody.FORM
  )

  const {
    getParticipantsHook,
    putParticipantDetailHook,
    participantDetail,
    participantTypes,
    participantRoles,
  } = useParticipantHook()

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<CreateParticipantAdminBody>({
    mode: "onChange",
  })

  const type = watch("type")
  const role = watch("role")
  const bank = watch("bank")
  const statusOption = watch("statusOption")

  useEffect(() => {
    if (bank !== undefined) {
      setValue("bank_code", bank.code)
    }
  }, [bank, setValue])

  useEffect(() => {
    if (participantTypes) {
      setOptionTypes(
        participantTypes
          .filter((item) => item.id !== 0)
          .map((item) => {
            return { value: `${item.id}`, label: item.name }
          })
      )
    }
  }, [participantTypes])

  useEffect(() => {
    if (participantRoles && type) {
      setOptionRoles(
        participantRoles
          .filter((item) => item.type_id === parseInt(type.value))
          .map((item) => {
            return { value: `${item.id}`, label: item.name }
          })
      )
    }
  }, [participantRoles, type, setValue])

  useEffect(() => {
    if (type) {
      setValue("type_id", parseInt(type.value))
    }
  }, [type, setValue])

  useEffect(() => {
    if (role) {
      setValue("role_id", parseInt(role.value))
    }
  }, [role, setValue])

  useEffect(() => {
    if (statusOption) {
      setValue("status", statusOption.value)
    }
  }, [statusOption, setValue])

  useEffect(() => {
    if (participantDetail !== null) {
      const _type = participantTypes
        .filter((item) => item.id === participantDetail.type_id)
        .map((item) => {
          return {
            label: item.name,
            value: `${item.id}`,
          }
        })
      const _role = participantRoles
        .filter((item) => item.id === participantDetail.role_id)
        .map((item) => {
          return {
            label: item.name,
            value: `${item.id}`,
          }
        })
      setSelectedRole(_role[0])
      let bank = undefined
      if (participantDetail.bank_code) {
        const _bankOptions = bankOptions()
        const currentBank = _bankOptions.filter((item) => item.code === participantDetail.bank_code)
        if (currentBank.length > 0) { 
          bank = currentBank[0]
        }
      }
      reset({
        company_name: participantDetail.company_name,
        type: _type[0],
        role: _role[0],
        type_id: participantDetail.type_id,
        role_id: participantDetail.role_id,
        bank: bank,
        bank_code: participantDetail.bank_code,
        juristic_id: participantDetail.juristic_id,
        statusOption:
          participantDetail.status === "active"
            ? { value: "true", label: "Active" }
            : { value: "false", label: "Inactive" },
        status: participantDetail.status,
      })
    }
  }, [participantDetail, participantTypes, participantRoles, reset])

  const validateForm = () => {
    const company_name = watch("company_name")
    const type = watch("type")
    const role = watch("role")
    const bank_code = watch("bank_code")
    const juristic_id = watch("juristic_id")
    const statusOption = watch("statusOption")
    if (company_name === "")
      setError("company_name", { message: "Please enter Company Name" })
    if (
      company_name !== undefined &&
      !RegExp(COMPANY_NAME_REGEX).test(company_name)
    ) {
      setError("company_name", {
        message: "Please enter valid company name without special character",
      })
    }
    if (type === undefined)
      setError("type", { message: "Please enter Company Type" })
    if (role === undefined) setError("role", { message: "Please enter Role" })
    if (bank_code === "")
      setError("bank_code", {
        message: "Please enter your Bank Code",
      })
    if (juristic_id === "")
      setError("juristic_id", { message: "Please enter Juristic ID" })
    if (
      juristic_id !== undefined &&
      !RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setError("juristic_id", { message: "Please enter valid Juristic ID" })
    }
    if (statusOption === undefined)
      setError("statusOption", { message: "Please enter Status" })
    if (
      company_name !== undefined &&
      RegExp(COMPANY_NAME_REGEX).test(company_name) &&
      type !== undefined &&
      role !== undefined &&
      bank_code !== undefined &&
      juristic_id !== undefined &&
      statusOption !== undefined &&
      RegExp(JURISTIC_ID_REGEX).test(juristic_id)
    ) {
      setContentBody(FormWithConfirmPasswordBody.PASSWORD)
    }
  }

  const onSubmit: SubmitHandler<CreateParticipantAdminBody> = async (data) => {
    if (participantDetail) {
      let temp: any = { ...data }
      delete temp.type
      delete temp.role
      delete temp.bank
      delete temp.statusOption
      const response = await putParticipantDetailHook(
        participantDetail.pt_id,
        temp
      )
      getParticipantsHook()

      let alert: Alert = { visible: true }
      if (response.statusCode === 200) {
        alert.data = {
          type: AlertType.SUCCESS,
          description: "Update Participant Detail Complete",
          button: {
            label: "Close",
          },
        }
        reset()
        onClose()
        setContentBody(FormWithConfirmPasswordBody.FORM)
      } else {
        alert.data = {
          type: AlertType.ERROR,
          description: response.message,
          button: {
            label: "Close",
          },
        }
        if (response.statusCode === 400) {
          setContentBody(FormWithConfirmPasswordBody.PASSWORD)
        } else { 
          setContentBody(FormWithConfirmPasswordBody.FORM)
        }
      }
      setAlert(alert)
      setValue("password", "")
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.FORM
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading size="sm">Edit Participant</Heading>
                </Box>
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Company Name"
                      placeholder="Enter your company name"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your company name",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(COMPANY_NAME_REGEX).test(v) ||
                        "Please enter valid company name without special character",
                    },
                  }}
                />
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Company Type</Heading>
                  </Box>
                  <Controller
                    name="type"
                    render={({ field }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select Company Type"
                          options={optionTypes}
                          onChange={(value) => {
                            field.onChange(value)
                            setValue("role", undefined)
                            setValue("role_id", undefined)
                            setSelectedRole(null)
                          }}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                  {errors.type && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.type.message}
                    </Text>
                  )}
                </Box>
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Role</Heading>
                  </Box>
                  <Controller
                    name="role"
                    render={({ field }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select Role"
                          options={optionRoles}
                          value={selectedRole}
                          onChange={(value) => {
                            field.onChange(value)
                            setSelectedRole(value)
                          }}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                  {errors.role && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.role.message}
                    </Text>
                  )}
                </Box>
                <Box mb={5} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Bank</Heading>
                  </Box>
                  <Controller
                    name="bank"
                    render={({ field, fieldState: { error } }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select bank"
                          options={bankOptions()}
                          components={{ Option: IconOption }}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: "Please select Bank" }}
                  />
                  {errors.bank && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.bank.message}
                    </Text>
                  )}
                </Box>
                <Controller
                  control={control}
                  name="bank_code"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Bank Code"
                      placeholder="Enter your Bank Code"
                      error={error}
                      isDisabled={true}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your bank code",
                    },
                  }}
                />
                <Controller
                  control={control}
                  name="juristic_id"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      label="Juristic ID"
                      placeholder="Enter your Juristic ID"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your Juristic ID",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(JURISTIC_ID_REGEX).test(v) ||
                        "Please enter valid Juristic ID",
                    },
                  }}
                />
                <Box mb={3} w="100%">
                  <Box mb={2}>
                    <Heading size="sm">Status</Heading>
                  </Box>
                  <Controller
                    name="statusOption"
                    render={({ field, fieldState: { error } }) => (
                      <Box w="100%" mb={1}>
                        <Select
                          {...field}
                          placeholder="Please select status"
                          options={[
                            { value: "active", label: "Active" },
                            { value: "inactive", label: "Inactive" },
                          ]}
                          onChange={(value) => field.onChange(value)}
                          isSearchable={true}
                        />
                      </Box>
                    )}
                    control={control}
                    rules={{ required: "Please select status" }}
                  />
                  {errors.statusOption && (
                    <Text fontSize="sm" color={"error"}>
                      {errors.statusOption.message}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton variant="outline" onClick={() => onClose()}>
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="#0050C8"
                  backgroundColor="#0050C8"
                  color="white"
                  onClick={() => validateForm()}
                >
                  Update
                </PrimaryButton>
              </Flex>
            </Box>
            <Box
              display={
                contentBody === FormWithConfirmPasswordBody.PASSWORD
                  ? "block"
                  : "none"
              }
            >
              <Flex direction="column" justify="center" align="center">
                <Box mb={4}>
                  <Heading fontSize="24px" color="#353535">
                    Confirm
                  </Heading>
                </Box>
                <Text color="#353535" mb={4}>
                  Enter your password to confirm.
                </Text>
                <Box w="100%">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <PasswordInput
                        label="Password"
                        type="text"
                        {...field}
                        error={fieldState.error}
                        autoComplete="current-password"
                      />
                    )}
                    rules={{ required: "Please enter your password" }}
                  />
                </Box>
              </Flex>
              <Flex gap={4}>
                <PrimaryButton
                  variant="outline"
                  onClick={() =>
                    setContentBody(FormWithConfirmPasswordBody.FORM)
                  }
                >
                  Back
                </PrimaryButton>
                <PrimaryButton
                  colorScheme="green"
                  backgroundColor="green"
                  color="white"
                  type="submit"
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default UpdateParticipantDetailModal
