// ** React **
import { useState } from "react"

// ** Components **
import { Box } from "@chakra-ui/react"

// ** Icons **
import { CopyIcon } from "assets/icons"

function WalletClipBoard({ wallet_address }: { wallet_address: string }) {
  const [textToCopy, setTextToCopy] = useState(wallet_address)
  const [copySuccess, setCopySuccess] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopySuccess(true)
      setTimeout(() => {
        setCopySuccess(false)
      }, 2000)
    })
  }

  return (
    <div className="relative">
      <input
        type="text"
        value={textToCopy}
        onChange={(e) => setTextToCopy(e.target.value)}
        className="hidden"
      />
      <Box ml={2} cursor="pointer" onClick={copyToClipboard}>
        <CopyIcon />
      </Box>
      {copySuccess && (
        <div className="clipboard-success">Copied to clipboard!</div>
      )}
    </div>
  )
}

export default WalletClipBoard
