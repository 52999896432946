// ** React **
import { useEffect, useState } from "react"

// ** Libs **
import { Flex, Stack } from "@chakra-ui/react"
import _ from "lodash"
import reactToText from "react-to-text"

// ** Components **
import PrimaryButton from "common/components/PrimaryButton"
import BoxDataTable from "./BoxDataTable"
import HeaderTable, { FilterOption } from "./HeaderTable"
import ListDataTable, { TableColumn } from "./ListDataTable"
import PaginationTable from "./PaginationTable"

// ** Utils **
import { exportToCSV, downloadCSV } from "common/utils/transform"

export enum TableStyle {
  LIST,
  BOX,
}

type ContainerTableProps = {
  column: TableColumn[]
  data: object[]
  rawData: object[]
  totalCount: number
  currentPage: number
  pageSize: number
  filter?: FilterOption[]
  headerTable?: string
  searchPlaceholder?: string
  isSearchOnlyNumber?: boolean
  canExport?: boolean
  actions?: React.ReactElement[]
  hideIndex?: boolean
  style?: TableStyle
  onPageChange: (page: number) => void
  onViewSizeChange: (size: number) => void
  onSearchChange?: (value: string) => void
  onRowClick?: (data: any) => void
  isCustom?: boolean
}

const ContainerTable: React.FC<ContainerTableProps> = (props) => {
  const {
    column,
    data,
    rawData,
    totalCount,
    currentPage,
    pageSize,
    filter,
    headerTable,
    searchPlaceholder,
    isSearchOnlyNumber = false,
    canExport = false,
    hideIndex = false,
    actions,
    style = TableStyle.LIST,
    onPageChange,
    onViewSizeChange,
    onSearchChange,
    onRowClick,
    isCustom = false,
  } = props

  const [selectedData, setSelectedData] = useState<any[]>([])
  const [sortBy, setSortBy] = useState<string>("")
  const [sortOrder, setSortOrder] = useState<string>("")
  const [displayData, setDisplayData] = useState(data)

  useEffect(() => {
    if (sortBy === "") {
      setDisplayData(data)
    } else {
      handleSort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sortBy, sortOrder, currentPage, pageSize])

  const handleExport = () => {
    const content = exportToCSV(
      selectedData.length > 0 ? selectedData : data,
      []
    )
    downloadCSV(content, "data.csv")
  }

  const handleSort = async () => {
    let temp: object[] = rawData

    const cols: TableColumn[] = column.filter((m) => m.value === sortBy)

    if (sortBy !== "" && cols.length > 0) {
      const col = cols[0]
      if (col.customRender) {
        let promises: any[] = []
        temp.forEach((item: any, index: any) => {
          promises.push(
            new Promise((resolve) => {
              resolve({
                ...item,
                custom:
                  col.sortType === 1
                    ? parseFloat(
                        reactToText(
                          col && col.customRender
                            ? col.customRender(`${index}`, item)
                            : ""
                        ).replace(/,/g, "")
                      )
                    : reactToText(
                        col && col.customRender
                          ? col.customRender(`${index}`, item)
                          : ""
                      ),
              })
            })
          )
        })

        temp = await Promise.all(promises)

        if (sortOrder === "asc") {
          if (col.sortType === 1) {
            temp = temp.sort(
              (a: any, b: any) => parseFloat(a.custom) - parseFloat(b.custom)
            )
          } else {
            temp = temp.sort((a: any, b: any) => {
              const aValue = a.custom == null ? "" : a.custom.toString()
              const bValue = b.custom == null ? "" : b.custom.toString()
              return aValue.localeCompare(bValue)
            })
          }

          const chunkData = _.chunk(temp, pageSize)
          setDisplayData(chunkData[currentPage - 1] ?? [])
        } else {
          if (col.sortType === 1) {
            temp = temp.sort(
              (a: any, b: any) => parseFloat(b.custom) - parseFloat(a.custom)
            )
          } else {
            temp = temp.sort((a: any, b: any) => {
              const aValue = a.custom != null ? a.custom.toString() : ""
              const bValue = b.custom != null ? b.custom.toString() : ""
              return bValue.localeCompare(aValue)
            })
          }

          const chunkData = _.chunk(temp, pageSize)
          setDisplayData(chunkData[currentPage - 1] ?? [])
        }
      } else {
        if (sortOrder === "asc") {
          if (col.sortType === 1) {
            temp = temp.sort(
              (a: any, b: any) => parseFloat(a[sortBy]) - parseFloat(b[sortBy])
            )
          } else {
            temp = temp.sort((a: any, b: any) => {
              let aValue = a[sortBy] == null ? "" : a[sortBy].toString()
              let bValue = b[sortBy] == null ? "" : b[sortBy].toString()

              return aValue.localeCompare(bValue)
            })
          }

          const chunkData = _.chunk(temp, pageSize)
          setDisplayData(chunkData[currentPage - 1] ?? [])
        } else {
          if (col.sortType === 1) {
            temp = temp.sort(
              (a: any, b: any) => parseFloat(b[sortBy]) - parseFloat(a[sortBy])
            )
          } else {
            temp = temp.sort((a: any, b: any) => {
              let aValue = a[sortBy] ?? ""
              let bValue = b[sortBy] ?? ""

              if (typeof aValue === "string" && typeof bValue === "string") {
                return bValue.localeCompare(aValue)
              } else {
                return 0
              }
            })
          }

          const chunkData = _.chunk(temp, pageSize)
          setDisplayData(chunkData[currentPage - 1] ?? [])
        }
      }
    }
  }

  return (
    <Stack gap={4}>
      <HeaderTable
        headerTable={headerTable}
        onViewSizeChange={onViewSizeChange}
        onSearchChange={onSearchChange}
        searchPlaceholder={searchPlaceholder}
        pageSize={pageSize}
        filter={filter}
        actions={actions}
        isSearchOnlyNumber={isSearchOnlyNumber}
      />
      {style === TableStyle.LIST && (
        <ListDataTable
          column={column}
          data={sortBy !== "" ? displayData : data}
          hideIndex={hideIndex}
          currentPage={currentPage}
          pageSize={pageSize}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          sortBy={sortBy}
          setSortBy={(value: string) => setSortBy(value)}
          sortOrder={sortOrder}
          setSortOrder={(value: string) => setSortOrder(value)}
        />
      )}
      {style === TableStyle.BOX && (
        <BoxDataTable
          column={column}
          data={data}
          hideIndex={hideIndex}
          onRowClick={onRowClick}
          isCustom={isCustom}
        />
      )}
      {canExport && data.length > 0 && (
        <Flex justify="center">
          <PrimaryButton
            onClick={selectedData.length > 0 ? handleExport : () => {}}
            bgColor={selectedData.length > 0 ? "blue" : "grey400"}
            colorScheme="white"
            w="250px"
          >
            Export to CSV
          </PrimaryButton>
        </Flex>
      )}
      <PaginationTable
        onPageChange={onPageChange}
        totalCount={totalCount}
        currentPage={currentPage}
        pageSize={pageSize}
      ></PaginationTable>
    </Stack>
  )
}

export default ContainerTable
