// ** React **
import React, { useEffect } from "react"

// ** Libs **
import { Box, Card, Center, Flex, Image, Spacer, Text } from "@chakra-ui/react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

// ** Components **
import BackButton from "common/components/BackButton"
import PrimaryButton from "common/components/PrimaryButton"
import TextInput from "common/components/TextInput"
import ChangePassword from "modules/profile/ChangePassword"

// ** Hooks **
import useAppHook from "hooks/useAppHook"
import useAuthHook from "hooks/useAuthHook"

// ** Types **
import type { UpdateUserProfileBody } from "types/User"
import { Alert, AlertType } from "types/Alert"

// ** Constants **
import { PHONE_REGEX } from "constants/AppStrings"

const EditUserProfilePage = () => {
  const { setAlert } = useAppHook()

  const { putUpdateUserProfileHook, postUploadAvatarHook, user } = useAuthHook()

  const { control, handleSubmit, reset } = useForm<UpdateUserProfileBody>({
    mode: "onChange",
  })

  useEffect(() => {
    if (user) {
      reset({
        firstname: user.firstname,
        lastname: user.lastname,
        contact: user.contact,
      })
    }
  }, [user, reset])

  const onSubmit: SubmitHandler<UpdateUserProfileBody> = async (data) => {
    const response = await putUpdateUserProfileHook(data)
    let alert: Alert = { visible: true }
    if (response.statusCode === 200) {
      alert.data = {
        type: AlertType.SUCCESS,
        description: "Update User Profile Complete",
        button: {
          label: "Close",
        },
      }
    } else {
      alert.data = {
        type: AlertType.ERROR,
        description: response.message,
        button: {
          label: "Close",
        },
      }
    }
    setAlert(alert)
  }

  const onClickEditPhoto = () => {
    const node = document.getElementById("avatar")
    node?.click()
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]

      if (!file.type.startsWith("image/")) {
        alert("Please select an image file.")
        return
      }

      postUploadAvatarHook("avatar", file)
    }
  }

  return (
    <Card pt={2} mb={16}>
      <Flex direction="column" gap={8} pb={16}>
        <Box>
          <BackButton />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex>
            <Box flex="0 0 275px">
              <Center>
                {user?.avatar ? (
                  <Image
                    borderRadius="30px"
                    objectFit="contain"
                    boxSize="225px"
                    src={process.env.REACT_APP_BASE_URL + "/" + user.avatar}
                  />
                ) : (
                  <Box
                    w={225}
                    h={225}
                    bgColor="grey400"
                    borderRadius={40}
                  ></Box>
                )}
              </Center>
              <Center mt={2}>
                <Text
                  fontWeight="semibold"
                  color="valueText"
                  fontSize="sm"
                  cursor="pointer"
                  onClick={onClickEditPhoto}
                >
                  Edit Photo
                </Text>
                <input
                  type="file"
                  onChange={handleImageChange}
                  hidden
                  id="avatar"
                />
              </Center>
            </Box>
            <Box flex="1 1 auto" px={8}>
              <Flex direction="column" gap={3}>
                <Flex gap={3}>
                  <Controller
                    control={control}
                    name="firstname"
                    render={({ field, fieldState: { error } }) => (
                      <TextInput
                        {...field}
                        label="First Name"
                        placeholder="Enter your First Name"
                        error={error}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="lastname"
                    render={({ field, fieldState: { error } }) => (
                      <TextInput
                        {...field}
                        label="Last Name"
                        placeholder="Enter your Last Name"
                        error={error}
                      />
                    )}
                  />
                </Flex>
                <Controller
                  control={control}
                  name="contact"
                  render={({ field, fieldState: { error } }) => (
                    <TextInput
                      {...field}
                      id="tel_number1"
                      type="tel"
                      label="Tel. Number 1"
                      placeholder="Enter your Tel. Number 1"
                      error={error}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter your Tel. Number 1",
                    },
                    validate: {
                      format: (v: string) =>
                        RegExp(PHONE_REGEX).test(v) ||
                        "Please enter valid phone number",
                    },
                  }}
                />
                <Flex gap={3}>
                  <TextInput
                    label="Role"
                    value={user?.pt_role}
                    isDisabled={true}
                    labelSmall
                  />
                  <TextInput
                    label="Email"
                    value={user?.email}
                    isDisabled={true}
                    labelSmall
                  />
                </Flex>
                <Flex mt={8}>
                  <Spacer />
                  <PrimaryButton
                    size="md"
                    w="300px"
                    variant="outline"
                    type="submit"
                  >
                    Save Change
                  </PrimaryButton>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </form>
        <ChangePassword />
      </Flex>
    </Card>
  )
}

export default EditUserProfilePage
