import { AxiosResponse } from "axios"
import $axios from "./instance"
import { GetCreditSponsorResponse } from "api/types/getCreditSponsorType"

const getCreditBalance = async (
  id: string
): Promise<
  AxiosResponse<GetCreditSponsorResponse>
> => {
  const response = await $axios().get("/credit/balance/" + id)
  return response
}

export default getCreditBalance
