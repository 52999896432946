import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetOwnInvoicesHistoryResponse } from "./types/getOwnInvoicesHistoryType";

const getOwnInvoicesHistory = async (): Promise<
  AxiosResponse<GetOwnInvoicesHistoryResponse>
> => {
  const response = await $axios().get("/own_invoices_history");
  return response;
};

export default getOwnInvoicesHistory;
