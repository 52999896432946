import { AxiosResponse } from "axios";
import $axios from "./instance";
import { GetCreditTransactionResponse } from "./types/getCreditTransactionType";

const getCreditTransaction = async (): Promise<
  AxiosResponse<GetCreditTransactionResponse>
> => {
  const response = await $axios().get("/credit/transaction");
  return response;
};

export default getCreditTransaction;
