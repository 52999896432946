import { Box, Flex, Grid, GridItem, HStack, Heading } from "@chakra-ui/react";
import { FC } from "react";
import { MyWallet } from "../../../types/MyWallet";
import {
  abbreviateHexString,
  numberFormatForCurrency,
} from "../../utils/transform";
import PrimaryButton from "../PrimaryButton";
import { WithdrawInputValue } from "./WithdrawModalContainer";

type WithdrawReviewProps = {
  withdrawValue?: WithdrawInputValue;
  currentWallet: MyWallet;
  unit: string;
  onClickConfirm: VoidFunction;
  onClickBack: VoidFunction;
};

const WithdrawReview: FC<WithdrawReviewProps> = (props) => {
  const { withdrawValue, currentWallet, unit, onClickConfirm, onClickBack } =
    props;
  if (!withdrawValue) return <></>;

  return (
    <Flex direction="column" justify="center" align="center" gap={10}>
      <Box>
        <Heading size="md">Review Withdraw</Heading>
      </Box>
      {/* Review */}
      <Box w="100%">
        <Grid templateColumns="115px 1fr" gap={4}>
          {/* From */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            From
          </GridItem>
          <GridItem></GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Wallet address
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {`${currentWallet?.company_name} - ${
              currentWallet.nickname
            } (${abbreviateHexString(currentWallet.wallet_address)})`}
          </GridItem>
          {/* To */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            To
          </GridItem>
          <GridItem></GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Bank Name
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {currentWallet.bank_name}
          </GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Bank Account
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {currentWallet.bank_account_number}
          </GridItem>
          {/* Amount */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Amount
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            {numberFormatForCurrency(withdrawValue.amount)} {unit}
          </GridItem>
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Fee(optional)
          </GridItem>
          <GridItem
            textAlign="right"
            color="#353535"
            fontSize="small"
            fontWeight="700"
          >
            0
          </GridItem>
          {/* Total Amount */}
          <GridItem color="#6D6D6D" fontSize="small" fontWeight="700">
            Received Amount
          </GridItem>
          <GridItem
            textAlign="right"
            color="primary"
            fontSize="small"
            fontWeight="700"
          >
            {numberFormatForCurrency(withdrawValue.amount)} THB
          </GridItem>
        </Grid>
      </Box>
      {/* Button */}
      <Box w="100%">
        <HStack>
          <PrimaryButton variant="outline" onClick={onClickBack}>
            Back
          </PrimaryButton>
          <PrimaryButton
            colorScheme="#51BC68"
            backgroundColor="#51BC68"
            color="white"
            onClick={onClickConfirm}
          >
            Confirm
          </PrimaryButton>
        </HStack>
      </Box>
    </Flex>
  );
};

export default WithdrawReview;
