import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback } from "react"
import { MyWallet } from "../../types/MyWallet"
import { abbreviateHexString } from "../utils/transform"
import EditNicknameWalletModal from "./EditNicknameWalletModal"
import { SettingIcon, WalletCreditCardIcon } from "./Icon"

type WalletSelectProps = {
  wallets: MyWallet[]
  onChange: (value: MyWallet) => void
  value: MyWallet
}

const WalletSelect: FC<WalletSelectProps> = (props) => {
  const { wallets, onChange, value } = props
  const editNicknameModalAction = useDisclosure()

  const handleOnSelect = useCallback(
    (val: MyWallet) => {
      onChange(val)
    },
    [onChange]
  )

  const walletWithoutSelected = wallets.filter((m) => m !== value)

  return (
    <>
      <Box position="relative">
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon color="primary" fontSize="2xl" />}
            bgColor="white"
            border="1px solid"
            borderRadius={8}
            borderColor="primary"
            h="auto"
            w="100%"
            _hover={{ bg: "white" }}
            _focus={{ boxShadow: "white" }}
            _expanded={{ bg: "white" }}
          >
            <Flex py={2} align="center" w="100%">
              <Box flex={"0 0 32px"}>
                <WalletCreditCardIcon boxSize={8} color={"primary"} />
              </Box>
              <Box flex={"1 1 auto"}>
                <Stack overflow="hidden" gap={1}>
                  <Box>
                    <Text fontSize="sm" color="primary">{`${
                      value.company_name
                    }${value.nickname ? " - " + value.nickname : ""}`}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" color="primary">
                      {abbreviateHexString(value.wallet_address)}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </Flex>
          </MenuButton>
          {walletWithoutSelected.length > 0 && (
            <MenuList>
              {walletWithoutSelected.map((m, i) => (
                <MenuItem key={i} onClick={() => handleOnSelect(m)}>
                  <Text fontSize="sm" color="#212529">
                    {value.company_name} - {m.nickname} (
                    {abbreviateHexString(m.wallet_address)})
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          )}
        </Menu>
        <Box flex={"0 0 24px"} position="absolute" top={3} right={9} cursor="pointer">
          <SettingIcon
            onClick={(e) => {
              editNicknameModalAction.onOpen()
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        </Box>
      </Box>
      {value && <EditNicknameWalletModal {...editNicknameModalAction} defaultWallet={value} />}
    </>
  )
}

export default WalletSelect
